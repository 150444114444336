import React from 'react';
import {
  Alert,
  Button,
} from 'reactstrap';
import { NumericFormat } from 'react-number-format';
import { MdEdit } from 'react-icons/md';
import { usePatchSectionVehicleMels } from 'apiHooks/OrganizationSections.Hook';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import type { ISectionMelWithName } from 'types/IOrganizationSections';
import OrganizationSectionsSelect from 'components/fields/OrganizationSectionsSelect';
import type { IOrganization } from 'types/IOrganization';

interface IEditVehicleTypeMel {
  vehicleTypeMels: ISectionMelWithName[],
  originalMel: ISectionMelWithName,
  orgDetails: IOrganization,
}

function EditSectionMelModal({ vehicleTypeMels, originalMel, orgDetails }: IEditVehicleTypeMel) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [sectionMel, setSectionMel] = React.useState<ISectionMelWithName>(originalMel);
  const patchSectionMels = usePatchSectionVehicleMels();

  const toggle = () => setModalOpen(!modalOpen);

  const resetState = () => setSectionMel(originalMel);

  const submitEditVehicleTypes = async () => {
    const isVehicleTypeReplaced = sectionMel.vehicleType !== originalMel.vehicleType;

    const melSection = orgDetails.sections.find((s) => s.sectionId === sectionMel.sectionId);

    if (!melSection) return;

    await patchSectionMels(
      (sectionMel.sectionId === originalMel.sectionId)
        ? {
          sectionId: sectionMel.sectionId,
          sectionName: melSection.sectionName,
          mel: [
            // eslint-disable-next-line unicorn/no-null
            ...(isVehicleTypeReplaced ? [{ vehicleType: originalMel.vehicleType }] : []),
            {
              vehicleType: originalMel.vehicleType || '',
              mel: sectionMel.mel || 0,
            },
          ],
        }
        : {
          sectionId: originalMel.sectionId,
          sectionName: originalMel.sectionName,
          mel: [
            { vehicleType: originalMel.vehicleType },
          ],
        },
    );
  };

  const hasExistingMel = () => (
    sectionMel.sectionId !== originalMel.sectionId
    && vehicleTypeMels.some((existingMel) => (
      existingMel.sectionId === sectionMel.sectionId
    ))
  );

  const isValid = () => !!(
    sectionMel.vehicleType
    && sectionMel.mel
    && !hasExistingMel()
  );

  return (
    <>
      <Button onClick={toggle} className="py-0 pe-0" color="link"><MdEdit /></Button>
      <RhinoModal
        title={`Modify Section MELs for ${originalMel.vehicleType} vehicles`}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        onSubmit={submitEditVehicleTypes}
        canSubmit={isValid()}
        onClosed={resetState}
      >
        <div className="d-flex flex-column">
          <div className="d-flex">
            <OrganizationSectionsSelect
              id="newMelSection"
              onValueChange={(sectionId: number) => {
                setSectionMel({
                  ...sectionMel,
                  sectionId,
                });
              }}
              defaultValue={originalMel.sectionId}
            />
            <div className="form-floating">
              <NumericFormat
                className="form-control"
                decimalScale={0}
                min={1}
                allowNegative={false}
                id="inputMel"
                defaultValue={sectionMel.mel || null}
                onValueChange={({ floatValue }) => {
                  setSectionMel({
                    ...sectionMel,
                    ...floatValue && { mel: floatValue },
                  });
                }}
                placeholder=" "
              />
              <label htmlFor="inputMel">
                MEL
              </label>
            </div>
          </div>
          {
            hasExistingMel()
            && <Alert color="danger">Section MEL(s) exists for this vehicle type</Alert>
          }
        </div>
      </RhinoModal>
    </>
  );
}

export default EditSectionMelModal;
