import type { Dispatch, SetStateAction } from 'react';
import React, { useState } from 'react';
import {
  Alert,
  Button,
} from 'reactstrap';

// import { userOrganizationName } from 'utils/Helpers';
import { MAX_SECTION_NAME_LENGTH } from 'utils/Constants';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import { useGetVehicleTypes, usePostVehicleType } from 'apiHooks/VehicleTypes.Hook';

interface Props {
  setNewVehicleTypeName: Dispatch<SetStateAction<string>>,
}

function AddNewVehicleTypeModal(props: Props) {
  const { setNewVehicleTypeName } = props;

  const [modalOpen, setModalOpen] = React.useState(false);
  const [tmpNewVehicleTypeName, setTmpNewVehicleTypeName] = useState('');
  const postNewVehicleType = usePostVehicleType();

  const { data: vehicleTypes } = useGetVehicleTypes();

  const toggle = () => setModalOpen(!modalOpen);

  const resetState = () => setTmpNewVehicleTypeName('');

  const onSubmit = () => (
    postNewVehicleType(tmpNewVehicleTypeName.trim())
      .then(() => {
        // sets new section name for use in parent
        setNewVehicleTypeName(tmpNewVehicleTypeName);
      })
  );

  // const typeExistsInOrg = () => organization.sections.some(
  //   (orgSection) => orgSection.toLowerCase() === tmpNewVehicleTypeName.toLowerCase(),
  // );

  const typeExistsInOrg = vehicleTypes?.some(
    (vehicleType) => vehicleType.toLowerCase() === tmpNewVehicleTypeName.toLowerCase(),
  );

  const nameTooLong = () => tmpNewVehicleTypeName.length > MAX_SECTION_NAME_LENGTH;

  const isValid = () => (
    !!tmpNewVehicleTypeName && !typeExistsInOrg && !nameTooLong()
  );

  return (
    <>
      <Button onClick={toggle}>
        New Vehicle Type
      </Button>
      <RhinoModal
        // title={`Name of New Vehicle Type for ${userOrganizationName()}`}
        title="Name of New Vehicle Type"
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={isValid()}
        onSubmit={onSubmit}
        onClosed={resetState}
      >
        <div className="form-floating">
          <input
            className="form-control"
            id="inputSectionName"
            onChange={(event) => setTmpNewVehicleTypeName(event.target.value.trim())}
            placeholder=" "
            maxLength={MAX_SECTION_NAME_LENGTH}
          />
          <label htmlFor="inputSectionName">
            New Vehicle Type
          </label>
        </div>
        {
          tmpNewVehicleTypeName.length > 0 && typeExistsInOrg
          && <Alert color="danger">Another vehicle type with this name exists for this organization.</Alert>
        }
        {
          nameTooLong() && <Alert color="danger">{`Name must be less than ${MAX_SECTION_NAME_LENGTH} characters in length.`}</Alert>
        }
      </RhinoModal>
    </>
  );
}

export default AddNewVehicleTypeModal;
