import React from 'react';
import {
  FaCheckCircle,
  FaTimesCircle,
  FaCircle,
} from 'react-icons/fa';
import {
  BsFillTriangleFill,
  BsFillExclamationTriangleFill,
  BsWrenchAdjustableCircleFill,
} from 'react-icons/bs';

import { VEHICLE_STATUS_MAP } from 'utils/Constants';
import type { VehicleStatus } from 'types/common';
import type { IconBaseProps } from 'react-icons';

function VehicleStatusIcon({
  status,
  size = '2.5rem',
}: {
  status: VehicleStatus | null | undefined;
  size?: IconBaseProps['size'];
}) {
  if (status !== VEHICLE_STATUS_MAP.WARNING) {
    return (
      <div className="d-flex h-100 align-items-center justify-content-center">
        <div className="position-absolute">
          {
            status === VEHICLE_STATUS_MAP.VALID
            && <FaCheckCircle size={size} className="text-success" />
          }
          {
            status === VEHICLE_STATUS_MAP.INVALID
            && <FaTimesCircle size={size} className="text-danger" />
          }
          {
            status === VEHICLE_STATUS_MAP.IN_MX
            && <BsWrenchAdjustableCircleFill size={size} className="text-secondary" />
          }
        </div>
        <div>
          <FaCircle size={size} className="text-white" />
        </div>

      </div>
    );
  }

  return (
    <div className="d-flex h-100 align-items-center justify-content-center">
      <div className="position-absolute">
        <BsFillExclamationTriangleFill size={size} className="text-warning" />
      </div>
      <div>
        <BsFillTriangleFill size={size} className="text-white" />
      </div>
    </div>
  );
}

export default VehicleStatusIcon;
