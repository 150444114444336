import type { ICellRendererParams } from '@ag-grid-community/core';
import { useGetUsers } from 'apiHooks/Users.Hook';

function AgGridUserCellRenderer(params: ICellRendererParams) {
  const { data: users, isLoading } = useGetUsers();

  if (isLoading) {
    return 'Loading...';
  }

  const user = users?.find((u) => u.userId === params.value);
  if (!users || !user) {
    return undefined;
  }

  return `${user.firstName} ${user.lastName}`;
}

export default AgGridUserCellRenderer;
