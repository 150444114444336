import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import type { ICellRendererParams } from '@ag-grid-community/core';

import { WORKFLOW_RULES } from 'utils/Constants';

function AgGridWorkflowRuleResultStatusCellRenderer({ data }: ICellRendererParams) {
  const {
    type,
    result,
    overview,
    units,
  } = data;
  if (type === WORKFLOW_RULES.THRESHOLD) {
    return `${result.value}/${overview.fields.absMax}`;
  }

  if (type === WORKFLOW_RULES.PASS_FAIL) {
    if (result.isPassing) {
      return <FaCheck className="text-success" />;
    }

    return <FaTimes className="text-danger" />;
  }

  if (type === WORKFLOW_RULES.TARGET) {
    return `${result.value} ${units}`;
  }

  return result.value;
}

export default AgGridWorkflowRuleResultStatusCellRenderer;
