import { usePostNewVehicleMxScheduled } from 'apiHooks/Vehicles.Hook';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Button, Input } from 'reactstrap';

const mxTypeRegEx = /^[\d ,.A-Za-z-]+$/;

export default function CreateScheduledModal({ regNumber }: { regNumber: string; }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [date, setDate] = useState<Date | null>(null);
  const [mxType, setMxType] = useState('');

  const postNewScheduled = usePostNewVehicleMxScheduled(regNumber);

  const resetInputStates = () => {
    setDate(null);
    setMxType('');
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const newText = e.target.value;
    if (mxTypeRegEx.test(newText)) {
      setMxType(newText ?? undefined);
    }
  };

  const handleSubmit = async () => {
    if (!date) return;
    await postNewScheduled(date, mxType)
      .then(() => {
        setModalOpen(false);
        resetInputStates();
      });
  };

  return (
    <>
      <Button
        type="button"
        onClick={() => setModalOpen(true)}
      >
        Schedule Maintenance
      </Button>

      <RhinoModal
        title={`Schedule Maintenance for ${regNumber}`}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        onSubmit={handleSubmit}
        canSubmit={!!date && !!mxType}
        bodyClassName="d-grid gap-3 text-secondary fw-bold"
        onClosed={resetInputStates}
      >

        <div className="d-flex align-items-center">
          <span className="px-2 col-2 text-end">Date:</span>
          <ReactDatePicker
            selected={date}
            onChange={setDate}
            showIcon
          />
        </div>
        <div className="d-flex align-items-center">
          <span className="px-2 text-nowrap">Mx Type:</span>
          <Input
            value={mxType}
            onChange={onChange}
            onSubmit={handleSubmit}
            maxLength={30}
          />
        </div>
      </RhinoModal>
    </>
  );
}
