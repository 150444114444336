// adapted from: https://github.com/herrmannplatz/axios-keycloak
import axios, {
  type AxiosRequestConfig,
  type AxiosRequestHeaders,
  AxiosHeaders,
} from 'axios';
import Keycloak from 'keycloak-js';
import type { IKeycloakJwtToken, ITokenUserInfo } from 'types/IJwt';
import { ROLE_LIST } from 'utils/Constants';

import KEYCLOAK_CONFIG from 'utils/constants/KeycloakConfig';

interface AdaptAxiosRequestConfig extends AxiosRequestConfig {
  headers: AxiosRequestHeaders
}

class AxiosKeycloakClass extends Keycloak {
  createAxiosInstance(createConfig: AxiosRequestConfig) {
    const instance = axios.create(createConfig);

    instance.interceptors.request.use(
      async (config): Promise<AdaptAxiosRequestConfig> => {
        await this.updateToken(5).catch(() => {
          this.login().catch(() => {});
        });

        if (config.headers) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${this.token}`;
        } else {
          // eslint-disable-next-line no-param-reassign
          config.headers = new AxiosHeaders({ Authorization: `Bearer ${this.token}` });
        }
        return config;
      },
      (error) => Promise.reject(error),
    );

    return instance;
  }

  static get axios() {
    return axios;
  }
}

const keycloakInstance = new AxiosKeycloakClass(KEYCLOAK_CONFIG);

const userInfo = (): ITokenUserInfo | undefined => {
  if (!keycloakInstance.userInfo) {
    return undefined;
  }

  /* eslint-disable @typescript-eslint/naming-convention */
  const {
    email,
    family_name,
    given_name,
    groups,
    name,
    org_id,
    preferred_username,
    sub,
  } = keycloakInstance.userInfo as IKeycloakJwtToken;
  /* eslint-enable @typescript-eslint/naming-convention */

  // TODO: find a better way to do this.
  const role = ROLE_LIST.find((currentRole) => groups.includes(`/rhino/${currentRole}`)) || null;

  return {
    email,
    familyName: family_name,
    givenName: given_name,
    isAdmin: groups.some((group) => group.includes('/admin')),
    groups,
    role,
    fullName: name,
    orgId: org_id,
    username: preferred_username,
    userId: sub,
  };
};

export {
  keycloakInstance,
  userInfo,
};
