import React from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  type LayoutPosition,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';

import type { IFmcResult, IFmcResults } from 'types/IOverview';

import styles from './Overview.module.css';

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const chartOptions = (title: string) => ({
  plugins: {
    legend: {
      position: ('bottom' as LayoutPosition),
    },
    title: {
      display: true,
      text: title,
    },
  },
});

const formatChartData = ({
  fmc,
  warning,
  inOp,
  inMx,
}: IFmcResult) => ({
  labels: ['FMC', 'Warning', 'Inoperable', 'In Mx'],
  datasets: [
    {
      data: [fmc.value, warning.value, inOp.value, inMx.value],
      labels: [fmc.pprint, warning.pprint, inOp.pprint, inMx.pprint],
      backgroundColor: [
        'rgba(75, 192, 192, 0.6)',
        'rgba(255, 206, 86, 0.6)',
        'rgba(255, 99, 132, 0.6)',
        'rgba(100,100,100,0.6)',
      ],
      borderWidth: 1,
    },
  ],
});

interface OverviewHeaderParams {
  fmcData: IFmcResults | undefined,
  sectionName?: string,
}

function OverviewHeader({ fmcData, sectionName }: OverviewHeaderParams) {
  if (!fmcData) return null;

  const { total, wrm, other } = fmcData;

  const noVehiclesSuffix = sectionName
    ? ` for ${sectionName}.`
    : '.';

  return (
    <div className={`shadow d-flex align-items-center p-3 ${styles['overview-header']}`}>
      {
        total
          ? (
            <div className="col-4 d-grid justify-content-center h-100">
              <Pie
                options={chartOptions(['Status of All', sectionName || '', 'Vehicles'].filter(Boolean).join((' ')))}
                data={formatChartData(total)}
              />
            </div>
          )
          : (
            <h6 className="col-4 text-center opacity-25 text-muted">
              {`No vehicles to report${noVehiclesSuffix}`}
            </h6>
          )
      }
      {
        wrm
          ? (
            <div className="col-4 d-grid justify-content-center h-100">
              <Pie
                options={chartOptions(['Status of WRM', sectionName || '', 'Vehicles'].filter(Boolean).join((' ')))}
                data={formatChartData(wrm)}
              />
            </div>
          )
          : (
            <h6 className="col-4 text-center opacity-25 text-muted">
              {`No WRM vehicles to report${noVehiclesSuffix}`}
            </h6>
          )
      }
      {
        other
          ? (
            <div className="col-4 d-grid justify-content-center h-100">
              <Pie
                options={chartOptions(['Status of Other', sectionName || '', 'Vehicles'].filter(Boolean).join((' ')))}
                data={formatChartData(other)}
              />
            </div>
          )
          : (
            <h6 className="col-4 text-center opacity-25 text-muted">
              {`No other vehicles to report${noVehiclesSuffix}`}
            </h6>
          )
      }
    </div>
  );
}

export default OverviewHeader;
