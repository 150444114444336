import type { IUserNameResponse } from 'types/IUser';

const AgGridUserNameResponseComparator = (valA: IUserNameResponse, valB: IUserNameResponse) => {
  if (valA.lastName !== valB.lastName) {
    return ((valA.lastName || '') > (valB.lastName || '') ? -1 : 1);
  }

  return ((valA.firstName || '') > (valB.firstName || '') ? -1 : 1);
};

export default AgGridUserNameResponseComparator;
