import React from 'react';
import { MdDirectionsCarFilled } from 'react-icons/md';

import { type IRoute } from 'types/IRoute';
import VehicleDetails from 'views/Vehicle/VehicleDetails';
import InspectionResults from 'views/Vehicle/InspectionResults';

const vehicleRoute: [IRoute, IRoute] = [
  {
    component: <VehicleDetails />,
    icon: <MdDirectionsCarFilled />,
    label: 'Vehicle Details',
    path: '/Vehicle/:regNumber',
    hideSidebarLink: true,
  }, {
    component: <InspectionResults />,
    icon: <MdDirectionsCarFilled />,
    label: 'Vehicle Inspections',
    path: '/Vehicle/:regNumber/inspection/:inspectionId',
    hideSidebarLink: true,
  },
];

export default vehicleRoute;
