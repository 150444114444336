import type { ColumnState } from '@ag-grid-community/core';
import { atomWithReset } from 'jotai/utils';

const InspectionWorkflowsColumnsAtom = atomWithReset<ColumnState[] | undefined>(undefined);

const InspectionWorkflowsInitialColumnsAtom = atomWithReset<ColumnState[] | undefined>(undefined);

export {
  InspectionWorkflowsColumnsAtom,
  InspectionWorkflowsInitialColumnsAtom,
};
