import type { AxiosResponse } from 'axios';

import { keycloakInstance } from './keycloakAxios.Helper';

const authHttp = keycloakInstance.createAxiosInstance({});

/**
 * Sugar syntax to resolve axios response.
 *
 * @param res
 * @returns
 */
const resolveData = <T>(res: AxiosResponse<T>) => res.data;

/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Sugar syntax to resolve axios error.
 *
 * @param error
 * @returns
 */
const resolveError = (error: any) => {
  const { response } = error || {};
  if (response) {
    const { status } = response;
    if (status === 403) {
      window.location.href = '/';
      window.location.reload();
    }
  }

  return error;
};

/* eslint-disable unicorn/no-useless-promise-resolve-reject */
const authHttpGet = <T = any>(url: string): Promise<T> => (
  authHttp.get<T>(url)
    .then(resolveData<T>)
    .catch((error) => Promise.reject(resolveError(error)))
);
const authHttpPost = <T = void>(url: string, data?: any): Promise<T> => (
  authHttp.post<T>(url, data)
    .then(resolveData<T>)
    .catch((error) => Promise.reject(resolveError(error)))
);
const authHttpPatch = <T = void>(url: string, data?: any): Promise<T> => (
  authHttp.patch<T>(url, data)
    .then(resolveData<T>)
    .catch((error) => Promise.reject(resolveError(error)))
);
const authHttpDelete = <T = void>(url: string): Promise<T> => (
  authHttp.delete<T>(url)
    .then(resolveData<T>)
    .catch((error) => Promise.reject(resolveError(error)))
);
/* eslint-enable unicorn/no-useless-promise-resolve-reject */
/* eslint-enable @typescript-eslint/no-explicit-any */

export {
  authHttp,
  authHttpGet,
  authHttpPost,
  authHttpPatch,
  authHttpDelete,
};
