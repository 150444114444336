const AG_GRID_USER_ADMIN_FIELDS = [
  'firstName',
  'lastName',
  'email',
  'role',
  'sectionId',
  'isAdmin',
  'active',
  'actions',
] as const;

type UserAdminField = typeof AG_GRID_USER_ADMIN_FIELDS[number];

const AG_GRID_USER_ADMIN_HEADER_MAP: Record<UserAdminField, string> = {
  firstName: 'Given Name', // cSpell: disable-line
  lastName: 'Family Name',
  email: 'Email',
  role: 'Role',
  sectionId: 'Section',
  isAdmin: 'Admin',
  active: 'Active',
  actions: '',
};

export {
  AG_GRID_USER_ADMIN_FIELDS,
  type UserAdminField,
  AG_GRID_USER_ADMIN_HEADER_MAP,
};
