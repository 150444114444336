import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { type ICellRendererParams } from '@ag-grid-community/core';
import { MdEdit, MdClose } from 'react-icons/md';
import { FaUndo } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';
import { useDeleteWorkflow, useUndeleteWorkflow } from 'apiHooks/WorkFlows.Hook';
import type { IWorkflow, IWorkflowDetails } from 'types/IWorkflows';

function DeleteWorkflowConfirmation({ workflow } : { workflow: IWorkflow }) {
  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
  const toggleConfirmation = () => setConfirmationModalOpen(!confirmationModalOpen);
  const deleteWorkflow = useDeleteWorkflow();

  const confirmDelete = () => {
    deleteWorkflow(workflow.workflowId)
      .then(toggleConfirmation)
      .catch(() => {});
  };

  return (
    <>
      <Button
        className="ml-2"
        color="link"
        onClick={toggleConfirmation}
      >
        <MdClose className="text-danger" />
      </Button>
      <Modal
        isOpen={confirmationModalOpen}
        toggle={toggleConfirmation}
      >
        <RhinoModalHeader toggle={toggleConfirmation}>
          Confirm Setting Workflow Inactive
        </RhinoModalHeader>
        <ModalBody className="d-flex flex-column">
          <h5 className="text-center py-2 fw-bold">
            Are you sure you want to set workflow &quot;
            {workflow.title}
            &quot; be inactive?
          </h5>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button onClick={toggleConfirmation}>
            Cancel
          </Button>
          <Button color="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

function AgGridWorkflowListActionsCellRenderer({ data }: ICellRendererParams<IWorkflowDetails>) {
  const navigate = useNavigate();
  const undeleteWorkflow = useUndeleteWorkflow();

  if (String(data?.title).startsWith('AF-1800 Full ')) {
    return (
      <div>
        <Button
          color="link"
          onClick={() => navigate(`/InspectionWorkflow/${data?.workflowId}`)}
        >
          <MdEdit />
        </Button>
      </div>
    );
  }

  return (
    <div>
      <Button
        color="link"
        onClick={() => navigate(`/InspectionWorkflow/${data?.workflowId}`)}
      >
        <MdEdit />
      </Button>
      {
        data?.active
          ? (
            <DeleteWorkflowConfirmation workflow={data} />
          )
          : data?.workflowId && (
            <Button
              className="ml-2"
              color="link"
              onClick={() => undeleteWorkflow(data.workflowId)}
            >
              <FaUndo />
            </Button>
          )
      }

    </div>
  );
}

export default AgGridWorkflowListActionsCellRenderer;
