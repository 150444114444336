import { useAtom } from 'jotai';
import React from 'react';
import type {
  IColumnFilter,
  IColumnFilterText,
  IColumnFilterSet,
  IColumnFilterBoolean,
  ICustomFilter,
  IColumnFilterDateTime,
  IColumnFilterNumberRange,
} from 'types/IFilter';
import { FILTER_TYPES } from 'utils/Constants';
import type { atomWithReset } from 'jotai/utils';
import BooleanFilter from './BooleanFilter';
import SetFilter from './SetFilter';
import TextFilter from './TextFilter';
import DateTimeFilter from './DateTimeFilter';
import NumberRangeFilter from './NumberRangeFilter';

interface IFilterOption {
  col: keyof ICustomFilter;
  filterAtom: ReturnType<typeof atomWithReset<ICustomFilter>>;
  headerMap: Record<keyof ICustomFilter, string>;
}

function FilterOption({ col, filterAtom, headerMap }: IFilterOption) {
  const [currentFilter, setCurrentFilter] = useAtom(filterAtom);

  /**
   *
   * @param isHidden
   */
  const updateVisibility = (isHidden: boolean) => {
    if (currentFilter && currentFilter[col]) {
      setCurrentFilter({
        ...currentFilter,
        [col]: {
          ...currentFilter[col] as IColumnFilter,
          isHidden,
        },
      });
    }
  };

  return (
    <tr key={col}>
      <td className="text-nowrap d-flex flex-column align-items-end px-2 py-1">
        <label className="d-flex align-items-center gap-1">
          <span>{headerMap[col]}</span>
          <input
            type="checkbox"
            checked={!currentFilter[col]?.isHidden}
            onChange={(e) => updateVisibility(!e.target.checked)}
          />
        </label>
      </td>
      <td>
        {
          currentFilter[col]?.filterType === FILTER_TYPES.TEXT
          && (
            <TextFilter
              textFilter={currentFilter[col] as IColumnFilterText}
              field={col}
              filterAtom={filterAtom}
            />
          )
        }
        {
          currentFilter[col]?.filterType === FILTER_TYPES.SET
          && (
            <SetFilter
              setFilter={currentFilter[col] as IColumnFilterSet}
              field={col}
              filterAtom={filterAtom}
            />
          )
        }
        {
          currentFilter[col]?.filterType === FILTER_TYPES.BOOLEAN
          && (
            <BooleanFilter
              booleanFilter={currentFilter[col] as IColumnFilterBoolean}
              field={col}
              filterAtom={filterAtom}
            />
          )
        }
        {
          currentFilter[col]?.filterType === FILTER_TYPES.DATE_TIME
          && (
            <DateTimeFilter
              dateTimeFilter={currentFilter[col] as IColumnFilterDateTime}
              field={col}
              filterAtom={filterAtom}
            />
          )
        }
        {
          currentFilter[col]?.filterType === FILTER_TYPES.NUMBER_RANGE
          && (
            <NumberRangeFilter
              numberRangeFilter={currentFilter[col] as IColumnFilterNumberRange}
              field={col}
              filterAtom={filterAtom}
            />
          )
        }
      </td>
    </tr>
  );
}

export default FilterOption;
