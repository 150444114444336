import React from 'react';
import { type ICellRendererParams } from '@ag-grid-community/core';

import VehicleStatusIcon from 'components/shared/VehicleStatusIcon/VehicleStatusIcon';
import type { VehicleStatus } from 'types/common';
import type { IVehicleDetails } from 'types/IVehicleDetails';

function AgGridFleetDetailStatusCellRenderer(
  { value }: ICellRendererParams<IVehicleDetails, VehicleStatus>,
) {
  return <VehicleStatusIcon status={value} size={25} />;
}

export default AgGridFleetDetailStatusCellRenderer;
