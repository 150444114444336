import React from 'react';
import { useAtom } from 'jotai';
import { UserAdminFilterAtom } from 'views/UserAdmin/UserAdminFilter.store';
import { AG_GRID_USER_ADMIN_HEADER_MAP } from 'views/UserAdmin/UserAdminTableColumns';
import type { ICustomFilter } from 'types/IFilter';
import type { atomWithReset } from 'jotai/utils';
import { FleetDetailsFilterAtom } from 'views/FleetDetails/FleetDetailsFilter.store';
import { FLEET_DETAIL_FIELD_HEADER_MAP } from 'views/FleetDetails/FleetDetailTableColumns';
import { InspectionWorkflowsFilterAtom } from 'views/InspectionWorkflows/InspectionWorkflowsFilter.store';
import { INSPECTION_WORKFLOWS_FIELD_HEADER_MAP } from 'views/InspectionWorkflows/InspectionWorkflowsAgGridColDefs.store';
import { Collapse } from 'react-collapse';
import FilterOption from './FilterOption';

const FILTER_NAMES = [
  'Users',
  'Fleet Details',
  'Workflows',
] as const;

type FilterName = typeof FILTER_NAMES[number];

export type FilterAtom = ReturnType<typeof atomWithReset<ICustomFilter>>;

type FilterRecordEntry = {
  filterAtom: FilterAtom,
  headerMap: Record<string, string>;
};

const FILTERS: Record<FilterName, FilterRecordEntry> = {
  Users: {
    filterAtom: UserAdminFilterAtom,
    headerMap: AG_GRID_USER_ADMIN_HEADER_MAP,
  },
  'Fleet Details': {
    filterAtom: FleetDetailsFilterAtom,
    headerMap: FLEET_DETAIL_FIELD_HEADER_MAP,
  },
  Workflows: {
    filterAtom: InspectionWorkflowsFilterAtom,
    headerMap: INSPECTION_WORKFLOWS_FIELD_HEADER_MAP,
  },
};

interface IFilterProps {
  filterName: FilterName;
  isOpened: boolean;
}

function Filter({ filterName, isOpened }: IFilterProps) {
  const { filterAtom, headerMap } = FILTERS[filterName];

  const [initialFilter] = useAtom(filterAtom);

  return (
    <Collapse theme={{ collapse: 'react-collapse-collapse-menu' }} isOpened={isOpened}>
      <div className="bg-secondary text-white py-4 shadow">
        <h3 className="row px-4 border-bottom text-center text-white mb-1 user-select-none">
          {`${filterName} Filter`}
        </h3>
        <div className="container flex-column">
          <table>
            <tbody>
              {Object.keys(initialFilter).map((field) => (
                <FilterOption
                  key={field}
                  col={field}
                  headerMap={headerMap}
                  filterAtom={filterAtom}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Collapse>
  );
}

export default Filter;
