import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import { useGetVehicle } from 'apiHooks/Vehicles.Hook';
import type { IVehicleDetails } from 'types/IVehicleDetails';

import Loading from 'components/shared/Loading/Loading';
import DetailSummary from './Summary/DetailSummary';
import Inspections from './Inspections/Inspections';
import Maintenance from './Maintenance/Maintenance';
import Washes from './Washes/Washes';
import Waivers from './Waivers/Waivers';
import Tdy from './TDY/Tdy';
import Notes from './Notes/Notes';
import Documents from './Documents/Documents';

const TAB_NAMES: string[] = [
  'Inspections',
  'Maintenance',
  'Waivers',
  'TDY',
  'Washes',
  'Notes',
  'Documents',
];

function DetailComponent({ vehicle }: { vehicle: IVehicleDetails }) {
  const [activeTab, setActiveTab] = React.useState('0');

  const tabClassNames = (tabNumber: string) => (
    activeTab === tabNumber
      ? 'active'
      : 'bg-secondary'
  );

  return (
    <div className="d-flex flex-column h-100">

      <DetailSummary vehicle={vehicle} />

      <Nav className="bg-gray border-0" tabs>
        {
          TAB_NAMES.map((tabName, tabIndex) => (
            <NavItem key={tabName}>
              <NavLink
                onClick={() => setActiveTab(String(tabIndex))}
                className={`${tabClassNames(String(tabIndex))} border border-bottom-0`}
              >
                {tabName}
              </NavLink>
            </NavItem>
          ))
        }
      </Nav>
      <TabContent className="flex-fill overflow-auto" activeTab={activeTab}>
        <TabPane className="h-100" tabId="0">
          <Inspections regNumber={vehicle.regNumber} />
        </TabPane>
        <TabPane className="h-100" tabId="1">
          <Maintenance regNumber={vehicle.regNumber} />
        </TabPane>
        <TabPane className="h-100" tabId="2">
          <Waivers regNumber={vehicle.regNumber} />
        </TabPane>
        <TabPane className="h-100" tabId="3">
          <Tdy regNumber={vehicle.regNumber} />
        </TabPane>
        <TabPane className="h-100" tabId="4">
          <Washes regNumber={vehicle.regNumber} />
        </TabPane>
        <TabPane className="h-100" tabId="5">
          <Notes regNumber={vehicle.regNumber} />
        </TabPane>
        <TabPane className="h-100" tabId="6">
          <Documents regNumber={vehicle.regNumber} />
        </TabPane>
      </TabContent>
    </div>
  );
}

function VehicleDetails() {
  const { regNumber } = useParams();
  const navigate = useNavigate();

  const {
    isLoading,
    isError,
    data,
    error,
  } = useGetVehicle(regNumber || '');

  if (!regNumber) {
    navigate('/Details');
    return <div />;
  }

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <div>{ error as string }</div>;
  }

  return <DetailComponent vehicle={data} />;
}

export default VehicleDetails;
