import React from 'react';
import { type ICellRendererParams } from '@ag-grid-community/core';
import ImageLightBoxModal from 'components/shared/ImageLightBox/ImageLightBoxModal';

function AgGridImageCellRenderer({ data } : ICellRendererParams) {
  if (!data.pictures || !Array.isArray(data.pictures) || data.pictures.length === 0) {
    return <div />;
  }

  const modalReference = data.itemNo ? `AF1800-${data.itemNo}` : (data.ruleId || data.id);

  return (
    <ImageLightBoxModal pictures={data.pictures} modalTitle={`Images for ${modalReference}`} />
  );
}

export default AgGridImageCellRenderer;
