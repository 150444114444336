import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import type { BatchItem, SendOptions } from '@rpldy/shared';
import Uploady, { type Batch, FILE_STATES } from '@rpldy/uploady';
import type { SendResult } from '@rpldy/sender';

import ImageLightBox from 'components/shared/ImageLightBox/ImageLightBox';
import PdfViewer from 'components/shared/PdfViewer/PdfViewer';
import { usePostNewVehicleWaiverCard } from 'apiHooks/Vehicles.Hook';
import { RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';
import UploadFailureModal from 'components/shared/UploadFailureModal/UploadFailureModal';
import {
  UploadyDropZone,
  UploadyUploadButton,
} from 'components/shared/Uploady/uploadyComponents';

function UploadVehicleWaiverCardModal({ regNumber }: { regNumber: string }) {
  const [imagePreview, setImagePreview] = React.useState('');
  const [pdfPreview, setPdfPreview] = React.useState<Uint8Array | undefined>();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [uploadError, setUploadError] = React.useState('');
  const postNewVehicleWaiverCard = usePostNewVehicleWaiverCard(regNumber);

  const clearPreviewData = () => {
    setImagePreview('');
    setPdfPreview(undefined);
  };

  const closeModal = () => {
    clearPreviewData();
    setModalOpen(false);
  };

  const newFileCallback = (batch: Batch) => {
    if (batch.items[0]?.file.type === 'application/pdf') {
      (batch.items[0]?.file as File).arrayBuffer()
        .then((pdfArray) => pdfArray && setPdfPreview(new Uint8Array(pdfArray)))
        .catch(() => {});
    } else {
      setImagePreview(URL.createObjectURL(batch.items[0]?.file as File));
    }
  };

  const setWaiverCardFile = (
    items: BatchItem[],
    url: string | undefined,
    sendOptions: SendOptions,
  ) => {
    const sendResponse = postNewVehicleWaiverCard(items, undefined, sendOptions);

    return {
      ...sendResponse,
      request: sendResponse.request.then((data) => {
        if (data.state === FILE_STATES.ERROR) {
          setUploadError(data.response.data);
          clearPreviewData();
        } else {
          closeModal();
        }

        return data;
      }).catch(() => {}),
    } as SendResult;
  };

  return (
    <>
      <Button
        type="button"
        onClick={() => setModalOpen(true)}
      >
        Upload Waiver Card
      </Button>
      <Modal
        isOpen={modalOpen}
        toggle={closeModal}
        className="image-lightbox-modal"
      >
        <Uploady
          autoUpload={false}
          accept=".png,.jpg,.jpeg,.pdf"
          send={setWaiverCardFile}
        >
          <UploadFailureModal
            error={uploadError}
            acknowledgeError={() => setUploadError('')}
          />
          <RhinoModalHeader toggle={closeModal}>
            {`Upload waiver card for ${regNumber}`}
          </RhinoModalHeader>

          <ModalBody className="d-flex justify-content-center text-secondary fw-bold">
            <div className="border">
              {(!imagePreview && !pdfPreview) && (
                <UploadyDropZone
                  newFileCallback={newFileCallback}
                  dropZoneText="Drop waiver card file or click here..."
                />
              )}
              {imagePreview && (
                <div className="drop-zone">
                  <ImageLightBox pictures={[{ url: imagePreview, file: '' }]} preLoaded />
                </div>
              )}
              {pdfPreview && (
                <div className="overflow-hidden">
                  <PdfViewer filename="" pdfData={pdfPreview} hideDownloadButton />
                </div>
              )}
            </div>
          </ModalBody>

          <ModalFooter className="d-flex justify-content-between">
            <Button onClick={closeModal}>
              Close
            </Button>
            {
              (imagePreview || pdfPreview)
                ? <Button onClick={clearPreviewData}>Remove</Button>
                : <div />
            }
            <UploadyUploadButton disabled={!imagePreview && !pdfPreview} />
          </ModalFooter>
        </Uploady>
      </Modal>
    </>
  );
}

export default UploadVehicleWaiverCardModal;
