import { decode } from 'html-entities';
import type { PropsWithChildren } from 'react';
import React from 'react';
import {
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap';
import type IReport from 'types/IReport';
import REPORT_FIELD_HEADER_MAP from 'utils/constants/ReportFields';
import styles from './Report.module.css';

interface IReportView {
  report: IReport,
  af1800RulesTitleMap: Record<string, string>,
}

interface IReportItem {
  name: string,
  value?: string | number | null | undefined,
}

function ReportItem({ name, value }: IReportItem) {
  return (
    <div className="d-flex fs-6 gap-1 w-100 justify-content-between">
      <span className="">
        {REPORT_FIELD_HEADER_MAP[name] ?? name}
        {' '}
      </span>

      <span>{value ?? ''}</span>
    </div>
  );
}

function Failure({
  af1800Id,
  af1800Title,
  quantity,
}: {
  af1800Id: string,
  af1800Title: string,
  quantity: number
}) {
  return (
    <div className="d-flex fs-6">
      <div className={styles['num-failures']}>
        {`${quantity}`}
      </div>

      <div className={styles['af1800-id']}>
        {`${af1800Id}`}
      </div>

      <div className={styles['failure-title']}>
        {decode(af1800Title)}
      </div>
    </div>
  );
}

function ReportSubsection({ title, children }: { title?: string | undefined } & PropsWithChildren) {
  return (
    <div className="d-flex flex-column">
      {title && (
        <div className="fs-5 mb-2 border-bottom">
          {title}
        </div>
      )}
      <div className="d-grid gap-2">
        {children}
      </div>
    </div>
  );
}

function ReportSection({ title, children }: { title?: string | undefined } & PropsWithChildren) {
  return (
    <Card className="p-1 shadow border h-100">
      <CardBody className="m-2">
        {title && <CardTitle className="fs-5 fw-bold mb-4">{title}</CardTitle>}
        {children}
      </CardBody>
    </Card>
  );
}

function Report({ report, af1800RulesTitleMap }: IReportView) {
  return (
    <div className="rounded-bottom bg-white p-3 h-100">
      <div className="d-grid mx-auto gap-3">
        <div className="row row-cols-3 row-cols-xxl-6 gx-3 gy-3 ">

          <div className="col">
            <ReportSection title={REPORT_FIELD_HEADER_MAP['vehicles']}>
              <ReportSubsection>
                {Object.keys(report.vehicles).map((key) => (
                  <ReportItem
                    key={key}
                    name={key}
                    value={report.vehicles[key as keyof typeof report.vehicles]}
                  />
                ))}
              </ReportSubsection>
            </ReportSection>
          </div>

          <div className="col">
            <ReportSection title={REPORT_FIELD_HEADER_MAP['inspections']}>
              <ReportSubsection>
                {Object.keys(report.inspections).map((key) => (
                  <ReportItem
                    key={key}
                    name={key}
                    value={report.inspections[key as keyof typeof report.inspections]}
                  />
                ))}
              </ReportSubsection>
            </ReportSection>
          </div>

          {/* MX stuff */}

          <div className="col">
            <ReportSection title={REPORT_FIELD_HEADER_MAP['discrepancies']}>
              <ReportSubsection>
                {Object.keys(report.mx.discrepancies).map((key) => (
                  <ReportItem
                    key={key}
                    name={key}
                    value={report.mx.discrepancies[key as keyof typeof report.mx.discrepancies]}
                  />
                ))}
              </ReportSubsection>
            </ReportSection>
          </div>

          <div className="col">
            <ReportSection title={REPORT_FIELD_HEADER_MAP['lti']}>
              <ReportSubsection>
                {Object.keys(report.mx.lti).map((key) => (
                  <ReportItem
                    key={key}
                    name={key}
                    value={report.mx.lti[key as keyof typeof report.mx.lti]}
                  />
                ))}
              </ReportSubsection>
            </ReportSection>
          </div>

          <div className="col">
            <ReportSection title={REPORT_FIELD_HEADER_MAP['pmi']}>
              <ReportSubsection>
                {Object.keys(report.mx.pmi).map((key) => (
                  <ReportItem
                    key={key}
                    name={key}
                    value={report.mx.pmi[key as keyof typeof report.mx.pmi]}
                  />
                ))}
              </ReportSubsection>
            </ReportSection>
          </div>

          <div className="col">
            <ReportSection title={REPORT_FIELD_HEADER_MAP['scheduled']}>
              <ReportSubsection>
                {Object.keys(report.mx.scheduled).map((key) => (
                  <ReportItem
                    key={key}
                    name={key}
                    value={report.mx.scheduled[key as keyof typeof report.mx.scheduled]}
                  />
                ))}
              </ReportSubsection>
            </ReportSection>
          </div>
        </div>

        {/* </ReportSection> */}

        <div className="d-flex gap-3">
          <ReportSection title={REPORT_FIELD_HEADER_MAP['problems']}>
            <div className="d-grid gap-4">
              {Object.values(report.problems).map(Boolean).reduce((a, b) => a || b) ? (
                Object.keys(report.problems).map((key) => (
                  <ReportSubsection title={REPORT_FIELD_HEADER_MAP[key]} key={key}>
                    {report.problems[key as keyof typeof report.problems] ? (
                      <>
                        <ReportItem
                          name="regNumber"
                          value={report.problems[
                            key as keyof typeof report.problems
                          ]?.regNumber}
                        />
                        <ReportItem
                          name="num"
                          value={report.problems[key as keyof typeof report.problems]?.num}
                        />
                      </>
                    ) : (<ReportItem name="None" />)}
                  </ReportSubsection>
                ))
              ) : (
                <ReportItem name="No Problems" />
              )}
            </div>

          </ReportSection>

          <div className="col">
            <ReportSection title={REPORT_FIELD_HEADER_MAP['failures']}>
              <ReportSubsection>
                {Object.keys(report.failures).length > 0 ? (
                  Object.keys(report.failures)
                    .sort((a, b) => (report.failures[b] ?? 0) - (report.failures[a] ?? 0))
                    .map((af1800Id) => (
                      // <div className="" }>
                      <Failure
                        key={af1800Id}
                        af1800Id={af1800Id}
                        af1800Title={af1800RulesTitleMap[af1800Id] ?? ''}
                        quantity={report.failures[af1800Id] ?? 0}
                      />
                      // </div>
                    ))
                ) : (
                  <ReportItem name="No Failures" />
                )}
                {/* <pre>{JSON.stringify(af1800Rules, null, '  ')}</pre> */}
              </ReportSubsection>
            </ReportSection>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Report;
