import type { ICellRendererParams } from '@ag-grid-community/core';
import { useDeleteVehicleAuxFile } from 'apiHooks/Vehicles.Hook';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import type { IVehicleAuxFile } from 'types/IVehicleDetails';

interface Props {
  regNumber: string;
  file: IVehicleAuxFile;
}

function ConfirmDeleteDocumentModal({ regNumber, file }: Props) {
  const [modalOpen, setModalOpen] = useState(false);

  const deleteAuxFile = useDeleteVehicleAuxFile(regNumber);

  const onSubmit = () => deleteAuxFile(file.filename);

  return (
    <>
      <Button onClick={() => setModalOpen(true)}>
        Delete
      </Button>

      <RhinoModal
        centered
        title="Confirm Deletion"
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit
        onSubmit={onSubmit}
        submitButtonText="Delete"
      >
        Are you sure you want to delete this file?
      </RhinoModal>
    </>
  );
}

const AgGridDeleteDocumentCellRenderer = (regNumber: string) => (
  function CellRenderer({ data }: ICellRendererParams) {
    return <ConfirmDeleteDocumentModal regNumber={regNumber} file={data as IVehicleAuxFile} />;
  }
);

export default AgGridDeleteDocumentCellRenderer;
