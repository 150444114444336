import React from 'react';
import { decode } from 'html-entities';

import { formatDate, useRestrictUpdates } from 'utils/Helpers';
import type { IVehicleDetails } from 'types/IVehicleDetails';

import useUserOrganizationName from 'hooks/useUserOrganizationName';
import type { VehicleStatus } from 'types/common';
import EditWorkflowModal from './EditWorkflowModal';
import EditOrganizationSectionModal from './EditSectionModal';
// import EditOrganizationModal from './EditOrganizationModal';
import EditMilesHoursModal from './EditMilesHoursModal';
import EditStatusModal from './EditStatusModal';
import EditRegNumberModal from './EditRegNumber';
import EditVehicleDescriptionModal from './EditVehicleDescription';
import DecommissionModal from './DecommissionModal';

interface IVehicleDetailSummary {
  vehicle: IVehicleDetails;
}

function VehicleDetailSummary({ vehicle }: IVehicleDetailSummary) {
  const restrictUpdates = useRestrictUpdates();
  const hasYearMakeOrModel = !!(vehicle.year || vehicle.make || vehicle.model);
  const orgName = useUserOrganizationName();

  return (
    <div className="d-flex flex-column justify-content-center p-2 gap-2 pb-3 bg-gray">

      <div className="d-flex align-items-center justify-content-center w-100">
        <div className="mx-auto text-muted">
          <small>
            Last Updated
            {' '}
            <span className="fw-bold">{formatDate(vehicle.management[0]?.validFrom)}</span>
          </small>
        </div>

        <div className="align-self-end">
          <DecommissionModal vehicle={vehicle} />
        </div>
      </div>

      <div className="d-flex justify-content-center bg-gray gap-3">
        <div className="d-flex flex-column gap-2 p-3 border bg-light rounded-4">
          <div className="d-flex flex-column">
            <small className="text-nowrap align-items-end text-gray-dark">
              Registration Number:
            </small>
            <EditRegNumberModal regNumber={vehicle.regNumber} />
          </div>
          <div className="d-flex flex-column">
            <small className="text-nowrap align-items-end text-gray-dark">
              Section:
            </small>
            <EditOrganizationSectionModal
              regNumber={vehicle.regNumber}
              sectionId={vehicle.management[0].sectionId || undefined}
            />
          </div>
          <div className="d-flex flex-column">
            <small className="text-nowrap align-items-end text-gray-dark">
              Owner:
            </small>
            <div className="col d-flex align-items-center">
              <span className="text-nowrap">{ orgName }</span>
            </div>
            {/* <EditOrganizationModal
              regNumber={vehicle.regNumber}
              orgId={vehicle.management[0].orgId}
            /> */}
          </div>
          {!restrictUpdates && (
            <div className="d-flex flex-column">
              <small className="text-nowrap align-items-end text-gray-dark">
                Inspection Workflow:
              </small>
              <EditWorkflowModal
                regNumber={vehicle.regNumber}
                workflowId={vehicle.management[0].workflowId}
              />
            </div>
          )}

        </div>

        <div className="d-flex flex-column p-3 border bg-light rounded-4">
          <div className="d-flex">
            <div className="d-flex gap-2 flex-column">
              <div className="d-flex flex-column">
                <small className="text-nowrap align-items-end text-gray-dark">
                  Type:
                </small>
                <span className="text-nowrap lh-1 fs-4">
                  {decode(vehicle.management[0].vehicleType) || 'N/A'}
                </span>
              </div>
              <div className="d-flex flex-column">
                {
                  hasYearMakeOrModel && (
                    <>
                      <small className="text-nowrap align-items-end text-gray-dark">
                        Year/Make/Model:
                      </small>
                      <span className={`${hasYearMakeOrModel ? 'text-nowrap' : 'text-keep-white-space'} lh-1 fs-5`}>
                        {[
                          vehicle.year,
                          vehicle.make,
                          vehicle.model,
                        ].filter(Boolean).join(' ') || ' '}
                        <span className="text-keep-white-space">{' '}</span>
                      </span>
                    </>
                  )
                }
              </div>
              <div>
                <small className="text-nowrap text-gray-dark align-items-end">
                  EOL Date:
                </small>
                <div className="text-nowrap lh-1">
                  {formatDate(vehicle.management[0].eolDate, false)}
                </div>
              </div>

              <div className="d-grid gap-2 mt-3">
                <span className="text-nowrap text-gray-dark">
                  {`WRM: ${vehicle.management[0].wrm ? 'Yes' : 'No'}`}
                </span>
              </div>
              <EditMilesHoursModal
                milesHoursValue={vehicle.lastReportedHours}
                milesOrHours="hours"
                regNumber={vehicle.regNumber}
              />
              <EditMilesHoursModal
                milesHoursValue={vehicle.lastReportedMileage}
                milesOrHours="miles"
                regNumber={vehicle.regNumber}
              />
            </div>

            {!restrictUpdates && (
              <div className="d-flex align-items-start">
                <EditVehicleDescriptionModal
                  regNumber={vehicle.regNumber}
                  vehicleType={decode(vehicle.management[0].vehicleType)}
                  make={vehicle.make}
                  model={vehicle.model}
                  year={vehicle.year}
                  eolDate={vehicle.management[0].eolDate}
                  wrm={vehicle.management[0].wrm}
                />
              </div>
            )}
          </div>

        </div>

        <div className="d-grid justify-content-evenly p-3 border bg-light rounded-4">
          <div className="d-flex px-2">
            <EditStatusModal
              regNumber={vehicle.regNumber}
              status={vehicle.status[0]?.status as VehicleStatus}
              eticDate={vehicle.management[0]?.eticDate}
            />
          </div>

        </div>
      </div>
    </div>
  );
}

export default VehicleDetailSummary;
