import { useQuery } from '@tanstack/react-query';

import type { IOverview } from 'types/IOverview';
import { authHttpGet, userInfo } from 'utils/Helpers';

const useGetOverview = () => (
  useQuery({
    queryKey: ['GetOverview'],
    queryFn: () => (
      authHttpGet<IOverview>(
        `/data/v1/organizations/${userInfo()?.orgId}/fleet`,
      )),
  })
);

export default useGetOverview;
