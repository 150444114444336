import React from 'react';
import { useAtom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import type { VehicleSelectionMap, IFleetDetail } from 'types/IFleetDetails';

import { RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';
import { keyByRegNumberWithSelected, SAVING_STAGE } from '../workflowUtils';
import styles from './VehicleSelectionModal.module.css';
import VehicleSelectionByType from './VehicleSelectionByType';

interface IVehicleSelectionComponent {
  existingWorkflowName: string,
  vehicles: IFleetDetail[],
  currentStage: string,
  setCurrentStage: (currentStage: string, selectedRegNumbers?: string[]) => void,
}

function NewVehicleAssociations({
  vehicles,
  existingWorkflowName,
  currentStage,
  setCurrentStage,
} : IVehicleSelectionComponent) {
  const vehicleMapAtom = React.useMemo(
    () => atomWithReset<VehicleSelectionMap>(keyByRegNumberWithSelected(vehicles)),
    [vehicles],
  );
  const [vehicleMap] = useAtom(vehicleMapAtom);

  const vehiclesSelected = () => (
    Object.values(vehicleMap).filter((x) => x.selected)
  );

  return (
    <Modal
      className={styles['vehicle-selection-modal']}
      isOpen={currentStage === SAVING_STAGE.ASSOCIATE_VEHICLES}
      toggle={() => setCurrentStage(SAVING_STAGE.CLOSED)}
    >
      <RhinoModalHeader toggle={() => setCurrentStage(SAVING_STAGE.CLOSED)}>
        { `Vehicles associated to ${existingWorkflowName}`}
      </RhinoModalHeader>
      <ModalBody>
        <div className="overflow-y-auto max-vh-50 border">
          <VehicleSelectionByType
            vehiclesByRegNumAtom={vehicleMapAtom}
            workflowName={existingWorkflowName}
          />
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color="secondary" onClick={() => setCurrentStage(SAVING_STAGE.CLOSED)}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => (
            setCurrentStage(SAVING_STAGE.NAMING, vehiclesSelected().map((x) => x.regNumber))
          )}
        >
          Associate
          { ' '}
          { vehiclesSelected().length}
          {' '}
          vehicles with new workflow
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default NewVehicleAssociations;
