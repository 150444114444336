import React from 'react';
import {
  Alert,
  Button,
} from 'reactstrap';
import { NumericFormat } from 'react-number-format';
import { MdEdit } from 'react-icons/md';

import type {
  IOrganizationSectionWithMel,
  ISectionMel,
  ISectionMelEphemeral,
} from 'types/IOrganizationSections';
import VehicleTypesSelect from 'components/fields/VehicleTypesSelect';
import { usePatchSectionVehicleMels } from 'apiHooks/OrganizationSections.Hook';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import { decode } from 'html-entities';

interface IEditVehicleTypeMel {
  section: IOrganizationSectionWithMel,
  originalMel: ISectionMel,
}

function EditVehicleTypeMelModal({ section, originalMel }: IEditVehicleTypeMel) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editVehicleTypeMel, setVehicleTypeMel] = React.useState<ISectionMelEphemeral>(originalMel);
  const updateOperationSectionVehicleMels = usePatchSectionVehicleMels();

  const toggle = () => setModalOpen(!modalOpen);

  const resetState = () => setVehicleTypeMel(originalMel);
  const submitEditVehicleTypes = () => {
    const isVehicleTypeReplaced = editVehicleTypeMel.vehicleType !== originalMel.vehicleType;

    return updateOperationSectionVehicleMels({
      sectionId: section.sectionId,
      sectionName: section.sectionName,
      mel: [
        // eslint-disable-next-line unicorn/no-null
        ...(isVehicleTypeReplaced ? [{ vehicleType: originalMel.vehicleType }] : []),
        {
          vehicleType: editVehicleTypeMel.vehicleType || '',
          mel: editVehicleTypeMel.mel || 0,
        },
      ],
    });
  };

  const hasExistingMel = () => (
    editVehicleTypeMel.vehicleType !== originalMel.vehicleType
    && (section.mel || []).some((existingMel) => (
      existingMel.vehicleType === editVehicleTypeMel.vehicleType
    ))
  );

  const isValid = () => !!(
    editVehicleTypeMel.vehicleType
    && editVehicleTypeMel.mel
    && !hasExistingMel()
  );

  return (
    <>
      <Button onClick={toggle} className="py-0 pe-0" color="link"><MdEdit /></Button>
      <RhinoModal
        title={`Modify vehicle type MEL for ${section.sectionName}`}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        onSubmit={submitEditVehicleTypes}
        canSubmit={isValid()}
        onClosed={resetState}
      >
        <div className="d-flex flex-column">
          <div className="d-flex">
            <VehicleTypesSelect
              id="inputVehicleType"
              onValueChange={(vehicleType) => {
                setVehicleTypeMel({
                  ...originalMel,
                  vehicleType: (vehicleType === undefined ? vehicleType : vehicleType.trim()),
                });
              }}
              defaultValue={decode(originalMel.vehicleType)}
              isInvalid={editVehicleTypeMel.vehicleType !== '' && hasExistingMel()}
            />
            <div className="form-floating">
              <NumericFormat
                className="form-control"
                id="inputMel"
                decimalScale={0}
                min={1}
                allowNegative={false}
                defaultValue={editVehicleTypeMel.mel || 0}
                onValueChange={({ floatValue }) => {
                  setVehicleTypeMel({
                    ...originalMel,
                    mel: floatValue,
                  });
                }}
                placeholder=" "
              />
              <label htmlFor="inputMel">
                MEL
              </label>
            </div>
          </div>
          {
            hasExistingMel() && <Alert color="danger">Vehicle Type MEL(s) exists for section</Alert>
          }
        </div>
      </RhinoModal>
    </>
  );
}

export default EditVehicleTypeMelModal;
