const AF1800_STATUS_CODES: string[] = ['C', 'C-T', 'C-P', 'D', 'D-P', 'D-M', 'D-D', 'W', 'N'];

const AF1800_STATUS_CODE_LABELS: Record<string, string> = {
  C: 'Corrected (C)',
  'C-T': 'Corrected By Temporary Fix (C-T)',
  'C-P': 'Corrected By Permanent Fix (C-P)',
  D: 'Delayed (D)',
  'D-D': 'Delayed for Disposition (D-D)',
  'D-M': 'Delayed for Maintenance (D-M)',
  'D-P': 'Delayed for Parts (D-P)',
  W: 'Waiver for Repair (W)',
  N: 'No Repairs Required (N)',
};

export {
  AF1800_STATUS_CODES,
  AF1800_STATUS_CODE_LABELS,
};
