import type { Dispatch, SetStateAction } from 'react';
import React, { useState } from 'react';
import {
  Alert,
  Button,
} from 'reactstrap';

import { usePostNewOrganizationSection } from 'apiHooks/OrganizationSections.Hook';
// import { userOrganizationName } from 'utils/Helpers';
import { MAX_SECTION_NAME_LENGTH } from 'utils/Constants';
import type { IOrganization } from 'types/IOrganization';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';

interface Props {
  organization: IOrganization,
  setNewSectionName: Dispatch<SetStateAction<string>>,
}

function AddNewSectionModal(props: Props) {
  const { organization, setNewSectionName } = props;

  const [modalOpen, setModalOpen] = React.useState(false);
  const [tmpNewSectionName, setTmpNewSectionName] = useState('');
  const postNewOrganizationSection = usePostNewOrganizationSection();

  const toggle = () => setModalOpen(!modalOpen);

  const resetState = () => setTmpNewSectionName('');

  const onSubmit = () => (
    postNewOrganizationSection(tmpNewSectionName.trim())
      .then(() => {
        // sets new section name for use in parent
        setNewSectionName(tmpNewSectionName);
      })
  );

  const nameExistsInOrg = () => organization.sections.some(
    (orgSection) => orgSection.sectionName.toLowerCase() === tmpNewSectionName.toLowerCase(),
  );

  const nameTooLong = () => tmpNewSectionName.length > MAX_SECTION_NAME_LENGTH;

  const isValid = () => (
    !!tmpNewSectionName && !nameExistsInOrg() && !nameTooLong()
  );

  return (
    <>
      <Button onClick={toggle}>
        New Section
      </Button>
      <RhinoModal
        // title={`Name of New Section for ${userOrganizationName()}`}
        title="Name of New Section"
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={isValid()}
        onSubmit={onSubmit}
        onClosed={resetState}
      >
        <div className="form-floating">
          <input
            className="form-control"
            id="inputSectionName"
            onChange={(event) => setTmpNewSectionName(event.target.value.trim())}
            placeholder=" "
            maxLength={MAX_SECTION_NAME_LENGTH}
          />
          <label htmlFor="inputSectionName">
            New Section
          </label>
        </div>
        {
          tmpNewSectionName.length > 0 && nameExistsInOrg()
          && <Alert color="danger">Another section with this name exists for this organization.</Alert>
        }
        {
          nameTooLong() && <Alert color="danger">{`Name must be less than ${MAX_SECTION_NAME_LENGTH} characters in length.`}</Alert>
        }
      </RhinoModal>
    </>
  );
}

export default AddNewSectionModal;
