import type { Role } from 'utils/constants/Roles';
import { userInfo } from 'utils/helpers/keycloakAxios.Helper';

type WebRole = Exclude<Role, 'inspector'>;

type Params = {
  authorized?: WebRole[];
  restricted?: WebRole[];
  requireAdmin?: boolean;
};

/**
 * Determines whether the logged-in user's role is restricted.
 * Optionally provide a list of authorized or restricted roles.
 * Optionally require admin privileges.
 * If no authorized or restricted roles are provided,
 * all roles are restricted except VCO, and admin privileges are not required.
 *
 * Note: accepted roles are typed as WebRole, which excludes 'inspector'
 *
 * @param params
 * Pass a `WebRole[]` to either `params.authorized` or `params.restricted`.
 * Set `params.requireAdmin` to `true` to restrict non-administrators.
 *
 * @returns `true` if and only if the user's role should be restricted or not authorized.
 */
const useRestrictUpdates = (params?: Params) => {
  const user = userInfo();

  if (!user) {
    return true;
  }

  // If no params are provided, restrict all roles except VCO
  if (!params || (
    !!params
    && !params.authorized?.[0]
    && !params.restricted?.[0]
    && params.requireAdmin === undefined
  )) {
    return user.role !== 'vco';
  }

  const { authorized, restricted, requireAdmin } = params;

  return (
    // Always restrict inspector role
    user.role === 'inspector'

    // restrict non-admin when <requireAdmin> is true
    || (requireAdmin && !user.isAdmin)

    // restrict all roles not contained in <authorized>
    || (Array.isArray(authorized) ? !authorized.includes(user.role || '') : false)

    // restrict all roles contained in <restricted>
    || (restricted?.includes(user.role || '') ?? false)
  );
};

export default useRestrictUpdates;
