// cspell:words uploady
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import {
  Input,
  Button,
  ModalFooter,
  Label,
  FormGroup,
  Alert,
} from 'reactstrap';
import type { BatchItem, SendOptions } from '@rpldy/shared';
import Uploady, { type Batch, FILE_STATES } from '@rpldy/uploady';
import type { SendResult } from '@rpldy/sender';

import ImageLightBox from 'components/shared/ImageLightBox/ImageLightBox';
import {
  useNewVehicleMxRequiredWithImage,
  useGetVehicleWorkflow,
  useNewVehicleMxRequiredWithoutImage,
  usePostNewVehicleMxRequired,
  useGetVehicleInspectionHistory,
} from 'apiHooks/Vehicles.Hook';
import { useGetWorkflows } from 'apiHooks/WorkFlows.Hook';
import VehicleWorkflowAf1800RuleSelect from 'components/fields/VehicleWorkflowAf1800RuleSelect';
import UploadFailureModal from 'components/shared/UploadFailureModal/UploadFailureModal';
import {
  UploadyDropZone,
  UploadyUploadButton,
} from 'components/shared/Uploady/uploadyComponents';
import { RhinoModalBody, RhinoModalContainer, RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';
import type { IRequiredMxInspectionAmendPatch, IWorkflowRule } from 'types/IWorkflows';
import type { IRequiredMxPost } from 'types/Mx.d';
import { userInfo } from 'utils/Helpers';
import type { ErrorResponse } from 'types/common';
import ReportMilesHours from './ReportHoursMiles';

interface ICreateRequiredModal {
  regNumber: string,
}

const parseItemNo = (af1800Id: string) => (
  Number.parseInt(af1800Id.split('-').pop() || '', 10) || undefined
);

function CreateRequiredModal({ regNumber }: ICreateRequiredModal) {
  const [imagePreview, setImagePreview] = React.useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [dateDiscovered, setDateDiscovered] = useState<Date | null>(new Date());
  const [discrepancy, setDiscrepancy] = useState('');
  const [uploadError, setUploadError] = React.useState('');
  const [itemNo, setItemNo] = useState<number | undefined>();
  const [reportMilesOrHours, setReportMilesOrHours] = useState(false);
  const [reportMiles, setReportMiles] = useState<number | null>(null);
  const [reportHours, setReportHours] = useState<number | null>(null);
  const [isBooleanRule, setIsBooleanRule] = useState(false);
  const [attachingImage, setAttachingImage] = useState(false);
  const [amendAf1800, setAmendAf1800] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const {
    data: workflowData,
  } = useGetVehicleWorkflow(regNumber);
  const { data: workflows } = useGetWorkflows();
  const { data: inspectionHistory } = useGetVehicleInspectionHistory(regNumber);

  const uploadImageWithDiscrepancy = useNewVehicleMxRequiredWithImage(regNumber);
  const patchDiscrepancy = useNewVehicleMxRequiredWithoutImage(regNumber);
  const postDiscrepancy = usePostNewVehicleMxRequired(regNumber);

  /**
   *
   * @returns
   */
  const toggle = () => setModalOpen((state) => !state);

  /**
   *
   * @returns Boolean
   */
  const canSubmit = () => (
    dateDiscovered !== null
      && discrepancy !== ''
      && itemNo !== undefined
      && (!attachingImage || imagePreview)
      && (
        !reportMilesOrHours
        || reportHours
        || reportMiles
      )
  );

  /**
   *
   */
  const resetStateAndClose = () => {
    setModalOpen(false);
    setDateDiscovered(new Date());
    setImagePreview('');
    setDiscrepancy('');
    setItemNo(undefined);
    setIsBooleanRule(false);
    setAttachingImage(false);
    setReportMilesOrHours(false);
    setReportHours(null);
    setReportMiles(null);
    setAmendAf1800(false);
  };

  /**
   *
   * @param batch
   */
  const newFileCallback = (batch: Batch) => {
    setImagePreview(URL.createObjectURL(batch.items[0]?.file as File));
  };

  /**
   *
   * @param items
   * @param url
   * @param sendOptions
   * @returns
   */
  const setDiscrepancyWithImageFile = (
    items: BatchItem[],
    url: string | undefined,
    sendOptions: SendOptions,
  ) => {
    const sendResponse = uploadImageWithDiscrepancy(items, undefined, sendOptions);

    return {
      ...sendResponse,
      request: sendResponse.request.then((data) => {
        if (data.state === FILE_STATES.ERROR) {
          setUploadError(data.response.data);
          setImagePreview('');
        } else {
          resetStateAndClose();
        }

        return data;
      }).catch(() => {}),
    } as SendResult;
  };

  /**
   *
   * @returns
   */
  const submitWithoutImage = () => {
    const { userId } = userInfo() || {};
    if (!dateDiscovered || !itemNo || !discrepancy || !userId) {
      return;
    }

    if (amendAf1800) {
      if (!workflowData) {
        return;
      }

      const patchData: IRequiredMxInspectionAmendPatch = {
        version: workflowData.version,
        workflowId: workflowData.workflowId,
        responses: JSON.stringify([{
          ruleId: `AF1800-${itemNo}`,
          type: 'passFail',
          result: {
            isPassing: false,
          },
          comment: discrepancy,
        }]),
        miles: reportMiles ?? undefined,
        hours: reportHours ?? undefined,
      };

      patchDiscrepancy(patchData).then(resetStateAndClose).catch(() => {});
    } else {
      const postData: IRequiredMxPost = {
        regNumber,
        itemNo,
        discrepancy,
        dateDiscovered: dateDiscovered.toISOString(),
        inspectorId: userId,
        reportMiles: reportMiles ?? undefined,
        reportHours: reportHours ?? undefined,
      };

      postDiscrepancy(postData)
        .then(resetStateAndClose)
        .catch(async (error_: Response) => {
          const error = JSON.parse(await error_.text()) as ErrorResponse;
          const msg = error.errorMessage;
          setErrorMessage(msg);
        });
    }
    setErrorMessage('');
  };

  /**
   *  XXX: for oddball scenario where a vehicle has a workflow but it is
   *       not a valid workflow for that organization (vehicle transfer, bad data, etc)
   * @returns Boolean
   */
  const canCreateDiscrepancy = () => (
    workflows
    && workflowData
    && workflows.some((workflow) => workflow.workflowId === workflowData.workflowId)
  );

  return (
    <>
      <Button
        type="button"
        disabled={!canCreateDiscrepancy()}
        onClick={() => setModalOpen(true)}
      >
        Report New Discrepancy
      </Button>

      <RhinoModalContainer
        scrollable
        className="image-lightbox-modal"
        isOpen={modalOpen}
        toggle={toggle}
      >

        <Uploady
          autoUpload={false}
          accept=".png,.jpg,.jpeg"
          send={setDiscrepancyWithImageFile}
        >
          <UploadFailureModal
            error={uploadError}
            acknowledgeError={() => setUploadError('')}
          />
          <RhinoModalHeader toggle={toggle}>
            {`Required Maintenance Discrepancy for ${regNumber}`}
          </RhinoModalHeader>

          <RhinoModalBody className="d-flex flex-column text-secondary fw-bold">
            <VehicleWorkflowAf1800RuleSelect
              id="newAf1800Discrepancy"
              regNumber={regNumber}
              onValueChange={(workflowRule?: IWorkflowRule) => {
                if (!workflowRule || !workflowRule.ruleId) {
                  setItemNo(undefined);
                  setIsBooleanRule(false);
                } else {
                  setItemNo(parseItemNo(workflowRule.ruleId));
                  setIsBooleanRule(workflowRule.isBoolean || false);
                }
              }}
            />
            <div className="d-flex align-items-center justify-content-end ">
              <span className="px-2">Date Discovered:</span>
              <ReactDatePicker
                selected={dateDiscovered}
                onChange={setDateDiscovered}
                showIcon
              />
            </div>

            <FormGroup floating className="mt-2">
              <input
                className="form-control"
                value={discrepancy}
                placeholder=" "
                id="floatingDiscrepancyInput"
                onChange={(e) => setDiscrepancy(e.target.value)}
              />
              <Label for="floatingDiscrepancyInput">
                Discrepancy
              </Label>
            </FormGroup>
            {
              workflowData
              && (
                <ReportMilesHours
                  workflowData={workflowData}
                  isBooleanRule={isBooleanRule}
                  updateReportMilesOrHours={setReportMilesOrHours}
                  updateReportedMiles={setReportMiles}
                  updateReportedHours={setReportHours}
                />
              )
            }
            {
              reportMilesOrHours
              && Array.isArray(inspectionHistory)
              && inspectionHistory.length > 0
                ? (
                  <Label>
                    <Input
                      type="checkbox"
                      disabled={!isBooleanRule}
                      defaultChecked={amendAf1800}
                      onChange={(e) => setAmendAf1800(e.target.checked)}
                    />
                    <span className={`px-2 ${isBooleanRule ? '' : 'text-black text-opacity-25'}`}>
                      Amend previous AF1800 (Optional)
                    </span>
                  </Label>
                )
                : null
            }
            {
              amendAf1800
                ? (
                  <div>
                    <Label>
                      <Input
                        type="checkbox"
                        defaultChecked={attachingImage}
                        onChange={(e) => setAttachingImage(e.target.checked)}
                      />
                      <span className="px-2">Upload image (Optional)</span>
                    </Label>
                  </div>
                )
                : null
            }
            {
              attachingImage
                ? (
                  <div className="border rounded overflow-hidden">
                    {
                      imagePreview
                        ? (
                          <div>
                            <ImageLightBox pictures={[{ url: imagePreview, file: '' }]} preLoaded />
                          </div>
                        ) : (
                          <UploadyDropZone
                            newFileCallback={newFileCallback}
                            dropZoneText="Drop image or click here..."
                          />
                        )
                    }
                  </div>
                )
                : null
            }
            {
              errorMessage && (
                <Alert color="danger">{errorMessage}</Alert>
              )
            }
          </RhinoModalBody>

          <ModalFooter className="d-flex justify-content-between">
            <Button onClick={resetStateAndClose}>Cancel</Button>
            {
              attachingImage && amendAf1800
                ? (
                  <UploadyUploadButton
                    disabled={!canSubmit()}
                    params={
                      {
                        version: workflowData?.version,
                        workflowId: workflowData?.workflowId,
                        responses: {
                          id: `AF1800-${itemNo}`,
                          type: 'passFail',
                          result: {
                            isPassing: false,
                          },
                          comment: discrepancy,
                        },
                        miles: reportMiles,
                        hours: reportHours,
                        amend: amendAf1800,
                      }
                    }
                  />
                )
                : (
                  <Button
                    disabled={!canSubmit()}
                    onClick={submitWithoutImage}
                  >
                    Submit
                  </Button>
                )
            }
          </ModalFooter>
        </Uploady>
      </RhinoModalContainer>
    </>
  );
}

export default CreateRequiredModal;
