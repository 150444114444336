import type { ValueFormatterParams } from '@ag-grid-community/core';
import { decode } from 'html-entities';

/**
 * AgGrid Value formatter for date/times
 *
 * @param params
 * @returns
 */
const AgGridHtmlDecodeValueFormatter = (params: ValueFormatterParams) => decode(params.value);

export default AgGridHtmlDecodeValueFormatter;
