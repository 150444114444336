import React from 'react';
import { Button } from 'reactstrap';
import { FaUndo } from 'react-icons/fa';

import styles from './RemovedStateOverlay.module.css';

/**
 *
 * @param param0
 * @returns
 */
function RemovedStateOverlay({ undeleteCallback } : { undeleteCallback: () => void }) {
  return (
    <div data-no-dnd="true" className="position-absolute w-100 h-100 top-0 left-0">
      <div className="bg-danger position-absolute w-100 h-100 opacity-25">{' '}</div>
      <div className="d-flex h-100 align-items-center justify-content-center">
        <Button color="link" className={styles['undelete-icon']} onClick={() => undeleteCallback()}>
          <FaUndo className="text-white display-4" />
        </Button>
      </div>
    </div>
  );
}

export default RemovedStateOverlay;
