import React from 'react';
import type { AgGridReactProps } from '@ag-grid-community/react';
import { AgGridReact } from '@ag-grid-community/react';

/* eslint-disable react/jsx-props-no-spreading */
function RhinoGrid<T>(props: AgGridReactProps<T>) {
  return (
    <AgGridReact
      maintainColumnOrder
      animateRows
      defaultColDef={{
        sortable: true,
        resizable: true,
        cellClass: 'px-2 my-0 align-items-center',
        headerClass: 'px-2',
        width: 175,
      }}
      rowHeight={40}
      headerHeight={40}
      {...props}
    />
  );
}
/* eslint-enable react/jsx-props-no-spreading */

export default RhinoGrid;
