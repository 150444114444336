const TIMEZONE_OPTIONS = Intl.supportedValuesOf('timeZone');

/**
 * Returns the signed timeDiff.
 * @param timeDiff number to determine sign of
 * @returns '+' or '−' (0 maps to +)
 */
const formatTimeDiff = (timeDiff: number) => {
  const absoluteTimeDiff = Math.abs(timeDiff);
  if (timeDiff < 0) {
    return `−${absoluteTimeDiff}`;
  }

  if (timeDiff > 0) {
    return `+${absoluteTimeDiff}`;
  }

  return ` ${absoluteTimeDiff}`;
};

/**
 *
 * @returns
 */
const formatTimeZoneLabels = () => {
  const now = new Date();
  const utcHours = now.getUTCHours();

  return TIMEZONE_OPTIONS.reduce((timeZoneMap: Record<string, string>, timeZone) => {
    const timeZoneHours = Number.parseInt(
      now.toLocaleTimeString(
        'en-US',
        {
          hour: '2-digit',
          hourCycle: 'h23',
          timeZone,
        },
      ),
      10,
    );
    const formattedLabel = [
      timeZone.replaceAll('/', ' / ').replaceAll('_', ' '),
      ' (UTC',
      formatTimeDiff(timeZoneHours - utcHours),
      ')',
    ].join('');

    return {
      ...timeZoneMap,
      [timeZone]: formattedLabel,
    };
  }, {});
};

const TIMEZONE_OPTIONS_LABELS = formatTimeZoneLabels();

export {
  TIMEZONE_OPTIONS,
  TIMEZONE_OPTIONS_LABELS,
};
