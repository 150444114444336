import React from 'react';
import {
  Button,
  Input,
  Spinner,
} from 'reactstrap';
import {
  MdEdit,
  MdCancel,
  MdCheck,
} from 'react-icons/md';
import Lightbox, { type SlideImage } from 'yet-another-react-lightbox';
import Inline from 'yet-another-react-lightbox/plugins/inline';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';

import { useGetVehicleInspectionImage } from 'apiHooks/Vehicles.Hook';
import type { IRhinoImage } from 'types/common';

export interface IImageLightBox {
  pictures: IRhinoImage[]
  preLoaded?: boolean,
  updateCaptionCallback?: undefined | ((fileId: string | number, newCaption: string) => void),
  currentRhinoImageCallback?: undefined | ((image: IRhinoImage) => void);
}

interface IImageCaption {
  rhinoImage: IRhinoImage | undefined,
  updateCaptionCallback?: undefined | ((fileId: string | number, newCaption: string) => void),
}

function ImageCaption({ rhinoImage, updateCaptionCallback }: IImageCaption) {
  const [editing, setEditing] = React.useState(false);
  const [newAnnotation, setNewAnnotation] = React.useState(rhinoImage?.annotation || '');

  const updateAnnotation = () => {
    if (updateCaptionCallback
      && newAnnotation !== undefined
      && (newAnnotation !== rhinoImage?.annotation || '')) {
      updateCaptionCallback(rhinoImage?.filename || '', newAnnotation);
    }
    setEditing(false);
  };

  if (editing && updateCaptionCallback) {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <Input
          defaultValue={rhinoImage?.annotation || ''}
          onChange={(e) => setNewAnnotation(e.target.value.trim())}
        />
        <Button onClick={updateAnnotation} color="link" className="text-success">
          <MdCheck />
        </Button>
        <Button onClick={() => setEditing(false)} color="link" className="text-danger">
          <MdCancel />
        </Button>
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center justify-content-between">
      <h5 className="px-1 m-0 text-wrap">
        {rhinoImage?.annotation || ''}
      </h5>
      {
        updateCaptionCallback
          ? (
            <Button onClick={() => setEditing(!editing)} color="primary">
              <MdEdit />
            </Button>
          )
          : null
      }
    </div>
  );
}

/**
 *
 * @param param0
 * @returns
 */
function ImageLightBox({
  pictures,
  preLoaded,
  updateCaptionCallback,
  currentRhinoImageCallback,
}: IImageLightBox) {
  const [slides, setSlides] = React.useState<SlideImage[] | undefined>();
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);
  const currentPicture = pictures[currentSlideIndex];

  const getImage = useGetVehicleInspectionImage();

  React.useEffect(() => {
    if ((pictures || []).length > 0 && !slides) {
      if (!preLoaded) {
        Promise.all((pictures).map((pic) => getImage(pic.url)))
          .then((responses: unknown[]) => (
            setSlides((responses as Blob[]).map((blob) => ({ src: URL.createObjectURL(blob) })))))
          .catch(() => {});
      }
      if (preLoaded) {
        setSlides((pictures).map((pic) => ({ src: pic.url })));
      }
    }
  }, [getImage, pictures, slides, preLoaded]);

  if (pictures.length === 0) {
    return <div />;
  }

  if (!slides) {
    return <Spinner />;
  }

  const updateCurrentSlideState = (index: number) => {
    setCurrentSlideIndex(index);
    const indexPicture = pictures[index];

    if (currentRhinoImageCallback && indexPicture) {
      currentRhinoImageCallback(indexPicture);
    }
  };

  if (currentRhinoImageCallback && currentPicture) {
    currentRhinoImageCallback(currentPicture);
  }

  return (
    <div style={{ minHeight: '400px' }} className={(pictures.length <= 1) ? 'yet-another-react-lightbox-hide-nav-controls' : ''}>
      <Lightbox
        plugins={[Inline, Zoom, Captions]}
        carousel={{ finite: pictures.length <= 1 }}
        inline={{
          style: {
            width: '100%',
            minWidth: '400px',
            maxWidth: '900px',
            aspectRatio: '3 / 2',
          },
        }}
        slides={slides}
        on={{
          view: ({ index }: { index: number; }) => updateCurrentSlideState(index),
        }}
      />
      <ImageCaption
        rhinoImage={pictures[currentSlideIndex]}
        updateCaptionCallback={updateCaptionCallback}
      />
    </div>
  );
}

export default ImageLightBox;
