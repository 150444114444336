import React from 'react';
import type {
  ColDef,
} from '@ag-grid-community/core';

import AgGridDateValueFormatter from 'components/agGridRenderers/AgGridDateValueFormatter';
import type { IApproachingMx } from 'types/IOverview';
import RhinoGrid from 'components/shared/RhinoGrid';

function ApproachingPMIs({ rows }: { rows: IApproachingMx[] }) {
  const [columnDefs] = React.useState<ColDef[]>([
    {
      field: 'regNumber',
      headerName: 'Reg Number',
    },
    {
      field: 'nextMxDate',
      headerName: 'Date',
      valueFormatter: AgGridDateValueFormatter,
    },
    {
      field: 'daysToMx',
      headerName: 'Days to PMI',
    },
  ]);

  return (
    <div className="ag-theme-material w-100 h-100">
      <RhinoGrid<IApproachingMx>
        rowData={rows}
        columnDefs={columnDefs}
      />
    </div>
  );
}

export default ApproachingPMIs;
