import type { ISelectObject } from 'types/ISelectObject';

const ROLE_LIST = [
  'inspector',
  'dispatcher',
  'jrSuper',
  'sectionLead',
  'vco',
] as const;

export type Role = typeof ROLE_LIST[number];

const ROLES = {
  inspector: 'Inspector',
  dispatcher: 'Dispatcher',
  jrSuper: 'Junior Supervisor',
  sectionLead: 'Section Lead',
  vco: 'VCO',
} as const;

const ROLES_SELECT_OPTIONS: ISelectObject[] = ROLE_LIST.map((role) => ({
  value: role,
  label: ROLES[role],
}));

const ROLE_REQUIRING_SECTION = [
  'dispatcher',
  'jrSuper',
  'sectionLead',
];

export {
  ROLES,
  ROLE_LIST,
  ROLES_SELECT_OPTIONS,
  ROLE_REQUIRING_SECTION,
};
