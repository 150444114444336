import React from 'react';
import { Progress } from 'reactstrap';
import { type ICellRendererParams } from '@ag-grid-community/core';

import type { IFleetDetail } from 'types/IFleetDetails';
import styles from './AgGridFuelGaugeCellRenderer.module.css';

/**
 *
 * @param percentage
 * @returns
 */
const calculateGaugeColor = (percentage: number) => {
  if (percentage < 30) {
    return 'danger';
  }
  if (percentage > 70) {
    return 'success';
  }

  return 'warning';
};

function AgGridFuelGaugeCellRenderer({ value }: ICellRendererParams<IFleetDetail, number | null>) {
  if (value === undefined || value === null) {
    return <div className="d-flex justify-content-center align-items-center h-100">N/A</div>;
  }
  const percentage = value * 100;

  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <Progress
        className={`position-relative w-100 ${styles['progress'] || ''}`}
        color={calculateGaugeColor(percentage)}
        value={percentage}
      >
        <span className="justify-content-center d-flex position-absolute w-100 text-dark">{percentage === 100 ? 'Full' : value}</span>
      </Progress>
    </div>
  );
}

export default AgGridFuelGaugeCellRenderer;
