import { useGetVehicles } from 'apiHooks/Vehicles.Hook';
import type { IFmcResults, IFmtFmcEntry } from 'types/IOverview';
import type { IVehicle } from 'types/IVehicleDetails';
import type { VehicleStatus } from 'types/common';

const makeFmtFmcEntry = (vehicles: IVehicle[], status: VehicleStatus): IFmtFmcEntry => {
  const relevantVehicles = vehicles.filter((v) => v.status === status);
  const percentage = 100 * (relevantVehicles.length / vehicles.length);
  return {
    value: Math.round(percentage) / 100,
    pprint: `${(percentage * 100).toFixed(0)}%`,
  };
};

const makeFmcResult = (vehicles: IVehicle[]) => (
  vehicles?.[0]
    ? {
      fmc: makeFmtFmcEntry(vehicles, 'FMC'),
      warning: makeFmtFmcEntry(vehicles, 'warning'),
      inOp: makeFmtFmcEntry(vehicles, 'InOp'),
      inMx: makeFmtFmcEntry(vehicles, 'inMx'),
    }
    : null
);

const useSectionFmcResults = (sectionId: number): IFmcResults | undefined => {
  const {
    data: vehiclesData,
  } = useGetVehicles();

  if (!vehiclesData) return undefined;

  const sectionVehicles = vehiclesData.filter((v) => v.sectionId === sectionId);

  const sectionFmcResults: IFmcResults = {
    total: makeFmcResult(sectionVehicles),
    wrm: makeFmcResult(sectionVehicles.filter((v) => v.wrm)),
    other: makeFmcResult(sectionVehicles.filter((v) => !v.wrm)),
  };

  return sectionFmcResults;
};

export default useSectionFmcResults;
