import type { Active, Over } from '@dnd-kit/core';

/*
 * Various helper utilities for dnd-kit sortable context
 *
 * https://docs.dndkit.com/presets/sortable
 */

/**
 *
 * @param activeOver
 * @returns
 */
const getSortableContainer = (activeOver: Active | Over) => activeOver.data.current?.['sortable'];

/**
 *
 * @param active
 * @returns
 */
const getActiveContainerId = (active: Active) => getSortableContainer(active)?.containerId;

/**
 *
 * @param over
 * @returns
 */
const getOverContainerId = (over: Over) => getSortableContainer(over)?.containerId || over.id;

/**
 *
 * @param active
 * @param over
 * @returns
 */
const areSameContainer = (active: Active, over: Over) => (
  getActiveContainerId(active) === getOverContainerId(over)
);

export {
  areSameContainer,
  getActiveContainerId,
  getOverContainerId,
  getSortableContainer,
};
