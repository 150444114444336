import React from 'react';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { type ICellRendererParams } from '@ag-grid-community/core';

import { formatDate } from 'utils/Helpers';
import type { IInspectionCommon } from 'types/IVehicleInspections';
import type { IRequiredMx } from 'types/Mx';

function AgGridVehicleInspectionResultsLinkCellRenderer<T extends IInspectionCommon & IRequiredMx>(
  { data }: ICellRendererParams<T>,
) {
  const navigate = useNavigate();

  if (!data) {
    return <div />;
  }

  const inspectionOrParentInspectionId = data.inspectionId || data.parentInspectionId;

  return (
    inspectionOrParentInspectionId
      ? (
        <Button
          color="link"
          onClick={() => navigate(
            `/Vehicle/${data.regNumber}/Inspection/${inspectionOrParentInspectionId}`,
          )}
        >
          {formatDate(data.dateDiscovered || data.completionDate || data.inspectionDate)}
        </Button>
      )
      : 'N/A'

  );
}

export default AgGridVehicleInspectionResultsLinkCellRenderer;
