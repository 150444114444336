import { atom } from 'jotai';
import setHours from 'date-fns/setHours';
import format from 'date-fns/format';
import setMinutes from 'date-fns/setMinutes';
import { TZDate } from '@date-fns/tz';

import type { IShiftDay } from 'types/IOrganization';

const orgShiftsAtom = atom<IShiftDay[] | undefined>(undefined);

// init to the time zone of the locale
const selectedTimeZoneAtom = atom<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);

/**
 *
 * @param date
 * @returns
 */
const formatShiftTime = (date: Date | TZDate | null | undefined) => (date ? format(date, 'HH:mm') : '');

/**
 *
 * @param shiftTime
 * @returns
 */
const parseShiftTimeAsDate = (shiftTime: string | undefined) => {
  if (!shiftTime) {
    return null;
  }

  const hours = Number.parseInt(shiftTime.slice(0, 2), 10);
  const minutes = Number.parseInt(shiftTime.slice(2, 4), 10);
  return new TZDate(setHours(setMinutes(TZDate.tz('UTC'), minutes), hours), 'UTC');
};

export {
  parseShiftTimeAsDate,
  formatShiftTime,
  orgShiftsAtom,
  selectedTimeZoneAtom,
};
