import { atom, useSetAtom } from 'jotai';

type AdminCollapseState = 'COLLAPSED' | 'EXPANDED';

const adminCollapseStateAtom = atom<AdminCollapseState>('EXPANDED');

const useToggleAdminCollapseState = () => {
  const setAdminCollapseState = useSetAtom(adminCollapseStateAtom);
  return () => (
    setAdminCollapseState(
      (prevState) => (prevState === 'COLLAPSED'
        ? 'EXPANDED'
        : 'COLLAPSED'),
    )
  );
};

export default adminCollapseStateAtom;
export { useToggleAdminCollapseState };
