import React from 'react';
import {
  Button,
  Spinner,
} from 'reactstrap';
import { decode } from 'html-entities';
import { MdEdit } from 'react-icons/md';

import { useGetWorkflows } from 'apiHooks/WorkFlows.Hook';
import { usePatchVehicle } from 'apiHooks/Vehicles.Hook';
import WorkflowsSelect from 'components/fields/WorkflowsSelect';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';

interface IEditWorkflowModal {
  workflowId: number | undefined,
  regNumber: string,
}

function EditWorkflowModal({ workflowId, regNumber }: IEditWorkflowModal) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [workflowName, setWorkflowName] = React.useState<string>('');
  const [tmpWorkflowId, setTmpWorkflowId] = React.useState(workflowId);
  const {
    isLoading,
    isError,
    data,
    error,
  } = useGetWorkflows();
  const patchVehicle = usePatchVehicle();

  React.useEffect(() => {
    if (data) {
      setWorkflowName(data.find((x) => x.workflowId === workflowId)?.title || '');
    }
  }, [data, workflowId]);

  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    return <div>{ error as string }</div>;
  }

  const toggle = () => setModalOpen(!modalOpen);

  const resetState = () => setTmpWorkflowId(workflowId);

  const canSubmit = (
    !!workflowId
    && workflowId !== tmpWorkflowId
  );

  const onSubmit = async () => {
    if (!tmpWorkflowId) return;

    await patchVehicle(regNumber, { workflowId: tmpWorkflowId })
      .then(() => {
        toggle();
        setWorkflowName(data.find((x) => x.workflowId === tmpWorkflowId)?.title || '');
      });
  };

  return (
    <>
      <div className="col d-flex align-items-center">
        {
          workflowName
            ? <span className="text-nowrap">{decode(workflowName)}</span>
            : <span className="text-nowrap text-muted">No Workflow selected</span>
        }
        <Button
          color="link"
          onClick={() => setModalOpen(true)}
          className="py-0"
        >
          <MdEdit />
        </Button>
      </div>
      <RhinoModal
        title={`Upddate Workflow for ${regNumber}`}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        onClosed={resetState}
      >
        <WorkflowsSelect
          id={`inputWorkflowNumber-${regNumber}`}
          onValueChange={(workflow) => {
            setTmpWorkflowId(workflow);
          }}
          defaultValue={workflowId}
          isInvalid={!tmpWorkflowId}
        />
      </RhinoModal>
    </>
  );
}

export default EditWorkflowModal;
