const validatePhoneInput = (input: string) => {
  // matches partial phone numbers
  const regEx = /^(\+?\d{0,2}\s?)?\(?\d{0,3}\)?[\s.-]?\d{0,3}[\s.-]?\d{0,4}$/;

  const matches = input.match(regEx);

  return matches?.[0] || null;
};

const validatePhoneSubmission = (input: string | null) => {
  // matches only entire phone numbers
  const regEx = /(^(\+?\d{1,2}\s?)?((\(\d{3}\))|\d{3})?[\s.-]?\d{3}[\s.-]?\d{4}$)?/;

  const matches = input?.match(regEx);

  return matches?.[0] || null;
};

export {
  validatePhoneInput,
  validatePhoneSubmission,
};
