import type { ICellRendererParams } from '@ag-grid-community/core';
import { usePatchVehicleAuxFile } from 'apiHooks/Vehicles.Hook';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import React, { useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { Button, Input } from 'reactstrap';
import type { IVehicleAuxFile } from 'types/IVehicleDetails';

interface Props {
  regNumber: string;
  file: IVehicleAuxFile;
}

function EditFileDescriptionModal({ regNumber, file }: Props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [description, setDescription] = useState('');

  const patchAuxFile = usePatchVehicleAuxFile(regNumber, file.filename);

  const resetState = () => setDescription('');

  const canSubmit = (
    !!description
    && description !== file.description
  );

  const onSubmit = () => patchAuxFile({ description });

  return (
    <>
      <Button onClick={() => setModalOpen(true)}>
        <MdEdit />
      </Button>

      <RhinoModal
        title={`Edit File Description (${regNumber})`}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        onClosed={resetState}
      >
        Description
        <Input
          defaultValue={file.description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </RhinoModal>
    </>
  );
}

const AgGridEditDocumentCellRenderer = (regNumber: string) => (
  function CellRenderer({ data }: ICellRendererParams) {
    return <EditFileDescriptionModal regNumber={regNumber} file={data as IVehicleAuxFile} />;
  }
);

export default AgGridEditDocumentCellRenderer;
