import type { ValueFormatterParams } from '@ag-grid-community/core';

/**
 * AgGrid Value formatter for date/times
 *
 * @param params
 * @returns
 */
const AgGridDateDeltaFormatter = (params: ValueFormatterParams<unknown, number>) => {
  const { value } = params;

  if (!value) return '';

  if (value < 0) return `${Math.abs(value)} days ago`;
  if (value > 1) return `${value} days away`;
  if (value === 1) return '1 day away';
  return 'Today';
};

export default AgGridDateDeltaFormatter;
