import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAtom, useSetAtom } from 'jotai';
import { decode } from 'html-entities';
import uniq from 'lodash-es/uniq';

import RhinoUserCacheAtom from 'store/Rhino.store';
import { authHttpGet, authHttpPost } from 'utils/Helpers';

const useGetVehicleTypes = () => {
  const [rhinoUserCache] = useAtom(RhinoUserCacheAtom);
  const setVehicleTypes = useSetAtom(rhinoUserCache.vehicleTypesAtom);

  return useQuery({
    queryKey: ['GetVehicleTypes'],
    queryFn: async () => {
      const vehicleTypes = await authHttpGet<string[]>('/data/v2/vehicles/types');

      const dedupedVehicleTypes = uniq(vehicleTypes).map((x) => decode(x)).sort();
      setVehicleTypes(dedupedVehicleTypes);
      return dedupedVehicleTypes;
    },
    refetchOnWindowFocus: false,
  });
};

const usePostVehicleType = () => {
  const queryClient = useQueryClient();

  return (vehicleType: string) => authHttpPost(
    '/data/v2/vehicles/types',
    { vehicleType },
  )
    .then(() => queryClient.invalidateQueries(['GetVehicleTypes']));
};

export {
  useGetVehicleTypes, // eslint-disable-line import/prefer-default-export
  usePostVehicleType,
};
