import type { PointerEvent } from 'react';
import { PointerSensor } from '@dnd-kit/core';

// Adapted from https://github.com/clauderic/dnd-kit/issues/477#issuecomment-985194908

// TODO: rewrite as recursive function.
function shouldHandleEvent(element: HTMLElement | null) {
  let cur = element;

  while (cur) {
    if (cur.dataset && cur.dataset?.['noDnd']) {
      return false;
    }
    cur = cur.parentElement;
  }

  return true;
}

class CustomPointerSensor extends PointerSensor {
  static override activators = [
    {
      eventName: 'onPointerDown' as const,
      handler: ({ nativeEvent: event }: PointerEvent) => (
        shouldHandleEvent(event.target as HTMLElement)
      ),
    },
  ];
}

export default CustomPointerSensor;
