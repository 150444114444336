import React, { useEffect, useState } from 'react';
import type { ITimeSeriesUsageDatum } from 'types/Insights';

import type {
  ChartData, TooltipItem,
} from 'chart.js';
import {
  Chart as ChartJS,
  PointElement,
  LinearScale,
  LineController,
  LineElement,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import zoom from 'chartjs-plugin-zoom';
import {
  dateAsDays,
  daysAsDate,
  stringToColor,
} from 'utils/Helpers';
import { uniq } from 'lodash-es';
import capitalize from 'to-capital-case';
import type { UsageInsightsAxisOption } from '../UsageView';

const makeLabel = (date: Date) => date.toLocaleDateString(
  'en-us',
  {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
);

type FormatData = (
  data: ITimeSeriesUsageDatum[],
  minDate: Date,
  maxDate: Date,
) => ChartData<'scatter', ITimeSeriesUsageDatum[]>;

const formatData: FormatData = (data: ITimeSeriesUsageDatum[], minDate: Date, maxDate: Date) => {
  if (!data[0]) return { datasets: [] };

  const regNumbers = uniq(data.map((datum) => datum.regNumber));

  const vehicleSeries = regNumbers.map((regNumber) => ({
    regNumber,
    series: data.filter((datum) => {
      const date = new Date(datum.date);
      return (
        datum.regNumber === regNumber
        && minDate < date
        && date < maxDate
      );
    }).map((datum) => ({
      days: dateAsDays(new Date(datum.date)),
      ...datum,
    })),
  }));

  if (!data[0]) return { datasets: [] };

  return ({
    datasets: vehicleSeries.map((vehicle) => {
      const color = stringToColor(vehicle.regNumber);
      return ({
        label: vehicle.regNumber,
        data: vehicle.series,
        backgroundColor: color,
        borderColor: color,
      });
    }),
  });
};

ChartJS.register(
  LineController,
  PointElement,
  LineElement,
  LinearScale,
  zoom,
);

type TimeSeriesUsageChartProps = {
  data: ITimeSeriesUsageDatum[],
  axisOption: UsageInsightsAxisOption,
  minDate: Date,
  maxDate: Date,
};

export default function TimeSeriesUsageChart({
  data,
  axisOption,
  minDate,
  maxDate,
}: TimeSeriesUsageChartProps) {
  const [chartData, setChartData] = useState<ChartData<'scatter', ITimeSeriesUsageDatum[]>>(
    formatData(data, minDate, maxDate),
  );

  useEffect(
    () => setChartData(formatData(data, minDate, maxDate)),
    [data, axisOption, maxDate, minDate],
  );

  const afterLabelCallback = (item: TooltipItem<'scatter'>) => (
    `${makeLabel(
      new Date((item.raw as ITimeSeriesUsageDatum).date),
    )}: ${(
      (item.raw as ITimeSeriesUsageDatum)[axisOption]
    )} ${axisOption}`
  );

  return (
    <div className="w-100">
      <Scatter
        id="TimeSeriesUsageChart"
        data={chartData}
        options={{
          responsive: true,
          aspectRatio: 4,
          showLine: true,
          scales: {
            x: {
              min: dateAsDays(minDate),
              max: dateAsDays(maxDate),
              type: 'linear',
              ticks: {
                callback: (value) => makeLabel(daysAsDate(value as number)),
              },
              title: {
                display: true,
                text: 'Date',
              },
            },
            y: {
              title: {
                display: true,
                text: capitalize(axisOption),
              },
              // beginAtZero: true,
            },
          },
          datasets: {
            scatter: {
              pointHoverRadius: 7,
            },
          },
          parsing: {
            xAxisKey: 'days',
            yAxisKey: axisOption,
          },
          animation: {
            duration: 200,
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: (item) => (item.raw as ITimeSeriesUsageDatum).regNumber,
                afterLabel: afterLabelCallback,
              },
            },
            legend: {
              display: false,
              position: 'right',
            },
            // zoom: {
            //   limits: {
            //     x: {
            //       min: dateAsDays(minDate),
            //       max: dateAsDays(maxDate),
            //     },
            //     y: {
            //       min: 0,
            //     },
            //   },
            //   pan: {
            //     enabled: true,
            //     mode: 'x',
            //   },
            //   zoom: {
            //     mode: 'x',
            //     scaleMode: 'x',
            //     wheel: {
            //       enabled: true,
            //     },
            //   },
            // },
          },
        }}
      />
    </div>
  );
}
