import { useSetAtom } from 'jotai';
import { useQuery } from '@tanstack/react-query';

import emptyAf1800PdfAtom from 'store/EmptyAf1800Pdf.store';
import type { IAf1800Result } from 'types/IAF1800Pdf';
import { authHttpGet, userInfo } from 'utils/Helpers';

const useAf1800ReportPdf = (enabled: boolean) => {
  const setPdfArrayBuffer = useSetAtom(emptyAf1800PdfAtom);

  return useQuery({
    queryKey: ['GetEmptyPdf'],
    queryFn: () => (
      fetch('/Af1800.pdf').then(async (pdfResponse) => {
        const buffer = await pdfResponse.arrayBuffer();
        setPdfArrayBuffer(buffer);
        return buffer;
      })),
    enabled,
    refetchOnWindowFocus: false,
  });
};

const useGetRecentVehicleAF1800Report = (regNumber: string, enabled: boolean) => (
  useQuery({
    queryKey: ['GetRecentVehicleAF1800', regNumber],
    queryFn: () => (
      authHttpGet<IAf1800Result>(`/data/v1/vehicles/${regNumber}/files/af1800/recent`)
    ),
    enabled,
    retry: false,
  })
);

const useGetHistoricAF1800Reports = (year: number, month: number) => (
  useQuery({
    queryKey: ['GetVehiclesHistoricAF1800', month, year],
    queryFn: () => (
      authHttpGet<IAf1800Result[]>(`/data/v1/organizations/${userInfo()?.orgId}/reports/af1800/${year}/${month < 10 ? '0' : ''}${month}`)
    ),
    retry: false,
  })
);

const useGetHistoricAF1800Report = (
  year: number,
  month: number,
  regNumber: string,
  enabled: boolean,
) => (
  useQuery({
    queryKey: ['GetVehicleHistoricAF1800', month, year, regNumber],
    queryFn: () => (
      authHttpGet(`/data/v1/vehicles/${regNumber}/files/af1800/${year}/${month < 10 ? '0' : ''}${month}`)
    ),
    retry: false,
    enabled,
  })
);

export {
  useGetRecentVehicleAF1800Report,
  useGetHistoricAF1800Reports,
  useGetHistoricAF1800Report,
  useAf1800ReportPdf,
};
