import React from 'react';
import { useAtom } from 'jotai';
import { Typeahead, type TypeaheadRef } from 'react-bootstrap-typeahead';

import { TIMEZONE_OPTIONS, TIMEZONE_OPTIONS_LABELS } from 'utils/constants/TimeZoneSelectOptions';
import { selectedTimeZoneAtom } from './shiftTimeUtils';

function SelectTimeZone() {
  const [selectedTimeZone, setSelectedTimeZone] = useAtom(selectedTimeZoneAtom);
  const typeaheadRef = React.useRef<TypeaheadRef>(null);

  return (
    <div className="d-flex flex-column gap-1 align-items-start">
      <span className="px-2">
        Time Zone:
        {' '}
        <span className="fw-bold">
          {TIMEZONE_OPTIONS_LABELS[selectedTimeZone]}
        </span>
      </span>
      <Typeahead
        ref={typeaheadRef}
        className="w-50"
        id="time-zone-typeahead"
        placeholder="Search time zones"
        options={TIMEZONE_OPTIONS}
        labelKey={(option) => TIMEZONE_OPTIONS_LABELS[option as string] ?? option as string}
        onChange={(selected) => {
          if (selected[0]) {
            setSelectedTimeZone(selected[0] as string);
            typeaheadRef.current?.clear();
            typeaheadRef.current?.blur();
          }
        }}
        onBlur={() => typeaheadRef.current?.hideMenu()}
      />
    </div>
  );
}

export default SelectTimeZone;
