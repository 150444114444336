import React from 'react';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { Button } from 'reactstrap';
import { CSS } from '@dnd-kit/utilities';

import type { NewRuleType } from 'types/IWorkflows';

import { WORKFLOW_NEW_RULE_LABEL } from 'utils/Constants';

interface INewRuleItem {
  newRuleType: NewRuleType,
  id: string,
  disabled?: boolean | undefined,
}

/**
 *
 * @param param0
 * @returns
 */
function NewRuleItem({ newRuleType, id, disabled }: INewRuleItem) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id, data: { newRuleType } });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes} // eslint-disable-line react/jsx-props-no-spreading
      {...listeners} // eslint-disable-line react/jsx-props-no-spreading
    >
      <Button outline disabled={disabled} className="px-1 text-nowrap text-dark text-truncate p-3 mb-2 w-100">
        { WORKFLOW_NEW_RULE_LABEL[newRuleType] }
      </Button>
    </div>
  );
}

function NewPassFailRule({ id }: { id: string }) {
  return (
    <SortableContext
      id="PASS_FAIL"
      items={[{ id }]}
    >
      <NewRuleItem
        newRuleType="PASS_FAIL"
        id={id}
      />
    </SortableContext>
  );
}

function NewNumericInputRule({ id }: { id: string }) {
  return (
    <SortableContext
      id="NUMERIC_INPUT"
      items={[{ id }]}
    >
      <NewRuleItem
        newRuleType="NUMERIC_INPUT"
        id={id}
      />
    </SortableContext>
  );
}

function NewCustomRule({ id, disabled }: { id: string, disabled: boolean }) {
  return (
    <SortableContext
      id="CUSTOM"
      items={[{ id }]}
      disabled={disabled}
    >
      <NewRuleItem
        newRuleType="CUSTOM"
        id={id}
        disabled={disabled}
      />
    </SortableContext>
  );
}

function AddMissingAf1800Rule({ id, disabled }: { id: string, disabled: boolean }) {
  return (
    <SortableContext
      id="MISSING_AF_1800"
      items={[{ id }]}
      disabled={disabled}
    >
      <NewRuleItem
        newRuleType="MISSING_AF_1800"
        id={id}
        disabled={disabled}
      />
    </SortableContext>
  );
}

export {
  NewPassFailRule,
  NewCustomRule,
  NewNumericInputRule,
  AddMissingAf1800Rule,
};
