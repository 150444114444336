import React from 'react';
import {
  Button,
  ModalFooter,
} from 'reactstrap';
import { MdEdit } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';

import { displayNumber } from '@stonewall-defense/swd-ui-utils';
import { usePatchVehicle } from 'apiHooks/Vehicles.Hook';
import { RhinoModalBody, RhinoModalContainer, RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';
import useRestrictUpdates from 'utils/helpers/useRestrictUpdates.Helper';

interface IEditMilesHoursModal {
  regNumber: string,
  milesHoursValue: number | null,
  milesOrHours: 'miles' | 'hours',
}

function EditMilesHoursModal({ regNumber, milesHoursValue, milesOrHours }: IEditMilesHoursModal) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [tmpMilesHours, setTmpMilesHours] = React.useState(milesHoursValue);
  const patchVehicle = usePatchVehicle();

  const label = milesOrHours === 'hours' ? 'Last Report Hours' : 'Last Report Mileage';

  const restrictUpdates = useRestrictUpdates();

  const toggle = () => {
    setModalOpen(!modalOpen);
  };

  const resetState = () => setTmpMilesHours(milesHoursValue);

  const canSubmit = (
    !!tmpMilesHours
    && tmpMilesHours !== milesHoursValue
  );

  const onSubmit = async () => {
    if (!canSubmit) {
      return;
    }

    await patchVehicle(regNumber, { [milesOrHours]: tmpMilesHours })
      .then(() => {
        toggle();
      });
  };

  if (milesHoursValue === null) {
    return null;
  }

  return (
    <>
      <div className="d-grid gap-2 mt-3">
        <div className="d-flex align-items-center">
          <span className="text-nowrap text-gray-dark">
            {`${label}: ${displayNumber(milesHoursValue)}`}
          </span>
          {!restrictUpdates && (
            <Button color="link" onClick={toggle} className="py-0">
              <MdEdit />
            </Button>
          )}
        </div>
      </div>
      <RhinoModalContainer
        isOpen={modalOpen}
        toggle={toggle}
        onClosed={resetState}
      >
        <RhinoModalHeader toggle={toggle}>
          {`Edit ${label} For ${regNumber}`}
        </RhinoModalHeader>
        <RhinoModalBody>
          <NumericFormat
            allowNegative={false}
            decimalScale={0}
            min={1}
            thousandSeparator
            className="form-control"
            defaultValue={milesHoursValue}
            placeholder=" "
            id="floatingReportMilesInput"
            onValueChange={({ floatValue }) => setTmpMilesHours(floatValue || null)}
          />
        </RhinoModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={onSubmit}
            disabled={!tmpMilesHours || tmpMilesHours === milesHoursValue}
          >
            Update
          </Button>
        </ModalFooter>
      </RhinoModalContainer>
    </>
  );
}

export default EditMilesHoursModal;
