import React from 'react';
import type { Dispatch, SetStateAction } from 'react';
import RhinoModal from './RhinoModal/RhinoModal';
import DownloadPwaButton from './DownloadPwaButton';

type Props = {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

function DownloadPwaModal(props: Props) {
  return (
    <RhinoModal
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      title="Mobile App"
      size="xs"
    >
      <DownloadPwaButton />
    </RhinoModal>
  );
}

export default DownloadPwaModal;
