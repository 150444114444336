import React from 'react';
import { Button } from 'reactstrap';
import type {
  GridApi,
  GridReadyEvent,
  IRowNode,
} from '@ag-grid-community/core';
import { MdFilterAlt } from 'react-icons/md';
import { useAtom } from 'jotai';
import isEqual from 'lodash-es/isEqual';

import { useGetWorkflows } from 'apiHooks/WorkFlows.Hook';
import { FILTER_TYPES } from 'utils/Constants';
import type { IWorkflowDetails } from 'types/IWorkflows';
import type {
  IColumnFilterText,
  IColumnFilterDateTime,
  IDateTimeFilter,
  IColumnFilterToggle,
} from 'types/IFilter';
import RhinoUserCacheAtom from 'store/Rhino.store';

import Loading from 'components/shared/Loading/Loading';
import Filter from 'components/shared/Filter/Filter';
import { usePatchUserSettings, useGetUserSettings } from 'apiHooks/Users.Hook';
import SubmitButton from 'components/shared/SubmitButton';
import RhinoGrid from 'components/shared/RhinoGrid';
import { InspectionWorkflowsFilterAtom, InspectionWorkflowsInitialFilterAtom } from './InspectionWorkflowsFilter.store';
import type { InspectionWorkflowField } from './InspectionWorkflowsAgGridColDefs.store';
import { INSPECTION_WORKFLOWS_FIELDS, InspectionWorkflowsAgGridColDefsAtom } from './InspectionWorkflowsAgGridColDefs.store';
import { InspectionWorkflowsColumnsAtom } from './InspectionWorkflowsColumns.store';

const isFilterApplied = () => (true);

const passesToggleFilter = (toggleFilter: IColumnFilterToggle, colData: boolean) => (
  (toggleFilter.isHidden && colData) || (!toggleFilter.isHidden)
);

const passesTextFilter = (textFilter: IColumnFilterText, colData: string) => (
  !textFilter.filterValue.trim() || (colData || '').toUpperCase().includes(textFilter.filterValue.toUpperCase())
);

const passesDateTimeFilter = (dateFilter: IColumnFilterDateTime, colData: string) => {
  const filterValue: IDateTimeFilter = { ...dateFilter.filterValue };
  const dateVal = new Date(colData);

  return ((!filterValue.endDateTime || dateVal <= filterValue.endDateTime)
  && (!filterValue.startDateTime || dateVal >= filterValue.startDateTime));
};

function InspectionWorkflows() {
  const [showFilterMenu, setShowFilterMenu] = React.useState(false);
  const [gridApi, setGridApi] = React.useState<GridApi>();
  const [rhinoUserCache, setRhinoUserCache] = useAtom(RhinoUserCacheAtom);
  const [columnDefs] = useAtom(InspectionWorkflowsAgGridColDefsAtom);
  const [appliedFilter, setAppliedFilter] = useAtom(InspectionWorkflowsFilterAtom);
  const [columnsState, setColumnsState] = useAtom(InspectionWorkflowsColumnsAtom);
  const [workflowsInitialFilter] = useAtom(InspectionWorkflowsInitialFilterAtom);
  const patchUserSettings = usePatchUserSettings();
  const { data: userSettings } = useGetUserSettings();

  const onColumnMoved = () => {
    setColumnsState(gridApi?.getColumnState());
  };

  const saveGridView = () => (
    patchUserSettings([
      { field: 'inspectionWorkflowsFilter', value: appliedFilter },
      { field: 'inspectionWorkflowsColumnsState', value: gridApi?.getColumnState() },
    ])
  );

  React.useEffect(() => {
    if (!userSettings) return;
    const { inspectionWorkflowsFilter, inspectionWorkflowsColumnsState } = userSettings;

    if (inspectionWorkflowsFilter) {
      setAppliedFilter(inspectionWorkflowsFilter);
    }
    if (columnsState) {
      gridApi?.applyColumnState({
        state: columnsState,
        applyOrder: true,
      });
    } else if (inspectionWorkflowsColumnsState) {
      gridApi?.applyColumnState({
        state: inspectionWorkflowsColumnsState,
        applyOrder: true,
      });
    }
  }, [gridApi, columnsState, setAppliedFilter, userSettings]);

  const {
    isLoading,
    isError,
    data,
    error,
  } = useGetWorkflows();

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  React.useEffect(() => {
    const visibleCols = INSPECTION_WORKFLOWS_FIELDS.filter((key) => !appliedFilter[key]?.isHidden);
    const hiddenCols = INSPECTION_WORKFLOWS_FIELDS.filter((key) => appliedFilter[key]?.isHidden);
    gridApi?.setColumnsVisible(visibleCols, true);
    gridApi?.setColumnsVisible(hiddenCols, false);
    gridApi?.onFilterChanged();
  }, [appliedFilter, gridApi]);

  React.useEffect(() => {
    if (!rhinoUserCache.InspectionWorkflowsInitialFilterAtom
      || !rhinoUserCache.InspectionWorkflowsFilterAtom
      || !rhinoUserCache.InspectionWorkflowsAgGridColDefsAtom) {
      setRhinoUserCache((prev) => ({
        ...prev,
        InspectionWorkflowsAgGridColDefsAtom,
        InspectionWorkflowsFilterAtom,
        InspectionWorkflowsInitialFilterAtom,
      }));
    }
  }, [rhinoUserCache]); // eslint-disable-line react-hooks/exhaustive-deps

  const resolveFilter = (
    isDisplayed: boolean,
    key: InspectionWorkflowField,
    node: IRowNode<IWorkflowDetails>,
  ) => {
    const workflowKey = key as keyof IWorkflowDetails;

    if (!isDisplayed || appliedFilter[key] === undefined) {
      return isDisplayed;
    }

    const colFilter = appliedFilter[key];
    const nodeData = node.data;

    if (colFilter === undefined || nodeData === undefined) {
      return isDisplayed;
    }

    if (colFilter.filterType === FILTER_TYPES.TOGGLE && workflowKey === 'active') {
      return passesToggleFilter(colFilter, nodeData[workflowKey]);
    }

    if (colFilter.isHidden) {
      return isDisplayed;
    }

    if (colFilter.filterType === FILTER_TYPES.TEXT && workflowKey === 'lastModifierName') {
      const { firstName, lastName } = nodeData[workflowKey];
      return passesTextFilter(colFilter, `${firstName} | ${lastName}`);
    }

    if (colFilter.filterType === FILTER_TYPES.TEXT) {
      return passesTextFilter(colFilter, nodeData[workflowKey] as string);
    }

    if (colFilter.filterType === FILTER_TYPES.DATE_TIME) {
      const colData = nodeData[workflowKey] as string;
      return passesDateTimeFilter(colFilter, colData);
    }

    return isDisplayed;
  };

  const externalFilter = (node: IRowNode<IWorkflowDetails>) => (
    INSPECTION_WORKFLOWS_FIELDS.reduce((isDisplayed, key) => (
      resolveFilter(isDisplayed, key, node)
    ), true)
  );

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <div>{ error as string }</div>;
  }

  return (
    <div className="d-flex flex-column bg-gray bg-gradient px-3 py-2 h-100">
      <h2 className="text-secondary px-3">Inspection Workflows</h2>
      <div className="d-flex justify-content-end bg-dark bg-gradient rounded-top p-3">
        <div className="d-flex gap-3">
          <SubmitButton
            title="Save filter options and column order"
            onSubmit={saveGridView}
            canSubmit
          >
            Save View
          </SubmitButton>

          <Button
            title="Filter Workflows"
            className="align-self-center border-0 bg-transparent"
            onClick={() => setShowFilterMenu(!showFilterMenu)}
          >
            <MdFilterAlt className={(isEqual(appliedFilter, workflowsInitialFilter) ? 'text-white' : 'text-primary')} />
          </Button>
        </div>
      </div>
      <div className="ag-theme-material w-100 h-100">

        <Filter filterName="Workflows" isOpened={showFilterMenu} />

        <RhinoGrid<IWorkflowDetails>
          onGridReady={onGridReady}
          rowData={data || []}
          columnDefs={columnDefs}
          isExternalFilterPresent={isFilterApplied}
          doesExternalFilterPass={externalFilter}
          onColumnMoved={onColumnMoved}
        />
      </div>
    </div>
  );
}

export default InspectionWorkflows;
