import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';
import { MdCameraAlt } from 'react-icons/md';
import { RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';
import ImageLightBox, { type IImageLightBox } from './ImageLightBox';

interface IImageLightBoxModal extends IImageLightBox {
  modalTitle: string,
  deleteImageCallback?: (() => Promise<void | void[]>),
}

interface IDeleteImageConfirmation {
  deleteImageCallback: () => Promise<void | void[]>
}

function DeleteImageConfirmation({ deleteImageCallback }: IDeleteImageConfirmation) {
  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
  const toggleConfirmation = () => setConfirmationModalOpen(!confirmationModalOpen);

  const deleteImage = () => {
    deleteImageCallback()
      .then(toggleConfirmation)
      .catch(() => {});
  };

  return (
    <>
      <Button onClick={toggleConfirmation}>
        Delete
      </Button>
      <Modal
        isOpen={confirmationModalOpen}
        toggle={toggleConfirmation}
      >
        <RhinoModalHeader toggle={toggleConfirmation}>Confirm deletion</RhinoModalHeader>
        <ModalBody className="d-flex flex-column">
          <h5 className="text-center py-2 fw-bold">Are you sure you want to remove this picture?</h5>
          <div className="text-center">This action cannot be undone.</div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button onClick={toggleConfirmation}>
            Cancel
          </Button>
          <Button color="danger" onClick={deleteImage}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

/**
 *
 * @param param0
 * @returns
 */
function ImageLightBoxModal({
  pictures,
  modalTitle,
  preLoaded,
  updateCaptionCallback,
  currentRhinoImageCallback,
  deleteImageCallback,
}: IImageLightBoxModal) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggle = () => setModalOpen(!modalOpen);

  return (
    <>
      <Button color="link" onClick={toggle}>
        <MdCameraAlt />
      </Button>
      <Modal isOpen={modalOpen} className="image-lightbox-modal" toggle={toggle}>
        <RhinoModalHeader toggle={toggle}>
          {modalTitle}
        </RhinoModalHeader>
        <ModalBody>
          {
            pictures
              ? (
                <ImageLightBox
                  pictures={pictures || []}
                  preLoaded={preLoaded || false}
                  updateCaptionCallback={updateCaptionCallback}
                  currentRhinoImageCallback={currentRhinoImageCallback}
                />
              )
              : <Spinner />
          }
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <div />
          {
            deleteImageCallback
              ? <DeleteImageConfirmation deleteImageCallback={deleteImageCallback} />
              : <div />
          }
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageLightBoxModal;
