import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useGetInspections } from 'apiHooks/Insights.Hook';
import { useGetUsers } from 'apiHooks/Users.Hook';
import { Card, CardTitle } from 'reactstrap';
import type { TypeaheadRef } from 'react-bootstrap-typeahead';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useGetFleet } from 'apiHooks/Vehicles.Hook';
import { uniq } from 'lodash-es';
import type { IInspectionDatum } from 'types/Insights';
import Loading from 'components/shared/Loading/Loading';
import { decode } from 'html-entities';
import type { IFleetDetail } from 'types/IFleetDetails';
import InspectionTimePerDayChart from './charts/InspectionTimePerDayChart';
import AverageInspectionTimesPerVehicleChart from './charts/AverageInspectionTimesPerVehicleChart';
import AverageInspectionTimesPerInspectorChart from './charts/AverageInspectionTimesPerInspectorChart';

export default function InspectionsView({
  startDate,
  endDate,
}: {
  startDate: Date,
  endDate: Date,
}) {
  const [selecteVehicleTypes, setSelectedVehicleTypes] = useState<string[]>([]);
  const [filteredInspectionsData, setFilteredInspectionsData] = useState<IInspectionDatum[]>([]);

  const vehicleTypesTypeaheadRef = useRef<TypeaheadRef>(null);

  const {
    data: inspectionsData,
    isLoading: inspectionsLoading,
    isError: isInspectionsError,
    error: inspectionsError,
    refetch: refetchInspections,
  } = useGetInspections(startDate, endDate);

  const {
    data: vehiclesData,
    isLoading: vehiclesAreLoading,
    isError: isVehiclesError,
    error: vehiclesError,
  } = useGetFleet();

  const {
    data: userData,
    isLoading: usersLoading,
    isError: isUsersError,
    error: usersError,
  } = useGetUsers();

  const vehiclesRecord: Record<string, IFleetDetail> = useMemo(
    (
      () => (vehiclesData ? (
        vehiclesData.reduce((a, v) => ({
          ...a,
          [v.regNumber]: v,
        }), {})
      ) : ({}))
    ), [vehiclesData],
  );

  const presentVehicleTypes: string[] = useMemo(
    (
      () => (inspectionsData ? (
        uniq(
          inspectionsData.map((datum) => (
            vehiclesRecord[datum.regNumber]?.vehicleType ?? ''
          )),
        )) : ([]))
    ), [inspectionsData, vehiclesRecord],
  );

  const inspectorIdNameMap: Record<string, string> = useMemo(
    (
      () => (userData ? (
        userData.reduce((o, user) => ({
          ...o,
          [user.userId]: `${user.firstName} ${user.lastName}`,
        }), {})
      ) : ({}))
    ), [userData],
  );

  const vehicleTypesToFilter = selecteVehicleTypes;

  const filterInspectionsData = useCallback(() => {
    if (!inspectionsData) {
      setFilteredInspectionsData([]);
      return;
    }
    const filteredData = vehicleTypesToFilter[0] ? (
      inspectionsData.filter((datum) => (
        vehicleTypesToFilter.includes(
          vehiclesRecord[datum.regNumber]?.vehicleType ?? '',
        )
      ))
    ) : (inspectionsData);
    setFilteredInspectionsData(filteredData);
  }, [inspectionsData, vehicleTypesToFilter, vehiclesRecord]);

  useEffect(() => {
    refetchInspections().catch(() => {});
  }, [startDate, endDate, refetchInspections]);

  useEffect(() => {
    filterInspectionsData();
  }, [filterInspectionsData]);

  if (inspectionsLoading
    || usersLoading
    || vehiclesAreLoading
    || !filteredInspectionsData
    || !userData
    || !vehiclesData
  ) {
    return <Loading />;
  }
  if (isInspectionsError || isUsersError || isVehiclesError) {
    return (
      <div>
        {inspectionsError as string}
        {usersError as string}
        {vehiclesError as string}
      </div>
    );
  }

  return (
    <div className="d-flex flex-column align-items-center w-100 gap-3 align-items-center">

      <div className="d-flex w-100 gap-3">
        <Typeahead
          id="select-vehicle-types-typeahead"
          className="w-100"
          multiple
          clearButton
          ref={vehicleTypesTypeaheadRef}
          options={presentVehicleTypes}
          placeholder="Filter by Vehicle Type"
          labelKey={(vehicleType) => decode(vehicleType as string)}
          onChange={(selected) => setSelectedVehicleTypes(selected as string[])}
        />

      </div>

      <Card className="p-4 shadow border w-100 display-block">
        <CardTitle className="text-center">
          <h4>Total Inspection Time / Day</h4>
        </CardTitle>
        <InspectionTimePerDayChart
          data={filteredInspectionsData}
          minDate={startDate}
          maxDate={endDate}
        />
      </Card>

      <div className="d-flex gap-3 w-100">
        <Card className="p-4 shadow border w-100">
          <CardTitle className="text-center">
            <h4 className="text-center">Average Inspection Time / Inspector</h4>
          </CardTitle>
          <AverageInspectionTimesPerInspectorChart
            data={filteredInspectionsData}
            inspectorIdNameMap={inspectorIdNameMap}
          />
        </Card>

        <Card className="p-4 shadow border w-100">
          <CardTitle className="text-center">
            <h4 className="text-center">Average Inspection Time / Vehicle</h4>
          </CardTitle>
          <AverageInspectionTimesPerVehicleChart
            data={filteredInspectionsData}
            vehiclesRecord={vehiclesRecord}
          />
        </Card>

      </div>

    </div>
  );
}
