import React from 'react';
import {
  atom,
  useAtom,
  useAtomValue,
} from 'jotai';
import { Button } from 'reactstrap';
import isSameMinute from 'date-fns/isSameMinute';
import format from 'date-fns/format';
import { TZDate } from '@date-fns/tz';
import ReactDatePicker from 'react-datepicker';

import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import type { IShiftDay, IShift } from 'types/IOrganization';

import {
  parseShiftTimeAsDate,
  orgShiftsAtom,
  selectedTimeZoneAtom,
  formatShiftTime,
} from './shiftTimeUtils';

interface ISetSingleShiftModal {
  dayIndex: number,
  shiftNumber: number,
}

function SetSingleShiftModal({
  dayIndex,
  shiftNumber,
}: ISetSingleShiftModal) {
  const [modalOpen, setModalOpen] = React.useState(false);

  const timeZone = useAtomValue(selectedTimeZoneAtom);
  const [utcShifts, setUtcShifts] = useAtom(orgShiftsAtom);

  const [localizedShiftDates] = useAtom(
    React.useMemo(() => atom((get) => {
      const shifts = get(orgShiftsAtom);
      if (!shifts) {
        return null;
      }
      const day = shifts[dayIndex];

      if (!day) {
        return null;
      }
      const dayShift = day.shifts.find((x) => x.number === shiftNumber);

      if (!dayShift) {
        return null;
      }

      const utcStartDate = parseShiftTimeAsDate(dayShift.start);
      const utcEndDate = parseShiftTimeAsDate(dayShift.end);

      return {
        startLocalDate: utcStartDate ? utcStartDate.withTimeZone(timeZone) : null,
        endLocalDate: utcEndDate ? utcEndDate.withTimeZone(timeZone) : null,
      };
    }), [dayIndex, shiftNumber, timeZone]),
  );

  const [
    startTime,
    setStartTime,
  ] = React.useState(localizedShiftDates?.startLocalDate as Date | null);
  const [endTime, setEndTime] = React.useState(localizedShiftDates?.endLocalDate as Date | null);

  React.useEffect(() => {
    setStartTime(localizedShiftDates?.startLocalDate as Date | null);
    setEndTime(localizedShiftDates?.endLocalDate as Date | null);
  }, [timeZone]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleModalOpen = () => setModalOpen((state) => !state);

  const canSetShifts = !!(
    (!startTime && !endTime)
    || (startTime
      && endTime
      && (
        !localizedShiftDates?.startLocalDate
        || !localizedShiftDates?.endLocalDate
        || !isSameMinute(startTime, localizedShiftDates?.startLocalDate)
        || !isSameMinute(endTime, localizedShiftDates?.endLocalDate))
    )
  );

  const onSubmit = () => {
    const currentDay = (utcShifts || [])[dayIndex];

    if (!utcShifts || !currentDay) {
      return Promise.reject();
    }

    const shifts = [1, 2, 3].reduce((newShifts: IShift[], currentShiftNumber) => {
      if (shiftNumber === currentShiftNumber && startTime && endTime) {
        return [
          ...newShifts,
          {
            number: shiftNumber,
            start: `${format(new TZDate(startTime, 'UTC'), 'HHmm')}z`,
            end: `${format(new TZDate(endTime, 'UTC'), 'HHmm')}z`,
          },
        ];
      }

      if (shiftNumber !== currentShiftNumber) {
        const existingShift = currentDay.shifts.find((x) => x.number === currentShiftNumber);

        if (existingShift) {
          return [
            ...newShifts,
            existingShift,
          ];
        }
      }

      return newShifts;
    }, []);

    const updatedShifts: IShiftDay[] = [
      ...utcShifts.slice(0, dayIndex),
      {
        day: currentDay.day,
        shifts,
      },
      ...utcShifts.slice(dayIndex + 1),
    ];
    setUtcShifts(updatedShifts);

    return Promise.resolve();
  };

  return (
    <>
      <Button onClick={toggleModalOpen}>
        {`${formatShiftTime(localizedShiftDates?.startLocalDate)} - ${formatShiftTime(localizedShiftDates?.endLocalDate)}`}
      </Button>
      <RhinoModal
        title={`Set times for shift ${shiftNumber} on ${(utcShifts || [])[dayIndex]?.day || ''}`}
        centered
        isOpen={modalOpen}
        setIsOpen={toggleModalOpen}
        canSubmit={canSetShifts}
        onSubmit={onSubmit}
        submitButtonText="Set Shift"
      >
        <div className="d-flex px-4 justify-content-evenly">
          <div className="d-flex flex-column">
            Start Time
            <ReactDatePicker
              className="col-8"
              showIcon
              showTimeSelect
              showTimeSelectOnly
              timeFormat="HH:mm"
              selected={startTime}
              onChange={(date) => setStartTime(date)}
              value={startTime ? formatShiftTime(startTime) : 'unset'}
            />
          </div>
          <div className="d-flex flex-column">
            End Time
            <ReactDatePicker
              className="col-8"
              showIcon
              showTimeSelect
              showTimeSelectOnly
              timeFormat="HH:mm"
              selected={endTime}
              onChange={(date) => setEndTime(date)}
              value={endTime ? formatShiftTime(endTime) : 'unset'}
            />
          </div>
        </div>
      </RhinoModal>
    </>
  );
}

export default SetSingleShiftModal;
