import type { ColDef, CellClassParams, ValueGetterParams } from '@ag-grid-community/core';
import { atomWithReset } from 'jotai/utils';

import AgGridBooleanIconCellRenderer from 'components/agGridRenderers/AgGridBooleanIconCellRenderer';
import AgGridDateValueFormatter from 'components/agGridRenderers/AgGridDateValueFormatter';
import AgGridAf1800DownloadCellRenderer from 'components/agGridRenderers/AgGridAf1800DownloadCellRenderer';
import AgGridFleetDetailStatusCellRenderer from 'views/FleetDetails/AgGridFleetDetailStatusCellRenderer';
import AgGridFuelGaugeCellRenderer from 'views/FleetDetails/AgGridFuelGaugeCellRenderer';
import AgGridFleetDetailVehicleLinkCellRenderer from 'views/FleetDetails/AgGridFleetDetailVehicleLinkCellRenderer';
import AgGridHtmlDecodeValueFormatter from 'components/agGridRenderers/AgGridHtmlDecodeValueFormatter';

import type { IFleetDetail } from 'types/IFleetDetails';
import {
  FLEET_DETAIL_FIELDS,
  FLEET_DETAIL_FIELD_HEADER_MAP,
  FLEET_DETAIL_FIELD_INITIAL_FILTERS,
  FLEET_DETAIL_BOOLEAN_ICON_FIELDS,
  FLEET_DETAIL_DATE_TIME_FIELDS,
} from './FleetDetailTableColumns';
import AgGridVehicleActiveCellRenderer from './AgGridVehicleActiveCellRenderer';

const AgGridSectionNameGetter = (params: ValueGetterParams<IFleetDetail>) => (
  params.data?.sectionName || ''
);

const initialColumns: ColDef[] = FLEET_DETAIL_FIELDS.map((field) => {
  const headerName = FLEET_DETAIL_FIELD_HEADER_MAP[field] || '';
  const initialHide = FLEET_DETAIL_FIELD_INITIAL_FILTERS[field]?.isHidden || false;
  if (FLEET_DETAIL_BOOLEAN_ICON_FIELDS.includes(field)) {
    return {
      field,
      headerName,
      width: 0,
      // resizable: false,
      cellRenderer: AgGridBooleanIconCellRenderer,
      initialHide,
    };
  }

  if (field === 'pdf') {
    return {
      field,
      headerName,
      cellRenderer: AgGridAf1800DownloadCellRenderer,
      width: 65,
      initialHide,
      pinned: 'left',
    };
  }

  if (field === 'status') {
    return {
      field,
      headerName,
      width: 55,
      cellRenderer: AgGridFleetDetailStatusCellRenderer,
      initialHide,
      pinned: 'left',
    };
  }

  if (field === 'fuelLevel') {
    return {
      field,
      headerName,
      cellRenderer: AgGridFuelGaugeCellRenderer,
      initialHide,
    };
  }

  if (field === 'daysToEtic') { // cSpell: disable-line
    return {
      field,
      headerName,
      initialHide,
      cellClassRules: {
        'text-success': (parameters: CellClassParams) => (
          Number.parseInt(parameters.value as string, 10) >= 7
        ),
        'text-warning': (parameters: CellClassParams) => (
          Number.parseInt(parameters.value as string, 10) < 7
          && Number.parseInt(parameters.value as string, 10) >= 0
        ),
        'text-danger': (parameters: CellClassParams) => (
          Number.parseInt(parameters.value as string, 10) < 0
        ),
      },
    };
  }

  if (field === 'regNumber') {
    return {
      field,
      headerName,
      cellRenderer: AgGridFleetDetailVehicleLinkCellRenderer,
      initialHide,
      pinned: 'left',
      width: 135,
    };
  }

  if (field === 'inCommission') {
    return {
      headerName,
      valueGetter: (params: ValueGetterParams<IFleetDetail>) => (
        params.data?.decommissionDate === null
      ),
      cellRenderer: AgGridVehicleActiveCellRenderer,
      initialHide: false,
      width: 150,
    };
  }

  if (field === 'inspectionDate') {
    return {
      field,
      headerName,
      valueFormatter: AgGridDateValueFormatter,
      initialHide,
      sort: 'desc',
    };
  }

  if (FLEET_DETAIL_DATE_TIME_FIELDS.includes(field)) {
    return {
      field,
      headerName,
      valueFormatter: AgGridDateValueFormatter,
      initialHide,
    };
  }

  if (field === 'sectionId') {
    return {
      headerName,
      field,
      valueGetter: AgGridSectionNameGetter,
    };
  }

  return {
    field,
    headerName,
    initialHide,
    valueFormatter: AgGridHtmlDecodeValueFormatter,
  };
});

const FleetDetailsAgGridColDefsAtom = atomWithReset<ColDef[]>(initialColumns);

export default FleetDetailsAgGridColDefsAtom;
