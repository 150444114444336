import React from 'react';
import { useAtomValue } from 'jotai';
import {
  Button,
  Modal,
  ModalBody,
} from 'reactstrap';
import { FaFilePdf } from 'react-icons/fa';

import { useGetHistoricAF1800Report, useAf1800ReportPdf } from 'apiHooks/Af1800Reports.Hook';
import emptyAf1800PdfAtom from 'store/EmptyAf1800Pdf.store';
import PdfViewer from 'components/shared/PdfViewer/PdfViewer';

import { populateSinglePdf } from './Af1800PdfFormUtils';
import { RhinoModalHeader } from '../RhinoModal/RhinoModal';

// *************************************************************************

interface IAF1800HistoricReport {
  regNumber: string,
  af1800FormDate: Date,
}

/**
 *
 * @returns
 */
function AF1800HistoricVehicleReportModal({ regNumber, af1800FormDate }: IAF1800HistoricReport) {
  const [modal, setModal] = React.useState(false);
  const [pdfData, setPdfData] = React.useState<Uint8Array | undefined>();
  const year = af1800FormDate?.getFullYear() || 0;
  const month = (af1800FormDate?.getMonth() || 0) + 1;
  const { data } = useGetHistoricAF1800Report(year, month, regNumber, modal);
  useAf1800ReportPdf(modal);
  const pdfFile = useAtomValue(emptyAf1800PdfAtom);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  React.useEffect(() => {
    if (data && pdfFile) {
      populateSinglePdf(data, pdfFile)
        .then((pdf) => pdf && setPdfData(pdf))
        .catch(() => {});
    }
  }, [data, pdfFile]);

  return (
    <div>
      <Button color="link" onClick={openModal} aria-label={`Download AF1800 for ${regNumber}`}>
        <FaFilePdf />
      </Button>
      <Modal className="pdf-modal" isOpen={modal} toggle={closeModal}>
        <RhinoModalHeader toggle={closeModal}>
          {`${month}/${year} AF1800 for ${regNumber}`}
        </RhinoModalHeader>
        <ModalBody className="p-0">
          <PdfViewer pdfData={pdfData} filename={`${regNumber}-${month}-${year}-af1800`} />
        </ModalBody>
      </Modal>
    </div>
  );
}

export default AF1800HistoricVehicleReportModal;
