import type { ICellRendererParams } from '@ag-grid-community/core';
import { usePatchWash } from 'apiHooks/Vehicles.Hook';
import { RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';
import { isEqual } from 'date-fns';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { MdEdit } from 'react-icons/md';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  Spinner,
} from 'reactstrap';
import type { IWash, IWashPatch } from 'types/IVehicleDetails';

function EditWashModal({ wash }: { wash: IWash; }) {
  const [modalOpen, setModalOpen] = useState(false);

  const [date, setDate] = useState<Date>(new Date(wash.washDate));
  const [completed, setCompleted] = useState(wash.completed);
  const [completionDate, setCompletionDate] = useState<Date | null>(
    wash.completionDate ? new Date(wash.completionDate) : null,
  );
  const [patching, setPatching] = useState(false);

  const toggleModalOpen = () => setModalOpen((state) => !state);

  const toggleCompleted = () => setCompleted((state) => {
    if (state) {
      setCompletionDate(null);
    } else {
      setCompletionDate(new Date());
    }

    return !state;
  });

  const handleSetCompletionDate = (newDate: Date | null) => {
    if (newDate) setCompleted(true);
    setCompletionDate(newDate);
  };

  const resetState = () => {
    setDate(new Date(wash.washDate));
    setCompleted(wash.completed);
    setCompletionDate(wash.completionDate ? new Date(wash.completionDate) : null);
  };

  const closeAndReset = () => {
    setModalOpen(false);
    resetState();
  };

  const patchWash = usePatchWash(wash.regNumber, wash.washId);

  const canSubmit = () => (
    !isEqual(date, new Date(wash.washDate))
    || completed !== wash.completed
  );

  const handleSubmit = () => {
    if (!canSubmit()) return;

    setPatching(true);

    const washPatch: IWashPatch = {
      ...(!isEqual(date, new Date(wash.washDate))) && { washDate: date.toISOString() },
      ...completed !== wash.completed && { completed },
      ...(!wash.completionDate
        || !completionDate
        || !isEqual(completionDate, new Date(wash.completionDate))
      ) && (
        { completionDate: completionDate?.toISOString() || null }
      ),
    };

    patchWash(washPatch)
      .then(closeAndReset)
      .catch(() => {})
      .finally(() => setPatching(false));
  };

  return (
    <>
      <Button
        type="button"
        color="link"
        onClick={() => setModalOpen(true)}
      >
        <MdEdit />
      </Button>

      <Modal
        centered
        size="sm"
        isOpen={modalOpen}
        toggle={toggleModalOpen}
      >
        <RhinoModalHeader toggle={toggleModalOpen}>
          {`Edit Wash for ${wash.regNumber}`}
        </RhinoModalHeader>

        <ModalBody className="d-grid gap-3 text-secondary fw-bold">

          <div className="d-grid">
            Scheduled For
            <ReactDatePicker
              showIcon
              placeholderText={new Date(wash.washDate).toLocaleDateString('en-US')}
              selected={date}
              onChange={(d) => (d ? setDate(d) : undefined)}
            />
          </div>

          <div className="d-flex gap-1">
            <Input
              type="checkbox"
              checked={completed}
              onClick={toggleCompleted}
            />
            Completed
          </div>

          <div className="d-grid">
            Manual Completion Date
            <ReactDatePicker
              showIcon
              maxDate={new Date()}
              selected={completionDate}
              onChange={handleSetCompletionDate}
            />
          </div>
        </ModalBody>

        <ModalFooter className="justify-content-between">
          <Button onClick={closeAndReset}>
            Close
          </Button>

          <Button disabled={!canSubmit() || patching} onClick={handleSubmit}>
            {patching ? (<Spinner size="sm" />) : ('Submit')}
          </Button>

        </ModalFooter>
      </Modal>
    </>
  );
}

function AgGridEditWashCellRenderer({ data }: ICellRendererParams) {
  return <EditWashModal wash={data as IWash} />;
}

export default AgGridEditWashCellRenderer;
