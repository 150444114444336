import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';

interface IUploadFailureModal {
  error: string,
  acknowledgeError: () => void
}

function UploadFailureModal({ error, acknowledgeError }: IUploadFailureModal) {
  return (
    <Modal
      isOpen={error !== ''}
      toggle={acknowledgeError}
    >
      <RhinoModalHeader toggle={acknowledgeError}>Confirm deletion</RhinoModalHeader>
      <ModalBody className="d-flex flex-column">
        <h5 className="text-center py-2 fw-bold">
          {
            error === 'File size limit has been reached'
              ? 'File is too large.  Please try another file or resizing this file.'
              : error
          }
        </h5>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <Button color="primary" onClick={acknowledgeError}>
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default UploadFailureModal;
