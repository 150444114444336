import React, { useEffect } from 'react';
import type {
  GridApi,
  GridReadyEvent,
  ColDef,
  ValueGetterParams,
} from '@ag-grid-community/core';
import {
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import isThisMonth from 'date-fns/isThisMonth';

import AgGridDateValueFormatter from 'components/agGridRenderers/AgGridDateValueFormatter';
import AgGridIsTrueIconCellRenderer from 'components/agGridRenderers/AgGridIsTrueIconCellRenderer';
import AgGridAf1800RuleCellRenderer from 'components/agGridRenderers/AgGridAf1800RuleCellRenderer';
import AgGridNumericValueFormatter from 'components/agGridRenderers/AgGridNumericValueFormatter';
import AgGridVehicleInspectionResultsLinkCellRenderer from 'components/agGridRenderers/AgGridVehicleInspectionResultsLinkCellRenderer';
import { useGetVehicleMxRequired } from 'apiHooks/Vehicles.Hook';
import type { IRequiredMx } from 'types/Mx';
import AgGridUserCellRenderer from 'components/agGridRenderers/AgGridUserCellRenderer';
import RhinoGrid from 'components/shared/RhinoGrid';
import CreateRequiredModal from './CreateRequiredModal';
import AgGridDiscrepancyImageCellRenderer from './AgGridDiscrepancyImageCellRenderer';
import AgGridEditRequiredMxCellRenderer from './AgGridEditRequiredMxCellRenderer';

const DETAIL_COLUMNS: (keyof IRequiredMx)[] = [
  'reportDate',
  'reportMiles',
  'reportHours',
  'reporterUserId',
  'workOrder',
  'validFrom',
  'af1800StatusCode',
  'mxUserId',
  'systemCode',
  'numFixed',
];

export default function VehicleMaintenanceRequired({ regNumber }: { regNumber: string; }) {
  const REQUIRED_MX_COLUMNS_COL_DEFS: ColDef[] = [
    {
      field: 'itemNo',
      headerName: 'Rule',
      valueGetter: ({ data }: ValueGetterParams) => `AF1800-${data.itemNo}`,
      cellRenderer: AgGridAf1800RuleCellRenderer,
      width: 120,
      pinned: 'left',
    }, {
      field: 'discrepancy',
      pinned: 'left',
      wrapText: true,
      autoHeight: true,
      cellClass: 'lh-base p-2',
    }, {
      field: 'pictures',
      cellRenderer: AgGridDiscrepancyImageCellRenderer,
      width: 100,
      pinned: 'left',
    }, {
      field: 'dateDiscovered',
      valueFormatter: AgGridDateValueFormatter,
      width: 250,
    }, {
      field: 'reportDate',
      valueFormatter: AgGridDateValueFormatter,
      initialHide: DETAIL_COLUMNS.includes('dateReported'),
    }, {
      field: 'reportMiles',
      valueFormatter: AgGridNumericValueFormatter,
      initialHide: DETAIL_COLUMNS.includes('reportMiles'),
    }, {
      field: 'reportHours',
      valueFormatter: AgGridNumericValueFormatter,
      initialHide: DETAIL_COLUMNS.includes('reportHours'),
    }, {
      field: 'reporterUserId',
      headerName: 'Reporter',
      initialHide: DETAIL_COLUMNS.includes('reporterID'),
      valueGetter: ({ data }: ValueGetterParams) => (
        data.reporterUserId || data.inspectorId
      ),
      cellRenderer: AgGridUserCellRenderer,
    }, {
      field: 'workOrder',
      initialHide: DETAIL_COLUMNS.includes('workOrder'),
    }, {
      field: 'validFrom',
      headerName: 'Last Updated',
      valueFormatter: AgGridDateValueFormatter,
      initialHide: DETAIL_COLUMNS.includes('lastModificationDate'),
    }, {
      field: 'af1800StatusCode',
      headerName: 'Status Code',
      initialHide: DETAIL_COLUMNS.includes('af1800StatusCode'),
    }, {
      field: 'mxUserId',
      headerName: 'Mx Employee',
      initialHide: DETAIL_COLUMNS.includes('mxUserID'),
    }, {
      field: 'disregard',
      cellRenderer: AgGridIsTrueIconCellRenderer,
      width: 100,
    }, {
      field: 'systemCode',
      initialHide: DETAIL_COLUMNS.includes('systemCode'),
    }, {
      field: 'numFixed',
      initialHide: DETAIL_COLUMNS.includes('numFixed'),
    }, {
      field: 'parentInspectionId',
      headerName: 'Parent Inspection',
      cellRenderer: AgGridVehicleInspectionResultsLinkCellRenderer,
    }, {
      cellRenderer: AgGridEditRequiredMxCellRenderer,
      field: 'edit',
      width: 100,
      pinned: 'right',
    },
  ];

  const [columnDefs] = React.useState(REQUIRED_MX_COLUMNS_COL_DEFS);
  const [gridApi, setGridApi] = React.useState<GridApi>();
  const [showDetails, setShowDetails] = React.useState(false);
  const [showHistoric, setShowHistoric] = React.useState(false);
  const [filteredRequiredMxData, setCurrentRowData] = React.useState<IRequiredMx[]>();

  const {
    data: requiredMxData,
    isLoading,
    isError,
    error,
  } = useGetVehicleMxRequired(regNumber);

  const requiredMxIsCurrent = (requiredMx: IRequiredMx) => (
    isThisMonth(new Date(requiredMx.dateDiscovered)) || (
      requiredMx.actualCompletionDate === null
      && requiredMx.disregard === false
    )
  );

  useEffect(() => {
    setCurrentRowData(requiredMxData?.filter(requiredMxIsCurrent));
  }, [requiredMxData]);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const toggleShowDetails = () => {
    gridApi?.setColumnsVisible(DETAIL_COLUMNS, !showDetails);
    setShowDetails(!showDetails);
  };

  if (isLoading || !requiredMxData) {
    return <Spinner />;
  }

  if (isError) {
    return <span>{error as string}</span>;
  }

  return (
    <div className="d-flex flex-column h-100">
      <div className="w-100 d-flex p-2 align-items-center justify-content-between border-bottom px-2">
        <Label>
          <span className="px-2">Show Details:</span>
          <Input
            defaultChecked={showDetails}
            onClick={toggleShowDetails}
            type="checkbox"
          />
        </Label>
        <Label>
          <span className="px-2">Show Historic Discrepancies:</span>
          <Input
            defaultChecked={showHistoric}
            onClick={() => setShowHistoric(!showHistoric)}
            type="checkbox"
          />
        </Label>
        <CreateRequiredModal
          regNumber={regNumber}
        />
      </div>
      <div className="ag-theme-material w-100 h-100">
        <RhinoGrid<IRequiredMx>
          rowData={
            showHistoric
              ? requiredMxData
              : (filteredRequiredMxData || [])
          }
          columnDefs={columnDefs}
          onGridReady={onGridReady}
        />
      </div>
    </div>
  );
}
