import React from 'react';
import { Label, Input, Button } from 'reactstrap';
import { nanoid } from 'nanoid';
import { useAtom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import {
  DndContext,
  useSensor,
  useSensors,
  rectIntersection,
  type DragEndEvent,
  type DragStartEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useNavigate } from 'react-router-dom';

import type {
  IWorkflowRule,
  INewWorkflowRule,
  NewRuleTypeMap,
  NewRuleType,
  EditableWorkflowRules,
  WorkflowRuleMap,
} from 'types/IWorkflows';
import type { RuleArea } from 'types/common';
import {
  areSameContainer,
  getSortableContainer,
  CustomKeyboardSensor,
  CustomPointerSensor,
} from 'utils/Helpers';
import {
  AF1800_BASE_WORK_FLOWS,
  WORKFLOW_ADDED_EXISTING_RULES,
  WORKFLOW_CREATED_RULES,
} from 'utils/Constants';
import { usePostNewWorkflow, usePatchWorkflow, postNewRules } from 'apiHooks/WorkFlows.Hook';
import WorkflowCache from 'store/WorkflowsCacheMap';

import SortableWorkflowRule from './SortableWorkflowRule';
import {
  NewPassFailRule,
  NewCustomRule,
  // NewNumericInputRule,
  AddMissingAf1800Rule,
} from './NewRules';

import {
  keyById,
  newRuleGenerator,
  isWorkflowModifiedAndValid,
} from './workflowUtils';

import style from './WorkflowModificationDragAndDrop.module.css';
import VehicleSelectionModal from './vehicleSelectionComponents/CurrentlyAssociatedVehicles';
import SaveWorkflowAs from './SaveWorkflowAs';
import PreSavedRulesModal from './PreSavedRulesModal';

const RULE_LIST_DROPZONE_ID = 'rule-list-dropzone';

type IdMapType = Record<string | number, string | number>;

interface IWorkflowModificationDragAndDrop {
  originalRules: IWorkflowRule[],
  af1800Rules: IWorkflowRule[],
  customRules: IWorkflowRule[],
  workflowName: string,
  workflowId: number | string,
}

const formatPostData = (editableRules: EditableWorkflowRules[]) => {
  const formattedExistingRules = editableRules.filter((rule) => !rule.isRemoved)
    .map((rule) => ({
      ...rule,
      ...rule.description && { description: rule.description },
    }))
    .map((origRule) => {
      const { availableRules, ...rule } = origRule;
      return (rule.originalRuleId ? { ...rule, ruleId: `${rule.originalRuleId}` } : rule);
    });

  const newRules = formattedExistingRules.filter((rule) => (
    rule.newRuleType && WORKFLOW_CREATED_RULES.includes(rule.newRuleType)
  ));

  if (newRules.length > 0) {
    const formattedNewRules = newRules.map((rule) => ({
      ...rule,

      area: 'internal' as RuleArea,
      comment: 'either',
      fields: { fixable: true },
      icon: null,
      picture: 'either',
      requireAnnotationOnFailure: true,
    }));
    return postNewRules(formattedNewRules).then((response) => {
      const newRuleIdMap = response.reduce((idMap: IdMapType, newRuleResponse, index) => {
        const newRule = newRules[index];
        if (newRule) {
          return {
            ...idMap,
            [newRule.ruleId]: newRuleResponse.ruleId,
          };
        }
        return idMap;
      }, {});

      return formattedExistingRules.map<EditableWorkflowRules>(
        (rule) => {
          const newId = newRuleIdMap[rule.ruleId];
          if (newId) {
            return {
              ...rule,
              id: newId,
            };
          }
          return rule;
        },
      );
    });
  }

  return Promise.resolve(formattedExistingRules);
};

/**
 *
 * @param param0
 * @returns
 */
function WorkflowModificationDragAndDrop({
  originalRules,
  af1800Rules,
  customRules,
  workflowName,
  workflowId,
}: IWorkflowModificationDragAndDrop) {
  const sensors = useSensors(
    useSensor(CustomPointerSensor, { onActivation: ({ event }) => event.preventDefault() }),
    useSensor(CustomKeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  if (!WorkflowCache.has(workflowId)) {
    WorkflowCache.set(workflowId, atomWithReset<EditableWorkflowRules[]>(originalRules));
  }

  const rulesAtom = WorkflowCache.get(workflowId);

  const [
    originalRulesMap,
    setOriginalRulesMap,
  ] = React.useState<WorkflowRuleMap>(keyById(originalRules));
  const [
    workflowRules,
    setWorkflowRules,
  ] = useAtom(rulesAtom || atomWithReset<EditableWorkflowRules[]>(originalRules));
  const [clonedRules, setClonedRules] = React.useState<EditableWorkflowRules[] | undefined>();
  const [newRule, setNewRule] = React.useState<INewWorkflowRule>();
  const [newRuleIds, setNewRuleIds] = React.useState<NewRuleTypeMap>({
    PASS_FAIL: nanoid(),
    NUMERIC_INPUT: nanoid(),
    CUSTOM: nanoid(),
    MISSING_AF_1800: nanoid(),
  });
  const [showRemovedRules, setShowRemovedRules] = React.useState(true);
  const patchWorkflow = usePatchWorkflow();
  const navigate = useNavigate();
  const postNewWorkFlow = usePostNewWorkflow();

  const filterAvailableRules = (newRuleType: NewRuleType) => {
    if (!WORKFLOW_ADDED_EXISTING_RULES.includes(newRuleType)) {
      return undefined;
    }

    const usedWorkflowRuleIds = workflowRules.map((x) => x.originalRuleId || x.ruleId);

    if (newRuleType === 'CUSTOM') {
      return customRules.filter((customRule) => !usedWorkflowRuleIds.includes(customRule.ruleId));
    }

    return af1800Rules.filter((af1800Rule) => !usedWorkflowRuleIds.includes(af1800Rule.ruleId));
  };

  React.useEffect(() => {
    setOriginalRulesMap(keyById(originalRules));
  }, [originalRules]);

  if (!rulesAtom) {
    return <div />;
  }

  /**
   * While rule has started being dragged
   */
  const handleDragStart = ({ active }: DragStartEvent) => {
    const newRuleType = active.data.current?.['newRuleType'];
    setClonedRules(workflowRules);
    setNewRule(newRuleGenerator(
      newRuleType,
      active.id.toString(),
      filterAvailableRules(newRuleType),
    ));
  };

  /**
   * While rule is being dragged
   */
  const handleDragOver = ({ active, over }: DragEndEvent) => {
    // If a new rule has been dragged into the rule list, create a copy
    // but make sure the copy does not already exist
    if (over && !areSameContainer(active, over)) {
      setWorkflowRules((prevItems) => {
        if (!prevItems) {
          return [];
        }

        if (!newRule || prevItems.some((x) => x.ruleId === newRule.ruleId)) {
          return prevItems;
        }

        const overIndex = getSortableContainer(over).index;

        return [
          ...prevItems.slice(0, overIndex),
          newRule,
          ...prevItems.slice(overIndex),
        ];
      });
    }
  };

  /**
   * While rule is no longer being dragged and over a drop area
   */
  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    // if order changed, update order
    if (over && active.id !== over?.id && areSameContainer(active, over)) {
      setWorkflowRules((prevItems) => {
        if (!prevItems) {
          return [];
        }

        const oldIndex = prevItems.findIndex((x) => x.ruleId === active.id);
        const newIndex = prevItems.findIndex((x) => x.ruleId === over.id);

        return arrayMove(prevItems, oldIndex, newIndex);
      });
    }

    // if a new rule was created, generate a new id
    if (newRule && newRule.newRuleType) {
      setNewRuleIds({
        ...newRuleIds,
        [newRule.newRuleType]: nanoid(),
      });
    }
    setNewRule(undefined);
    setClonedRules(undefined);
  };

  /**
   * While rule is no longer being dragged and was not valid
   */
  const handleDragCancel = () => {
    if (clonedRules) {
      // Reset items to their original state in case items have been dragged across containers
      setWorkflowRules(clonedRules);
    }
    setClonedRules(undefined);
    setNewRule(undefined);
  };

  const canSaveAndReplace = () => (
    !AF1800_BASE_WORK_FLOWS.includes(workflowName)
    && isWorkflowModifiedAndValid(workflowRules, originalRulesMap)
  );

  const onSaveCompletion = () => {
    WorkflowCache.delete(workflowId);
    navigate('/InspectionWorkflow');
  };

  // const newRules =

  const saveAs = (title: string, selectedVehicles: string[]) => {
    formatPostData(workflowRules).then((savedRules) => (
      postNewWorkFlow({
        title,
        rules: savedRules,
      }, selectedVehicles)
        .then(onSaveCompletion)
        .catch(() => {})))
      .catch(() => {});
  };

  const saveAndReplace = () => {
    formatPostData(workflowRules).then((savedRules) => (
      patchWorkflow({
        title: workflowName,
        rules: savedRules,
      }, workflowId)
        .then(onSaveCompletion)
        .catch(() => {})))
      .catch(() => {});
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={rectIntersection}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
    >
      <div className={`col-10 overflow-y-auto ${style['dnd-container']}`}>
        <SortableContext
          id={RULE_LIST_DROPZONE_ID}
          items={
            workflowRules
              .filter((rule) => !rule.isRemoved || showRemovedRules)
              .map((rule) => ({ ...rule, id: rule.ruleId }))
          }
          strategy={verticalListSortingStrategy}
        >
          {workflowRules.filter((rule) => !rule.isRemoved || showRemovedRules).map((rule) => (
            <SortableWorkflowRule
              key={rule.ruleId}
              ruleId={rule.ruleId}
              originalRule={(originalRulesMap || {})[rule.originalRuleId || rule.ruleId]}
              rulesAtom={rulesAtom}
            />
          ))}
        </SortableContext>
      </div>
      <div className={`col-2 d-flex flex-column justify-content-center px-2 ${style['dnd-container']}`}>
        <div className="d-flex px-2 py-4 mb-4 flex-column border shadow">
          <SaveWorkflowAs
            disabled={!isWorkflowModifiedAndValid(workflowRules, originalRulesMap)}
            existingWorkflowName={workflowName}
            saveAsCallback={saveAs}
          />
          <Button
            onClick={saveAndReplace}
            disabled={!canSaveAndReplace()}
            className="mt-4"
          >
            Save and Replace
          </Button>
        </div>
        <div className="d-flex px-2 py-4 mb-4 flex-column border shadow">
          <VehicleSelectionModal workflowName={workflowName} />
        </div>
        <div className="px-2 py-4 border shadow">
          <span className="d-flex mb-1">Add Rule:</span>
          <small className="d-flex mb-1">(drag and drop into list)</small>
          <NewPassFailRule id={newRuleIds['PASS_FAIL']} />
          {/* <NewNumericInputRule id={newRuleIds['NUMERIC_INPUT']} /> */}
          <NewCustomRule
            id={newRuleIds['CUSTOM']}
            disabled={(filterAvailableRules('CUSTOM')?.length || 0) === 0}
          />
          <AddMissingAf1800Rule
            id={newRuleIds['MISSING_AF_1800']}
            disabled={(filterAvailableRules('MISSING_AF_1800')?.length || 0) === 0}
          />

          <Label className="mt-4 user-select-none">
            <Input
              type="checkbox"
              checked={showRemovedRules}
              onChange={(e) => setShowRemovedRules(e.target.checked)}
            />
            <span className="px-2">Show Removed Rules</span>
            <PreSavedRulesModal />
          </Label>
        </div>
      </div>
    </DndContext>
  );
}

export default WorkflowModificationDragAndDrop;
