import React from 'react';

import type { RuleFrequency } from 'types/common';
import {
  WORKFLOW_RULE_FREQUENCY,
  WORKFLOW_RULE_FREQUENCY_LABEL,
} from 'utils/Constants';

import ChangeControls from './ChangeControls';

interface IEditableFrequencyRuleField {
  canEdit: boolean,
  isDisabled: boolean,
  value: RuleFrequency | undefined,
  originalValue: RuleFrequency | undefined,
  updateFieldCallback: (value: RuleFrequency | undefined) => void,
  editingLockCallback: (inEdit: boolean) => void,
}

/**
 *
 * @param param0
 * @returns
 */
function FrequencyField({
  value,
  originalValue,
  canEdit,
  isDisabled,
  updateFieldCallback,
  editingLockCallback,
} : IEditableFrequencyRuleField) {
  const [frequency, setFrequency] = React.useState(value);
  const [isEditing, setIsEditing] = React.useState(false);

  const freq = WORKFLOW_RULE_FREQUENCY;
  const frequencyLimit = originalValue ? (freq.indexOf(originalValue) + 1) : freq.length;
  const frequencyOptions = WORKFLOW_RULE_FREQUENCY.slice(0, frequencyLimit);
  const canEditAndHaveOptions = canEdit && frequencyOptions.length > 1;

  React.useEffect(() => {
    setFrequency(value);
  }, [value]);

  const beginEditing = () => {
    setIsEditing(true);
    editingLockCallback(true);
  };

  const confirmEdits = () => {
    updateFieldCallback(frequency);
    setIsEditing(false);
    editingLockCallback(false);
  };

  const cancelEdits = () => {
    updateFieldCallback(value);
    setFrequency(value);
    setIsEditing(false);
    editingLockCallback(false);
  };

  const undoEdits = () => {
    setFrequency(originalValue);
    updateFieldCallback(originalValue);
  };

  return (
    <div data-no-dnd="true" className="text-capitalize">
      <small className="px-1 text-muted">Frequency: </small>
      { isEditing
        ? (
          <select value={frequency || ''} onChange={(e) => setFrequency(e.target.value as RuleFrequency)}>
            <option value="" disabled>Select a workflow frequency</option>
            { frequencyOptions.map((freqOption) => (
              <option key={freqOption} value={freqOption}>
                { WORKFLOW_RULE_FREQUENCY_LABEL[freqOption]}
              </option>
            ))}
          </select>
        )
        : (frequency ? WORKFLOW_RULE_FREQUENCY_LABEL[frequency] : 'Not Set')}
      { canEditAndHaveOptions
        && (
          <ChangeControls
            inEdit={beginEditing}
            isDisabled={isDisabled}
            confirmEdits={confirmEdits}
            cancelEdits={cancelEdits}
            undoEdits={undoEdits}
            canUndo={originalValue !== undefined && frequency !== originalValue}
            isInValid={frequency === undefined}
          />
        )}
    </div>
  );
}

export default FrequencyField;
