import React from 'react';
import { atomWithReset } from 'jotai/utils';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import { useGetFleet } from 'apiHooks/Vehicles.Hook';
import type { IFleetDetail, VehicleSelectionMap } from 'types/IFleetDetails';

import { RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';
import { keyByRegNumberWithSelected } from '../workflowUtils';
import styles from './VehicleSelectionModal.module.css';
import VehicleSelectionByType from './VehicleSelectionByType';

interface IVehicleSelectionModal {
  vehicles: IFleetDetail[],
  workflowName: string,
}

function VehicleSelectionModalComponent({ vehicles, workflowName } : IVehicleSelectionModal) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const vehicleMapAtom = React.useMemo(
    () => atomWithReset<VehicleSelectionMap>(keyByRegNumberWithSelected(vehicles, workflowName)),
    [vehicles, workflowName],
  );
  const numAssociated = vehicles.filter((x) => x.workflowTitle === workflowName).length;

  const toggle = () => setModalOpen(!modalOpen);

  return (
    <>
      <div className="d-flex flex-column">
        <Button onClick={toggle}>
          Associated Vehicle(s)
        </Button>
        <span className="mt-2 text-center">
          <b>{numAssociated}</b>
          { ' ' }
          <small>vehicles currently associated with this workflow</small>
        </span>
      </div>

      <Modal className={styles['vehicle-selection-modal']} isOpen={modalOpen} toggle={toggle}>
        <RhinoModalHeader toggle={toggle}>
          {`Vehicles associated to ${workflowName} workflow are selected`}
        </RhinoModalHeader>
        <ModalBody>
          <div className="overflow-y-auto max-vh-50">
            <VehicleSelectionByType
              vehiclesByRegNumAtom={vehicleMapAtom}
              workflowName={workflowName}
              isReadOnly
            />
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

/**
 *
 * @returns
 */
function VehicleSelectionModal({ workflowName } : { workflowName: string }) {
  const {
    isLoading,
    isError,
    data,
    error,
  } = useGetFleet();

  if (isLoading) {
    return (
      <Button disabled>
        Associated Vehicle(s)
      </Button>
    );
  }
  if (isError) {
    return <div>{ error as string }</div>;
  }

  return <VehicleSelectionModalComponent vehicles={data} workflowName={workflowName} />;
}

export default VehicleSelectionModal;
