import { addDays, differenceInDays } from 'date-fns';

const BEGINNING_OF_TIME = new Date('Jan 1 1970 00:00:00');

/**
 * Given a date, return # full days since Jan 1 1970
 */
export const dateAsDays = (date: Date) => (
  differenceInDays(date.setHours(0, 0, 0, 0), BEGINNING_OF_TIME)
);

/**
 * Given # days since Jan 1 1970, return the Date (with time set to 00:00)
 */
export const daysAsDate = (days: number) => addDays(BEGINNING_OF_TIME, days);
