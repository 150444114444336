import { atomWithReset } from 'jotai/utils';

import type { ICustomFilter } from 'types/IFilter';
import { FILTER_TYPES } from 'utils/Constants';
import type { InspectionWorkflowField } from './InspectionWorkflowsAgGridColDefs.store';

const INSPECTION_WORKFLOWS_INITIAL_FILTERS: ICustomFilter = {
  title: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  lastModificationDate: {
    filterValue: {
      startDateTime: null,
      endDateTime: null,
    },
    isHidden: false,
    filterType: FILTER_TYPES.DATE_TIME,
  },
  lastModifierName: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  active: {
    filterType: FILTER_TYPES.TOGGLE,
    isHidden: true,
  },
};

const InspectionWorkflowsFilterAtom = atomWithReset<ICustomFilter<InspectionWorkflowField>>(
  INSPECTION_WORKFLOWS_INITIAL_FILTERS,
);

const InspectionWorkflowsInitialFilterAtom = atomWithReset<ICustomFilter<InspectionWorkflowField>>(
  INSPECTION_WORKFLOWS_INITIAL_FILTERS,
);

export {
  InspectionWorkflowsFilterAtom,
  InspectionWorkflowsInitialFilterAtom,
};
