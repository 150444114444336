import { useQueries } from '@tanstack/react-query';
import { getFleetQuery } from './Vehicles.Hook';
import { getWorkflowsQuery } from './WorkFlows.Hook';

const useGetWorkflowsAndFleet = () => (
  useQueries({
    queries: [
      getFleetQuery,
      getWorkflowsQuery,
    ],
  })
);

export {
  useGetWorkflowsAndFleet, // eslint-disable-line import/prefer-default-export
};
