import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAtom, useSetAtom } from 'jotai';

import type {
  IWorkflowDetails,
  IWorkflowRule,
  IWorkflowPost,
  EditableWorkflowRules,
  IWorkflow,
} from 'types/IWorkflows';
import RhinoUserCacheAtom from 'store/Rhino.store';
import {
  authHttpDelete,
  authHttpGet,
  authHttpPatch,
  authHttpPost,
  userInfo,
} from 'utils/Helpers';

type RuleIdMap = Record<string | number, IWorkflowRule>;

const orgUrl = () => `/data/v1/organizations/${userInfo()?.orgId}`;

const getWorkflowsQuery = {
  queryKey: ['GetWorkflows'],
  queryFn: (): Promise<IWorkflowDetails[]> => authHttpGet(`${orgUrl()}/workflows?showArchives=true`),
};

const useGetWorkflows = () => useQuery(getWorkflowsQuery);

const useGetWorkflow = (workflowId: number) => (
  useQuery((
    {
      queryKey: ['GetWorkflow', workflowId],
      queryFn: () => (
        authHttpGet(
          `${orgUrl()}/workflows/${workflowId}?showRules=true`,
        )
      ),
    }
  ))
);

const useGetOrganizationRules = () => {
  const [rhinoUserCache] = useAtom(RhinoUserCacheAtom);
  const setWorkflowRuleIdMap = useSetAtom(rhinoUserCache.WorkflowRuleIdMapAtom);

  return useQuery({
    queryKey: ['GetOrganizationRules', userInfo()?.orgId],
    queryFn: async () => {
      const workflowRules = await authHttpGet<IWorkflowRule[]>(`${orgUrl()}/rules`);

      const mappedRuleIds = workflowRules.reduce(
        (mappedRules: RuleIdMap, rule: IWorkflowRule) => ({
          ...mappedRules,
          [rule.ruleId]: rule,
        }),
        {},
      );
      setWorkflowRuleIdMap(mappedRuleIds);

      return workflowRules;
    },
  });
};

/**
 * Add New section
 *
 * @param name
 * @returns
 */
const usePostNewWorkflow = () => {
  const queryClient = useQueryClient();

  return (postData: IWorkflowPost, regNumbers: string[]) => (
    authHttpPost<Record<string, string>>(`${orgUrl()}/workflows/`, postData)
      .then((data) => {
        if (regNumbers.length > 0) {
          const { url } = data;
          const id = String(url).split('/').pop();
          authHttpPost(`${orgUrl()}/workflows/${id}/assignByRegNumber`, regNumbers).catch(() => {});
        }

        return queryClient.invalidateQueries({ queryKey: ['GetWorkflows'] });
      })
      .catch(() => queryClient.invalidateQueries({ queryKey: ['GetWorkflows'] }))
  );
};

const usePatchWorkflow = () => {
  const queryClient = useQueryClient();

  // eslint-disable-next-line unicorn/consistent-function-scoping
  return (patchData: IWorkflowPost, workflowId: number | string) => (
    authHttpPatch(`${orgUrl()}/workflows/${workflowId}`, patchData)
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetWorkflow', workflowId] }))
  );
};

const useDeleteWorkflow = () => {
  const queryClient = useQueryClient();

  return (workflowId: number | string) => (
    authHttpDelete(`${orgUrl()}/workflows/${workflowId}`)
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetWorkflows'] }))
      .catch(() => queryClient.invalidateQueries({ queryKey: ['GetWorkflows'] }))
  );
};

const useUndeleteWorkflow = () => {
  const queryClient = useQueryClient();

  // eslint-disable-next-line unicorn/consistent-function-scoping
  return (workflowId: IWorkflow['workflowId']) => (
    authHttpPatch(`${orgUrl()}/workflows/${workflowId}`, { active: true })
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetWorkflows'] }))
      .catch(() => queryClient.invalidateQueries({ queryKey: ['GetWorkflows'] }))
  );
};

// NOT A HOOK
// XXX: find a better place for this.
const postNewRules = (newRules: EditableWorkflowRules[]) => (
  Promise.all(newRules.map((rule) => authHttpPost<IWorkflowRule>(`${orgUrl()}/rules`, rule)))
);

export {
  useGetWorkflows,
  useGetWorkflow,
  useGetOrganizationRules,
  usePostNewWorkflow,
  usePatchWorkflow,
  useDeleteWorkflow,
  useUndeleteWorkflow,
  getWorkflowsQuery,
  postNewRules,
};
