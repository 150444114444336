import React from 'react';
import { MdInsertChart } from 'react-icons/md';

import type { IRoute } from 'types/IRoute';
import MonthlyReport from 'views/MonthlyReports/MonthlyReports';

const monthlyReportsRoute: [IRoute] = [{
  component: <MonthlyReport />,
  icon: <MdInsertChart />,
  label: 'Reports',
  path: '/Reports',
  allowedRoles: ['vco', 'sectionLead'],
}];

export default monthlyReportsRoute;
