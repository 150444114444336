import type { ColumnState } from '@ag-grid-community/core';
import { atomWithReset } from 'jotai/utils';

const FleetDetailsColumnsAtom = atomWithReset<ColumnState[] | undefined>(undefined);

const FleetDetailsInitialColumnsAtom = atomWithReset<ColumnState[] | undefined>(undefined);

export {
  FleetDetailsColumnsAtom,
  FleetDetailsInitialColumnsAtom,
};
