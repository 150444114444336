import React, { useEffect, useState } from 'react';
import type {
  ColDef,
  CsvExportParams,
  GridApi,
  GridReadyEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';

import type { IVehicleTypeAvailability } from 'types/IOverview';
import AgGridHtmlDecodeValueFormatter from 'components/agGridRenderers/AgGridHtmlDecodeValueFormatter';
import { Button } from 'reactstrap';
import { formatDateTimeForFileName } from 'utils/Helpers';
import RhinoGrid from 'components/shared/RhinoGrid';
import AgGridAvailabilityStatusCellRenderer from './agGrid/AgGridAvailabilityStatusCellRenderer';

const cols: ColDef<IVehicleTypeAvailability>[] = [
  {
    field: 'vehicleType',
    headerName: 'Vehicle Type',
    valueFormatter: AgGridHtmlDecodeValueFormatter,
    width: 150,
    resizable: true,
  }, {
    field: 'total',
    headerName: 'Total',
  }, {
    colId: 'status',
    headerName: 'Status',
    cellRenderer: AgGridAvailabilityStatusCellRenderer,
    width: 100,
    headerTooltip: 'Shows whether # available vehicles is above, equal to, or below MEL',
  }, {
    field: 'available',
    headerName: 'Available',
    headerTooltip: '# FMC vehicles that are not on TDY',
    width: 125,
  }, {
    field: 'mel',
    headerName: 'MEL',
    valueFormatter: (params: ValueFormatterParams<IVehicleTypeAvailability, number>) => (
      `${params.value ?? '-'}`
    ),
  }, {
    field: 'fmc',
    headerName: 'FMC',
  }, {
    field: 'tdy',
    headerName: 'TDY',
  }, {
    colId: 'availabilityPercentage',
    headerName: 'Availability Percentage',
    wrapHeaderText: true,
    width: 125,
    cellClass: 'tabular-nums',
    valueGetter: (params: ValueGetterParams<IVehicleTypeAvailability>) => (
      params.data?.total
        ? (params.data.available / params.data.total)
        : undefined
    ),
    valueFormatter: (params: ValueFormatterParams<IVehicleTypeAvailability, number>) => (
      params.value === undefined || params.value === null
        ? '-'
        : `${(params.value * 100).toFixed(0)}%`
    ),
  },
];
const defaultCsvExportParams: CsvExportParams = {
  columnKeys: [
    'total',
    'vehicleType',
    // 'status',
    'available',
    'mel',
    'fmc',
    'tdy',
    'availabilityPercentage',
  ],
};

interface SectionAvailabilityProps {
  vehicleTypes: IVehicleTypeAvailability[] | undefined;
  sectionName?: string;
}

function Availability({ vehicleTypes, sectionName }: SectionAvailabilityProps) {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [columnDefs] = React.useState(cols);
  const [rows, setRows] = React.useState(vehicleTypes || []);
  const [dataDate, setDataDate] = useState(new Date());

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setGridOption('rowData', vehicleTypes || []);
    setGridApi(params.api);
  };

  useEffect(
    () => {
      setRows(vehicleTypes || []);
      setDataDate(new Date());
    },
    [vehicleTypes],
  );

  const exportFileName = (
    `availability_${(
      sectionName?.replaceAll(' ', '-') ?? 'whole-fleet'
    )}_${formatDateTimeForFileName(dataDate)}`
  );

  return (
    <div className="d-flex flex-column w-100 h-100">

      <Button
        className="m-2 align-self-start"
        onClick={
          () => gridApi?.exportDataAsCsv({
            fileName: exportFileName,
          })
        }
      >
        Download CSV
      </Button>

      <div className="ag-theme-material w-100 h-100">

        <RhinoGrid<IVehicleTypeAvailability>
          onGridReady={onGridReady}
          rowData={rows}
          columnDefs={columnDefs}
          animateRows
          defaultColDef={{
            sortable: true,
            resizable: false,
            width: 100,
          }}
          defaultCsvExportParams={defaultCsvExportParams}
        />
      </div>
    </div>
  );
}

export default Availability;
