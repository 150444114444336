import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Table,
  UncontrolledTooltip,
} from 'reactstrap';
import { Collapse } from 'react-collapse';
import { FaChevronCircleRight } from 'react-icons/fa';

import type { IOrganization } from 'types/IOrganization';
import type { IOrganizationSectionWithMel } from 'types/IOrganizationSections';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import { useDeleteOrganizationSection } from 'apiHooks/OrganizationSections.Hook';
import { decode } from 'html-entities';
import { useGetUsers } from 'apiHooks/Users.Hook';
import { useAtomValue } from 'jotai';
import AddVehicleTypeMELsModal from './AddVehicleTypeMelsModal';
import RenameSectionModal from './RenameSectionModal';
import EditVehicleTypeMelModal from './EditVehicleMelModal';
import DeleteVehicleTypeMelModal from './DeleteVehicleMelModal';
import adminCollapseStateAtom from './adminCollapseState.store';

function DeleteSectionModal({ section }: { section: IOrganizationSectionWithMel; }) {
  const [modalOpen, setModalOpen] = useState(false);

  const deleteSection = useDeleteOrganizationSection(section.sectionId);

  return (
    !section.activeUsers && !section.mel?.length && (
      <div className="d-flex p-3 gap-3 align-items-center align-self-start text-muted">
        This section has no MEL nor active users.
        <Button
          onClick={() => setModalOpen(true)}
        >
          Delete?
        </Button>
        <RhinoModal
          title="Confirm Section Deletion"
          isOpen={modalOpen}
          setIsOpen={setModalOpen}
          onSubmit={deleteSection}
          submitButtonText="Delete"
        >
          {`Are you sure you want to delete section ${section.sectionName}?`}
        </RhinoModal>
      </div>
    )
  );
}

interface IOrganizationSection {
  organization: IOrganization,
  section: IOrganizationSectionWithMel,
}

function OrganizationSection({ section, organization }: IOrganizationSection) {
  const adminCollapseState = useAtomValue(adminCollapseStateAtom);

  const [isOpen, setIsOpen] = React.useState(adminCollapseState === 'EXPANDED');

  const { data: users } = useGetUsers();

  const toggle = () => setIsOpen(!isOpen);

  useEffect(
    () => setIsOpen(adminCollapseState === 'EXPANDED'),
    [adminCollapseState],
  );

  const badgeId = `activeUsersBadge_section${section.sectionId}`;

  return (
    <div>
      <Button
        className="w-100 rounded-0 d-flex justify-content-between bg-secondary-custom-gradient border-top-0"
        onClick={toggle}
      >
        <span className="d-flex align-items-center">
          <FaChevronCircleRight className={`rotate ${isOpen ? 'down' : ''}`} />
          <span className="px-2">{section.sectionName}</span>
        </span>
        <span className="text-end">
          Vehicle Types Defined:
          { ' ' }
          <Badge color="primary" className="text-body" pill>
            { Array.isArray(section.mel) ? section.mel.length : 0 }
          </Badge>
        </span>
      </Button>
      <Collapse isOpened={isOpen}>
        <div className="d-flex justify-content-between">
          <div className="d-flex p-4">
            <table className="table table-borderless px-4 w-auto">
              <tbody>
                <tr>
                  <td className="text-end py-0">Section:</td>
                  <td className="py-0">
                    <span className="text-nowrap align-text-top">{section.sectionName}</span>
                    <RenameSectionModal section={section} organization={organization} />
                  </td>
                </tr>

                <tr>
                  <td className="text-end py-0">Active users:</td>
                  <td className="py-0">
                    <Badge color="secondary" id={badgeId} pill>
                      {section.activeUsers}
                    </Badge>
                    {
                      !!section.activeUsers && (
                        <UncontrolledTooltip placement="right" target={badgeId}>
                          {
                            users?.filter((u) => u.sectionId === section.sectionId && u.active)
                              .map(
                                (user) => (
                                  <div key={user.userId}>
                                    {`${user.firstName} ${user.lastName}`}
                                  </div>
                                ),
                              )
                          }
                        </UncontrolledTooltip>
                      )
                    }
                  </td>
                </tr>

                <tr>
                  <td colSpan={2}>
                    <AddVehicleTypeMELsModal section={section} />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="d-flex flex-column px-4">
              <Table className={((Array.isArray(section.mel) && section.mel.length > 0) ? 'table-striped' : '')}>
                <thead>
                  <tr>
                    <th>
                      Vehicle Type
                    </th>
                    <th>
                      MEL
                    </th>
                    <th>{' '}</th>
                    <th>{' '}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Array.isArray(section.mel) && section.mel.map((mel) => (
                      <tr
                        key={`section-${section.sectionName}-defaultType-${mel.vehicleType || ''}`}
                      >
                        <td>{decode(mel.vehicleType)}</td>
                        <td className="text-end">{mel.mel}</td>
                        <td>
                          <EditVehicleTypeMelModal section={section} originalMel={mel} />
                        </td>
                        <td>
                          <DeleteVehicleTypeMelModal
                            sectionName={section.sectionName}
                            mel={mel}
                          />
                        </td>
                      </tr>
                    ))
                  }
                  {
                    (!section.mel || section.mel.length === 0) && (
                      <tr>
                        <td className="text-muted" colSpan={4}>No Vehicle Type MELs defined</td>
                      </tr>
                    )
                  }
                </tbody>
              </Table>
            </div>

          </div>

          <DeleteSectionModal section={section} />
        </div>
      </Collapse>
    </div>
  );
}

interface OrganizationDetailsGroupBySectionProps {
  orgDetails: IOrganization,
  newSectionName: string,
}

function OrganizationDetailsGroupBySection(props: OrganizationDetailsGroupBySectionProps) {
  const { orgDetails, newSectionName } = props;

  const newSection = orgDetails.sections.find((section) => section.sectionName === newSectionName);

  const oldSections = orgDetails.sections.filter(
    (section) => section.sectionName !== newSectionName,
  );

  const sections = newSection ? [newSection, ...oldSections] : oldSections;

  return (
    <div className="d-flex flex-column overflow-auto">
      {
        sections.map((section) => (
          <OrganizationSection
            key={section.sectionId}
            section={section}
            organization={orgDetails}
          />
        ))
      }
    </div>
  );
}

export default OrganizationDetailsGroupBySection;
