import React from 'react';
import type { ButtonProps } from 'reactstrap';
import { Button } from 'reactstrap';
import { PWA_APK_DOWNLOAD_URL } from 'utils/Constants';

function DownloadPwaButton(props: ButtonProps) {
  return (
    <Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onClick={() => { window.open(PWA_APK_DOWNLOAD_URL, '_blank'); }}
      className="border border-light"
    >
      Download RHINO For Android
    </Button>
  );
}

export default DownloadPwaButton;
