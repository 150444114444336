import React from 'react';
import {
  Button,
  Badge,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { formatDistance, format } from 'date-fns';
import {
  FaWrench,
  FaRegCalendarAlt,
} from 'react-icons/fa';
import { MdNoteAlt } from 'react-icons/md';

import { displayNumber } from '@stonewall-defense/swd-ui-utils';

import VehicleStatusIcon from 'components/shared/VehicleStatusIcon/VehicleStatusIcon';
import { VEHICLE_STATUS_MAP } from 'utils/Constants';
import { formatDate } from 'utils/Helpers';
import type { IPprintResult } from 'types/IVehicleInspections';

function InspectionResultsSummary({ inspection }: { inspection: IPprintResult }) {
  const navigate = useNavigate();

  const STATUSES = VEHICLE_STATUS_MAP;
  const vehicleStatus = inspection.passed ? STATUSES.VALID : STATUSES.INVALID;

  const inspectionOrCompletionDate = inspection.inspectionDate || inspection.completionDate;

  return (
    <div className="d-flex justify-content-between py-2">
      <div className="px-2">
        <Button onClick={() => navigate(`/Vehicle/${inspection.regNumber}`)}>Back</Button>
      </div>

      <div className="d-flex justify-content-center border bg-light rounded-4 p-2">
        <div className="px-2">
          <div className="d-flex flex-column">
            <small className="text-nowrap align-items-end text-gray-dark">Registration Number:</small>
            <span className="text-nowrap lh-1 fs-4">{inspection.regNumber}</span>
          </div>
          <div className="d-flex  align-items-end mt-2">
            <small className="text-gray-dark text-nowrap">Miles:</small>
            <span className="text-nowrap px-2">{inspection.miles ? displayNumber(inspection.miles) : 'N/A'}</span>
          </div>
          <div className="d-flex align-items-end">
            <small className="text-gray-dark text-nowrap">Hours:</small>
            <span className="text-nowrap px-2">{inspection.hours ? displayNumber(inspection.hours) : 'N/A'}</span>
          </div>
        </div>
        <div className="px-2">
          <div className="d-flex flex-column">
            <VehicleStatusIcon status={vehicleStatus} />
            <div className="d-flex align-items-end py-2">
              <small className="text-gray-dark text-nowrap px-2">Status:</small>
              <span>{vehicleStatus}</span>
            </div>

          </div>

          <div className="d-flex">
            <Badge
              role="button"
              title={`Number of fixes: ${inspection.numFixes}`}
              color={inspection.numFixes ? 'warning' : 'success'}
              className={inspection.numFixes > 0 ? 'text-black mx2-4' : 'mx-2'}
            >
              <FaWrench />
              { ' '}
              {inspection.numFixes}
            </Badge>
            <Badge
              role="button"
              title={`Number of notes: ${inspection.numNotes}`}
              color={inspection.numNotes ? 'warning' : 'success'}
              className={inspection.numNotes > 0 ? 'text-black' : ''}
            >
              <MdNoteAlt />
              { ' '}
              {inspection.numNotes}
            </Badge>
          </div>
        </div>
        <div className="px-3">
          <div className="d-flex flex-column mb-2">
            <small className="text-nowrap text-end text-gray-dark px-2">Date Performed</small>

            <span className="text-nowrap text-end align-items-end lh-1 fs-4">
              {format(new Date(inspectionOrCompletionDate), 'MM/dd/yyyy')}
              {' '}
              <FaRegCalendarAlt
                role="button"
                title={formatDate(inspectionOrCompletionDate)}
                className="fs-5"
              />
            </span>
          </div>
          {
            inspection.submissionDate && (
              <div className="d-flex align-items-end justify-content-end">
                <small className="text-gray-dark text-nowrap px-2">Submitted:</small>
                <span className="text-nowrap">
                  {format(new Date(inspection.submissionDate), 'MM/dd/yyyy')}
                  {' '}
                  <FaRegCalendarAlt
                    role="button"
                    title={formatDate(inspection.submissionDate)}
                  />
                </span>
              </div>
            )
          }
          <div className="d-flex mt-1 align-items-end justify-content-end">
            <small className="text-nowrap text-gray-dark px-2">Inspected By: </small>
            <span className="text-nowrap">
              {inspection.inspector}
              {inspection.shift && <small>{` (shift ${inspection.shift})`}</small> }
            </span>
          </div>
          <div className="d-flex align-items-end justify-content-end">
            <small className="text-nowrap text-gray-dark px-2">Duration:</small>
            <span className="text-nowrap">{formatDistance(0, inspection.duration * 1000, { includeSeconds: true })}</span>
          </div>
        </div>
      </div>
      <div>{ ' ' }</div>
    </div>
  );
}

export default InspectionResultsSummary;
