import React from 'react';
import { decode } from 'html-entities';
import { useAtom, type PrimitiveAtom } from 'jotai';
import {
  Badge,
  Button,
} from 'reactstrap';
import { Collapse } from 'react-collapse';
import { FaCheck } from 'react-icons/fa';

import type { IVehicleSelection, VehicleSelectionMap } from 'types/IFleetDetails';
import { groupByVehicleType } from '../workflowUtils';

interface IVehicleSelectionByType {
  vehiclesByRegNumAtom: PrimitiveAtom<VehicleSelectionMap>,
  workflowName: string,
  isReadOnly?: boolean,
}

interface IVehicleTypeSection extends IVehicleSelectionByType {
  vehicleType: string,
}

function VehicleTypeSection({
  vehicleType,
  workflowName,
  isReadOnly,
  vehiclesByRegNumAtom,
}: IVehicleTypeSection) {
  const [isOpen, setIsOpen] = React.useState(true);
  const checkboxRef = React.useRef<HTMLInputElement>(null);
  const [vehiclesByRegNum, setVehiclesByRegNum] = useAtom(vehiclesByRegNumAtom);

  const vehiclesOfType = () => (
    Object.values(vehiclesByRegNum).filter((x) => x.vehicleType === vehicleType)
  );
  const regNumbersOfType = vehiclesOfType().map((x) => x.regNumber);

  const totalVehiclesOfType = regNumbersOfType.length;

  const toggle = () => setIsOpen(!isOpen);

  const [
    numOfVehiclesSelected,
    setNumOfVehiclesSelected,
  ] = React.useState(vehiclesOfType().filter((x) => x.selected).length);

  const onCheckAll = () => {
    if (isReadOnly) return;
    const updates = vehiclesOfType().reduce((updateMap: VehicleSelectionMap, vehicle) => ({
      ...updateMap,
      [vehicle.regNumber]: {
        ...vehicle,
        selected: (numOfVehiclesSelected < totalVehiclesOfType),
      },
    } as VehicleSelectionMap
    ), {});

    setVehiclesByRegNum({
      ...vehiclesByRegNum,
      ...updates,
    });
  };

  const onCheck = (regNumber: string) => {
    if (isReadOnly) {
      return;
    }
    setVehiclesByRegNum({
      ...vehiclesByRegNum,
      [regNumber]: {
        ...vehiclesByRegNum[regNumber],
        selected: !vehiclesByRegNum[regNumber]?.selected,
      } as IVehicleSelection,
    });
  };

  React.useEffect(() => {
    const numSelected = vehiclesOfType().filter((x) => x.selected).length;
    setNumOfVehiclesSelected(numSelected);

    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = numSelected > 0 && numSelected < totalVehiclesOfType;
    }
  }, [vehiclesByRegNum]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Button
        className="w-100 rounded-0 d-flex justify-content-between bg-secondary-custom-gradient border-top-0"
        onClick={toggle}
      >
        <span>{vehicleType}</span>
        <span className="text-end">
          Vehicles:
          { ' ' }
          <Badge color="primary" className="text-body mx-2" pill>
            { numOfVehiclesSelected }
            { ' ' }
            Selected
          </Badge>
          <Badge color="primary" className="text-body" pill>
            { totalVehiclesOfType }
            { ' ' }
            Total
          </Badge>
        </span>
      </Button>
      <Collapse isOpened={isOpen}>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>
                {isReadOnly
                  ? null
                  : (
                    <input
                      ref={checkboxRef}
                      onChange={onCheckAll}
                      type="checkbox"
                      readOnly={isReadOnly}
                    />
                  )}
              </th>
              <th>Registration Number</th>
              <th>Assigned Workflow</th>
              {
                !isReadOnly && <th>Assigned to current workflow</th>
              }
            </tr>
          </thead>
          <tbody>
            {
              regNumbersOfType.sort().map((regNumber) => (
                <tr key={regNumber} onClick={() => onCheck(regNumber)}>
                  <td>
                    <input
                      type="checkbox"
                      checked={vehiclesByRegNum[regNumber]?.selected}
                      readOnly={isReadOnly}
                      onChange={() => onCheck(regNumber)}
                    />
                  </td>
                  <td>{regNumber}</td>
                  <td>
                    <span title={decode(vehiclesByRegNum[regNumber]?.workflowTitle)}>
                      {decode(vehiclesByRegNum[regNumber]?.workflowTitle)}
                    </span>
                  </td>
                  {
                    !isReadOnly && (
                      <td>
                        {
                          (
                            decode(
                              vehiclesByRegNum[regNumber]?.workflowTitle,
                            ) === decode(workflowName)
                          )
                            ? <FaCheck className="text-success" />
                            : ' '
                        }
                      </td>
                    )
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
      </Collapse>
    </div>
  );
}

function VehicleSelectionByType({
  vehiclesByRegNumAtom,
  workflowName,
  isReadOnly,
}: IVehicleSelectionByType) {
  const [vehiclesByRegNum] = useAtom(vehiclesByRegNumAtom);
  const [
    groupedByVehicleType,
  ] = React.useState(groupByVehicleType(Object.values(vehiclesByRegNum)));

  return (
    <>
      {
        Object.keys(groupedByVehicleType).sort().map((vehicleType) => (
          <VehicleTypeSection
            key={vehicleType}
            vehicleType={vehicleType}
            vehiclesByRegNumAtom={vehiclesByRegNumAtom}
            isReadOnly={isReadOnly || false}
            workflowName={workflowName}
          />
        ))
      }
    </>
  );
}

export default VehicleSelectionByType;
