const tabClassNames = (tabNumber: number, activeTab: number) => (
  `border border-bottom-0 d-flex gap-2 align-items-center user-select-none ${activeTab === tabNumber
    ? 'active'
    : 'bg-secondary'
  }`
);
const badgeClassNames = (tabIndex: number, activeTab: number) => (
  activeTab === tabIndex
    ? 'text-light'
    : 'text-secondary'
);

/* eslint-disable sonarjs/no-duplicate-string */
const OVERVIEW_TAB_NAMES = [
  'Availability',
  'Status Details',
  'Scheduled Mx',
  'Approaching LTIs',
  'Approaching PMIs',
  'Current TDYs',
  'Approaching TDYs',
  'Approaching Washes',
  'Approaching EOL/Depo Turn-In',
] as const;
/* eslint-enable sonarjs/no-duplicate-string */

type OverviewTabName = typeof OVERVIEW_TAB_NAMES[number];

export type { OverviewTabName };
export {
  tabClassNames,
  badgeClassNames,
  OVERVIEW_TAB_NAMES,
};
