import React from 'react';
import { isAF1800Rule } from '../workflowUtils';

/**
 *
 * @param param0
 * @returns
 */
function AF1800Badge({ id }: { id: number | string }) {
  if (!isAF1800Rule(id) || typeof id === 'number') {
    return <div />;
  }

  const ruleParts = id.split('-');
  const isNumericRule = (ruleParts.length === 2 && Number.parseFloat(ruleParts[1] || ''));

  return (
    <span className="badge bg-primary-darker">
      AF1800
      {' '}
      {isNumericRule ? `#${ruleParts[1]}` : ruleParts.slice(1).join('-')}
    </span>
  );
}

export default AF1800Badge;
