import React from 'react';
import type { IInspectionDatum } from 'types/Insights';

import type { ChartData } from 'chart.js';
import {
  Chart as ChartJS,
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import zoom from 'chartjs-plugin-zoom';
import { uniq } from 'lodash-es';
import getAverageInspectionDuration from 'utils/helpers/averageInspectionDuration.Helper';

interface IInspectorDatum {
  id: number,
  inspectorName: string,
  averageInspectionDuration: number | undefined; // in secs
  inspections: IInspectionDatum[],
}

type IFormatInspectionData = (
  data: IInspectionDatum[],
  inspectorIdNameMap: Record<string, string>
) => ChartData<'bar', IInspectorDatum[]>;

const formatInspectionData: IFormatInspectionData = (data, inspectorIdNameMap) => {
  const inspectorIDs = uniq(data.map((datum) => datum.inspectorId));

  const inspectorData: IInspectorDatum[] = inspectorIDs.map((id) => {
    const inspections = data.filter((datum) => datum.inspectorId === id);
    const inspectorName = inspectorIdNameMap[id] ?? `Inspector ${id}`;

    return ({
      id,
      inspectorName,
      averageInspectionDuration: getAverageInspectionDuration(inspections),
      inspections,
    });
  }).sort((a, b) => (a.inspectorName < b.inspectorName ? -1 : 1));

  return ({
    labels: inspectorData.map((datum) => datum.inspectorName),
    datasets: [{
      label: 'Average Inspection Time (min)',
      data: inspectorData,
      backgroundColor: 'rgb(0,0,0)',
    }],
  });
};

const labelCallback = (inspector: IInspectorDatum) => (
  `${inspector.averageInspectionDuration?.toFixed(1) || 0} minutes`
);

ChartJS.register(
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
  zoom,
);

interface IAverageInspectionTimesPerInspectorChart {
  data: IInspectionDatum[],
  inspectorIdNameMap: Record<string, string>,
}

export default function AverageInspectionTimesPerInspectorChart({
  data,
  inspectorIdNameMap,
}: IAverageInspectionTimesPerInspectorChart) {
  const chartData = formatInspectionData(data, inspectorIdNameMap);
  return (
    <div className="w-100">

      <Bar<IInspectorDatum[]>
        id="AverageInspectionTimesPerInspectorChart"
        data={chartData}
        options={{
          scales: {
            x: {
              title: {
                display: true,
                text: 'Inspector',
              },
            },
            y: {
              title: {
                display: true,
                text: 'Average Inspection Time (min)',
              },
              beginAtZero: true,
            },
          },
          animation: {
            duration: 200,
          },
          parsing: {
            xAxisKey: 'inspectorName',
            yAxisKey: 'averageInspectionDuration',
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: (item) => labelCallback(item.raw as IInspectorDatum),
              },
            },
            legend: {
              display: false,
            },
            // zoom: {
            //   pan: {
            //     enabled: true,
            //     mode: 'x',
            //   },
            //   zoom: {
            //     mode: 'x',
            //     wheel: {
            //       enabled: true,
            //     },
            //   },
            // },
          },
        }}
      />
    </div>
  );
}
