import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  authHttpGet,
  authHttpPatch,
  authHttpPost,
  formatQueryParams,
} from 'utils/Helpers';
import type { ITdy, ITdyPatch, ITdyPost } from 'types/IVehicleDetails';

const TDY_QUERY_PARAM_KEYS = [
  'regNumber',
  'start',
  'end',
] as const;

type TdyQueryParam = typeof TDY_QUERY_PARAM_KEYS[number];

interface ITdyQueryParams
  extends Partial<Record<TdyQueryParam, string>> {}

const useGetTdy = (params?: ITdyQueryParams) => (
  useQuery({
    queryKey: ['GetTdy', params],
    queryFn: () => (
      authHttpGet<ITdy[]>(
        `/data/v2/tdy${formatQueryParams(params)}`,
      )
    ),
  })
);

const usePostTdy = (regNumber: string) => {
  const queryClient = useQueryClient();

  return ((tdyPost: ITdyPost) => authHttpPost(
    '/data/v2/tdy',
    tdyPost,
  )
    .then(() => queryClient.invalidateQueries({ queryKey: ['GetTdy', { regNumber }] }))
  );
};

const usePatchTdy = (regNumber: string, tdyId: number) => {
  const queryClient = useQueryClient();

  return (tdyPatch: ITdyPatch) => (
    authHttpPatch(
      `/data/v2/tdy/${tdyId}`,
      tdyPatch,
    )
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetTdy', { regNumber }] }))
  );
};

export {
  useGetTdy,
  usePostTdy,
  usePatchTdy,
};
