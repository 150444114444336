import type { WritableAtom } from 'jotai';
import type { RESET } from 'jotai/utils';

import type { EditableWorkflowRules } from 'types/IWorkflows';

type SetStateActionWithReset<Value> =
  | Value
  | typeof RESET
  | ((prev: Value) => Value | typeof RESET);

type WritableEditableWorkflowRulesAtom = WritableAtom<
EditableWorkflowRules[], [SetStateActionWithReset<EditableWorkflowRules[]>], void
>;

const WorkflowCache = new Map<number | string, WritableEditableWorkflowRulesAtom>();

export default WorkflowCache;
