import type { ICellRendererParams } from '@ag-grid-community/core';
import React from 'react';
import { FaCheck, FaExclamationTriangle, FaTimes } from 'react-icons/fa';
import type { IVehicleTypeAvailability } from 'types/IOverview';
import { MEL_STATUSES } from 'utils/Constants';
import { melStatusResolver } from 'utils/Helpers';

function AgGridAvailabilityStatusCellRenderer(
  props: ICellRendererParams<IVehicleTypeAvailability>,
) {
  const { data } = props;

  if (!data?.mel) return '-';

  const status = melStatusResolver(data.available - data.mel);
  if (status === MEL_STATUSES.PASS) {
    return <FaCheck className="text-success" />;
  }

  if (status === MEL_STATUSES.WARNING) {
    return <FaExclamationTriangle className="text-warning" />;
  }

  return <FaTimes className="text-danger" />;
}

export default AgGridAvailabilityStatusCellRenderer;
