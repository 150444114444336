import React from 'react';
import type { IInspectionDatum } from 'types/Insights';

import type { ChartData } from 'chart.js';
import {
  Chart as ChartJS,
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import zoom from 'chartjs-plugin-zoom';
import { uniq } from 'lodash-es';
import getAverageInspectionDuration from 'utils/helpers/averageInspectionDuration.Helper';
import type { IFleetDetail } from 'types/IFleetDetails';

interface IVehicleDatum {
  vehicle: IFleetDetail,
  averageInspectionDuration: number | undefined;// in secs
  inspections: IInspectionDatum[],
}

type IFormatInspectionData = (
  data: IInspectionDatum[],
  vehiclesRecord: Record<string, IFleetDetail>,
) => ChartData<'bar', IVehicleDatum[], string>;

const formatInspectionData: IFormatInspectionData = (data, vehiclesRecord) => {
  const regNumbers = uniq(data.map((datum) => datum.regNumber));

  const rawVehicleData: (IVehicleDatum | null)[] = regNumbers.map((regNumber) => {
    const inspections = data.filter((datum) => datum.regNumber === regNumber);
    const vehicle = vehiclesRecord[regNumber];
    return vehicle ? ({
      vehicle,
      averageInspectionDuration: getAverageInspectionDuration(inspections),
      inspections,
    }) : null;
  });

  const vehicleData: IVehicleDatum[] = (rawVehicleData
    .filter((datum) => !!datum) as IVehicleDatum[])
    .sort((a, b) => (a.vehicle.regNumber < b.vehicle.regNumber ? -1 : 1));

  return ({
    labels: vehicleData.map((datum) => datum.vehicle.regNumber),
    datasets: [{
      label: 'Average Inspection Time (min)',
      data: vehicleData,
      backgroundColor: 'rgb(0,0,0)',
    }],
  });
};

const afterTitleTooltipCallback = (datum: IVehicleDatum) => {
  const v = datum.vehicle;
  const yearMakeModel = [v.year, v.make, v.model].filter((item) => !!item);
  const yearMakeModelString = yearMakeModel[0] ? yearMakeModel.join(' ') : undefined;
  const lines = [
    v.vehicleType,
    yearMakeModelString,
  ].filter((s) => !!s);
  return lines[0] ? lines.join('\n') : undefined;
};

const labelTooltipCallback = (vehicle: IVehicleDatum) => (
  `${vehicle.averageInspectionDuration?.toFixed(1) || 0} minutes`
);

ChartJS.register(
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
  zoom,
);

interface IAverageInspectionTimesPerInspectorChart {
  data: IInspectionDatum[];
  vehiclesRecord: Record<string, IFleetDetail>;
}

export default function AverageInspectionTimesPerVehicleChart({
  data,
  vehiclesRecord,
}: IAverageInspectionTimesPerInspectorChart) {
  const chartData = formatInspectionData(data, vehiclesRecord);
  return (
    <div className="w-100">
      <Bar<IVehicleDatum[], string>
        id="AverageInspectionTimesPerVehicleChart"
        data={chartData}
        options={{
          scales: {
            x: {
              title: {
                display: true,
                text: 'Vehicle',
              },
            },
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Average Inspection Time (min)',
              },
            },
          },
          animation: {
            duration: 200,
          },
          parsing: {
            xAxisKey: 'vehicle.regNumber',
            yAxisKey: 'averageInspectionDuration',
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                afterTitle: (items) => (
                  items[0] ? afterTitleTooltipCallback(items[0].raw as IVehicleDatum) : undefined
                ),
                label: (item) => labelTooltipCallback(item.raw as IVehicleDatum),
              },
            },
            // zoom: {
            //   pan: {
            //     enabled: true,
            //     mode: 'x',
            //   },
            //   zoom: {
            //     mode: 'x',
            //     wheel: {
            //       enabled: true,
            //     },
            //   },
            // },
          },
        }}
      />
    </div>
  );
}
