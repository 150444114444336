const REPORT_FIELD_HEADER_MAP: Record<string, string> = {
  vehicles: 'Vehicles',
  total: 'Total',
  inOp: 'In Op',
  wrm: 'WRM',
  inspections: 'Inspections',
  failed: 'Failed',
  notes: 'Notes',
  mx: 'Mx',
  discrepancies: 'Discrepancies',
  addressable: 'Addressable',
  disregarded: 'Disregarded',
  lti: 'LTI',
  scheduled: 'Scheduled',
  performed: 'Performed',
  pmi: 'PMI',
  failures: 'Failures',
  minorDamage: 'Minor Damage',
  fluids: 'Fluids',
  fuel: 'Fuel',
  safety: 'Safety',
  drive: 'Drive',
  wheels: 'Wheels',
  winter: 'Winter',
  accessory: 'Accessory',
  cargo: 'Cargo',
  other: 'Other',
  problems: 'Problem Vehicles',
  mostFailedInspections: 'Most Failed Inspections',
  mostTotalDiscrepancies: 'Most Total Discrepancies',
  mostAddressableDiscrepancies: 'Most Addressable Discrepancies',
  regNumber: 'Reg Number',
  num: 'Number',
};

export default REPORT_FIELD_HEADER_MAP;
