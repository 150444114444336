import { useSetAtom } from 'jotai';
import {
  useEffect, useRef, useState,
} from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { WEBSOCKET_HOST } from 'utils/Constants';
import { authHttpPost, userInfo } from 'utils/Helpers';
import { rhinoEventsWsOpenAtom, useRecordRhinoEvent } from 'components/layout/UserLayout/FleetUpdatesSideBar/RhinoEvent.store';

const useRhinoEventsWebSocket = () => {
  const ws = useRef<ReconnectingWebSocket>();
  const [ticket, setTicket] = useState<string>();
  const setWebSocketOpen = useSetAtom(rhinoEventsWsOpenAtom);
  const recordRhinoEvent = useRecordRhinoEvent();

  // Retrieve ticket
  useEffect(
    () => {
      if (!ticket && userInfo()?.role !== 'inspector') {
        authHttpPost<{ ticket?: string; }>('/stream/ticket')
          .then(({ ticket: t }) => setTicket(t))
          .catch(() => {});
      }
    },
    [ticket],
  );

  // Start websocket once ticket has been retrieved
  useEffect(() => {
    const closeWebSocket = () => {
      ws.current?.close();
    };

    closeWebSocket();

    if (ticket) {
      ws.current = new ReconnectingWebSocket(
        `wss://${WEBSOCKET_HOST}/stream/events?ticket=${ticket}`,
      );

      // console.log('New WebSocket CREATED');

      ws.current.addEventListener('open', () => {
        // console.log('WebSocket OPEN');
        setWebSocketOpen(true);
      });
      ws.current.addEventListener('close', () => {
        // console.log('WebSocket CLOSED');
        setWebSocketOpen(false);
      });
      ws.current.addEventListener('message', recordRhinoEvent);
      return closeWebSocket;
    }
    return undefined;
    /**
     * when all of the suggested deps are included,
     * the websocket keeps reconnecting and
     * new messages are occasionally not recorded.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket]);
};

export default useRhinoEventsWebSocket;
