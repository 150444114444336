import React from 'react';
import type { ChartData } from 'chart.js';
import {
  Chart as ChartJS,
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import zoom from 'chartjs-plugin-zoom';
import type { IFailuresOverviewResponseItem } from 'types/Failures';
import { dateAsDays, daysAsDate, stringToColor } from 'utils/Helpers';
// import type { IWorkflowRule } from 'types/IWorkflows';
import { useGetFleet } from 'apiHooks/Vehicles.Hook';
import { Spinner } from 'reactstrap';

const makeLabel = (date: Date) => date.toLocaleDateString(
  'en-us',
  {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
);

type IFormatFailureData = (
  data: IFailuresOverviewResponseItem[],
  minDate: Date,
  maxDate: Date,
) => ChartData<'bar', { x: string, y: number }[]>;

const formatFailureData: IFormatFailureData = (
  data,
  minDate,
  maxDate,
) => {
  if (!data[0]) return { datasets: [] };

  const minDay = dateAsDays(minDate);
  const maxDay = dateAsDays(maxDate);

  const days = Array.from(
    { length: maxDay - minDay + 1 },
    (_, i) => (i + minDay),
  );

  return ({
    labels: days.map((day) => makeLabel(daysAsDate(day))),
    datasets: data.map((failureTypeDatum) => ({
      label: failureTypeDatum.ruleId,
      data: failureTypeDatum.data.map((datum) => ({
        x: makeLabel(new Date(datum.day)),
        y: datum.totalFailures,
      })),
      backgroundColor: stringToColor(failureTypeDatum.ruleId),
    })),
  });
};

ChartJS.register(
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
  zoom,
);

interface IFailuresOverviewChart {
  data: IFailuresOverviewResponseItem[],
  af1800RulesTitleMap: Record<string, string>,
  minDate: Date,
  maxDate: Date,
}

export default function FailuresOverviewChart({
  data,
  af1800RulesTitleMap,
  minDate,
  maxDate,
}: IFailuresOverviewChart) {
  const {
    data: vehiclesData,
    isLoading: vehiclesAreLoading,
    isError: isVehiclesError,
    error: vehiclesError,
  } = useGetFleet();

  if (vehiclesAreLoading || !vehiclesData) {
    return <Spinner />;
  }
  if (isVehiclesError) {
    return (
      <div>
        {vehiclesError as string}
      </div>
    );
  }

  const chartData = formatFailureData(
    data,
    minDate,
    maxDate,
  );

  return (
    <div className="position-relative w-100">
      <Bar
        id="FailuresOverviewChart"
        data={chartData}
        options={{
          responsive: true,
          aspectRatio: 4,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              beginAtZero: true,
              stacked: true,
              ticks: {
                stepSize: 1,
              },
            },
          },
          animation: {
            duration: 200,
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: ((ctx) => {
                  const af1800Id = ctx.dataset.label ?? '';
                  return `${af1800Id}: ${af1800RulesTitleMap[af1800Id]}`;
                }),
              },
            },
            legend: {
              display: false,
            },
            // zoom: {
            //   pan: {
            //     enabled: true,
            //     mode: 'x',
            //   },
            //   zoom: {
            //     mode: 'x',
            //     wheel: {
            //       enabled: true,
            //     },
            //   },
            // },
          },
        }}
      />
    </div>
  );
}
