import React from 'react';
import type { IInspectionDatum } from 'types/Insights';

import type { ChartData } from 'chart.js';
import {
  Chart as ChartJS,
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import zoom from 'chartjs-plugin-zoom';
import { dateAsDays, daysAsDate } from 'utils/Helpers';

interface IDailyInspections {
  utcDay: number // full days since Jan 1 1970
  totalInspectionTime: number // in secs
  inspections: IInspectionDatum[],
}

const makeLabel = (date: Date) => date.toLocaleDateString(
  'en-us',
  {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
);

type FormatInspectionData = (
  date: IInspectionDatum[],
  minDate: Date,
  maxDate: Date,
) => ChartData<'bar'>;

const formatInspectionData: FormatInspectionData = (data, minDate, maxDate) => {
  const dayData = data.map((datum) => {
    const date = new Date(datum.inspectionDate);
    return ({
      ...datum,
      utcDay: dateAsDays(date),
    });
  });

  if (!data[0]) return { datasets: [] };

  const minDay = dateAsDays(minDate);
  const maxDay = dateAsDays(maxDate);

  // if (!minDay || !maxDay) {
  //   return { datasets: [] };
  // }

  const dailyInspections: IDailyInspections[] = Array.from(
    { length: maxDay - minDay + 1 },
    (_, i) => ({
      utcDay: i + minDay,
      totalInspectionTime: 0,
      inspections: [],
    }),
  );

  dayData.forEach((datum) => {
    const day = dailyInspections.find((d) => datum.utcDay === d.utcDay);

    if (!day) {
      // console.log(`UTC day ${datum.utcDay} not found in dailyInspections`);
      return;
    }

    // TODO: push inspection data to dailyInspections for tooltip purposes
    // day.inspections.push(datum);
    day.totalInspectionTime += datum.durationSeconds;
  });

  return ({
    labels: dailyInspections.map((day) => makeLabel(daysAsDate(day.utcDay))),
    datasets: [{
      label: 'Inspection Time (min)',
      data: dailyInspections.map((day) => day.totalInspectionTime / 60),
      backgroundColor: 'rgb(0,0,0)',
    }],
  });
};

const labelTooltipCallback = (inspectionTime: number) => (
  `${inspectionTime.toFixed(0)} minutes`
);

ChartJS.register(
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
  zoom,
);

interface IInspectionTimePerDayChart {
  data: IInspectionDatum[],
  minDate: Date,
  maxDate: Date,
}

export default function InspectionTimePerDayChart({
  data,
  minDate,
  maxDate,
}: IInspectionTimePerDayChart) {
  const chartData = formatInspectionData(data, minDate, maxDate);
  return (
    <div className="position-relative w-100">
      <Bar
        id="InspectionTimePerDayChart"
        data={chartData}
        options={{
          aspectRatio: 4,
          maintainAspectRatio: true,
          responsive: true,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Date',
              },
            },
            y: {
              title: {
                display: true,
                text: 'Total Inspection Time (min)',
              },
              beginAtZero: true,
            },
          },
          animation: {
            duration: 200,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (item) => labelTooltipCallback(item.raw as number),
              },
            },
            // zoom: {
            //   pan: {
            //     enabled: true,
            //     mode: 'x',
            //   },
            //   zoom: {
            //     mode: 'x',
            //     wheel: {
            //       enabled: true,
            //     },
            //   },
            // },
          },
        }}
      />
    </div>
  );
}
