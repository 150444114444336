import { useAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { useQueryClient } from '@tanstack/react-query';

import RhinoUserCacheAtom from 'store/Rhino.store';
import WorkflowCache from 'store/WorkflowsCacheMap';
import { keycloakInstance } from 'utils/Helpers';

// FIXME: SWEET JEBUS THIS IS OVERCOMPLICATED!!! FIND A BETTER WAY TO DO THIS

const useLogout = () => {
  const [rhinoUserCache] = useAtom(RhinoUserCacheAtom);
  const queryClient = useQueryClient();

  const resetWorkflowRuleIdMapAtom = useResetAtom(rhinoUserCache.WorkflowRuleIdMapAtom);
  const resetVehicleTypesAtom = useResetAtom(rhinoUserCache.vehicleTypesAtom);
  const resetFleetDetailsAgGridColDefsAtom = useResetAtom(
    rhinoUserCache.FleetDetailsAgGridColDefsAtom || atomWithReset(0),
  );
  const resetFleetDetailsFilterAtom = useResetAtom(
    rhinoUserCache.FleetDetailsFilterAtom || atomWithReset(0),
  );
  const resetFleetDetailsInitialFilterAtom = useResetAtom(
    rhinoUserCache.FleetDetailsInitialFilterAtom || atomWithReset(0),
  );
  const resetInspectionWorkflowsFilterAtom = useResetAtom(
    rhinoUserCache.InspectionWorkflowsFilterAtom || atomWithReset(0),
  );
  const resetInspectionWorkflowsInitialFilterAtom = useResetAtom(
    rhinoUserCache.InspectionWorkflowsInitialFilterAtom || atomWithReset(0),
  );
  const resetInspectionWorkflowsAgGridColDefsAtom = useResetAtom(
    rhinoUserCache.InspectionWorkflowsAgGridColDefsAtom || atomWithReset(0),
  );
  const resetOverviewMELsFilterAtom = useResetAtom(
    rhinoUserCache.OverviewMELsFilterAtom || atomWithReset(0),
  );
  const resetOverviewMELsInitialFilterAtom = useResetAtom(
    rhinoUserCache.OverviewMELsInitialFilterAtom || atomWithReset(0),
  );
  const resetUserAdminAgGridColDefsAtom = useResetAtom(
    rhinoUserCache.UserAdminAgGridColDefsAtom || atomWithReset(0),
  );
  const resetUserAdminFilterAtom = useResetAtom(
    rhinoUserCache.UserAdminFilterAtom || atomWithReset(0),
  );
  const resetUserAdminInitialFilterAtom = useResetAtom(
    rhinoUserCache.UserAdminInitialFilterAtom || atomWithReset(0),
  );

  return () => {
    queryClient.clear();
    resetWorkflowRuleIdMapAtom();
    resetVehicleTypesAtom();
    resetFleetDetailsAgGridColDefsAtom();
    resetFleetDetailsFilterAtom();
    resetFleetDetailsInitialFilterAtom();
    resetInspectionWorkflowsFilterAtom();
    resetInspectionWorkflowsInitialFilterAtom();
    resetInspectionWorkflowsAgGridColDefsAtom();
    resetOverviewMELsFilterAtom();
    resetOverviewMELsInitialFilterAtom();
    resetUserAdminAgGridColDefsAtom();
    resetUserAdminFilterAtom();
    resetUserAdminInitialFilterAtom();
    WorkflowCache.clear();
    keycloakInstance.logout().catch(() => {});
  };
};

export default useLogout;
