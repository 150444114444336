import { usePostNewVehicleWash } from 'apiHooks/Vehicles.Hook';
import { RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import {
  Button, Modal, ModalBody, ModalFooter, Spinner,
} from 'reactstrap';
import { formatDate } from 'utils/Helpers';

export default function ScheduleVehicleWashModal({ regNumber }: { regNumber: string; }) {
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModalOpen = () => setModalOpen((b) => !b);
  const [date, setDate] = useState<Date | null>(null);
  const [posting, setPosting] = useState(false);

  const postNewWash = usePostNewVehicleWash(regNumber);

  const canSubmit = !!date;

  const handleSubmit = () => {
    if (!canSubmit) return;
    setPosting(true);
    postNewWash({ washDate: date.toISOString() })
      .then(() => {
        setModalOpen(false);
        setDate(null);
      })
      .catch(() => {})
      .finally(() => setPosting(false));
  };

  return (
    <>
      <Button
        type="button"
        onClick={() => setModalOpen(true)}
      >
        Schedule Wash
      </Button>

      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen((state) => !state)}
      >
        <RhinoModalHeader toggle={toggleModalOpen}>
          {`Schedule Wash for ${regNumber}`}
        </RhinoModalHeader>

        <ModalBody>
          <div className="d-grid text-secondary fw-bold">
            Date
            <ReactDatePicker
              className="form-control"
              selected={date}
              onChange={setDate}
              showIcon
              showTimeInput
              value={formatDate(date?.toISOString())}
            />
          </div>
        </ModalBody>

        <ModalFooter className="justify-content-between">
          <Button onClick={() => setModalOpen(false)}>Close</Button>

          <Button disabled={!canSubmit || posting} onClick={handleSubmit}>
            {posting ? (
              <Spinner size="sm" />
            ) : (
              'Submit'
            )}
          </Button>

        </ModalFooter>
      </Modal>
    </>
  );
}
