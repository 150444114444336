import type { IInspectionDatum } from 'types/Insights';

/**
 * returns average duration (minutes) of the provided inspections.
 */
const getAverageInspectionDuration = (inspections: IInspectionDatum[]) => (
  inspections[0]
    ? (
      ((inspections
        .map((datum) => datum.durationSeconds)
        .reduce((a, b) => (a + b))
      ) / inspections.length) / 60
    )
    : (undefined)
);

export default getAverageInspectionDuration;
