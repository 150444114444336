/* eslint-disable no-bitwise */
const stringToColor = (input: string, saturation = 65, lightness = 60) => {
  let hash = 0;
  // following is disabled because using spread on string requires downLevelIteration flag
  // eslint-disable-next-line unicorn/prefer-spread
  (`${input}Certus`).split('').forEach((c) => {
    hash = (c.codePointAt(0) as number) * 11 + ((hash << 5) - hash);
  });

  return `hsl(${(hash % 360)}, ${saturation}%, ${lightness}%)`;
};
/* eslint-enable no-bitwise */

export default stringToColor;
