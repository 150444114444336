import React, { useState } from 'react';
import { Button } from 'reactstrap';
import ReactDatePicker from 'react-datepicker';
import InspectionsView from './InspectionsView';
import FailuresView from './FailuresView';
import UsageView from './UsageView';

const VIEWS = [
  'Inspections',
  'Usage',
  'Failures',
] as const;

type View = typeof VIEWS[number];

function Insights() {
  const now = new Date();

  const [startDate, setStartDate] = useState<Date>(new Date(`${now.getFullYear() - 1}`));
  const [endDate, setEndDate] = useState<Date>(now);
  const [currentView, setCurrentView] = useState<View>(VIEWS[0]);

  const datesValid = (start: Date, end: Date) => (start && end && start <= end);

  return (
    <div className="d-flex bg-gray w-100 h-100">
      <div className="d-flex flex-column bg-gray px-3 py-2 w-100 h-100 overflow-auto">

        <h2 className="text-secondary px-3">
          Insights:
          {` ${currentView}`}
        </h2>

        {/* Header bar with buttons */}
        <div className="d-flex flex-column w-100">
          <div className="w-100">
            <div className="flex-column bg-dark rounded-top p-3 gap-2 w-100">

              <div className="d-flex gap-3 text-light align-items-end">
                <div className="d-grid gap-1">
                  <span className="px-2">Start Date</span>
                  <ReactDatePicker
                    showIcon
                    minDate={new Date('2020')}
                    maxDate={endDate ?? now}
                    placeholderText="Start Date"
                    selected={startDate}
                    onChange={() => null}
                    onSelect={(date) => setStartDate(date)}
                    onBlur={(e) => {
                      const date = new Date(e.target.value);
                      if (datesValid(date, endDate)) setStartDate(date);
                    }}
                  />
                </div>

                <div className="d-grid gap-1">
                  <span className="px-2">End Date</span>
                  <ReactDatePicker
                    showIcon
                    minDate={startDate}
                    maxDate={now}
                    placeholderText="End Date"
                    selected={endDate}
                    onChange={() => null}
                    onSelect={(date) => setEndDate(date)}
                    onBlur={(e) => {
                      const date = new Date(e.target.value);
                      if (datesValid(startDate, date)) setEndDate(date);
                    }}
                  />
                </div>

                {VIEWS.map((view) => (
                  <Button
                    className="p-2 px-3"
                    title={view}
                    key={view}
                    active={view === currentView}
                    onClick={() => setCurrentView(view)}
                  >
                    {view}
                  </Button>
                ))}
              </div>

            </div>

            <div className="flex-fill d-flex flex-column p-3 rounded-bottom bg-white overflow-auto">
              {currentView === 'Inspections' && (
                <InspectionsView
                  startDate={startDate}
                  endDate={endDate}
                />
              )}
              {currentView === 'Usage' && (
                <UsageView
                  startDate={startDate}
                  endDate={endDate}
                />
              )}
              {currentView === 'Failures' && (
                <FailuresView
                  startDate={startDate}
                  endDate={endDate}
                />
              )}
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Insights;
