export const ORG_LEAD_TIME_FIELDS = [
  'eolLeadTime',
  'mxLeadTime',
  'tdyLeadTime',
  'washLeadTime',
  'ltiLeadTime',
  'pmiLeadTime',
] as const;

export type LeadTimeField = typeof ORG_LEAD_TIME_FIELDS[number];

export const LeadTimeFieldHeaderMap: Record<LeadTimeField, string> = {
  eolLeadTime: 'EOL',
  mxLeadTime: 'MX',
  tdyLeadTime: 'TDY',
  washLeadTime: 'Wash',
  ltiLeadTime: 'LTI',
  pmiLeadTime: 'PMI',
};
