import { useGetSelfProfile, usePatchSelfProfile } from 'apiHooks/Users.Hook';
import Loading from 'components/shared/Loading/Loading';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import type { SetStateAction } from 'react';
import React, { useEffect, useState } from 'react';
import {
  Collapse,
  DropdownItem,
  Input,
} from 'reactstrap';
import { ROLES } from 'utils/Constants';
import { userInfo } from 'utils/Helpers';

interface IEditProfileModal {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<SetStateAction<boolean>>;
  onClick: React.MouseEventHandler<HTMLElement> | undefined;
}

function EditProfileModal(props: IEditProfileModal) {
  const { modalOpen, setModalOpen, onClick } = props;

  const [givenName, setGivenName] = useState<string>(userInfo()?.givenName || '');
  const [familyName, setFamilyName] = useState<string>(userInfo()?.familyName || '');

  const {
    data: userProfile,
    isLoading,
    isError,
    error,
  } = useGetSelfProfile(modalOpen);

  const patchSelfProfile = usePatchSelfProfile();

  useEffect(() => {
    if (userProfile?.firstName) {
      setGivenName(userProfile.firstName);
      setFamilyName(userProfile.lastName);
    }
  }, [userProfile]);

  if (isError) return error as string;

  const canSubmit = (
    !!userProfile
    && givenName.length <= 50
    && familyName.length <= 50
    && (
      userProfile.firstName !== givenName
      || userProfile.lastName !== familyName
    )
  );

  const onSubmit = () => patchSelfProfile({
    ...givenName !== userProfile?.firstName && { firstName: givenName },
    ...familyName !== userProfile?.lastName && { lastName: familyName },
  });

  return (
    <>

      <DropdownItem
        onClick={(e) => {
          onClick?.(e);
          setModalOpen(true);
        }}
        className="rounded"
      >
        Profile
      </DropdownItem>

      <RhinoModal
        title={`${userInfo()?.isAdmin ? 'Edit ' : ''} Profile`}
        size="sm"
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={canSubmit}
        onSubmit={userInfo()?.isAdmin ? onSubmit : undefined}
        bodyClassName="d-flex flex-column align-items-center"
      >
        {isLoading
          ? (
            <Loading />
          ) : (
            <div className="d-flex flex-column gap-3">

              <Input
                placeholder="Given Name"
                value={givenName}
                onChange={(e) => setGivenName(e.target.value)}
                disabled={!userInfo()?.isAdmin}
              />
              <Input
                placeholder="Family Name"
                value={familyName}
                onChange={(e) => setFamilyName(e.target.value)}
                disabled={!userInfo()?.isAdmin}
              />

              <span className="px-3 text-center">
                {`Role: ${userProfile?.role ? ROLES[userProfile?.role] : 'None'}`}
              </span>
            </div>
          )}
        <Collapse isOpen={canSubmit} className="text-muted text-center">
          <div className="mt-3 pt-2 border-top">
            Name will update everywhere upon next login.
          </div>
        </Collapse>
      </RhinoModal>
    </>

  );
}

export default EditProfileModal;
