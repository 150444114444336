import React from 'react';
import type { ICellRendererParams } from '@ag-grid-community/core';
import { FaCheck, FaTimes, FaExclamationTriangle } from 'react-icons/fa';

function AgGridStatusOverviewStatusCellRenderer({ data } : ICellRendererParams) {
  if (data.relative > 0) {
    return <FaCheck className="text-success" />;
  }

  if (data.relative === 0) {
    return <FaExclamationTriangle className="text-warning" />;
  }

  return <FaTimes className="text-danger" />;
}

export default AgGridStatusOverviewStatusCellRenderer;
