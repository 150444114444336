import React from 'react';
import {
  Button,
} from 'reactstrap';
import { MdClose } from 'react-icons/md';

import type {
  ISectionMel,
} from 'types/IOrganizationSections';
import { usePatchSectionVehicleMels } from 'apiHooks/OrganizationSections.Hook';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';

interface IDeleteVehicleTypeMel {
  sectionName: string,
  mel: ISectionMel,
}

function DeleteVehicleTypeMelModal({ sectionName, mel }: IDeleteVehicleTypeMel) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const updateOperationSectionVehicleMels = usePatchSectionVehicleMels();

  const toggle = () => setModalOpen(!modalOpen);

  const submitDeleteVehicleType = () => (
    updateOperationSectionVehicleMels({
      sectionId: mel.sectionId,
      sectionName,
      mel: [
        { vehicleType: mel.vehicleType, mel: null }, // eslint-disable-line unicorn/no-null
      ],
    })
  );

  return (
    <>
      <Button onClick={toggle} className="p-0" color="link">
        <MdClose className="text-danger" />
      </Button>
      <RhinoModal
        title={`Delete ${mel.vehicleType} MEL for ${sectionName}`}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        onSubmit={submitDeleteVehicleType}
        submitButtonText="Delete"
      >
        <div className="d-flex">
          {`Are you sure you want to remove MEL for ${mel.vehicleType}?`}
        </div>
      </RhinoModal>
    </>
  );
}

export default DeleteVehicleTypeMelModal;
