import type { FilterByCallback, Option } from 'react-bootstrap-typeahead/types/types';
/**
 * Creates a custom FilterByCallback for the react-boostrap Typeahead component.
 * it enables showing all options on focus,
 * filtering options only once the user changes the input.
 * @interface `ISelectType` type of the Option fed to the typeahead component
 * @param fieldName key of ISelectType to filter by
 * @returns true if the
 */
const customFilterBy: <ISelectType> (fieldName: keyof ISelectType) => FilterByCallback = (
  (fieldName) => {
    type ObjectWithFieldName = Option & Record<typeof fieldName, string>;
    return (
      (option, state) => {
        if (state.selected.length > 0) {
          return true;
        }
        return (option as ObjectWithFieldName)[fieldName]
          ?.toLowerCase().includes(state.text.toLowerCase())
          || false;
      }
    );
  }
);

export default customFilterBy;
