import React from 'react';
import { useAtomValue } from 'jotai';
import { nanoid } from 'nanoid';
import { type ICellRendererParams } from '@ag-grid-community/core';
import { decode } from 'html-entities';
import { UncontrolledTooltip } from 'reactstrap';
import { MdInfo } from 'react-icons/md';

import { useGetOrganizationRules } from 'apiHooks/WorkFlows.Hook';
import RhinoUserCacheAtom from 'store/Rhino.store';

function AgGridAf1800RuleCellRenderer({ value } : ICellRendererParams) {
  const rhinoUserCache = useAtomValue(RhinoUserCacheAtom);
  const workflowRuleIdMap = useAtomValue(rhinoUserCache.WorkflowRuleIdMapAtom);
  const { isLoading, data } = useGetOrganizationRules();
  const id = `Af1800RuleTooltip-${nanoid()}`;

  if (isLoading || !data) {
    return <div>{value}</div>;
  }

  return (
    <div>
      {value}
      <span
        className="px-1"
        id={id}
        aria-describedby={id}
        aria-haspopup="true"
      >
        <MdInfo className="text-info" />
      </span>
      <UncontrolledTooltip
        placement="top"
        target={id}
      >
        {decode(workflowRuleIdMap[value]?.title)}
      </UncontrolledTooltip>
    </div>
  );
}

export default AgGridAf1800RuleCellRenderer;
