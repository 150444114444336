import React from 'react';
import { type ICellRendererParams } from '@ag-grid-community/core';

import ImageLightBoxModal from 'components/shared/ImageLightBox/ImageLightBoxModal';
import UploadImageModal from 'components/shared/ImageLightBox/UploadImageModal';
import {
  usePatchIndividualWaiverImageAnnotation,
  useDeleteWaiverImage,
  usePatchNewWaiverImage,
} from 'apiHooks/Vehicles.Hook';
import useRestrictUpdates from 'utils/helpers/useRestrictUpdates.Helper';

function AgGridWaiverImageCellRenderer({ data } : ICellRendererParams) {
  const restrictUpdates = useRestrictUpdates();

  const patchCaption = usePatchIndividualWaiverImageAnnotation(data.regNumber, data.id);
  const deleteImage = useDeleteWaiverImage(data.regNumber, data.id);
  const patchNewWaiverImage = usePatchNewWaiverImage(data.regNumber, data.id);
  const [currentRhinoImage, setCurrentRhinoImage] = React.useState<number | string>();

  const imagesExists = data.pictures && Array.isArray(data.pictures) && data.pictures.length > 0;

  return (
    <div>
      {
        imagesExists && (
          <ImageLightBoxModal
            pictures={data.pictures}
            modalTitle={`Images for ${data.rule}`}
            updateCaptionCallback={data.itemNo ? undefined : patchCaption}
            currentRhinoImageCallback={(image) => setCurrentRhinoImage(image.filename)}
            deleteImageCallback={() => (
              currentRhinoImage ? deleteImage(currentRhinoImage) : Promise.reject()
            )}
          />
        )
      }
      {
        !restrictUpdates && (
          <UploadImageModal
            modalTitle={`Upload waiver image for ${data.regNumber}`}
            uploadImageCallback={patchNewWaiverImage}
            disableAnnotation={false}
          />
        )
      }
    </div>
  );
}

export default AgGridWaiverImageCellRenderer;
