import { MEL_STATUSES } from 'utils/Constants';

/**
 * Returns an MEL status given an integer representing
 * (available vehicles) - (vehicle type MEL)
 */
const melStatusResolver = (value: number): typeof MEL_STATUSES[keyof typeof MEL_STATUSES] => {
  if (value > 0) {
    return MEL_STATUSES.PASS;
  }

  if (value === 0) {
    return MEL_STATUSES.WARNING;
  }

  // if (value < 0)
  return MEL_STATUSES.FAIL;
};

export default melStatusResolver;
