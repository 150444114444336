/**
 * Formats the given Date like so: MM-DD-YY_hhmm
 */

const formatDateTimeForFileName = (date: Date) => (
  date.toLocaleString('en-US', {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
  })
    .replaceAll(':', '')
    .replaceAll('/', '-')
    .replaceAll(', ', '_')
);

export default formatDateTimeForFileName;
