import React from 'react';
import {
  Button,
  Spinner,
} from 'reactstrap';
import { pdfjs, Document, Page } from 'react-pdf';
import { saveAs } from 'file-saver';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.min?url';

import styles from './PdfViewer.module.css';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

interface IPdfViewer {
  filename: string,
  pdfData: Uint8Array | undefined,
  hideDownloadButton?: boolean,
}

function PdfViewer({ filename, pdfData, hideDownloadButton }: IPdfViewer) {
  const [totalPages, setTotalPages] = React.useState(0);

  return (
    <div className="d-flex flex-column">
      {
        pdfData
          ? (
            <>
              {
                hideDownloadButton
                  ? <div />
                  : (
                    <div className="d-flex justify-content-start px-3">
                      <Button onClick={() => saveAs(new Blob([(pdfData || '')]), `${filename}.pdf`)}>
                        Download
                      </Button>
                    </div>
                  )
              }
              <Document
                className={styles['pdf-view']}
                file={{ data: new Uint8Array(pdfData) }}
                onLoadSuccess={({ numPages }: { numPages: number }) => setTotalPages(numPages)}
              >
                {Array.from(
                  new Array(totalPages),
                  (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                    />
                  ),
                )}
              </Document>
            </>
          )
          : <Spinner />
      }
    </div>
  );
}

export default PdfViewer;
