import type { WritableAtom } from 'jotai';
import { atomWithReset, type RESET } from 'jotai/utils';
import type { ColDef } from '@ag-grid-community/core';

import { type IWorkflowRule } from 'types/IWorkflows.d';
import type { ICustomFilter } from 'types/IFilter';

// FIXME: HOLY OVERCOMPLICATED BATMAN!!! FIND A BETTER WAY TO DO THIS

type WorkflowRuleIdMap = Record<string | number, IWorkflowRule>;

type SetStateActionWithReset<Value> =
  | Value
  | typeof RESET
  | ((prev: Value) => Value | typeof RESET);

const WorkflowRuleIdMapAtom = atomWithReset<WorkflowRuleIdMap>({});
const vehicleTypesAtom = atomWithReset<string[]>([]);

type WritableWorkflowRuleIdMapAtom = WritableAtom<
WorkflowRuleIdMap, [SetStateActionWithReset<WorkflowRuleIdMap>], void
>;
type WritableVehicleTypesAtom = WritableAtom<
string[], [SetStateActionWithReset<string[]>], void
>;

type WritableFilterAtom = WritableAtom<
ICustomFilter, [SetStateActionWithReset<ICustomFilter>], void
>;

type WritableColDefsAtom = WritableAtom<
ColDef[], [SetStateActionWithReset<ColDef[]>], void
>;

// type WritableBooleanAtom = WritableAtom<
// boolean, [SetStateActionWithReset<boolean>], void
// >;

export interface IRhinoUserCacheAtom {
  WorkflowRuleIdMapAtom: WritableWorkflowRuleIdMapAtom,
  vehicleTypesAtom: WritableVehicleTypesAtom,
  FleetDetailsAgGridColDefsAtom: WritableColDefsAtom | undefined,
  FleetDetailsFilterAtom: WritableFilterAtom | undefined,
  FleetDetailsInitialFilterAtom: WritableFilterAtom | undefined,
  InspectionWorkflowsFilterAtom: WritableFilterAtom | undefined,
  InspectionWorkflowsInitialFilterAtom: WritableFilterAtom | undefined,
  InspectionWorkflowsAgGridColDefsAtom: WritableColDefsAtom | undefined,
  OverviewMELsFilterAtom: WritableFilterAtom | undefined,
  OverviewMELsInitialFilterAtom: WritableFilterAtom | undefined,
  UserAdminAgGridColDefsAtom: WritableColDefsAtom | undefined,
  UserAdminFilterAtom: WritableFilterAtom | undefined,
  UserAdminInitialFilterAtom: WritableFilterAtom | undefined,
}

const RhinoUserCacheAtom = atomWithReset<IRhinoUserCacheAtom>({
  WorkflowRuleIdMapAtom,
  vehicleTypesAtom,
  FleetDetailsAgGridColDefsAtom: undefined,
  FleetDetailsFilterAtom: undefined,
  FleetDetailsInitialFilterAtom: undefined,
  InspectionWorkflowsFilterAtom: undefined,
  InspectionWorkflowsInitialFilterAtom: undefined,
  InspectionWorkflowsAgGridColDefsAtom: undefined,
  OverviewMELsFilterAtom: undefined,
  OverviewMELsInitialFilterAtom: undefined,
  UserAdminAgGridColDefsAtom: undefined,
  UserAdminFilterAtom: undefined,
  UserAdminInitialFilterAtom: undefined,
});

export default RhinoUserCacheAtom;
