import React from 'react';
import { useAtomValue } from 'jotai';
import { type ICellRendererParams } from '@ag-grid-community/core';
import { decode } from 'html-entities';
import { MdEdit } from 'react-icons/md';
import {
  Button,
  Input,
  Spinner,
} from 'reactstrap';

import { usePatchIndividualWaiver } from 'apiHooks/Vehicles.Hook';
import { useGetOrganizationRules } from 'apiHooks/WorkFlows.Hook';
import RhinoUserCacheAtom from 'store/Rhino.store';
import type { IWaiverPatch, IWaiveredItem, WaiverType } from 'types/IVehicleDetails';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import { WAIVER_TYPES } from 'utils/Constants';

function EditWaiverCard({ waiver }: { waiver: IWaiveredItem; }) {
  const {
    waiverType,
    explanation,
    regNumber,
    waiverId,
    ruleId,
    status,
  } = waiver;
  const [waiverPatch, setWaiverPatch] = React.useState<IWaiverPatch>({});
  const [modalOpen, setModalOpen] = React.useState(false);
  const patchWaiver = usePatchIndividualWaiver(regNumber, waiverId);
  const { isLoading } = useGetOrganizationRules();
  const rhinoUserCache = useAtomValue(RhinoUserCacheAtom);
  const workflowRuleIdMap = useAtomValue(rhinoUserCache.WorkflowRuleIdMapAtom);

  const canSubmit = (
    waiverPatch.status !== status
    || waiverPatch.waiverType !== waiverType
    || waiverPatch.explanation !== explanation
  );

  const handleSubmit = async () => {
    await patchWaiver(waiverPatch).catch(() => {});
  };

  return (
    <>
      <Button color="link" onClick={() => setModalOpen(true)}>
        <MdEdit />
      </Button>
      <RhinoModal
        title={`Edit ${ruleId} Waiver`}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={canSubmit}
        onSubmit={handleSubmit}
      >
        <div className="d-flex">
          <span className="fw-bold px-1">Associated Rule:</span>
          <span className="px-1">{ruleId}</span>
        </div>
        <div className="d-flex">
          <span className="fw-bold px-1">Rule Description:</span>
          {
            isLoading ? <Spinner /> : <span className="px-1">{decode(workflowRuleIdMap[ruleId]?.title) || ''}</span>
          }
        </div>
        <div className="d-flex align-items-center">
          <span className="fw-bold px-1">Type:</span>
          <Input
            type="select"
            className="text-capitalize px-1"
            onChange={(e) => setWaiverPatch({
              ...waiverPatch,
              ...waiverType && { waiverType: e.target.value as WaiverType },
            })}
            defaultValue={waiverType}
          >
            {WAIVER_TYPES.map((type) => (
              <option className="text-capitalize" key={type} value={type}>{type}</option>
            ))}
          </Input>
        </div>
        <div className="d-grid">
          <span className="fw-bold px-1">Explanation:</span>
          <Input
            defaultValue={explanation}
            onChange={(e) => (
              setWaiverPatch({
                ...waiverPatch,
                ...explanation && { explanation: e.target.value },
              })
            )}
          />
        </div>
      </RhinoModal>
    </>
  );
}

function AgGridVehicleWaiverActionColumnCellRenderer({ data } : ICellRendererParams) {
  return <EditWaiverCard waiver={data as IWaiveredItem} />;
}

export default AgGridVehicleWaiverActionColumnCellRenderer;
