import {
  addMonths,
  differenceInMonths,
  startOfMonth,
} from 'date-fns';
import { saveAs } from 'file-saver';

import type { ITimeSeriesUsageDatum } from 'types/Insights';

type RegNumberMilesUsage = {
  date: string,
  miles: number,
};

type RegNumberHoursUsage = {
  date: string,
  hours: number,
};

type MilesGroupedByMonth = Record<number, number | null>;
type HoursGroupedByMonth = Record<number, number | null>;

type StatsForMonth = {
  miles: number | null;
  hours: number | null;
};

type MonthlyStats = Record<number, StatsForMonth>;

interface IRegNumberUsage {
  miles: RegNumberMilesUsage[];
  hours: RegNumberHoursUsage[];
}

type RegNumberUsageMap = Record<string, IRegNumberUsage>;
type RegNumberMonthlyStatsMap = Record<string, MonthlyStats>;

/**
 * https://stackoverflow.com/questions/4256030/limit-the-amount-of-number-shown-after-a-decimal-place-in-javascript/27230941#27230941
 *
 * @param val
 * @returns
 */
const limitDecimalPoints = (val: number) => (Number.parseFloat((val).toFixed(2)));

/**
 *
 * @param date
 * @returns
 */
const formatDateForCSV = (date: Date) => date.toLocaleString('en-US', { month: 'short', year: 'numeric' });

/**
 * Array of dates corresponding with the months to include in the CSV
 *
 * @param data
 */
const calculateMonthUsageDateRange = (data: ITimeSeriesUsageDatum[]) => {
  const { date: oldestDateOverall } = data[0] || {};
  const { date: newestDateOverall } = data?.at(-1) || {};

  // do not proceed without these dates
  if (!oldestDateOverall || !newestDateOverall) {
    return [];
  }

  const monthRange = differenceInMonths(new Date(newestDateOverall), new Date(oldestDateOverall));
  const initMonthDate = startOfMonth(new Date(oldestDateOverall));

  // https://stackoverflow.com/questions/4852017/how-to-initialize-an-arrays-length-in-javascript/28599347#28599347
  // eslint-disable-next-line unicorn/prefer-spread
  return Array.from(new Array(monthRange + 1)).map((_, i) => addMonths(initMonthDate, i).getTime());
};

/**
 *
 * @param miles
 * @returns
 */
const validateMiles = (miles: RegNumberMilesUsage[]) => (
  miles.reduce((normalizedMiles: RegNumberMilesUsage[], currentValue, i) => {
    // initialize normalizedMiles
    if (i === 0) {
      return [currentValue];
    }

    const previousValue = normalizedMiles[i - 1];
    const nextValue = miles[i + 1];

    // check to prevent esLint from yelling
    if (!previousValue) {
      return normalizedMiles;
    }

    // if the current miles is greater than the previous miles, add current miles
    if (previousValue.miles <= currentValue.miles) {
      return [...normalizedMiles, currentValue];
    }

    // if the previous miles are greater than the current miles and the next miles are greater
    //   than the previous miles, or there are no next miles, assume the current miles are bad
    //   and continue
    if (!nextValue
      || (previousValue.miles > currentValue.miles && nextValue.miles > previousValue.miles)) {
      return normalizedMiles;
    }

    // if the previous miles are greater than the current miles and the next miles, assume the
    //  previous miles are bad, remove the previous miles and add the current miles
    if (previousValue.miles > currentValue.miles && nextValue.miles < previousValue.miles) {
      return [...normalizedMiles.slice(0, -1), currentValue];
    }

    // TODO: find other edge cases
    return normalizedMiles;
  }, [])
);

/**
 *
 * @param hours
 * @returns
 */
const validateHours = (hours: RegNumberHoursUsage[]) => (
  hours.reduce((normalizedHours: RegNumberHoursUsage[], currentValue, i) => {
    // initialize normalizedHours
    if (i === 0) {
      return [currentValue];
    }

    const previousValue = normalizedHours[i - 1];
    const nextValue = hours[i + 1];

    // check to prevent esLint from yelling
    if (!previousValue) {
      return normalizedHours;
    }

    // if the current hours is greater than the previous hours, add current hours
    if (previousValue.hours <= currentValue.hours) {
      return [...normalizedHours, currentValue];
    }

    // if the previous hours are greater than the current hours and the next hours are greater
    //   than the previous hours, or there are no next hours, assume the current hours are bad
    //   and continue
    if (!nextValue
      || (previousValue.hours > currentValue.hours && nextValue.hours > previousValue.hours)) {
      return normalizedHours;
    }

    // if the previous hours are greater than the current hours and the next hours, assume the
    //  previous hours are bad, remove the previous hours and add the current hours
    if (previousValue.hours > currentValue.hours && nextValue.hours < previousValue.hours) {
      return [...normalizedHours.slice(0, -1), currentValue];
    }

    // TODO: find other edge cases
    return normalizedHours;
  }, [])
);

/**
 *
 * @param data
 * @returns
 */
const groupByRegNumber = (data: ITimeSeriesUsageDatum[], usageMonthRange: number[]) => {
  const byRegNumber = data.reduce((agg: RegNumberUsageMap, currentDatum) => {
    const {
      regNumber,
      date,
      miles,
      hours,
    } = currentDatum;

    const newMiles: RegNumberMilesUsage[] = miles === null ? [] : [{ date, miles }];
    const newHours: RegNumberHoursUsage[] = hours === null ? [] : [{ date, hours }];
    const previousRegNumberValues = agg[regNumber];

    if (previousRegNumberValues) {
      return {
        ...agg,
        [regNumber]: {
          miles: [...previousRegNumberValues.miles, ...newMiles],
          hours: [...previousRegNumberValues.hours, ...newHours],
        },
      };
    }

    return {
      ...agg,
      [regNumber]: {
        miles: newMiles,
        hours: newHours,
      },
    };
  }, {});

  const regNumbers = Object.keys(byRegNumber);
  const stats = regNumbers.reduce((regNumStatMap: RegNumberMonthlyStatsMap, regNumber) => {
    const { miles, hours } = byRegNumber[regNumber] || {};

    if (!miles || !hours) {
      return regNumStatMap;
    }

    const normalizedMiles = validateMiles(miles);
    const normalizedHours = validateHours(hours);

    const milesGrouped = normalizedMiles.reduce((groupedMiles: MilesGroupedByMonth, mileValue) => {
      const reportedMonth = startOfMonth(new Date(mileValue.date)).getTime();

      if (groupedMiles[reportedMonth]) {
        return groupedMiles;
      }

      return {
        ...groupedMiles,
        [reportedMonth]: mileValue.miles,
      };
    }, {});

    const hoursGrouped = normalizedHours.reduce((groupedHours: HoursGroupedByMonth, hoursValue) => {
      const reportedMonth = startOfMonth(new Date(hoursValue.date)).getTime();

      if (groupedHours[reportedMonth]) {
        return groupedHours;
      }

      return {
        ...groupedHours,
        [reportedMonth]: hoursValue.hours,
      };
    }, {});

    const groupedByMonth = usageMonthRange.reduce((monthStats: MonthlyStats, month) => (
      {
        ...monthStats,
        [month]: {
          hours: hoursGrouped[month] ?? null,
          miles: milesGrouped[month] ?? null,
        },
      }
    ), {});

    return {
      ...regNumStatMap,
      [regNumber]: groupedByMonth,
    };
  }, {});

  return regNumbers.reduce((regNumDeltaMap: RegNumberMonthlyStatsMap, regNumber) => {
    const monthUsage = stats[regNumber];

    if (!monthUsage) {
      return regNumDeltaMap;
    }

    const deltasByMonth = usageMonthRange.reduce((deltas: MonthlyStats, month, i) => {
      const prevIndex = i === 0 ? undefined : usageMonthRange[i - 1];
      const previousMonth = prevIndex === undefined ? undefined : monthUsage[prevIndex];
      const currentMonth = monthUsage[month];

      if (!previousMonth || !currentMonth) {
        return {
          ...deltas,
          [month]: {
            hours: null,
            miles: null,
          },
        };
      }

      const { hours: currentHours, miles: currentMiles } = currentMonth;
      const previousHours = previousMonth.hours ?? 0;
      const previousMiles = previousMonth.miles ?? 0;

      const hours = currentHours === null ? null : limitDecimalPoints(currentHours - previousHours);
      const miles = currentMiles === null ? null : limitDecimalPoints(currentMiles - previousMiles);

      return { ...deltas, [month]: { hours, miles } };
    }, {});

    return {
      ...regNumDeltaMap,
      [regNumber]: deltasByMonth,
    };
  }, {});
};

/**
 *
 * @param data
 * @returns
 */
const saveVehicleUsageAsCsv = (data: ITimeSeriesUsageDatum[]) => {
  const usageMonthRange: number[] = calculateMonthUsageDateRange(data);

  // Usage requires more than one month of reporting.
  if (usageMonthRange.length <= 1) {
    return;
  }

  // Group data into series by vehicle
  const statsByVehicle = groupByRegNumber(data, usageMonthRange);

  const sortedRegNumbers = Object.keys(statsByVehicle).sort();

  // construct CSV
  const reportHeadersCSV = `"${[
    'Registration Number',
    ...usageMonthRange.flatMap<string>((monthDate) => {
      const monthString = formatDateForCSV(new Date(monthDate));
      return [`${monthString} miles`, `${monthString} kilometers`, `${monthString} hours`];
    }),
  ].join('","')}"`;

  const reportsEntriesCSV = sortedRegNumbers.map<string>((regNumber) => {
    const stats = statsByVehicle[regNumber];

    if (!stats) {
      return '';
    }

    // https://superuser.com/questions/318420/formatting-a-comma-delimited-csv-to-force-excel-to-interpret-value-as-a-string/318421#318421
    return [
      `"=""${regNumber}""`,
      ...usageMonthRange.flatMap((month) => {
        const monthStats = stats[month];

        if (!monthStats) {
          return ['', '', ''];
        }
        const km = monthStats.miles ? limitDecimalPoints(monthStats.miles * 1.6) : '';
        return [`${monthStats.miles ?? ''}`, `${km}`, `${monthStats.hours ?? ''}`];
      }),
      '"',
    ].join('","');
  });

  const reportCsvString = [reportHeadersCSV, ...reportsEntriesCSV].join('\n');
  const reportBlob = new Blob([reportCsvString]);
  saveAs(reportBlob, `vehicle-usage_${formatDateForCSV(new Date(usageMonthRange[0] ?? 0))}-${formatDateForCSV(new Date((usageMonthRange.at(-1) ?? 0)))}.csv`);
};

export default saveVehicleUsageAsCsv;
