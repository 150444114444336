import React from 'react';
import { MdDashboardCustomize } from 'react-icons/md';

import type { IRoute } from 'types/IRoute';
import Overview from 'views/Overview/Overview';

const overviewRoute: [IRoute] = [{
  component: <Overview />,
  icon: <MdDashboardCustomize />,
  label: 'Overview',
  path: '/',
}];

export default overviewRoute;
