import React from 'react';
import { type ICellRendererParams } from '@ag-grid-community/core';
import ReactDatePicker from 'react-datepicker';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from 'reactstrap';
import { MdEdit } from 'react-icons/md';

import { usePatchScheduledMx } from 'apiHooks/Vehicles.Hook';
import type { IScheduledMx, IScheduledMxPatch } from 'types/Mx';
import { RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';

function EditScheduledMxModal({ scheduledMx }: { scheduledMx: IScheduledMx }) {
  const origCompleteDate = scheduledMx.completionDate ? new Date(scheduledMx.completionDate) : null;
  const [modalOpen, setModalOpen] = React.useState(false);
  const [mxType, setMxType] = React.useState(scheduledMx.mxType);
  const [date, setDate] = React.useState<Date>(new Date(scheduledMx.mxDate));
  const [completed, setCompleted] = React.useState(scheduledMx.completed);
  const [completionDate, setCompletionDate] = React.useState<Date | null>(origCompleteDate);
  const patchScheduledMx = usePatchScheduledMx(scheduledMx.regNumber, scheduledMx.mxId);

  const toggleCompleted = () => {
    setCompletionDate(completed ? null : new Date());
    setCompleted(!completed);
  };

  const resetState = () => {
    setMxType(scheduledMx.mxType);
    setDate(new Date(scheduledMx.mxDate));
    setCompleted(scheduledMx.completed);
    setCompletionDate(origCompleteDate);
    setModalOpen(false);
  };

  const canSubmit = () => mxType === '' || (completed && completionDate === null);

  const updatedSchedule = () => {
    if (!canSubmit) {
      return;
    }

    const scheduledMxPatch: IScheduledMxPatch = {
      ...mxType && { mxType },
      ...date && { mxDate: date?.toISOString() },
      ...completed !== scheduledMx.completed && { completed },
      ...completionDate && { completionDate: completionDate?.toISOString() },
    };

    patchScheduledMx(scheduledMxPatch)
      .then(() => {
        setModalOpen(false);
        resetState();
      })
      .catch(() => {});
  };

  return (
    <>
      <Button color="link" onClick={() => setModalOpen(true)}>
        <MdEdit />
      </Button>
      <Modal isOpen={modalOpen}>
        <RhinoModalHeader toggle={resetState}>
          {`Edit Scheduled Mx for ${scheduledMx.regNumber}`}
        </RhinoModalHeader>

        <ModalBody className="d-grid gap-1 text-secondary">
          <div className="d-flex align-items-center">
            <span className="px-2 text-nowrap col-3">Mx Type:</span>
            <Input
              value={mxType}
              onChange={(e) => setMxType(e.target.value)}
              placeholder={scheduledMx.mxType}
            />
          </div>

          <div className="d-flex">
            <span className="px-2 text-nowrap">Scheduled For:</span>
            <ReactDatePicker
              placeholderText={new Date(scheduledMx.mxDate).toLocaleDateString('en-US')}
              selected={date}
              showIcon
              onChange={(newDate) => newDate && setDate(newDate)}
            />
          </div>

          <div className="d-flex align-items-center gap-1">
            <Label className="d-flex align-items-center">
              <Input
                type="checkbox"
                defaultChecked={completed}
                onClick={toggleCompleted}
              />
              <span className="d-flex px-2">Completed</span>
            </Label>
            {
              completed
                ? (
                  <ReactDatePicker
                    maxDate={new Date()}
                    selected={completionDate}
                    onChange={setCompletionDate}
                    placeholderText="Completion Date"
                    showIcon
                  />
                ) : null
            }

          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button onClick={resetState}>
            Cancel
          </Button>
          <Button
            disabled={canSubmit()}
            onClick={updatedSchedule}
          >
            Update Scheduled Mx
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

function AgGridEditScheduledMxCellRenderer({ data } : ICellRendererParams) {
  return <EditScheduledMxModal scheduledMx={data as IScheduledMx} />;
}

export default AgGridEditScheduledMxCellRenderer;
