import React from 'react';
import {
  Nav,
  NavItem,
  TabPane,
  TabContent,
  NavLink,
  Badge,
} from 'reactstrap';
import useGetOverview from 'apiHooks/Overview.Hook';
import { melStatusResolver } from 'utils/Helpers';
import { MEL_STATUSES } from 'utils/Constants';
import Loading from 'components/shared/Loading/Loading';
import OverviewHeader from './OverviewHeader';
import ApproachingLTIs from './tabs/ApproachingLti';
import ApproachingPMIs from './tabs/ApproachingPmi';
import ApproachingTDYs from './tabs/ApproachingTdy';
import CurrentTDYs from './tabs/CurrentTdy';
import ScheduledMx from './tabs/ScheduledMx';
import ApproachingWashes from './tabs/ApproachingWashes';
import ApproachingEol from './tabs/ApproachingEol';
import StatusOverview from './tabs/StatusOverview';
import Availability from './tabs/Availability';
import useSectionFmcResults from './useSectionFmcResults';
import type { OverviewTabName } from './common';
import { OVERVIEW_TAB_NAMES, badgeClassNames, tabClassNames } from './common';

function SectionOverview({ sectionId, sectionName }: { sectionId: number, sectionName: string; }) {
  const {
    isLoading,
    isError,
    data,
    error,
  } = useGetOverview();

  const sectionFmcResults = useSectionFmcResults(sectionId);

  const [activeTab, setActiveTab] = React.useState(0);

  const sectionAvailability = data?.availability.find(
    (entry) => entry.sectionId === sectionId,
  );

  const getBadgeTotal = (
    tabName: OverviewTabName,
    melStatus?: typeof MEL_STATUSES[keyof typeof MEL_STATUSES],
  ) => {
    switch (tabName) {
      case 'Status Details': {
        return data?.mx.allInOpStatus.length;
      }
      case 'Availability': {
        return sectionAvailability?.vehicleTypes.filter((entry) => (
          melStatus && entry.mel
            ? melStatusResolver(entry.available - entry.mel) === melStatus
            : false
        )).length;
      }
      case 'Scheduled Mx': {
        return data?.mx.approachingScheduledMx.length;
      }
      case 'Approaching LTIs': {
        return data?.mx.approachingLti.length;
      }
      case 'Approaching PMIs': {
        return data?.mx.approachingPmi.length;
      }
      case 'Current TDYs': {
        return data?.tdy.current.length;
      }
      case 'Approaching TDYs': {
        return data?.tdy.approaching.length;
      }
      case 'Approaching Washes': {
        return data?.wash.length;
      }
      case 'Approaching EOL/Depo Turn-In': {
        return data?.eol.length;
      }
      default: {
        return undefined;
      }
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <div>{error as string}</div>;
  }

  return (
    <div className="d-flex flex-column h-100 overflow-hidden">
      <OverviewHeader fmcData={sectionFmcResults} sectionName={sectionName} />
      <div className="d-flex flex-column h-100">
        <Nav className="bg-gray" tabs>
          {
            OVERVIEW_TAB_NAMES.map((tabName, tabIndex) => (
              <NavItem key={tabName}>
                <NavLink
                  onClick={() => setActiveTab(tabIndex)}
                  className={tabClassNames(tabIndex, activeTab)}
                >
                  {tabName}
                  <div className="d-flex align-items-center gap-1">
                    {tabName === 'Availability'
                      ? (
                        <>
                          <Badge pill color="success" className={badgeClassNames(tabIndex, activeTab)}>
                            {getBadgeTotal(tabName, MEL_STATUSES.PASS) || undefined}
                          </Badge>
                          <Badge pill color="warning" className={badgeClassNames(tabIndex, activeTab)}>
                            {getBadgeTotal(tabName, MEL_STATUSES.WARNING) || undefined}
                          </Badge>
                          <Badge pill color="danger" className={badgeClassNames(tabIndex, activeTab)}>
                            {getBadgeTotal(tabName, MEL_STATUSES.FAIL) || undefined}
                          </Badge>
                        </>
                      ) : (
                        <Badge pill color="primary" className={badgeClassNames(tabIndex, activeTab)}>
                          {getBadgeTotal(tabName) || undefined}
                        </Badge>
                      )}
                  </div>
                </NavLink>
              </NavItem>
            ))
          }
        </Nav>
        <TabContent className="h-100" activeTab={activeTab}>
          {
            OVERVIEW_TAB_NAMES.map((tabName, tabIndex) => (
              <TabPane key={tabName} tabId={tabIndex} className="h-100">
                {tabName === 'Status Details' && (
                  <StatusOverview
                    inOpStatusRows={data.mx.allInOpStatus}
                    wrm={data.wrm}
                    availability={sectionAvailability?.vehicleTypes || []}
                  />
                )}
                {tabName === 'Availability' && <Availability vehicleTypes={sectionAvailability?.vehicleTypes} sectionName={sectionName} />}
                {tabName === 'Scheduled Mx' && <ScheduledMx rows={data.mx.approachingScheduledMx} />}
                {tabName === 'Approaching LTIs' && <ApproachingLTIs rows={data.mx.approachingLti} />}
                {tabName === 'Approaching PMIs' && <ApproachingPMIs rows={data.mx.approachingPmi} />}
                {tabName === 'Current TDYs' && <CurrentTDYs rows={data.tdy.current} />}
                {tabName === 'Approaching TDYs' && <ApproachingTDYs rows={data.tdy.approaching} />}
                {tabName === 'Approaching Washes' && <ApproachingWashes rows={data.wash} />}
                {tabName === 'Approaching EOL/Depo Turn-In' && <ApproachingEol rows={data.eol} />}
              </TabPane>
            ))
          }
        </TabContent>
      </div>
    </div>
  );
}

export default SectionOverview;
