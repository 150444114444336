import { useQuery, useQueryClient } from '@tanstack/react-query';

import type { IOrganizationSections, ISectionVehicleMelPatch } from 'types/IOrganizationSections';
import {
  authHttpDelete,
  authHttpGet,
  authHttpPatch,
  authHttpPost,
  userInfo,
} from 'utils/Helpers';

const organizationSectionUrl = () => `/data/v1/organizations/${userInfo()?.orgId}/sections`;

const useGetOrganizationSections = () => (
  useQuery({
    queryKey: ['GetOrganizationSections'],
    queryFn: () => (
      authHttpGet<IOrganizationSections[]>('/data/v2/organization/sections')
    ),
  })
);

/**
 * Add New section
 *
 * @param name
 * @returns
 */
const usePostNewOrganizationSection = () => {
  const queryClient = useQueryClient();

  return async (name: string) => {
    const sectionPost: Pick<IOrganizationSections, 'sectionName'> = {
      sectionName: name,
    };
    await authHttpPost(organizationSectionUrl(), sectionPost)
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetOrganization'] }))
      .catch(() => queryClient.invalidateQueries({ queryKey: ['GetOrganization'] }));
  };
};

const usePatchSectionVehicleMels = () => {
  const queryClient = useQueryClient();

  return (melPatch: ISectionVehicleMelPatch) => (
    authHttpPatch(`${organizationSectionUrl()}/${melPatch.sectionId}`, melPatch)
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetOrganization'] }))
      .catch(() => queryClient.invalidateQueries({ queryKey: ['GetOrganization'] }))
  );
};

const usePatchSectionName = () => {
  const queryClient = useQueryClient();

  return async (sectionId: number, newName: string) => {
    const sectionPatch: Pick<IOrganizationSections, 'sectionName'> = {
      sectionName: newName,
    };
    await authHttpPatch(`${organizationSectionUrl()}/${sectionId}`, sectionPatch)
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetOrganization'] }));
  };
};

const useDeleteOrganizationSection = (sectionId: number) => {
  const queryClient = useQueryClient();

  return () => (
    authHttpDelete(`${organizationSectionUrl()}/${sectionId}`)
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetOrganization'] }))
  );
};

export {
  useGetOrganizationSections,
  usePostNewOrganizationSection,
  usePatchSectionVehicleMels,
  usePatchSectionName,
  useDeleteOrganizationSection,
};
