import React from 'react';
import { decode } from 'html-entities';
import ChangeControls from './ChangeControls';

type MaybeString = string | null | undefined;

interface IEditableStringRuleField {
  canEdit: boolean,
  isDisabled: boolean,
  value: MaybeString,
  originalValue: MaybeString,
  updateFieldCallback: (value: MaybeString) => void,
  editingLockCallback: (inEdit: boolean) => void,
}

function DescriptionField({
  value,
  originalValue,
  canEdit,
  isDisabled,
  updateFieldCallback,
  editingLockCallback,
}: IEditableStringRuleField) {
  const [ruleDescription, setRuleDescription] = React.useState(decode(value));
  const [isEditing, setIsEditing] = React.useState(false);

  React.useEffect(() => {
    setRuleDescription(decode(value));
  }, [value]);

  const beginEditing = () => {
    setIsEditing(true);
    editingLockCallback(true);
  };

  const confirmEdits = () => {
    updateFieldCallback(ruleDescription);
    setIsEditing(false);
    editingLockCallback(false);
  };

  const cancelEdits = () => {
    updateFieldCallback(value);
    setRuleDescription(decode(value));
    setIsEditing(false);
    editingLockCallback(false);
  };

  const undoEdits = () => {
    setRuleDescription(originalValue || '');
    updateFieldCallback(originalValue);
  };

  return (
    <div data-no-dnd="true" className="d-flex w-100">
      <div className="px-1">
        <small className="text-muted">Description: </small>
        <div className="d-flex justify-content-end">
          { canEdit
          && (
            <ChangeControls
              inEdit={beginEditing}
              isDisabled={isDisabled}
              confirmEdits={confirmEdits}
              cancelEdits={cancelEdits}
              undoEdits={undoEdits}
              canUndo={originalValue !== undefined
                && decode(ruleDescription) !== decode(originalValue)}
              isInValid={false}
            />
          )}
        </div>
      </div>
      <textarea
        className="w-100"
        value={ruleDescription}
        readOnly={!isEditing}
        maxLength={300}
        onChange={(e) => setRuleDescription(e.target.value)}
      />
    </div>
  );
}

export default DescriptionField;
