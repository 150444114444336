import React from 'react';
import { type ICellRendererParams } from '@ag-grid-community/core';

import Af1800PdfModal from 'components/shared/Af1800Reports/Af1800PdfModal';
import AF1800HistoricVehicleReportModal from 'components/shared/Af1800Reports/Af1800HistoricVehicleReportModal';

function AgGridAf1800DownloadCellRenderer({ data } : ICellRendererParams) {
  const {
    regNumber,
    completionDate,
    inspectionDate,
    visitsToMx,
  } = data || {};

  const hasNoAf1800 = (
    !(inspectionDate || completionDate)
    && visitsToMx === 0
  );

  if (!regNumber || hasNoAf1800) {
    return <div />;
  }

  if (completionDate) {
    return (
      <AF1800HistoricVehicleReportModal
        regNumber={regNumber}
        af1800FormDate={new Date(completionDate)}
      />

    );
  }

  return (
    <Af1800PdfModal regNumber={regNumber} />
  );
}

export default AgGridAf1800DownloadCellRenderer;
