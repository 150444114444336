import React from 'react';
import {
  Input,
  Label,
  FormGroup,
} from 'reactstrap';
import { NumericFormat } from 'react-number-format';

import type { IVehicleWorkflow } from 'types/IWorkflows';

interface IReportMilesHours {
  workflowData: IVehicleWorkflow | undefined;
  isBooleanRule: boolean;
  updateReportMilesOrHours: (reportMilesOrHours: boolean) => void;
  updateReportedMiles: (reportMiles: number | null) => void;
  updateReportedHours: (reportHours: number | null) => void;
}

function ReportMilesHours({
  workflowData,
  isBooleanRule,
  updateReportMilesOrHours,
  updateReportedMiles,
  updateReportedHours,

}: IReportMilesHours) {
  const [reportMilesOrHours, setReportMilesOrHours] = React.useState(false);
  const [hasAf1800M, setHasAf1800M] = React.useState<boolean>();
  const [hasAf1800H, setHasAf1800H] = React.useState<boolean>();

  React.useEffect(() => {
    if (workflowData && (hasAf1800H === undefined || hasAf1800M === undefined)) {
      setHasAf1800H(workflowData.rules.some((x) => x.ruleId === 'AF1800-H'));
      setHasAf1800M(workflowData.rules.some((x) => x.ruleId === 'AF1800-M'));
    }
  }, [workflowData, hasAf1800M, hasAf1800H]);

  if (!workflowData || (!hasAf1800H && !hasAf1800M)) {
    return <div />;
  }

  const toggleReportMilesOrHours = (checked: boolean) => {
    setReportMilesOrHours(checked);
    updateReportMilesOrHours(checked);

    if (!checked) {
      updateReportedHours(null);
      updateReportedHours(null);
    }
  };

  return (
    <>
      <div>
        <Label>
          <Input
            type="checkbox"
            disabled={!isBooleanRule}
            onChange={(e) => toggleReportMilesOrHours(e.target.checked)}
          />
          <span className={`px-2 ${isBooleanRule ? '' : 'text-black text-opacity-25'}`}>
            {`Report ${[
              hasAf1800M && 'Miles',
              hasAf1800H && 'Hours',
            ].filter(Boolean).join(' and ')}`}
          </span>
        </Label>
      </div>
      <div className="d-flex justify-space-between">
        {
        reportMilesOrHours && hasAf1800M
          ? (
            <FormGroup floating className="mt-2 col-5">
              <NumericFormat
                allowNegative={false}
                decimalScale={0}
                min={1}
                thousandSeparator
                className="form-control"
                placeholder=" "
                id="floatingReportMilesInput"
                onValueChange={({ floatValue }) => updateReportedMiles(floatValue || null)}
              />
              <Label for="floatingReportMileInput">
                Report Miles (Required)
              </Label>
            </FormGroup>
          )
          : null
        }
        {
          reportMilesOrHours && hasAf1800H
            ? (
              <FormGroup floating className="mt-2 col-5">
                <NumericFormat
                  allowNegative={false}
                  decimalScale={0}
                  min={1}
                  thousandSeparator
                  className="form-control"
                  placeholder=" "
                  id="floatingReportHoursInput"
                  onValueChange={({ floatValue }) => updateReportedHours(floatValue || null)}
                />
                <Label for="floatingReportHoursInput">
                  Report Hours (Required)
                </Label>
              </FormGroup>
            )
            : null
        }
      </div>
    </>
  );
}

export default ReportMilesHours;
