import { decode } from 'html-entities';
import React, { useEffect } from 'react';
import { FaChevronCircleRight } from 'react-icons/fa';
import {
  Button,
  Badge,
  Table,
} from 'reactstrap';
import { Collapse } from 'react-collapse';
import type { IOrganization } from 'types/IOrganization';
import type { ISectionMelWithName } from 'types/IOrganizationSections';
import { useGetVehicleTypes } from 'apiHooks/VehicleTypes.Hook';
import { useAtomValue } from 'jotai';
import AddSectionMelsModal from './AddSectionMelsModal';
import DeleteVehicleTypeMelModal from './DeleteVehicleMelModal';
import EditSectionMelModal from './EditSectionMelModal';
import adminCollapseStateAtom from './adminCollapseState.store';

type VehicleTypeMels = Record<string, ISectionMelWithName[]>;

interface IOrganizationVehicleType {
  vehicleType: string,
  mels: ISectionMelWithName[] | undefined,
  orgDetails: IOrganization,
}

function OrganizationVehicleType({ vehicleType, mels, orgDetails }: IOrganizationVehicleType) {
  const adminCollapseState = useAtomValue(adminCollapseStateAtom);

  const [isOpen, setIsOpen] = React.useState(adminCollapseState === 'EXPANDED');

  const toggle = () => setIsOpen((b) => !b);

  useEffect(
    () => setIsOpen(adminCollapseState === 'EXPANDED'),
    [adminCollapseState],
  );

  return (
    <div>

      <Button
        className="w-100 rounded-0 d-flex justify-content-between bg-secondary-custom-gradient border-top-0"
        onClick={toggle}
      >
        <span className="d-flex align-items-center">
          <FaChevronCircleRight className={`rotate ${isOpen ? 'down' : ''}`} />
          <span className="px-2">{vehicleType}</span>
        </span>
        <span className="text-end">
          Sections with MEL for this vehicle type:
          {' '}
          <Badge color="primary" className="text-body" pill>
            {mels?.length || 0}
          </Badge>
        </span>
      </Button>

      <Collapse isOpened={isOpen}>
        <div className="d-flex justify-content-between">
          <div className="d-flex p-4">
            <table className="table table-borderless px-4 w-auto">
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <AddSectionMelsModal
                      vehicleType={vehicleType}
                      vehicleTypeMels={mels}
                      orgDetails={orgDetails}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="d-flex flex-column px-4">
              <Table className="table-striped">
                <thead>
                  <tr>
                    <th>
                      Section
                    </th>
                    <th>
                      MEL
                    </th>
                    <th>{' '}</th>
                    <th>{' '}</th>
                  </tr>
                </thead>
                <tbody>
                  {mels
                    ? (
                      mels.map((melEntry) => (
                        <tr
                          key={`section-${melEntry.sectionId}-defaultType-${melEntry.vehicleType || ''}`}
                        >
                          <td>{decode(melEntry.sectionName)}</td>
                          <td className="text-end">{melEntry.mel}</td>

                          <td>
                            {/* <EditVehicleTypeMelModal section={section} originalMel={mel} /> */}
                          </td>

                          <td>
                            <EditSectionMelModal
                              vehicleTypeMels={mels}
                              originalMel={melEntry}
                              orgDetails={orgDetails}
                            />
                          </td>
                          <td>
                            <DeleteVehicleTypeMelModal
                              sectionName={melEntry.sectionName}
                              mel={melEntry}
                            />
                          </td>

                        </tr>
                      )))
                    : (
                      <tr>
                        <td className="text-muted" colSpan={4}>No Vehicle Type MELs defined</td>
                      </tr>
                    )}
                </tbody>
              </Table>
            </div>

          </div>
        </div>
      </Collapse>
    </div>
  );
}

type OrganizationDetailsGroupByVehicleTypeProps = {
  orgDetails: IOrganization;
  newVehicleTypeName: string;
};

function OrganizationDetailsGroupByVehicleType(props: OrganizationDetailsGroupByVehicleTypeProps) {
  const { orgDetails, newVehicleTypeName } = props;

  const { data: vehicleTypes } = useGetVehicleTypes();

  const allSectionMels = orgDetails.sections.flatMap<ISectionMelWithName | undefined>(
    (sectionWithMel) => sectionWithMel.mel?.map(
      (sectionMel) => ({
        ...sectionMel,
        vehicleType: decode(sectionMel.vehicleType),
        sectionName: sectionWithMel.sectionName,
      }),
    ),
  ).filter(Boolean);

  const vehicleTypeMels = allSectionMels.reduce<VehicleTypeMels>(
    (agg, curr) => {
      const { vehicleType } = curr;
      if (!agg[vehicleType]) {
        // eslint-disable-next-line no-param-reassign
        agg[vehicleType] = [];
      }
      agg[vehicleType]?.push(curr);
      return agg;
    },
    {},
  );

  if (!vehicleTypes) return null;

  const vehicleTypesOrdered = [
    newVehicleTypeName,
    ...vehicleTypes.filter((vType) => vType !== newVehicleTypeName),
  ].filter(Boolean);

  return (
    <div className="overflow-auto">
      {
        vehicleTypesOrdered.map(
          (vehicleType) => {
            const mels = vehicleTypeMels[vehicleType];
            return (
              <OrganizationVehicleType
                key={vehicleType}
                vehicleType={vehicleType}
                mels={mels}
                orgDetails={orgDetails}
              />
            );
          },
        )
      }
    </div>
  );
}

export default OrganizationDetailsGroupByVehicleType;
