import { useDecommissionVehicle, useRecommissionVehicle } from 'apiHooks/Vehicles.Hook';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import type { IVehicleDetails } from 'types/IVehicleDetails';
import { formatDate } from 'utils/Helpers';

const POSSIBLY_COMMISSIONED_VARIABLES = [
  'buttonClassName',
  'buttonText',
  'modalTitle',
  // 'modalOnSubmit',
  'modalSubmitButtonText',
  'modalDescription',
] as const;
type PossiblyCommissionedModalVariable = typeof POSSIBLY_COMMISSIONED_VARIABLES[number];
type PossiblyCommissionedLegend = Record<PossiblyCommissionedModalVariable, string>;

function DecommissionModal({ vehicle }: { vehicle: IVehicleDetails; }) {
  const [modalOpen, setModalOpen] = useState(false);
  const decommissionVehicle = useDecommissionVehicle(vehicle.regNumber);
  const recommissionVehicle = useRecommissionVehicle(vehicle.regNumber);

  const inCommissionLegend: PossiblyCommissionedLegend = {
    buttonClassName: '',
    buttonText: 'Decommission',
    modalTitle: `Confirm Vehicle ${vehicle.regNumber} Decommission`,
    modalDescription: `Vehicle ${vehicle.regNumber} will be decommissioned.
      It will no longer be included in Overview and will be hidden from Fleet Details.
      Do you wish to proceed?`,
    modalSubmitButtonText: 'Decommission',
  };
  const decommissionedLegend: PossiblyCommissionedLegend = {
    buttonClassName: 'text-danger',
    buttonText: 'Decommissioned',
    modalTitle: `Vehicle ${vehicle.regNumber} is Decommissioned`,
    modalDescription: `Vehicle ${vehicle.regNumber} was decommissioned
      on ${formatDate(vehicle.decommissionDate, false)}.
      You may redesignate the vehicle as in-commission if you wish.`,
    modalSubmitButtonText: 'Re-commission',
  };

  const isInCommission = vehicle.decommissionDate === null;

  const legend = (
    isInCommission
      ? inCommissionLegend
      : decommissionedLegend
  );

  const onSubmit = (
    isInCommission
      ? decommissionVehicle
      : recommissionVehicle
  );

  return (
    <>
      <Button
        outline
        // eslint-disable-next-line react/jsx-props-no-spreading
        className={legend.buttonClassName}
        onClick={() => setModalOpen(true)}
      >
        {legend.buttonText}
      </Button>

      <RhinoModal
        title={legend.modalTitle}
        onSubmit={onSubmit}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        submitButtonText={legend.modalSubmitButtonText}
      >
        {legend.modalDescription}
      </RhinoModal>
    </>
  );
}

export default DecommissionModal;
