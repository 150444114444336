import React from 'react';
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import { useGetWorkflowsAndFleet } from 'apiHooks/Shared.Hook';
import type { IWorkflow } from 'types/IWorkflows';
import { RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';
import { SAVING_STAGE } from './workflowUtils';
import NewVehicleAssociations from './vehicleSelectionComponents/NewVehicleAssociations';

interface ISaveWorkflowAs {
  existingWorkflowName: string
  disabled: boolean,
  saveAsCallback: (workflowName: string, selectedVehicles: string[]) => void,
}

interface INameWorkflowComponent {
  existingWorkflows: IWorkflow[],
  currentStage: string,
  setCurrentStage: (currentStage: string, name?: string) => void,
  saveWorkflow: (workflowName: string) => void,
}

// eslint-disable-next-line unicorn/better-regex
const ValidWorkflowNameRegEx = /^\w[A-Za-z0-9. \-,/%()&'";#]+$/;

function NameWorkflowComponent({
  existingWorkflows,
  currentStage,
  setCurrentStage,
  saveWorkflow,
}: INameWorkflowComponent) {
  const [newWorkflowName, setNewWorkflowName] = React.useState('');
  const existingWorkflowNames = existingWorkflows.map((x) => x.title.trim().toLowerCase());

  const isValidName = () => (
    newWorkflowName.length > 1
    && newWorkflowName.length < 120
    && !existingWorkflowNames.includes(newWorkflowName.trim().toLowerCase())
    && ValidWorkflowNameRegEx.test(newWorkflowName)
  );

  return (
    <Modal
      isOpen={currentStage === SAVING_STAGE.NAMING}
      toggle={() => setCurrentStage(SAVING_STAGE.CLOSED)}
    >
      <RhinoModalHeader toggle={() => setCurrentStage(SAVING_STAGE.CLOSED)}>
        Name for Workflow
      </RhinoModalHeader>
      <ModalBody>
        <div className="p-3">
          <input
            className="w-100"
            onChange={(e) => setNewWorkflowName(e.target.value)}
          />
        </div>
        {
          existingWorkflowNames.includes(newWorkflowName.trim().toLowerCase())
          && <Alert color="danger">Workflow title already in use</Alert>
        }
        {
          newWorkflowName.length > 1
          && !ValidWorkflowNameRegEx.test(newWorkflowName)
          && <Alert color="danger">Invalid character used in title</Alert>
        }
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color="secondary" onClick={() => setCurrentStage(SAVING_STAGE.ASSOCIATE_VEHICLES)}>
          Back
        </Button>
        <Button
          color="primary"
          disabled={!isValidName()}
          onClick={() => saveWorkflow(newWorkflowName.trim())}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}

/**
 *
 * @returns
 */
function SaveWorkflowAs({
  disabled,
  existingWorkflowName,
  saveAsCallback,
} : ISaveWorkflowAs) {
  const results = useGetWorkflowsAndFleet();
  const [currentStage, setCurrentStage] = React.useState(SAVING_STAGE.CLOSED);
  const [selectedVehicles, setSelectedVehicles] = React.useState<string[]>([]);

  if (results.some((result) => result.isLoading)) {
    return (
      <Button className="bg-primary-darker" disabled>
        Save As...
      </Button>
    );
  }
  if (results.some((result) => result.isError)) {
    return (
      <div>
        { results.filter((x) => x.isError).map((x) => (
          <div key={x.error as string}>{x.error as string}</div>
        ))}
      </div>
    );
  }

  return (
    <>
      <Button
        disabled={disabled}
        className="bg-primary-darker"
        onClick={() => setCurrentStage(SAVING_STAGE.ASSOCIATE_VEHICLES)}
      >
        Save As...
      </Button>
      {
        currentStage === SAVING_STAGE.ASSOCIATE_VEHICLES
        && (
          <NewVehicleAssociations
            vehicles={results[0].data || []}
            existingWorkflowName={existingWorkflowName}
            currentStage={currentStage}
            setCurrentStage={(stage: string, selectedRegNumbers: string[] | undefined) => {
              setCurrentStage(stage);
              if (selectedRegNumbers) {
                setSelectedVehicles(selectedRegNumbers);
              }
            }}
          />
        )
      }
      {
        currentStage === SAVING_STAGE.NAMING
        && (
          <NameWorkflowComponent
            existingWorkflows={results[1].data || []}
            currentStage={currentStage}
            setCurrentStage={(stage: string) => setCurrentStage(stage)}
            saveWorkflow={(name: string) => saveAsCallback(name, selectedVehicles)}
          />
        )
      }
    </>
  );
}

export default SaveWorkflowAs;
