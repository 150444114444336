import React from 'react';
import type { ButtonProps } from 'reactstrap';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import type { BatchItem, SendOptions } from '@rpldy/shared';
import Uploady, {
  type Batch,
  FILE_STATES,
  useUploady,
} from '@rpldy/uploady';
import type { SendResult } from '@rpldy/sender';
import { MdUpload } from 'react-icons/md';

import { RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';
import {
  UploadyDropZone,
  UploadyUploadButton,
} from 'components/shared/Uploady/uploadyComponents';
import UploadFailureModal from 'components/shared/UploadFailureModal/UploadFailureModal';
import ImageLightBox from './ImageLightBox';

function UploadyClearPendingButton(props: ButtonProps) {
  const { onClick } = props;
  const uploady = useUploady();
  return (
    <Button onClick={(e) => { uploady.clearPending(); onClick?.(e); }}>
      Remove
    </Button>
  );
}

interface IUploadImageModal {
  modalTitle: string,
  disableAnnotation: boolean,
  uploadImageCallback:
  (items: BatchItem[], url: string | undefined, sendOptions: SendOptions) => SendResult
}

function UploadImageModal({
  modalTitle,
  uploadImageCallback,
  disableAnnotation,
}: IUploadImageModal) {
  const [imagePreview, setImagePreview] = React.useState('');
  const [pdfPreview, setPdfPreview] = React.useState<Uint8Array | undefined>();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [uploadError, setUploadError] = React.useState('');
  const [annotations, setAnnotations] = React.useState('');

  const clearPreviewData = () => {
    setImagePreview('');
    setPdfPreview(undefined);
  };

  const closeModal = () => {
    clearPreviewData();
    setModalOpen(false);
  };

  const newFileCallback = (batch: Batch) => {
    setImagePreview(URL.createObjectURL(batch.items[0]?.file as File));
  };

  const setCardFile = (
    items: BatchItem[],
    url: string | undefined,
    sendOptions: SendOptions,
  ) => {
    const sendResponse = uploadImageCallback(items, undefined, sendOptions);

    return {
      ...sendResponse,
      request: sendResponse.request.then((data) => {
        if (data.state === FILE_STATES.ERROR) {
          setUploadError(data.response.data);
          clearPreviewData();
        } else {
          closeModal();
          setAnnotations('');
        }

        return data;
      }).catch(() => {}),
    } as SendResult;
  };

  return (
    <>
      <Button
        color="link"
        onClick={() => setModalOpen(true)}
      >
        <MdUpload title="Upload image" />
      </Button>
      <Modal
        isOpen={modalOpen}
        toggle={closeModal}
        className="image-lightbox-modal"
      >
        <Uploady
          autoUpload={false}
          accept=".png,.jpg,.jpeg"
          send={setCardFile}
        >
          <UploadFailureModal
            error={uploadError}
            acknowledgeError={() => setUploadError('')}
          />
          <RhinoModalHeader toggle={closeModal}>{modalTitle}</RhinoModalHeader>

          <ModalBody className="d-flex justify-content-center text-secondary fw-bold">
            <div className="border">
              {
                imagePreview
                  ? <div />
                  : <UploadyDropZone newFileCallback={newFileCallback} dropZoneText="Drop image or click here..." />
              }
              {
                imagePreview
                  ? (
                    <div className="drop-zone">
                      <ImageLightBox pictures={[{ url: imagePreview, file: '' }]} preLoaded />
                      {
                         disableAnnotation
                           ? <div />
                           : (
                             <div className="d-flex align-items-center justify-content-between">
                               <Input
                                 placeholder="Annotation (Optional)"
                                 defaultValue={annotations}
                                 onChange={(e) => setAnnotations(e.currentTarget.value.trim())}
                               />
                             </div>
                           )
                      }
                    </div>
                  )
                  : <div />
              }
            </div>
          </ModalBody>

          <ModalFooter className="d-flex justify-content-between">
            <Button onClick={closeModal}>
              Close
            </Button>
            {
              (imagePreview || pdfPreview)
                ? <UploadyClearPendingButton onClick={clearPreviewData} />
                : <div />
            }
            <UploadyUploadButton
              disabled={!imagePreview}
              params={annotations ? { params: { annotations } } : {}}
            />
          </ModalFooter>
        </Uploady>
      </Modal>
    </>
  );
}

export default UploadImageModal;
