import React from 'react';
import { useAtom } from 'jotai';
import { Button } from 'reactstrap';
import ReactDatePicker from 'react-datepicker';
import format from 'date-fns/format';
import { TZDate } from '@date-fns/tz';

import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import type { IShiftDay, IShift } from 'types/IOrganization';
import {
  orgShiftsAtom,
  formatShiftTime,
} from './shiftTimeUtils';

function SetShiftEveryDayModal({ shiftNumber }: { shiftNumber: number }) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [utcShifts, setUtcShifts] = useAtom(orgShiftsAtom);
  const toggleModalOpen = () => setModalOpen((s) => !s);

  const [startTime, setStartTime] = React.useState<Date | null>(null);
  const [endTime, setEndTime] = React.useState<Date | null>(null);

  const canSetShifts = !!(startTime && endTime);

  const onSubmit = () => {
    if (!utcShifts || !startTime || !endTime) {
      return Promise.reject();
    }

    const updatedShifts: IShiftDay[] = utcShifts.map((shiftDay) => {
      const shifts = [1, 2, 3].reduce((newShifts: IShift[], currentShiftNumber) => {
        if (shiftNumber === currentShiftNumber && startTime && endTime) {
          return [
            ...newShifts,
            {
              number: shiftNumber,
              start: `${format(new TZDate(startTime, 'UTC'), 'HHmm')}z`,
              end: `${format(new TZDate(endTime, 'UTC'), 'HHmm')}z`,
            },
          ];
        }

        if (shiftNumber !== currentShiftNumber) {
          const existingShift = shiftDay.shifts.find((x) => x.number === currentShiftNumber);

          if (existingShift) {
            return [
              ...newShifts,
              existingShift,
            ];
          }
        }

        return newShifts;
      }, []);

      return { day: shiftDay.day, shifts };
    });

    setUtcShifts(updatedShifts);

    return Promise.resolve();
  };

  return (
    <>
      <Button onClick={toggleModalOpen}>
        Edit All
      </Button>
      <RhinoModal
        title={`Set times for shift ${shiftNumber} every day`}
        centered
        isOpen={modalOpen}
        setIsOpen={toggleModalOpen}
        canSubmit={canSetShifts}
        onSubmit={onSubmit}
        submitButtonText="Set Shift"
      >
        <div className="d-flex px-4 justify-content-evenly">
          <div className="d-flex flex-column">
            Start Time
            <ReactDatePicker
              className="col-8"
              showIcon
              showTimeSelect
              showTimeSelectOnly
              timeFormat="HH:mm"
              selected={startTime}
              onChange={(date) => setStartTime(date)}
              value={startTime ? formatShiftTime(startTime) : 'unset'}
            />
          </div>
          <div className="d-flex flex-column">
            End Time
            <ReactDatePicker
              className="col-8"
              showIcon
              showTimeSelect
              showTimeSelectOnly
              timeFormat="HH:mm"
              selected={endTime}
              onChange={(date) => setEndTime(date)}
              value={endTime ? formatShiftTime(endTime) : 'unset'}
            />
          </div>
        </div>
      </RhinoModal>
    </>
  );
}

export default SetShiftEveryDayModal;
