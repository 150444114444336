import React from 'react';

import { useGetOrganization } from 'apiHooks/Organization.Hook';

import Loading from 'components/shared/Loading/Loading';
import OrganizationSummary from './OrganizationSummary';
import OrganizationDetails from './OrganizationDetails';

function OrganizationAdministration() {
  const {
    isLoading,
    isError,
    data,
    error,
  } = useGetOrganization();

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <div>{ error as string }</div>;
  }

  return (
    <div className="d-flex flex-column vh-100">
      <OrganizationSummary orgDetails={data} />
      <OrganizationDetails orgDetails={data} />
    </div>
  );
}

export default OrganizationAdministration;
