import React from 'react';
import { NumericFormat } from 'react-number-format';
import ChangeControls from './ChangeControls';

interface IEditableThresholdRuleField {
  canEdit: boolean,
  isDisabled: boolean,
  value: number | undefined,
  originalValue: number | undefined,
  max: number,
  updateFieldCallback: (value: number | undefined) => void,
  editingLockCallback: (inEdit: boolean) => void,
}

/**
 *
 * @param param0
 * @returns
 */
function ThresholdTargetField({
  value,
  originalValue,
  max,
  canEdit,
  isDisabled,
  updateFieldCallback,
  editingLockCallback,
} : IEditableThresholdRuleField) {
  const [ruleTarget, setRuleTarget] = React.useState(value);
  const [isEditing, setIsEditing] = React.useState(false);

  React.useEffect(() => {
    setRuleTarget(value);
  }, [value]);

  const beginEditing = () => {
    setIsEditing(true);
    editingLockCallback(true);
  };

  const confirmEdits = () => {
    updateFieldCallback(ruleTarget);
    setIsEditing(false);
    editingLockCallback(false);
  };

  const cancelEdits = () => {
    setRuleTarget(value);
    setIsEditing(false);
    editingLockCallback(false);
  };

  const undoEdits = () => {
    setRuleTarget(originalValue);
    updateFieldCallback(originalValue);
  };

  return (
    <div data-no-dnd="true" className="d-flex align-items-center">
      <small className="px-1 text-muted">Target: </small>
      {
        isEditing
          ? (
            <>
              <NumericFormat
                allowNegative={false}
                decimalScale={0}
                defaultValue={ruleTarget === undefined ? null : ruleTarget}
                isAllowed={({ floatValue }) => (
                  floatValue === undefined || (floatValue > 0 && floatValue <= max)
                )}
                onValueChange={({ floatValue }) => setRuleTarget(floatValue)}
              />
              { ' / '}
              { max }
            </>
          )
          : (ruleTarget === undefined ? 'Not Set' : `${ruleTarget} / ${max}`)
      }
      { canEdit
        && (
          <ChangeControls
            inEdit={beginEditing}
            isDisabled={isDisabled}
            confirmEdits={confirmEdits}
            cancelEdits={cancelEdits}
            undoEdits={undoEdits}
            canUndo={originalValue !== undefined && ruleTarget !== originalValue}
            isInValid={ruleTarget === undefined}
          />
        )}
    </div>
  );
}

export default ThresholdTargetField;
