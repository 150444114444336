import { usePostTdy } from 'apiHooks/Tdy.Hook';
import { RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import {
  Button, Modal, ModalBody, ModalFooter, Input, Spinner,
} from 'reactstrap';
import type { ITdyPost } from 'types/IVehicleDetails';

export default function ScheduleVehicleTdyModal({ regNumber }: { regNumber: string; }) {
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModalOpen = () => setModalOpen((b) => !b);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [description, setDescription] = useState<string>('');

  const [posting, setPosting] = useState(false);

  const postNewTdy = usePostTdy(regNumber);

  const canSubmit = startDate && description;

  const handleSubmit = () => {
    if (!canSubmit) return;

    setPosting(true);
    const tdyPost: ITdyPost = {
      startDate: startDate.toISOString(),
      endDate: endDate?.toISOString() ?? null,
      description,
      regNumber,
    };
    postNewTdy(tdyPost)
      .then(() => {
        setModalOpen(false);
        setStartDate(null);
      })
      .catch(() => {})
      .finally(() => setPosting(false));
  };

  return (
    <>
      <Button
        type="button"
        onClick={() => setModalOpen(true)}
      >
        Create TDY
      </Button>

      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen((state) => !state)}
      >
        <RhinoModalHeader toggle={toggleModalOpen}>
          {`Schedule TDY for ${regNumber}`}
        </RhinoModalHeader>

        <ModalBody>
          <div className="d-grid text-secondary gap-2 fw-bold">
            <div className="d-grid">
              Start Date
              <ReactDatePicker
                selected={startDate}
                onChange={setStartDate}
                showIcon
              />
            </div>
            <div className="d-grid">
              End Date
              <ReactDatePicker
                selected={endDate}
                onChange={setEndDate}
                showIcon
              />
            </div>
            <div className="d-grid">
              Description
              <Input
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

          </div>
        </ModalBody>

        <ModalFooter className="justify-content-between">
          <Button onClick={() => setModalOpen(false)}>Close</Button>

          <Button disabled={!canSubmit || posting} onClick={handleSubmit}>
            {posting ? (<Spinner size="sm" />) : ('Submit')}
          </Button>

        </ModalFooter>
      </Modal>
    </>
  );
}
