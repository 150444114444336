import React from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { Badge, Button, Spinner } from 'reactstrap';
import { Menu, MenuItem, Sidebar } from 'react-pro-sidebar';
import { FaList } from 'react-icons/fa';
import { useResetAtom } from 'jotai/utils';
import RhinoEvent from './RhinoEvent';
import rhinoEventsAtom, { rhinoEventsWsOpenAtom } from './RhinoEvent.store';
import styles from './FleetUpdatesSideBar.module.css';
import fleetUpdatesSideBarCollapsedAtom from './FleetUpdatesSideBar.store';

export const SIDEBAR_WIDTH = 500;
export const SIDEBAR_COLLAPSED_WIDTH = 75;

function FleetUpdatesSideBar() {
  const [collapsed, setCollapsed] = useAtom(fleetUpdatesSideBarCollapsedAtom);
  const toggleCollapsed = () => setCollapsed((prev) => !prev);

  const events = useAtomValue(rhinoEventsAtom);
  const clearEvents = useResetAtom(rhinoEventsAtom);

  const webSocketOpen = useAtomValue(rhinoEventsWsOpenAtom);

  return (
    <Sidebar
      collapsed={collapsed}
      width={`${SIDEBAR_WIDTH}px`}
      collapsedWidth={`${SIDEBAR_COLLAPSED_WIDTH}px`}
      backgroundColor="transparent"
      className="d-flex flex-column vh-100 bg-secondary"
    >

      <div className="text-white">
        <Menu>
          <MenuItem
            icon={<FaList />}
            onClick={toggleCollapsed}
            className={`${styles['menu-item']}`}
          >
            <h3 className="pt-2 text-secondary text-center text-white">
              Fleet Updates
            </h3>
          </MenuItem>
        </Menu>
      </div>

      <div style={{ overflowY: 'auto', height: '100vh - 70px' }}>
        {
          collapsed
            ? (events.length > 0 && (
              <div className="d-flex flex-column align-items-center">
                <Badge color="primary">
                  {events.length}
                </Badge>
              </div>

            ))
            : (
              <div className="d-flex flex-column">
                {
                  events.length > 0 && (
                    <Button className="align-self-end mx-3" onClick={clearEvents}>
                      Clear All
                    </Button>
                  )
                }
                <div className="h-100 d-grid p-3 gap-3">
                  {
                    !webSocketOpen && (
                      <div className="d-flex align-items-center gap-3 text-secondary">
                        <Spinner />
                        Connecting...
                      </div>
                    )
                  }
                  {
                    events.map(
                      (e) => (
                        <RhinoEvent
                          event={e}
                          key={e.eventId}
                        />
                      ),
                    )
                  }
                </div>
              </div>
            )
        }
      </div>

    </Sidebar>
  );
}

export default FleetUpdatesSideBar;
