import React from 'react';
import { MdOutlineSsidChart } from 'react-icons/md'; // cspell: disable-line

import type { IRoute } from 'types/IRoute';
import Insights from 'views/Insights/Insights';

const insightsRoute: [IRoute] = [{
  component: <Insights />,
  icon: <MdOutlineSsidChart />, // cspell: disable-line
  label: 'Insights',
  path: '/Insights',
}];

export default insightsRoute;
