import { usePostNewVehicleWaiver } from 'apiHooks/Vehicles.Hook';
import { decode } from 'html-entities';
import React, { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import {
  Button,
  Modal,
  ModalBody,
  Input,
  ModalFooter,
} from 'reactstrap';
import type {
  IWorkflowRule,
} from 'types/IWorkflows';
import type { IWaiverPost } from 'types/IVehicleDetails';
import { RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';

const WAIVER_TYPES = ['warning', 'fatal'] as const;

type WaiverType = typeof WAIVER_TYPES[number];

const defaultWaiverType: WaiverType = 'warning';

export default function AddNewWaiveredItemModal(
  { regNumber, rulesData }: { regNumber: string, rulesData: IWorkflowRule[], },
) {
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModalOpen = () => setModalOpen((b) => !b);
  const [ruleSelection, setRuleSelection] = useState<IWorkflowRule[]>([]);
  const [selectedType, setSelectedType] = useState<WaiverType>(defaultWaiverType);
  const [explanation, setExplanation] = useState<string>('');

  const postNewWaiver = usePostNewVehicleWaiver(regNumber);

  const resetInputStates = () => {
    setRuleSelection([]);
    setSelectedType(defaultWaiverType);
    setExplanation('');
  };

  const selectedRule = ruleSelection[0];

  const canSubmit = selectedRule && selectedType && explanation;

  const handleSubmit = () => {
    if (!canSubmit) return;

    const waiverPost: IWaiverPost = {
      ruleId: selectedRule.ruleId,
      waiverType: selectedType,
      explanation,
    };

    postNewWaiver(waiverPost)
      .then(() => {
        setModalOpen(false);
        resetInputStates();
      })
      .catch(() => {});
  };

  return (
    <>
      <Button outline color="light" onClick={() => setModalOpen(true)}>
        New Waiver
      </Button>

      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen((state) => !state)}
      >
        <RhinoModalHeader toggle={toggleModalOpen}>
          {`Create Waiver for ${regNumber}`}
        </RhinoModalHeader>

        <ModalBody className="d-grid gap-1 text-secondary">
          <div className="d-grid">
            <span className="fw-bold px-1">Associated Rule: </span>
            <Typeahead
              id="select-rule-typeahead"
              clearButton
              options={rulesData}
              placeholder="Select Rule"
              onChange={(selected) => setRuleSelection(selected as IWorkflowRule[])}
              labelKey={(rule) => `${(rule as IWorkflowRule).ruleId} - ${decode((rule as IWorkflowRule).title)}`}
            />
          </div>
          <div className="d-flex align-items-center">
            <span className="fw-bold px-1">Type:</span>
            <Input
              type="select"
              className="text-capitalize px-1"
              onChange={(e) => setSelectedType(e.target.value as WaiverType)}
              defaultValue={defaultWaiverType}
            >
              {WAIVER_TYPES.map((type) => (
                <option className="text-capitalize" key={type} value={type}>{type}</option>
              ))}
            </Input>
          </div>
          <div className="d-grid">
            <span className="fw-bold px-1">Explanation:</span>
            <Input
              defaultValue={explanation}
              onChange={(e) => (
                setExplanation(e.target.value)
              )}
            />
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button onClick={() => setModalOpen(false)}>
            Close
          </Button>
          <Button
            disabled={!canSubmit}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
