import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { decode } from 'html-entities';
import { Card, CardTitle } from 'reactstrap';
import type { TypeaheadRef } from 'react-bootstrap-typeahead';
import { Typeahead } from 'react-bootstrap-typeahead';

import { useGetFailuresOverview, useGetFailuresPerVehicle } from 'apiHooks/Insights.Hook';
import { useGetOrganizationRules } from 'apiHooks/WorkFlows.Hook';
import { useGetFleet } from 'apiHooks/Vehicles.Hook';
import type { IVehicleDetails } from 'types/IVehicleDetails';
import type { IFailuresOverviewResponseItem, IFailuresPerVehicleResponseItem, IRegNumberAndFailures } from 'types/Failures';
import type { IWorkflowRule } from 'types/IWorkflows';

import Loading from 'components/shared/Loading/Loading';
import { ruleIsCustom } from 'utils/Helpers';
import FailuresOverviewChart from './charts/FailuresOverviewChart';
import FailuresPerVehicleChart from './charts/FailuresPerVehicleChart';

export default function FailuresView({
  startDate,
  endDate,
}: {
  startDate: Date,
  endDate: Date,
}) {
  const [selectedAf1800Rules, setSelectedAf1800Rules] = useState<IWorkflowRule[]>([]);
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState<string[]>([]);

  const [
    filteredFailuresOverviewData,
    setFilteredFailuresOverviewData,
  ] = useState<IFailuresOverviewResponseItem[]>([]);

  const [
    filteredFailuresPerVehicleData,
    setFilteredFailuresPerVehicleData,
  ] = useState<IFailuresPerVehicleResponseItem[]>([]);

  const failuresTypeaheadRef = useRef<TypeaheadRef>(null);
  const vehicleTypesTypeaheadRef = useRef<TypeaheadRef>(null);

  const {
    data: failuresOverviewData,
    isLoading: failuresOverviewIsLoading,
    isError: failuresOverviewIsError,
    error: failuresOverviewError,
    refetch: refetchFailuresOverview,
  } = useGetFailuresOverview(startDate, endDate);

  const {
    data: failuresPerVehicleData,
    isLoading: failuresPerVehicleIsLoading,
    isError: failuresPerVehicleIsError,
    error: failuresPerVehicleError,
    refetch: refetchFailuresPerVehicle,
  } = useGetFailuresPerVehicle(startDate, endDate);

  const {
    isLoading: rulesDataLoading,
    isError: isRulesDataError,
    data: rulesData,
    error: rulesDataError,
  } = useGetOrganizationRules();

  const {
    data: vehiclesData,
    isLoading: vehiclesDataLoading,
    isError: isVehiclesDataError,
    error: vehiclesDataError,
  } = useGetFleet();

  const vehiclesRecord: Record<string, IVehicleDetails> = useMemo((() => (
    vehiclesData?.[0] ? (
      vehiclesData.reduce((a, v) => ({
        ...a,
        [v.regNumber]: v,
      }), {})
    ) : ({})
  )), [vehiclesData]);

  const af1800RulesTitleMap = useMemo(() => (
    rulesData ? (
      rulesData
        .filter((rule) => !ruleIsCustom(rule))
        .reduce((o, rule) => ({
          ...o,
          [rule.ruleId]: decode(rule.title),
        }), {})
    ) : ({})
  ), [rulesData]);

  const presentAf1800Rules = useMemo((() => (
    (rulesData && failuresOverviewData) ? (
      rulesData.filter((rule) => (
        !ruleIsCustom(rule) && (
          failuresOverviewData?.map((ruleDatum) => ruleDatum.ruleId).includes(`${rule.ruleId}`)
        )
      ))
    ) : ([])
  )), [rulesData, failuresOverviewData]);

  const presentVehicleTypes: string[] = useMemo((() => (
    failuresPerVehicleData ? (
      failuresPerVehicleData.map((datum) => (
        datum.vehicleType
      ))
    ) : ([])
  )), [failuresPerVehicleData]);

  const af1800RulesToFilter = useMemo(() => (
    selectedAf1800Rules.map((rule) => rule.ruleId)
  ), [selectedAf1800Rules]);

  // FAILURES OVERVIEW FILTERS //

  const filterFailuresOverviewData = useCallback(() => {
    if (!failuresOverviewData) return;

    // af1800 filter
    const af1800FilteredData = (
      af1800RulesToFilter[0] ? (
        failuresOverviewData.filter((ruleData) => af1800RulesToFilter.includes(ruleData.ruleId))
      ) : (
        failuresOverviewData
      )
    );

    // vehicle type filter
    const filteredData = (
      selectedVehicleTypes[0] ? (
        af1800FilteredData.map((ruleData) => ({
          ...ruleData,
          data: ruleData.data.map((dayData) => {
            const newDayDetails: IRegNumberAndFailures[] = dayData.details.filter((datum) => (
              selectedVehicleTypes.includes(vehiclesRecord[datum.regNumber]?.management?.[0]?.vehicleType ?? '')
            ));
            return ({
              ...dayData,
              totalFailures: newDayDetails[0] ? (
                newDayDetails.map((datum) => datum.failures).reduce((a, b) => a + b)
              ) : (0),
              details: newDayDetails,
            });
          }),
        }))
      ) : (af1800FilteredData)
    );

    setFilteredFailuresOverviewData(filteredData);
  }, [
    af1800RulesToFilter,
    failuresOverviewData,
    selectedVehicleTypes,
    vehiclesRecord,
  ]);

  // FILTER FAILURES PER VEHICLE //

  const filterFailuresPerVehicle = useCallback(() => {
    if (!failuresPerVehicleData) return;

    // af1800 filter
    const af1800FilteredData = (
      selectedAf1800Rules[0] ? (
        failuresPerVehicleData.map((vehicleTypeDatum) => ({
          ...vehicleTypeDatum,
          vehicles: vehicleTypeDatum.vehicles.map((vehicleDatum) => {
            const failuresByRule = vehicleDatum.failuresByRule
              .filter((failureDatum) => (
                selectedAf1800Rules.map((rule) => rule.ruleId).includes(failureDatum.ruleId)
              ));
            return ({
              regNumber: vehicleDatum.regNumber,
              failuresByRule,
              totalFailures: failuresByRule[0] ? (
                failuresByRule.map((failure) => failure.failures).reduce((a, b) => (a + b))
              ) : (0),
            });
          }),
        }))
      ) : (failuresPerVehicleData)
    );

    // vehicle type filter
    const filteredData = (
      selectedVehicleTypes[0] ? (
        af1800FilteredData.filter((datum) => (
          selectedVehicleTypes.includes(datum.vehicleType)
        ))
      ) : (af1800FilteredData)
    );

    setFilteredFailuresPerVehicleData(filteredData);
  }, [
    failuresPerVehicleData,
    selectedAf1800Rules,
    selectedVehicleTypes,
  ]);

  useEffect(() => {
    refetchFailuresOverview().catch(() => {});
    refetchFailuresPerVehicle().catch(() => {});
  }, [
    startDate,
    endDate,
    refetchFailuresOverview,
    refetchFailuresPerVehicle,
  ]);

  useEffect(() => {
    filterFailuresOverviewData();
    filterFailuresPerVehicle();
  }, [
    filterFailuresOverviewData,
    filterFailuresPerVehicle,
  ]);

  if (
    failuresOverviewIsLoading
    || failuresPerVehicleIsLoading
    || rulesDataLoading
    || vehiclesDataLoading
  ) {
    return <Loading />;
  }
  if (
    isRulesDataError
    || failuresOverviewIsError
    || failuresPerVehicleIsError
    || isVehiclesDataError
  ) {
    return (
      <div>
        {rulesDataError as string}
        {failuresOverviewError as string}
        {failuresPerVehicleError as string}
        {vehiclesDataError as string}
      </div>
    );
  }

  return (
    <div className="d-flex flex-column align-items-center gap-3 align-items-center">

      <div className="d-flex w-100 gap-3">
        <Typeahead
          id="select-vehicle-types-typeahead"
          multiple
          clearButton
          ref={vehicleTypesTypeaheadRef}
          options={presentVehicleTypes}
          placeholder="Filter by Vehicle Type"
          labelKey={(vehicleType) => decode(vehicleType as string)}
          onChange={(selected) => setSelectedVehicleTypes(selected as string[])}
        />
        <Typeahead
          id="select-failures-typeahead"
          className="flex-fill"
          multiple
          clearButton
          ref={failuresTypeaheadRef}
          options={presentAf1800Rules}
          placeholder="Filter by AF1800 Rule"
          labelKey={(af1800Rule) => (
            `${(af1800Rule as IWorkflowRule).ruleId} \u2013 ${decode((af1800Rule as IWorkflowRule).title)}`
          )}
          onChange={(selected) => setSelectedAf1800Rules(selected as IWorkflowRule[])}
        />

      </div>
      <Card className="p-4 shadow border w-100 display-block">
        <CardTitle className="text-center">
          <h4 className="text-center">Failures Overview</h4>
        </CardTitle>
        <FailuresOverviewChart
          data={filteredFailuresOverviewData}
          af1800RulesTitleMap={af1800RulesTitleMap}
          minDate={startDate}
          maxDate={endDate}
        />
      </Card>

      <Card className="p-4 shadow border w-100 display-block">
        <CardTitle className="text-center">
          <h4 className="text-center">Failures / Vehicle</h4>
        </CardTitle>
        <FailuresPerVehicleChart
          data={filteredFailuresPerVehicleData}
          af1800RulesTitleMap={af1800RulesTitleMap}
        />
      </Card>

    </div>
  );
}
