import type { NewRuleTypeMap, NewRuleType } from 'types/IWorkflows';
import type { RuleType, RuleFrequency } from 'types/common';

const WORKFLOW_NEW_RULE_LABEL: NewRuleTypeMap = {
  PASS_FAIL: 'Pass/Fail',
  NUMERIC_INPUT: 'Numeric Input',
  CUSTOM: 'Pre-Saved Rule',
  MISSING_AF_1800: 'Template AF-1800 Rule',
};

const WORKFLOW_ADDED_EXISTING_RULES: NewRuleType[] = ['CUSTOM', 'MISSING_AF_1800'];

const WORKFLOW_CREATED_RULES: NewRuleType[] = ['PASS_FAIL', 'NUMERIC_INPUT'];

const WORKFLOW_RULE_LABEL: Record<RuleType, string> = {
  threshold: 'Range',
  passFail: 'Pass/Fail',
  counter: 'Counter',
  lookup: 'Target',
};

const WORKFLOW_RULES = {
  THRESHOLD: 'threshold',
  PASS_FAIL: 'passFail',
  COUNTER: 'counter',
  TARGET: 'target',
};

const WORKFLOW_RULE_FREQUENCY: RuleFrequency[] = [
  'daily',
  'weekly',
  'monthly',
  'quarterly',
  'semiannual',
  'annual',
];

const WORKFLOW_RULE_FREQUENCY_LABEL: Record<RuleFrequency, string> = {
  daily: 'Every Inspection',
  weekly: 'Weekly',
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  semiannual: 'Semiannually',
  annual: 'Annually',
};

const AF1800_BASE_WORK_FLOWS = [
  'AF-1800 Full (Hours)',
  'AF-1800 Full (Miles)',
  'AF-1800 Full (Miles & Hours)',
];

export {
  WORKFLOW_NEW_RULE_LABEL,
  WORKFLOW_RULE_LABEL,
  WORKFLOW_RULE_FREQUENCY,
  WORKFLOW_RULE_FREQUENCY_LABEL,
  WORKFLOW_ADDED_EXISTING_RULES,
  WORKFLOW_RULES,
  AF1800_BASE_WORK_FLOWS,
  WORKFLOW_CREATED_RULES,
};
