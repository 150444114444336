import type { ValueFormatterParams } from '@ag-grid-community/core';
import { formatDate } from 'utils/Helpers';

/**
 * AgGrid Value formatter for date/times
 *
 * @param params
 * @returns
 */
const AgGridDateValueFormatter = <T extends object>(params: ValueFormatterParams<T, string>) => (
  formatDate(params.value)
);

export default AgGridDateValueFormatter;
