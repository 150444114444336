import type { ICellEditorParams } from '@ag-grid-community/core';
import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import type { IFleetDetail } from 'types/IFleetDetails';

function AgGridVehicleActiveCellRenderer({ data }: ICellEditorParams<IFleetDetail>) {
  const { decommissionDate } = data;

  const active = decommissionDate === null;

  return (
    active
      ? <FaCheck className="text-success" />
      : <FaTimes className="text-danger" />
  );
}

export default AgGridVehicleActiveCellRenderer;
