import type { ValueFormatterParams } from '@ag-grid-community/core';
import { displayNumber } from '@stonewall-defense/swd-ui-utils';

/**
 * AgGrid Value formatter for human readable numbers
 *
 * @param params
 * @returns
 */
const AgGridNumericValueFormatter = (params: ValueFormatterParams) => displayNumber(params.value);

export default AgGridNumericValueFormatter;
