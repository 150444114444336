import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { decode } from 'html-entities';

import { useGetWorkflow, useGetOrganizationRules } from 'apiHooks/WorkFlows.Hook';
import Loading from 'components/shared/Loading/Loading';
import { ruleIsCustom } from 'utils/Helpers';
import WorkflowModificationDragAndDrop from './WorkflowModificationDragAndDrop';

function EditWorkflow() {
  const { workflowId } = useParams();

  const navigate = useNavigate();
  const {
    data: workflowData,
    isLoading: workflowLoading,
    isError: workflowErrored,
    error: workflowError,
  } = useGetWorkflow(Number.parseInt(workflowId || '0', 10));
  const {
    data: rulesData,
    isLoading: rulesLoading,
    isError: rulesErrored,
    error: rulesError,
  } = useGetOrganizationRules();

  if (!workflowId) {
    navigate('InspectionWorkflow');
    return <div />;
  }

  if (rulesLoading || workflowLoading) {
    return <Loading />;
  }

  if (rulesErrored || workflowErrored) {
    return (
      <div className="d-flex flex-column">
        <div>{rulesError as string}</div>
        <div>{workflowError as string}</div>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column justify-content-center align-workflowRules-center h-100">
      <div className="d-flex p-2">
        <h6 className="px-3">Editing:</h6>
        <h3>
          { decode(workflowData.title) }
        </h3>
      </div>
      <div className="d-flex px-4 overflow-auto">
        <WorkflowModificationDragAndDrop
          workflowName={decode(workflowData.title)}
          workflowId={workflowData.workflowId}
          originalRules={workflowData.rules}
          customRules={rulesData.filter((rule) => ruleIsCustom(rule)) || []}
          af1800Rules={rulesData.filter((rule) => !ruleIsCustom(rule)) || []}
        />
      </div>
    </div>
  );
}

export default EditWorkflow;
