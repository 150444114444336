import React from 'react';
import { type ICellRendererParams } from '@ag-grid-community/core';

import ImageLightBoxModal from 'components/shared/ImageLightBox/ImageLightBoxModal';
import type { IVehicleAuxFile } from 'types/IVehicleDetails';

function AgGridViewDocumentCellRenderer({ data: file }: ICellRendererParams<IVehicleAuxFile>) {
  if (!file?.url) return null;

  return (
    <div>
      <ImageLightBoxModal
        pictures={[{ url: file.url }]}
        modalTitle={file.description}
      />
    </div>
  );
}

export default AgGridViewDocumentCellRenderer;
