/**
 * Format Date String
 *
 * @param dateStr ISO Date string
 * @returns
 */
const formatDate = (dateStr: string | null | undefined, showTime: boolean = true) => {
  if (!dateStr) {
    return '';
  }

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h23',
  };

  const formatted = new Date(dateStr).toLocaleString('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    ...showTime && timeOptions,
  });

  return (
    formatted === 'Invalid Date'
      ? ''
      : formatted
  );
};

export default formatDate;
