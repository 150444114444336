import React, { useEffect } from 'react';
import {
  Button,
  Label,
  Input,
  Spinner,
} from 'reactstrap';
import ReactDatePicker from 'react-datepicker';
import { MdEdit } from 'react-icons/md';
import { formatDistanceToNow, format } from 'date-fns';

import VehicleStatusIcon from 'components/shared/VehicleStatusIcon/VehicleStatusIcon';
import { usePatchVehicle } from 'apiHooks/Vehicles.Hook';
import { VEHICLE_STATUS_MAP } from 'utils/Constants';
import type { IVehiclePatch } from 'types/IVehicleDetails';
import { formatDate } from 'utils/Helpers';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import type { VehicleStatus } from 'types/common';
import { useGetTdy } from 'apiHooks/Tdy.Hook';

interface IEditStatusModal {
  status: VehicleStatus,
  eticDate: string | undefined | null,
  regNumber: string,
}

function EditStatusModal({
  status,
  eticDate,
  regNumber,
}: IEditStatusModal) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [tmpStatus, setTmpStatus] = React.useState(status);
  const [tmpEtic, setTmpEtic] = React.useState<Date | null>(eticDate ? new Date(eticDate) : null);
  const [clearEtic, setClearEtic] = React.useState(true);

  const tdyParams = {
    regNumber,
    end: format(new Date(), 'yyyy-MM-dd'),
  };

  const { data: tdyData } = useGetTdy(tdyParams);
  const onTdy = tdyData?.some((tdy) => !tdy.completed);

  const patchVehicle = usePatchVehicle();

  const resetState = () => setTmpStatus(status);

  const nullEtic = (clearEtic && (tmpStatus === VEHICLE_STATUS_MAP.VALID));

  useEffect(() => {
    setClearEtic(tmpStatus === VEHICLE_STATUS_MAP.VALID);
  }, [tmpStatus]);

  const canSubmit = (
    !!tmpStatus && (
      status !== tmpStatus
      // || onTdy !== tmpTdy
      || new Date(eticDate || '').getTime() !== tmpEtic?.getTime()
      || nullEtic
    )
  );

  const onSubmit = async () => {
    if (!canSubmit) return;

    const eticDateStr = tmpEtic
      ? new Date(tmpEtic?.toUTCString()).toISOString()
      : undefined;

    const patchData: IVehiclePatch = {
      ...(status !== tmpStatus) && { status: tmpStatus },
      // onTdy: tmpTdy,
      ...(
        (clearEtic && {
          eticDate: null,
        })
        || (!(nullEtic || !tmpEtic) && eticDateStr && {
          eticDate: eticDateStr,
        })
      ),
    };

    await patchVehicle(regNumber, patchData);
  };

  return (
    <>
      <div className="d-flex flex-column justify-content-center w-100 bg-light p-2 gap-2">
        <VehicleStatusIcon status={status} />
        <div className="d-flex justify-content-center align-items-center fw-bold">
          <small className="text-nowrap px-1 text-muted">Status:</small>
          <span>{status}</span>
        </div>
        <Button color="link" onClick={() => setModalOpen(true)} className="py-0">
          <MdEdit />
        </Button>
        {
          (eticDate || status === VEHICLE_STATUS_MAP.INVALID)
            ? (
              <div className="d-flex align-items-center">
                <small className="col text-end text-nowrap px-1 text-muted">ETIC:</small>
                {
                  eticDate
                    ? (
                      <span className="col text-nowrap">
                        {formatDate(eticDate)}
                        <div>
                          (
                          {formatDistanceToNow(new Date(eticDate), { addSuffix: true })}
                          )
                        </div>

                      </span>
                    )
                    : <span className="col text-nowrap">Unknown</span>
                }
              </div>
            )
            : null
        }
        <div className="d-flex justify-content-center">
          <small className="text-nowrap px-2 text-gray-dark">
            TDY:
            {' '}
            {
              onTdy === undefined
                ? <Spinner />
                : <span className="text-dark">{onTdy ? 'Yes' : 'No'}</span>
            }
          </small>
        </div>
      </div>
      <RhinoModal
        title={`Update Status for ${regNumber}`}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        onClosed={resetState}
      >
        <div className="d-flex align-items-center">
          <span className="px-2">Status:</span>
          <select
            className="form-select"
            value={tmpStatus || ''}
            onChange={(e) => setTmpStatus(e.target.value as VehicleStatus)}
          >
            <option value="" disabled>Select vehicle status</option>
            <option value={VEHICLE_STATUS_MAP.VALID}>FMC (Fully Mission Capable)</option>
            <option value={VEHICLE_STATUS_MAP.WARNING}>Warn of potential issue</option>
            <option value={VEHICLE_STATUS_MAP.INVALID}>Inoperable</option>
            <option value={VEHICLE_STATUS_MAP.IN_MX}>In Maintenance</option>
          </select>
        </div>
        {
          (tmpStatus !== VEHICLE_STATUS_MAP.VALID)
          && (
            <div className="d-flex w-100 pl-3 pt-3">
              <span className="text-end px-2">Estimated Time for Completion (ETIC):</span>
              <div className="d-flex">

                <ReactDatePicker
                  showIcon
                  disabled={clearEtic}
                  selected={
                    (!clearEtic && tmpEtic)
                      ? tmpEtic
                      : null
                  }
                  onChange={(date) => setTmpEtic(date)}
                />
              </div>

            </div>
          )
        }
        {
          tmpStatus === VEHICLE_STATUS_MAP.VALID
          && (
            <div className="d-flex justify-content-center pt-3">
              <Label check>
                Clear ETIC?
                {' '}
                <Input
                  type="checkbox"
                  defaultChecked
                  onChange={(e) => setClearEtic(e.target.checked)}
                />
              </Label>
            </div>
          )
        }
      </RhinoModal>
    </>
  );
}

export default EditStatusModal;
