import { atomWithReset } from 'jotai/utils';

import type { ICustomFilter } from 'types/IFilter';
import type { FleetDetailField } from './FleetDetailTableColumns';
import { FLEET_DETAIL_FIELD_INITIAL_FILTERS } from './FleetDetailTableColumns';

const FleetDetailsFilterAtom = atomWithReset<ICustomFilter<FleetDetailField>>(
  FLEET_DETAIL_FIELD_INITIAL_FILTERS,
);

const FleetDetailsInitialFilterAtom = atomWithReset<ICustomFilter<FleetDetailField>>(
  FLEET_DETAIL_FIELD_INITIAL_FILTERS,
);

export {
  FleetDetailsFilterAtom,
  FleetDetailsInitialFilterAtom,
};
