import React, { useEffect, useState } from 'react';
import type { IExcessUsageDatum } from 'types/Insights';

import {
  Chart as ChartJS,
  PointElement,
  LinearScale,
  type ChartData,
  type TooltipItem,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import zoom from 'chartjs-plugin-zoom';
import { stringToColor } from 'utils/Helpers';
import capitalize from 'to-capital-case';
import type { UsageInsightsAxisOption } from '../UsageView';

type ExcessUsageChartData = ChartData<'scatter', IExcessUsageDatum[]>;

type FormatData = (
  data: IExcessUsageDatum[],
) => ExcessUsageChartData;

const formatData: FormatData = (data) => ({
  datasets: data.map((datum) => ({
    data: [datum],
    backgroundColor: stringToColor(datum.regNumber),
  })),
});

ChartJS.register(PointElement, LinearScale, zoom);

type ExcessUsageChartProps = {
  data: IExcessUsageDatum[],
  axisOption: UsageInsightsAxisOption,
};

export default function ExcessUsageChart({ data, axisOption }: ExcessUsageChartProps) {
  const [chartData, setChartData] = useState(formatData(data));

  useEffect(
    () => setChartData(formatData(data)),
    [axisOption, data],
  );

  const afterLabelCallback = (item: TooltipItem<'scatter'>) => (
    `${(item.raw as IExcessUsageDatum)[axisOption]} ${axisOption}, ${(item.raw as IExcessUsageDatum).errors} errors`
  );

  return (
    <div className="w-100">
      <Scatter
        id="ExcessUsageChart"
        data={chartData}
        options={{
          responsive: true,
          aspectRatio: 4,
          scales: {
            x: {
              title: {
                display: true,
                text: capitalize(axisOption),
              },
              beginAtZero: true,
            },
            y: {
              title: {
                display: true,
                text: 'Errors',
              },
              beginAtZero: true,
            },
          },
          datasets: {
            scatter: {
              pointRadius: 5,
              pointHoverRadius: 7,
            },
          },
          parsing: {
            xAxisKey: axisOption,
            yAxisKey: 'errors',
          },
          animation: {
            duration: 200,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (item) => (item.raw as IExcessUsageDatum).regNumber,
                afterLabel: afterLabelCallback,
              },
              external: (context) => {
                // Tooltip Element
                let tooltipEl = document.querySelector('#chartjs-tooltip');

                // Create element on first render
                if (!tooltipEl) {
                  tooltipEl = document.createElement('div');
                  tooltipEl.id = 'chartjs-tooltip';
                  tooltipEl.innerHTML = '<table></table>';
                  document.body.append(tooltipEl);
                }
                const tooltipModel = context.tooltip;

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                  tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                  tooltipEl.classList.add('no-transform');
                }
              },
            },
            // zoom: {
            //   limits: {
            //     x: {
            //       min: 'original',
            //       max: 'original',
            //     },
            //     y: {
            //       min: 'original',
            //       max: 'original',
            //     },
            //   },
            //   pan: {
            //     enabled: true,
            //     mode: 'xy',
            //   },
            //   zoom: {
            //     mode: 'xy',
            //     wheel: {
            //       enabled: true,
            //     },
            //   },
            // },
          },
        }}
      />
    </div>
  );
}
