import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useGetExcessUsage, useGetTimeSeriesUsage } from 'apiHooks/Insights.Hook';
import { Button, Card, CardTitle } from 'reactstrap';
import { useGetFleet } from 'apiHooks/Vehicles.Hook';
import { uniq } from 'lodash-es';
import type { IExcessUsageDatum, ITimeSeriesUsageDatum } from 'types/Insights';
import type { TypeaheadRef } from 'react-bootstrap-typeahead';
import { Typeahead } from 'react-bootstrap-typeahead';
import type { IVehicleDetails } from 'types/IVehicleDetails';
import Loading from 'components/shared/Loading/Loading';
import { decode } from 'html-entities';
import saveVehicleUsageAsCsv from 'utils/helpers/makeUsageCsvReport';
import TimeSeriesUsageChart from './charts/TimeSeriesUsageChart';
import ExcessUsageChart from './charts/ExcessUsageChart';

export type UsageInsightsAxisOption = 'miles' | 'hours';

export default function UsageView({
  startDate,
  endDate,
}: {
  startDate: Date,
  endDate: Date,
}) {
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState<string[]>([]);
  const [filteredExcessUsageData, setFilteredExcessUsageData] = useState<IExcessUsageDatum[]>([]);
  const [
    filteredTimeSeriesUsageData,
    setFilteredTimeSeriesUsageData,
  ] = useState<ITimeSeriesUsageDatum[]>();

  const [axisOption, setAxisOption] = useState<UsageInsightsAxisOption>('hours');

  const toggleDepAxisOption = () => setAxisOption(
    (prev) => (prev === 'miles' ? 'hours' : 'miles'),
  );

  const vehicleTypesTypeaheadRef = useRef<TypeaheadRef>(null);

  const {
    data: excessUsageData,
    isLoading: excessUsageIsLoading,
    isError: isExcessUsageError,
    error: excessUsageError,
    refetch: refetchExcessUsage,
  } = useGetExcessUsage(startDate, endDate);

  const {
    data: timeSeriesUsageData,
    isLoading: timeSeriesUsageIsLoading,
    isError: isTimeSeriesUsageError,
    error: timeSeriesUsageError,
    refetch: refetchTimeSeriesUsage,
  } = useGetTimeSeriesUsage();

  const {
    data: vehiclesData,
    isLoading: vehiclesAreLoading,
    isError: isVehiclesError,
    error: vehiclesError,
  } = useGetFleet();

  const vehiclesRecord: Record<string, IVehicleDetails> = useMemo((() => (
    vehiclesData?.[0] ? (
      vehiclesData.reduce((a, v) => ({
        ...a,
        [v.regNumber]: v,
      }), {})
    ) : ({})
  )), [vehiclesData]);

  const presentVehicleTypes: string[] = useMemo((() => (
    excessUsageData ? (
      uniq(
        excessUsageData.map((datum) => (
          datum.vehicleType
        )),
      )) : ([])
  )), [excessUsageData]);

  const filterExcessUsageData = useCallback(() => {
    if (!excessUsageData) return;
    const filteredData = selectedVehicleTypes[0] ? (
      excessUsageData.filter((datum) => (
        selectedVehicleTypes.includes(datum.vehicleType)
      ))
    ) : (excessUsageData);
    setFilteredExcessUsageData(filteredData);
  }, [excessUsageData, selectedVehicleTypes]);

  const filterTimeSeriesUsageData = useCallback(() => {
    if (!timeSeriesUsageData) return;
    const filteredData = selectedVehicleTypes[0] ? (
      timeSeriesUsageData.filter((datum) => (
        selectedVehicleTypes.includes(
          vehiclesRecord[datum.regNumber]?.management?.[0].vehicleType ?? '',
        )
      ))
    ) : (timeSeriesUsageData);
    setFilteredTimeSeriesUsageData(filteredData);
  }, [timeSeriesUsageData, selectedVehicleTypes, vehiclesRecord]);

  useEffect(() => {
    refetchExcessUsage().catch(() => {});
    refetchTimeSeriesUsage().catch(() => {});
  }, [
    startDate,
    endDate,
    refetchExcessUsage,
    refetchTimeSeriesUsage,
  ]);

  useEffect(() => {
    filterExcessUsageData();
  }, [filterExcessUsageData]);

  useEffect(() => {
    filterTimeSeriesUsageData();
  }, [filterTimeSeriesUsageData]);

  if (
    excessUsageIsLoading
    || timeSeriesUsageIsLoading
    || vehiclesAreLoading
    || !filteredExcessUsageData
    || !filteredTimeSeriesUsageData
    || !vehiclesData
  ) {
    return <Loading />;
  }

  if (
    isExcessUsageError
    || isTimeSeriesUsageError
    || isVehiclesError
  ) {
    return (
      <div>
        {(excessUsageError) as string}
        {(timeSeriesUsageError) as string}
        {(vehiclesError) as string}
      </div>
    );
  }

  return (
    <div className="d-flex flex-column align-items-center w-100 gap-3 align-items-center">

      <div className="d-flex w-100 gap-3">
        <Button className="text-nowrap text-capitalize" onClick={toggleDepAxisOption}>
          {`Showing: ${axisOption}`}
        </Button>

        <Typeahead
          id="select-vehicle-types-typeahead"
          multiple
          clearButton
          ref={vehicleTypesTypeaheadRef}
          options={presentVehicleTypes}
          placeholder="Filter by Vehicle Type"
          labelKey={(vehicleType) => decode(vehicleType as string)}
          onChange={(selected) => setSelectedVehicleTypes(selected ? selected as string[] : [])}
        />

        <Button onClick={() => saveVehicleUsageAsCsv(timeSeriesUsageData)}>
          Download Vehicle Usage
        </Button>

      </div>

      <Card className="p-4 shadow border w-100 display-block">
        <CardTitle className="text-center">
          <h4 className="text-center">Usage over Time</h4>
        </CardTitle>
        <TimeSeriesUsageChart
          data={filteredTimeSeriesUsageData}
          axisOption={axisOption}
          minDate={startDate}
          maxDate={endDate}
        />
      </Card>

      <Card className="p-4 shadow border w-100">
        <CardTitle className="text-center">
          <h4 className="text-center">Excessive Usage</h4>
        </CardTitle>
        <ExcessUsageChart
          data={filteredExcessUsageData}
          axisOption={axisOption}
        />
      </Card>

    </div>
  );
}
