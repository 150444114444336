import {
  AF1800_STATUS_CODES,
  AF1800_STATUS_CODE_LABELS,
} from './constants/Af1800StatusCodes';
import COOKIE_NAME from './constants/CookieName';
import {
  ROLES,
  ROLES_SELECT_OPTIONS,
  ROLE_LIST,
  ROLE_REQUIRING_SECTION,
} from './constants/Roles';
import {
  FILTER_TYPES,
  FILTER_NUMBER_RANGE_TYPES,
} from './constants/FilterTypes';
import MEL_STATUSES from './constants/MelStatuses';
import VEHICLE_STATUS_MAP from './constants/VehicleStatusMap';
import {
  WORKFLOW_NEW_RULE_LABEL,
  WORKFLOW_RULE_LABEL,
  WORKFLOW_RULE_FREQUENCY,
  WORKFLOW_RULE_FREQUENCY_LABEL,
  WORKFLOW_ADDED_EXISTING_RULES,
  WORKFLOW_RULES,
  AF1800_BASE_WORK_FLOWS,
  WORKFLOW_CREATED_RULES,
} from './constants/WorkflowTypes';
import {
  DISABLED_TEXT_COLOR_CLASS,
  ENABLED_TEXT_COLOR_CLASS,
} from './constants/Filters';
import { WAIVER_TYPES, WAIVER_STATUSES } from './constants/Waivers';

const MAX_SECTION_NAME_LENGTH = 20;

const IS_RHINO_APP_US = window.location.host.includes('rhinoapp.us');
const IS_RHINO_APP_QA = window.location.host.includes('rhinoqa.us');

const WEBSOCKET_HOST = IS_RHINO_APP_US ? window.location.host : 'www.rhinoqa.us';
const UPDATE_SERVER_HOST = IS_RHINO_APP_US ? window.location.host.replace('www', 'pwa') : 'pwa.rhinoqa.us';
const PWA_APK_DOWNLOAD_URL = `https://${UPDATE_SERVER_HOST}/data/apk/download`;

const AUTH_TOKEN_TIMEOUT_MINUTES = 8 * 60;
const REFRESH_TOKEN_TIMEOUT_MINUTES = 32 * 60;

let siteTitle;
if (IS_RHINO_APP_US) {
  siteTitle = 'RHINO';
} else if (IS_RHINO_APP_QA) {
  siteTitle = 'RHINO (dev)';
} else {
  siteTitle = 'RHINO (local dev)';
}
const SITE_TITLE = siteTitle;

export {
  AF1800_BASE_WORK_FLOWS,
  AF1800_STATUS_CODES,
  AF1800_STATUS_CODE_LABELS,
  COOKIE_NAME,
  FILTER_NUMBER_RANGE_TYPES,
  FILTER_TYPES,
  MEL_STATUSES,
  MAX_SECTION_NAME_LENGTH,
  ROLES,
  ROLES_SELECT_OPTIONS,
  ROLE_REQUIRING_SECTION,
  ROLE_LIST,
  SITE_TITLE,
  VEHICLE_STATUS_MAP,
  WORKFLOW_NEW_RULE_LABEL,
  WORKFLOW_RULE_LABEL,
  WORKFLOW_RULE_FREQUENCY,
  WORKFLOW_RULE_FREQUENCY_LABEL,
  WORKFLOW_RULES,
  WORKFLOW_ADDED_EXISTING_RULES,
  WORKFLOW_CREATED_RULES,
  DISABLED_TEXT_COLOR_CLASS,
  ENABLED_TEXT_COLOR_CLASS,
  WAIVER_TYPES,
  WAIVER_STATUSES,
  PWA_APK_DOWNLOAD_URL,
  WEBSOCKET_HOST,
  AUTH_TOKEN_TIMEOUT_MINUTES,
  REFRESH_TOKEN_TIMEOUT_MINUTES,
};
