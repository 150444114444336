import React from 'react';

import { useGetOrganizationName } from 'apiHooks/Organization.Hook';

const useUserOrganizationName = () => {
  const [orgName, setOrgName] = React.useState('');

  const { data, isLoading } = useGetOrganizationName();

  React.useEffect(() => {
    if (!isLoading && data) {
      setOrgName(data.orgName || '');
    }
  }, [isLoading, data]);

  return orgName;
};

export default useUserOrganizationName;
