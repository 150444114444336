import userAdminRoute from './UserAdmin/Route';
import fleetDetailsRoute from './FleetDetails/Route';
import insightsRoute from './Insights/Route';
import inspectionWorkflowRoute from './InspectionWorkflows/Route';
import monthlyReportsRoute from './MonthlyReports/Route';
import organizationAdministrationRoute from './OrganizationAdministration/Route';
import overviewRoute from './Overview/Route';
import vehicleRoute from './Vehicle/Route';

const viewRoutes = [
  ...overviewRoute,
  ...vehicleRoute,
  ...fleetDetailsRoute,
  ...monthlyReportsRoute,
  ...insightsRoute,
  ...organizationAdministrationRoute,
  ...inspectionWorkflowRoute,
  ...userAdminRoute,
];

export default viewRoutes;
