import React from 'react';
import { type ICellRendererParams } from '@ag-grid-community/core';

import ImageLightBoxModal from 'components/shared/ImageLightBox/ImageLightBoxModal';
import UploadImageModal from 'components/shared/ImageLightBox/UploadImageModal';
import {
  useDeleteRequiredMxImage,
  usePatchNewRequiredMxImage,
} from 'apiHooks/Vehicles.Hook';
import useRestrictUpdates from 'utils/helpers/useRestrictUpdates.Helper';
import type { IRequiredMx } from 'types/Mx';

function AgGridDiscrepancyImageCellRenderer({ data }: ICellRendererParams<IRequiredMx>) {
  const restrictUpdates = useRestrictUpdates();

  const deleteImage = useDeleteRequiredMxImage(data?.regNumber, data?.mxId);
  const patchNewDiscrepancyImage = usePatchNewRequiredMxImage(data?.regNumber, data?.mxId);
  const [currentRhinoImage, setCurrentRhinoImage] = React.useState<number>();

  if (!data) return null;

  const imagesExists = data.pictures && Array.isArray(data.pictures) && data.pictures.length > 0;

  return (
    <div>
      {
        imagesExists && (
          <ImageLightBoxModal
            pictures={data.pictures.map((mxImage) => ({
              filename: mxImage.mxPictureId,
              url: mxImage.url,
            }))}
            modalTitle={`Discrepancy images for ${data.regNumber}`}
            currentRhinoImageCallback={(image) => setCurrentRhinoImage(image.filename)}
            deleteImageCallback={() => (
              currentRhinoImage
                ? deleteImage(currentRhinoImage)
                : Promise.reject())}
          />
        )
      }
      {
        !restrictUpdates && (
          <UploadImageModal
            modalTitle={`Upload discrepancy image for ${data.regNumber}`}
            uploadImageCallback={patchNewDiscrepancyImage}
            disableAnnotation
          />
        )
      }
    </div>
  );
}

export default AgGridDiscrepancyImageCellRenderer;
