import React from 'react';
import {
  Button,
  Spinner,
} from 'reactstrap';
import { MdEdit } from 'react-icons/md';

import { useGetOrganizationSections } from 'apiHooks/OrganizationSections.Hook';
import { usePatchVehicle } from 'apiHooks/Vehicles.Hook';
import OrganizationSectionsSelect from 'components/fields/OrganizationSectionsSelect';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import useRestrictUpdates from 'utils/helpers/useRestrictUpdates.Helper';

interface IEditOrganizationSectionModal {
  sectionId: number | undefined,
  regNumber: string,
}

function EditOrganizationSectionModal({ sectionId, regNumber }: IEditOrganizationSectionModal) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [sectionName, setSectionName] = React.useState<string>('');
  const [tmpOrganizationSectionId, setTmpOrganizationSectionId] = React.useState(sectionId);
  const {
    isLoading,
    isError,
    data,
    error,
  } = useGetOrganizationSections();
  const patchVehicle = usePatchVehicle();

  const restrictUpdates = useRestrictUpdates();

  React.useEffect(() => {
    if (data) {
      setSectionName(data.find((x) => x.sectionId === sectionId)?.sectionName || '');
    }
  }, [data, sectionId]);

  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    return <div>{ error as string }</div>;
  }

  const toggle = () => setModalOpen(!modalOpen);

  const resetState = () => {
    setTmpOrganizationSectionId(sectionId);
  };

  const canSubmit = (
    !!tmpOrganizationSectionId
    && tmpOrganizationSectionId !== sectionId
  );

  const onSubmit = async () => {
    if (!canSubmit) return;

    await patchVehicle(regNumber, { sectionId: tmpOrganizationSectionId }).then(() => {
      toggle();
      setSectionName(data.find((x) => x.sectionId === tmpOrganizationSectionId)?.sectionName || '');
    });
  };

  return (
    <>
      <div className="col d-flex align-items-center">
        {
          sectionName
            ? <span className="text-nowrap">{sectionName}</span>
            : <span className="text-nowrap text-muted lh-1">No Section selected</span>
        }
        {!restrictUpdates && (
          <Button color="link" onClick={toggle} className="py-0">
            <MdEdit />
          </Button>
        )}
      </div>
      <RhinoModal
        title={`Assign Section for ${regNumber}`}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        onClosed={resetState}
      >
        <OrganizationSectionsSelect
          id={`inputOrganizationSectionNumber-${regNumber}`}
          onValueChange={(section) => {
            setTmpOrganizationSectionId(section);
          }}
          defaultValue={sectionId}
          isInvalid={!tmpOrganizationSectionId}
        />
      </RhinoModal>
    </>
  );
}

export default EditOrganizationSectionModal;
