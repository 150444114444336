import React from 'react';
import { GiHomeGarage } from 'react-icons/gi';

import { type IRoute } from 'types/IRoute';
import InspectionWorkflow from 'views/OrganizationAdministration/OrganizationAdministration';

const organizationAdministrationRoute: [IRoute] = [{
  component: <InspectionWorkflow />,
  icon: <GiHomeGarage />,
  label: 'Administration',
  path: '/OrganizationAdministration',
  allowedRoles: ['vco', 'sectionLead'],
}];

export default organizationAdministrationRoute;
