import React from 'react';
import { FaClipboardList } from 'react-icons/fa';

import type { IRoute } from 'types/IRoute';
import InspectionWorkflows from 'views/InspectionWorkflows/InspectionWorkflows';
import EditWorkflow from 'views/InspectionWorkflows/Workflow/EditWorkflow';

const inspectionWorkflowRoute: [IRoute, IRoute] = [{
  component: <InspectionWorkflows />,
  icon: <FaClipboardList />,
  label: 'Inspection Workflow',
  path: '/InspectionWorkflow',
  allowedRoles: ['vco'],
}, {
  component: <EditWorkflow />,
  icon: <FaClipboardList />,
  label: 'Edit Workflow',
  path: '/InspectionWorkflow/:workflowId',
  hideSidebarLink: true,
  allowedRoles: ['vco'],
}];

export default inspectionWorkflowRoute;
