import React from 'react';
import { decode } from 'html-entities';
import { useAtomValue } from 'jotai';

import { useGetOrganizationRules } from 'apiHooks/WorkFlows.Hook';
import RhinoUserCacheAtom from 'store/Rhino.store';
import ChangeControls from './ChangeControls';

type MaybeString = string | null | undefined;

interface IEditableStringRuleField {
  canEdit: boolean,
  isDisabled: boolean,
  value: MaybeString,
  originalValue: MaybeString,
  updateFieldCallback: (value: MaybeString) => void,
  editingLockCallback: (inEdit: boolean) => void,
}

function TitleField({
  value,
  originalValue,
  canEdit,
  isDisabled,
  updateFieldCallback,
  editingLockCallback,

}: IEditableStringRuleField) {
  const [ruleTitle, setRuleTitle] = React.useState(decode(value));
  const [isEditing, setIsEditing] = React.useState(false);

  const { isLoading } = useGetOrganizationRules();
  const rhinoUserCache = useAtomValue(RhinoUserCacheAtom);
  const workflowRuleIdMap = useAtomValue(rhinoUserCache.WorkflowRuleIdMapAtom);

  React.useEffect(() => {
    setRuleTitle(decode(value));
  }, [value]);

  const beginEditing = () => {
    setIsEditing(true);
    editingLockCallback(true);
  };

  const confirmEdits = () => {
    updateFieldCallback(ruleTitle);
    setIsEditing(false);
    editingLockCallback(false);
  };

  const cancelEdits = () => {
    updateFieldCallback(value);
    setRuleTitle(decode(value));
    setIsEditing(false);
    editingLockCallback(false);
  };

  const undoEdits = () => {
    setRuleTitle(originalValue || '');
    updateFieldCallback(originalValue);
  };

  const isTitleValid = () => (
    ruleTitle !== undefined
    && Object.values(workflowRuleIdMap).every((orgRule) => (
      orgRule.title !== String(ruleTitle).trim()
    ))
  );

  return (
    <div data-no-dnd="true" className="d-flex mb-1 gap-1 align-items-center">
      {
        isEditing ? (
          <input
            className="rounded"
            type="text"
            value={ruleTitle}
            onChange={(e) => setRuleTitle(e.target.value)}
          />
        ) : (
          <span className="mx-1">
            {ruleTitle || '[ Not Set ]'}
          </span>
        )
      }
      { canEdit && !isLoading
        && (
          <ChangeControls
            inEdit={beginEditing}
            isDisabled={isDisabled}
            confirmEdits={confirmEdits}
            cancelEdits={cancelEdits}
            undoEdits={undoEdits}
            canUndo={originalValue !== undefined && ruleTitle !== decode(originalValue)}
            isInValid={!isTitleValid()}
          />
        )}
    </div>
  );
}

export default TitleField;
