import type { ICellRendererParams } from '@ag-grid-community/core';
import { usePatchTdy } from 'apiHooks/Tdy.Hook';
import { RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';
import { isEqual } from 'date-fns';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { MdEdit } from 'react-icons/md';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  Spinner,
} from 'reactstrap';
import type { ITdy, ITdyPatch } from 'types/IVehicleDetails';

function EditTdyModal({ tdy }: { tdy: ITdy; }) {
  const [modalOpen, setModalOpen] = useState(false);

  const [startDate, setStartDate] = useState<Date>(new Date(tdy.startDate));
  const [endDate, setEndDate] = useState<Date | null>(tdy.endDate ? new Date(tdy.endDate) : null);
  const [completed, setCompleted] = useState<boolean>(tdy.completed);
  const [description, setDescription] = useState<string>(tdy.description);

  const [patching, setPatching] = useState(false);

  const toggleModalOpen = () => setModalOpen((state) => !state);

  const resetState = () => {
    setStartDate(new Date(tdy.startDate));
    setEndDate(tdy.endDate ? new Date(tdy.endDate) : null);
    setCompleted(tdy.completed);
    setDescription(tdy.description);
  };

  const closeAndReset = () => {
    setModalOpen(false);
    resetState();
  };

  const patchTdy = usePatchTdy(tdy.regNumber, tdy.tdyId);

  const handleCompleted = () => setCompleted((b) => {
    const nowCompleted = !b;
    setEndDate(
      nowCompleted
        ? new Date()
        : null,
    );
    return nowCompleted;
  });

  const canSubmit = () => (
    !isEqual(startDate, new Date(tdy.startDate))
    || (
      (endDate && tdy.endDate) ? (
        !isEqual(endDate, new Date(tdy.endDate))
      ) : (
        endDate !== tdy.endDate
      )
    )
    || completed !== tdy.completed
    || description !== tdy.description
  );

  const handleSubmit = () => {
    if (!canSubmit()) return;

    setPatching(true);

    const tdyPatch: ITdyPatch = {
      ...!isEqual(startDate, new Date(tdy.startDate)) && { startDate: startDate.toISOString() },
      ...(!tdy.endDate || !endDate || !isEqual(endDate, new Date(tdy.endDate))) && (
        { endDate: endDate?.toISOString() || null }
      ),
      ...(completed !== tdy.completed) && { completed },
      ...(description !== tdy.description) && { description },
    };

    patchTdy(tdyPatch)
      .then(closeAndReset)
      .catch(() => {})
      .finally(() => setPatching(false));
  };

  return (
    <>
      <Button
        type="button"
        color="link"
        onClick={() => setModalOpen(true)}
      >
        <MdEdit />
      </Button>

      <Modal
        centered
        size="sm"
        isOpen={modalOpen}
        toggle={toggleModalOpen}
      >
        <RhinoModalHeader toggle={toggleModalOpen}>
          {`Edit TDY for ${tdy.regNumber}`}
        </RhinoModalHeader>

        <ModalBody className="d-grid gap-3 text-secondary fw-bold">

          <div className="d-grid">
            Start Date
            <ReactDatePicker
              showIcon
              selected={startDate}
              onChange={(d) => (d && setStartDate(d))}
            />
          </div>

          <div className="d-grid">
            End Date
            <ReactDatePicker
              showIcon
              selected={endDate}
              onChange={(d) => {
                setEndDate(d);
                setCompleted(!!d);
              }}
            />
          </div>

          <div className="d-flex gap-1">
            <Input
              type="checkbox"
              checked={completed}
              onClick={handleCompleted}
            />
            Completed
          </div>

          <div className="d-grid">
            Description
            <Input
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </ModalBody>

        <ModalFooter className="justify-content-between">
          <Button onClick={closeAndReset}>
            Close
          </Button>

          <Button disabled={!canSubmit() || patching} onClick={handleSubmit}>
            {patching ? (<Spinner size="sm" />) : ('Submit')}
          </Button>

        </ModalFooter>
      </Modal>
    </>
  );
}

function AgGridEditTdyCellRenderer({ data }: ICellRendererParams) {
  return <EditTdyModal tdy={data as ITdy} />;
}

export default AgGridEditTdyCellRenderer;
