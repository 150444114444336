import { useGetRecentVehicleNote, usePostVehicleNote } from 'apiHooks/Vehicles.Hook';
import SubmitButton from 'components/shared/SubmitButton';
import React, { useState } from 'react';
import {
  Alert,
  Button,
  Input,
} from 'reactstrap';
import Loading from 'components/shared/Loading/Loading';
import { decode } from 'html-entities';
import NoteHistory from './NoteHistory';
import Note from './Note';

export default function Notes({ regNumber }: { regNumber: string; }) {
  const [note, setNote] = useState('');
  const [editing, setEditing] = useState(false);
  const {
    data: encodedNote,
    isLoading,
    isError,
    error,
  } = useGetRecentVehicleNote(regNumber);

  const recentNote = encodedNote?.[0]
    ? {
      ...encodedNote[0],
      note: decode(encodedNote[0].note),
    }
    : undefined;

  const postNote = usePostVehicleNote(regNumber);

  const noteLengthValid = note.length < 256;

  const canSubmit = (
    note !== (recentNote?.note ?? '')
    && noteLengthValid
  );

  const handleSubmit = () => (
    postNote(note)
      .then(() => setEditing(false))
  );

  if (isLoading) return <Loading />;
  if (isError) return error as string;

  if (!recentNote) {
    return (
      <div className="w-100 p-3">
        <h5 className="fw-bold">
          {editing ? `Adding Note for ${regNumber}` : 'No Notes'}
        </h5>
        {
          editing
            ? (
              <>
                <Input
                  type="textarea"
                  rows={5}
                  onChange={(e) => setNote(e.target.value)}
                />

                {
                  !noteLengthValid && (
                    <Alert color="danger">
                      Notes must contain fewer than 256 characters.
                    </Alert>
                  )
                }
                <div className="d-flex gap-2 mt-2">
                  <SubmitButton
                    canSubmit={canSubmit}
                    onSubmit={handleSubmit}
                  />
                  <Button
                    onClick={() => setEditing(false)}
                    outline
                  >
                    Cancel
                  </Button>
                </div>

              </>
            ) : (
              <Button onClick={() => setEditing(true)}>
                Add Note
              </Button>
            )
        }
      </div>
    );
  }

  return (
    <div className="w-100 d-flex flex-column align-items-start p-3 gap-3">

      <div className="w-100 d-flex flex-column align-items-start">
        {
          editing && (
            <h5 className="fw-bold">
              {`Editing Note for ${regNumber}`}
            </h5>
          )
        }

        <div className="w-100 d-flex flex-column gap-3">
          {
            editing
              ? (
                <>
                  <Input
                    type="textarea"
                    rows={5}
                    defaultValue={recentNote?.note}
                    onChange={(e) => setNote(e.target.value)}
                  />

                  {
                    !noteLengthValid && (
                      <Alert color="danger">
                        Notes must contain fewer than 256 characters.
                      </Alert>
                    )
                  }
                  <div className="d-flex gap-2">
                    <SubmitButton
                      canSubmit={canSubmit}
                      onSubmit={handleSubmit}
                    />
                    <Button
                      onClick={() => setEditing(false)}
                      outline
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <Note note={recentNote} />

                  <Button
                    className="align-self-start"
                    onClick={() => setEditing(true)}
                  >
                    Update
                  </Button>
                </>
              )
          }
        </div>

      </div>

      <NoteHistory regNumber={regNumber} />

    </div>
  );
}
