import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Navbar,
  NavLink,
  Nav,
  Container,
} from 'reactstrap';
import { MdPerson } from 'react-icons/md';
import { FaCaretDown } from 'react-icons/fa';

import { userInfo } from 'utils/Helpers';
import useLogout from 'hooks/UseLogout';

function NoPermissions() {
  const mainPanelRef = React.useRef(null);
  const location = useLocation();
  const logout = useLogout();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement!.scrollTop = 0;
    if (mainPanelRef.current) {
      (mainPanelRef.current as Element).scrollTop = 0;
    }
  }, [location]);

  return (
    <div className="main-panel" ref={mainPanelRef}>
      <Navbar
        className="navbar-absolute bg-secondary"
        expand="lg"
      >
        <Container fluid>
          <Collapse className="d-flex justify-content-end" navbar isOpen>
            <Nav navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  color="default"
                  data-toggle="dropdown"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="d-flex align-items-center text-white">
                    <MdPerson size={36} />
                    <span className="px-2">{userInfo()?.fullName}</span>
                    <FaCaretDown size={24} />
                  </div>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" end tag="ul">
                  <NavLink tag="li">
                    <DropdownItem className="nav-item text-white" onClick={logout}>Log out</DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="separator d-lg-none" />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <div className="d-flex content p-4">
        <div className="row ml-0 w-100 justify-content-center">
          <div className="col-lg-6 col-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title text-white p-2 mb-0">No role provided</h5>
                <p className="card-text">You lack the permissions to access any part of this system.  Please log out and contact your unit&apos;s admin to be provided with an appropriate role.</p>
                <div className="d-flex justify-content-center py-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={logout}
                  >
                    Log Out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoPermissions;
