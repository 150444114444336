import { useGetVehicleAuxFiles } from 'apiHooks/Vehicles.Hook';
import React from 'react';
import { Spinner } from 'reactstrap';
import type { ColDef } from '@ag-grid-community/core';
import AgGridDateValueFormatter from 'components/agGridRenderers/AgGridDateValueFormatter';
import useRestrictUpdates from 'utils/helpers/useRestrictUpdates.Helper';
import RhinoGrid from 'components/shared/RhinoGrid';
import type { IVehicleAuxFile } from 'types/IVehicleDetails';
import UploadDocumentsModal from './UploadDocumentsModal';
import AgGridEditDocumentCellRenderer from './AgGridEditDocumentCellRenderer';
import AgGridDeleteDocumentCellRenderer from './AgGridDeleteDocumentCellRenderer';
import AgGridViewDocumentCellRenderer from './AgGridViewDocumentCellRenderer';

const makeInitialColumns: (
  (
    regNumber: string,
    restrictUpdates: boolean,
  ) => ColDef[]
) = (
  regNumber,
  restrictUpdates,
) => ([
  {
    field: 'view',
    headerName: '',
    cellRenderer: AgGridViewDocumentCellRenderer,
    initialWidth: 70,
  }, {
    field: 'description',
    headerName: 'Description',
  }, {
    field: 'mimeType',
    headerName: 'File Type',
  }, {
    field: 'creator',
    headerName: 'Creator',
    initialWidth: 150,
  }, {
    field: 'creationDate',
    headerName: 'Creation Date',
    valueFormatter: AgGridDateValueFormatter,
    initialWidth: 250,
  }, {
    field: 'lastModifier',
    headerName: 'Last Modifier',
  }, {
    field: 'lastModificationDate',
    headerName: 'Last Modification Date',
    valueFormatter: AgGridDateValueFormatter,
    initialWidth: 250,
  }, {
    field: 'edit',
    headerName: '',
    cellRenderer: AgGridEditDocumentCellRenderer(regNumber),
    initialWidth: 75,
    hide: restrictUpdates,
  }, {
    field: 'delete',
    headerName: '',
    cellRenderer: AgGridDeleteDocumentCellRenderer(regNumber),
    hide: restrictUpdates,
  },
]);

export default function Documents({ regNumber }: { regNumber: string; }) {
  const restrictUpdates = useRestrictUpdates();

  const [columnDefs] = React.useState(makeInitialColumns(regNumber, restrictUpdates));
  const {
    data: files,
    isLoading,
    isError,
    error,
  } = useGetVehicleAuxFiles(regNumber);

  if (isLoading || !files) return <Spinner />;
  if (isError) return error as string;

  return (
    <div className="h-100 p-2 d-flex flex-column align-items-end">
      {!restrictUpdates && <UploadDocumentsModal regNumber={regNumber} />}
      <div className="ag-theme-material h-100 w-100">
        <RhinoGrid<IVehicleAuxFile>
          rowData={files}
          columnDefs={columnDefs}
        />
      </div>

    </div>
  );
}
