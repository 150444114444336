import { useGetTdy } from 'apiHooks/Tdy.Hook';
import React, { useEffect } from 'react';
import { Input, Label, Spinner } from 'reactstrap';
import type { ColDef, GridApi, GridReadyEvent } from '@ag-grid-community/core';
import AgGridDateValueFormatter from 'components/agGridRenderers/AgGridDateValueFormatter';
import type { ITdy } from 'types/IVehicleDetails';
import useRestrictUpdates from 'utils/helpers/useRestrictUpdates.Helper';
import RhinoGrid from 'components/shared/RhinoGrid';
import AgGridUserCellRenderer from 'components/agGridRenderers/AgGridUserCellRenderer';
import ScheduleVehicleTdyModal from './ScheduleVehicleTdyModal';
import AgGridEditTdyCellRenderer from './AgGridEditTdyCellRenderer';

interface ITdyCols extends ITdy {
  edit?: unknown;
}

const TDY_COLUMNS_COL_DEFS: (restrictUpdates: boolean) => ColDef<ITdyCols>[] = (
  (restrictUpdates) => [
    {
      field: 'startDate',
      valueFormatter: AgGridDateValueFormatter,
      headerName: 'Start Date',
      width: 250,
    }, {
      field: 'endDate',
      valueFormatter: AgGridDateValueFormatter,
      headerName: 'End Date',
      width: 250,
    }, {
      field: 'description',
      headerName: 'Description',
      width: 250,
    }, {
      field: 'creationDate',
      valueFormatter: AgGridDateValueFormatter,
      headerName: 'Scheduled On',
      width: 250,
    }, {
      field: 'creatorId',
      headerName: 'Scheduled By',
      width: 250,
      cellRenderer: AgGridUserCellRenderer,
    }, {
      field: 'lastModificationDate',
      valueFormatter: AgGridDateValueFormatter,
      headerName: 'Updated On',
      width: 250,
    }, {
      field: 'lastModifierId',
      headerName: 'Updated By',
      width: 250,
      cellRenderer: AgGridUserCellRenderer,
    }, {
      field: 'edit',
      headerName: '',
      cellRenderer: AgGridEditTdyCellRenderer,
      width: 100,
      hide: restrictUpdates,
      pinned: 'right',
    },
  ]
);

export default function VehicleTdy({ regNumber }: { regNumber: string; }) {
  // const LastModifierCellRenderer = useAgGridUserCellRenderer<ITdy>('lastModifierId');
  // const CreatorCellRenderer = useAgGridUserCellRenderer<ITdy>('creatorId');

  const restrictUpdates = useRestrictUpdates();

  const [columnDefs] = React.useState(TDY_COLUMNS_COL_DEFS(restrictUpdates));
  const [gridApi, setGridApi] = React.useState<GridApi>();
  const [showHistoric, setShowHistoric] = React.useState(false);
  const [currentRowData, setCurrentRowData] = React.useState<ITdy[]>();

  const {
    data,
    isLoading,
    isError,
    error,
  } = useGetTdy({ regNumber });

  useEffect(() => {
    setCurrentRowData(data?.filter((x) => !x.completed));
  }, [data]);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const toggleShowHistoric = () => {
    gridApi?.setColumnsVisible(['completionDate'], !showHistoric);
    setShowHistoric((b) => !b);
  };

  if (isLoading || !data) return <Spinner />;
  if (isError) return error as string;

  return (
    <div className="d-flex flex-column h-100">
      <div className="w-100 d-flex align-items-center justify-content-between border-bottom p-2">
        <Label>
          <span className="px-2">Show Completed TDY:</span>
          <Input
            defaultChecked={showHistoric}
            onClick={toggleShowHistoric}
            type="checkbox"
          />
        </Label>
        {!restrictUpdates && <ScheduleVehicleTdyModal regNumber={regNumber} />}
      </div>

      <div className="ag-theme-material w-100 h-100">
        <RhinoGrid<ITdy>
          rowData={(showHistoric ? data : (currentRowData || []))}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
        />
      </div>
    </div>
  );
}
