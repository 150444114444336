import React from 'react';
import { useAtom } from 'jotai';
import { Button, Table } from 'reactstrap';

import { useGetOrganizationShiftTimes, usePutOrganizationShiftTimes } from 'apiHooks/Organization.Hook';
import type { IShiftDay } from 'types/IOrganization';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import Loading from 'components/shared/Loading/Loading';

import { orgShiftsAtom } from './ShiftTimes/shiftTimeUtils';
import SetShiftEveryDayModal from './ShiftTimes/SetShiftEveryDayModal';
import SetSingleShiftModal from './ShiftTimes/SetSingleShiftModal';
import SelectTimeZone from './ShiftTimes/SelectTimeZone';

function ShiftTimesModal() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [origShifts, setOrigShifts] = React.useState<IShiftDay[] | undefined>();
  const [shifts, setShifts] = useAtom(orgShiftsAtom);

  const putOrganizationShiftTimes = usePutOrganizationShiftTimes();
  const {
    data: shiftResponse,
    isFetching,
    isError,
    error,
  } = useGetOrganizationShiftTimes(modalOpen);

  const toggleModalOpen = () => {
    setShifts(undefined);
    setOrigShifts(undefined);
    setModalOpen((s) => !s);
  };

  React.useEffect(() => {
    if (shiftResponse && !shifts) {
      setOrigShifts(shiftResponse);
      setShifts(shiftResponse);
    }
  }, [shiftResponse, shifts, setShifts]);
  //   setShifts((currShifts) => {
  //     if (!currShifts) return undefined;

  //     const newShift: IShift = {
  //       number: shiftIndex + 1,
  //       start: parseDateAsShiftTime(start),
  //       end: parseDateAsShiftTime(end),
  //     };

  //     const updatedShifts: IShiftDay[] = currShifts.map((shiftDay) => ({
  //       ...shiftDay,
  //       shifts: [
  //         ...shiftDay.shifts.slice(0, shiftIndex),
  //         { ...newShift },
  //         ...shiftDay.shifts.slice(shiftIndex + 1),
  //       ],
  //     }));

  //     return updatedShifts;
  //   });
  // });

  /**
   *
   * @returns
   */
  const canSubmit = () => {
    for (let i = 0; i < 7; i += 1) {
      for (let j = 0; j < 3; j += 1) {
        const currShift = origShifts?.[i]?.shifts[j];
        const newShift = shifts?.[i]?.shifts[j];
        if (
          currShift?.start !== newShift?.start
          || currShift?.end !== newShift?.end
        ) {
          return true;
        }
      }
    }
    return false;
  };

  /**
   *
   */
  const handleSubmit = shifts ? () => putOrganizationShiftTimes({ shifts }) : undefined;

  if (isError) {
    return <span>{error as string}</span>;
  }

  return (
    <>
      <Button
        className="border"
        onClick={toggleModalOpen}
      >
        Shift Times
      </Button>

      <RhinoModal
        // title={`Shift Times for ${userOrganizationName()}`}
        title="Shift Times"
        size="xl"
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={canSubmit()}
        onSubmit={handleSubmit}
      >

        {(isFetching || !shifts || !origShifts)
          ? <Loading />
          : (
            <>
              <Table>
                <thead>
                  <tr>
                    <th>
                      {/* edit all */}
                    </th>
                    <th>
                      {/* Shift number */}
                    </th>
                    {shifts?.map((shiftDay) => (
                      <th key={shiftDay.day}>{shiftDay.day}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {[1, 2, 3].map((shiftNumber) => (
                    <tr key={shiftNumber}>
                      <td>
                        <SetShiftEveryDayModal shiftNumber={shiftNumber} />
                      </td>
                      <td className="fw-bold">{`Shift ${shiftNumber}`}</td>
                      {shifts?.map((shiftDay, dayIndex) => (
                        <td key={shiftDay.day}>
                          <SetSingleShiftModal
                            dayIndex={dayIndex}
                            shiftNumber={shiftNumber}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <SelectTimeZone />
            </>
          )}
      </RhinoModal>
    </>
  );
}

export default ShiftTimesModal;
