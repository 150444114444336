import type { ICustomFilter } from 'types/IFilter';
import { VEHICLE_STATUS_MAP, FILTER_TYPES } from 'utils/Constants';

const FLEET_DETAIL_FIELDS = [
  'pdf',
  'regNumber',
  'wrm',
  'sectionId',
  'vehicleType',
  'status',
  'onTdy',
  'workflowTitle',
  'inspectionDate',
  'inspector',
  'fuelLevel',
  'eticDate', // cSpell: disable-line
  'daysToEtic', // cSpell: disable-line
  'inCommission',
  'nextMxDate',
  'nextMxType',
  'nextLtiDate',
  'nextPmiDate',
  'openWaivers',
  'fixedWaivers',
  'recentlyRepaired',
  'visitsToMx',
  'daysInMx',
  'recentNotes',
  'recentFixes',
  'mostRecentAnnotation',
] as const;

export type FleetDetailField = typeof FLEET_DETAIL_FIELDS[number];

const FLEET_DETAIL_FIELD_HEADER_MAP: Record<FleetDetailField, string> = {
  pdf: 'AF-1800',
  regNumber: 'Reg Number',
  wrm: 'WRM',
  inCommission: 'In Commission',
  sectionId: 'Section',
  vehicleType: 'Vehicle Type',
  status: 'Status',
  onTdy: 'TDY',
  workflowTitle: 'Inspection Workflow',
  inspectionDate: 'Inspection Date',
  inspector: 'Last Inspector',
  fuelLevel: 'Fuel Level',
  eticDate: 'ETIC', // cSpell: disable-line
  daysToEtic: 'Days to ETIC', // cSpell: disable-line
  nextMxDate: 'Next Mx Date',
  nextMxType: 'Next Mx Type',
  nextLtiDate: 'Next LTI',
  nextPmiDate: 'Next PMI',
  openWaivers: 'Open Waivers',
  fixedWaivers: 'Fixed Waivers',
  recentlyRepaired: 'Recently Repaired',
  visitsToMx: 'Visits to Mx',
  daysInMx: 'Days in Maintenance',
  recentNotes: 'Recent Notes',
  recentFixes: 'Recent Fixes',
  mostRecentAnnotation: 'Most Recent Annotation',
};

const FLEET_DETAIL_BOOLEAN_ICON_FIELDS: FleetDetailField[] = [
  'wrm',
  'onTdy',
];

const FLEET_DETAIL_DATE_TIME_FIELDS: FleetDetailField[] = [
  'inspectionDate',
  'eticDate', // cSpell: disable-line
  'nextMxDate',
  'nextLtiDate',
  'nextPmiDate',
  'mostRecentAnnotation',
];

const FLEET_DETAIL_FIELD_INITIAL_FILTERS: ICustomFilter<FleetDetailField> = {
  regNumber: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  inCommission: {
    filterType: FILTER_TYPES.BOOLEAN,
    options: [true, false],
    labels: ['Yes', 'No'],
    filterValues: [true, false],
    isHidden: false,
  },
  wrm: {
    filterType: FILTER_TYPES.BOOLEAN,
    options: [true, false],
    labels: ['Yes', 'No'],
    filterValues: [true, true],
    isHidden: true,
  },
  sectionId: {
    options: [],
    labels: [],
    filterValues: [],
    isHidden: false,
    filterType: FILTER_TYPES.SET,
  },
  vehicleType: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  status: {
    options: [
      VEHICLE_STATUS_MAP.VALID,
      VEHICLE_STATUS_MAP.INVALID,
      VEHICLE_STATUS_MAP.WARNING,
    ],
    labels: ['Valid', 'Invalid', 'Warning'],
    filterValues: [true, true, true],
    isHidden: false,
    filterType: FILTER_TYPES.SET,
  },
  onTdy: {
    filterType: FILTER_TYPES.BOOLEAN,
    options: [true, false],
    labels: ['Yes', 'No'],
    filterValues: [true, true],
    isHidden: false,
  },
  workflowTitle: {
    options: [],
    labels: [],
    filterValues: [],
    isHidden: false,
    filterType: FILTER_TYPES.SET,
  },
  inspectionDate: {
    filterValue: {
      startDateTime: null,
      endDateTime: null,
    },
    isHidden: false,
    filterType: FILTER_TYPES.DATE_TIME,
  },
  inspector: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  // fuelLevel:
  eticDate: { // cSpell: disable-line
    filterValue: {
      startDateTime: null,
      endDateTime: null,
    },
    isHidden: false,
    filterType: FILTER_TYPES.DATE_TIME,
  },
  daysToEtic: {
    filterValue: null,
    rangeType: '=',
    isHidden: false,
    filterType: FILTER_TYPES.NUMBER_RANGE,
  },
  nextMxDate: {
    filterValue: {
      startDateTime: null,
      endDateTime: null,
    },
    isHidden: true,
    filterType: FILTER_TYPES.DATE_TIME,
  },
  nextMxType: {
    filterValue: '',
    isHidden: true,
    filterType: FILTER_TYPES.TEXT,
  },
  nextLtiDate: {
    filterValue: {
      startDateTime: null,
      endDateTime: null,
    },
    isHidden: true,
    filterType: FILTER_TYPES.DATE_TIME,
  },
  nextPmiDate: {
    filterValue: {
      startDateTime: null,
      endDateTime: null,
    },
    isHidden: true,
    filterType: FILTER_TYPES.DATE_TIME,
  },
  openWaivers: {
    filterValue: null,
    rangeType: '=',
    isHidden: true,
    filterType: FILTER_TYPES.NUMBER_RANGE,
  },
  fixedWaivers: {
    filterValue: null,
    rangeType: '=',
    isHidden: true,
    filterType: FILTER_TYPES.NUMBER_RANGE,
  },
  recentlyRepaired: {
    filterValue: null,
    rangeType: '=',
    isHidden: true,
    filterType: FILTER_TYPES.NUMBER_RANGE,
  },
  visitsToMx: {
    filterValue: null,
    rangeType: '=',
    isHidden: true,
    filterType: FILTER_TYPES.NUMBER_RANGE,
  },
  daysInMx: {
    filterValue: null,
    rangeType: '=',
    isHidden: true,
    filterType: FILTER_TYPES.NUMBER_RANGE,
  },
  recentNotes: {
    filterValue: null,
    rangeType: '=',
    isHidden: true,
    filterType: FILTER_TYPES.NUMBER_RANGE,
  },
  recentFixes: {
    filterValue: null,
    rangeType: '=',
    isHidden: true,
    filterType: FILTER_TYPES.NUMBER_RANGE,
  },
  mostRecentAnnotation: {
    filterValue: {
      startDateTime: null,
      endDateTime: null,
    },
    isHidden: true,
    filterType: FILTER_TYPES.DATE_TIME,
  },
};

export {
  FLEET_DETAIL_FIELDS,
  FLEET_DETAIL_FIELD_HEADER_MAP,
  FLEET_DETAIL_FIELD_INITIAL_FILTERS,
  FLEET_DETAIL_BOOLEAN_ICON_FIELDS,
  FLEET_DETAIL_DATE_TIME_FIELDS,
};
