import React from 'react';
import {
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import VehicleMaintenanceLti from './LTI/Lti';
import VehicleMaintenanceRequired from './Required/Required';
import VehicleMaintenanceScheduled from './Scheduled/Scheduled';
import VehicleMaintenancePmi from './PMI/Pmi';

const TAB_NAMES: string[] = [
  'Required',
  'Scheduled',
  'LTI',
  'PMI',
];

function Maintenance({ regNumber }: { regNumber: string }) {
  const [activeTabName, setActiveTabName] = React.useState('Required');

  const tabClassNames = (tabName: string) => (activeTabName === tabName ? 'active' : 'bg-secondary');

  return (
    <div className="d-flex flex-column h-100">
      <Nav className="bg-gray" tabs>
        {
          TAB_NAMES.map((tabName) => (
            <NavItem key={tabName}>
              <NavLink
                onClick={() => setActiveTabName(tabName)}
                className={`${tabClassNames(tabName)} border border-bottom-0`}
              >
                {tabName}
              </NavLink>
            </NavItem>
          ))
        }
      </Nav>
      <TabContent className="flex-fill overflow-auto" activeTab={activeTabName}>
        <TabPane className="h-100" tabId="Required">
          <VehicleMaintenanceRequired regNumber={regNumber} />
        </TabPane>
        <TabPane className="h-100" tabId="Scheduled">
          <VehicleMaintenanceScheduled regNumber={regNumber} />
        </TabPane>
        <TabPane className="h-100" tabId="LTI">
          <VehicleMaintenanceLti regNumber={regNumber} />
        </TabPane>
        <TabPane className="h-100" tabId="PMI">
          <VehicleMaintenancePmi regNumber={regNumber} />
        </TabPane>
      </TabContent>
    </div>
  );
}

export default Maintenance;
