import React from 'react';
import {
  Alert,
  Button,
} from 'reactstrap';
import { NumericFormat } from 'react-number-format';

import type { ISectionMelPatch, ISectionMelWithName, SectionMelForVehicleType } from 'types/IOrganizationSections';
import { usePatchSectionVehicleMels } from 'apiHooks/OrganizationSections.Hook';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import OrganizationSectionsSelect from 'components/fields/OrganizationSectionsSelect';
import type { IOrganization } from 'types/IOrganization';

interface IAddSectionMelsModal {
  vehicleType: string;
  vehicleTypeMels: ISectionMelWithName[] | undefined;
  orgDetails: IOrganization;
}

function AddSectionMelsModal({
  vehicleType,
  vehicleTypeMels,
  orgDetails,
}: IAddSectionMelsModal) {
  const emptySectionMel: SectionMelForVehicleType = {
    mel: undefined,
    vehicleType,
    sectionId: undefined,
  } as const;

  const [modalOpen, setModalOpen] = React.useState(false);
  const [newSectionMel, setNewSectionMel] = React.useState<SectionMelForVehicleType>(
    { ...emptySectionMel },
  );

  const updateOperationSectionVehicleMels = usePatchSectionVehicleMels();

  const toggle = () => setModalOpen((b) => !b);

  const resetState = () => {
    setNewSectionMel({ ...emptySectionMel });
  };

  const hasExistingMel = (sectionId: number | undefined) => (
    vehicleTypeMels?.some((existingMel) => existingMel.sectionId === sectionId)
  );

  const isValid = (
    !!newSectionMel.vehicleType
    && !!newSectionMel.mel
    && !!newSectionMel.sectionId
    && !hasExistingMel(newSectionMel.sectionId)
  );

  const submitAddSectionMel = async () => {
    if (!newSectionMel.sectionId || !newSectionMel.mel) return;

    const sectionOfMel = orgDetails.sections?.find((section) => (
      section.sectionId === newSectionMel.sectionId
    ));

    if (!sectionOfMel) return;

    const sectionMels: ISectionMelPatch[] = sectionOfMel.mel?.map((mel) => ({
      vehicleType: mel.vehicleType,
      mel: mel.mel,
    })) ?? [];

    const newMel: ISectionMelPatch = {
      vehicleType: newSectionMel.vehicleType,
      mel: newSectionMel.mel,
    };

    await updateOperationSectionVehicleMels({
      sectionId: newSectionMel.sectionId,
      sectionName: sectionOfMel.sectionName,
      mel: [
        ...sectionMels,
        newMel,
      ],
    });
  };

  return (
    <>
      <Button onClick={toggle} className="mt-4">
        Add Section MELs
      </Button>

      <RhinoModal
        title={`New Section MELs for ${vehicleType} vehicles`}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        onSubmit={submitAddSectionMel}
        canSubmit={isValid}
        onClosed={resetState}
      >
        <div className="d-flex flex-column">
          <div className="d-flex gap-3">
            <OrganizationSectionsSelect
              id="newMelSection"
              onValueChange={(sectionId: number) => {
                setNewSectionMel({
                  ...newSectionMel,
                  sectionId,
                });
              }}
              // defaultValue={newSectionMel.sectionName}
              isInvalid={!!newSectionMel.sectionId && !!hasExistingMel(newSectionMel.sectionId)}
            />
            <div className="form-floating">
              <NumericFormat
                className="form-control"
                decimalScale={0}
                min={1}
                allowNegative={false}
                id="inputMel"
                defaultValue={newSectionMel.mel || null}
                onValueChange={({ floatValue }) => {
                  setNewSectionMel({
                    ...newSectionMel,
                    mel: floatValue,
                  });
                }}
                placeholder=" "
              />
              <label htmlFor="inputMel">
                MEL
              </label>
            </div>
          </div>
          {
            hasExistingMel(newSectionMel.sectionId)
            && <Alert color="danger">Section MEL(s) exists for this vehicle type</Alert>
          }
        </div>
      </RhinoModal>
    </>
  );
}

export default AddSectionMelsModal;
