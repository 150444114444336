import type { ColumnState } from '@ag-grid-community/core';
import { atomWithReset } from 'jotai/utils';

const UserAdminColumnsAtom = atomWithReset<ColumnState[] | undefined>(undefined);

const UserAdminInitialColumnsAtom = atomWithReset<ColumnState[] | undefined>(undefined);

export {
  UserAdminColumnsAtom,
  UserAdminInitialColumnsAtom,
};
