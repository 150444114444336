import React from 'react';
import type { IVehicleNote } from 'types/IVehicleDetails';
import { formatDate } from 'utils/Helpers';
import { Card } from 'reactstrap';
import { decode } from 'html-entities';
import styles from './Note.module.css';

function Note({ note }: { note: IVehicleNote; }) {
  return (
    <Card className="p-2 px-3 d-flex flex-column gap-1 shadow border">
      <div className="display-linebreak fst-italic">
        {
          note.note
            ? <span>{decode(note.note)}</span>
            : <span className="text-secondary">No notes</span>
        }
      </div>

      <div className={`text-secondary ${styles['metadata']}`}>
        <div>{note.userName}</div>
        <div>{formatDate(note.lastModificationDate)}</div>
      </div>
    </Card>
  );
}

export default Note;
