import React from 'react';
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import { type ICellRendererParams } from '@ag-grid-community/core';
import { MdEdit } from 'react-icons/md';

import RolesSelect from 'components/fields/RoleSelect';
import OrganizationSectionsSelect from 'components/fields/OrganizationSectionsSelect';
import { usePatchUser } from 'apiHooks/Users.Hook';
import type { AgGridUser, IUserProfile } from 'types/IUser';
import { ROLE_REQUIRING_SECTION } from 'utils/Constants';

import { RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';
import { useRestrictUpdates, userInfo } from 'utils/Helpers';
import useLogout from 'hooks/UseLogout';
import styles from './AgGridUserAdminEditCellRenderer.module.css';

const errorClass = `is-invalid ${styles['no-blur'] || ''}`;

function EditUserModal({ user }: { user: IUserProfile; }) {
  const { userId } = userInfo() || {};
  const isEditingSelf = user.userId === userId;

  const [modalOpen, setModalOpen] = React.useState(false);
  const patchUser = usePatchUser();
  const logout = useLogout();

  const toggle = () => setModalOpen(!modalOpen);

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    control,
    watch,
    reset,
  } = useForm<AgGridUser>({
    mode: 'onBlur',
    defaultValues: user,
  });

  const cancelAddSectionName = () => {
    toggle();
    reset(user);
  };

  // NOTE: org section and role are registered through react-hook-form's control
  const givenNameFormRegistration = register('firstName', { required: true });
  const familyNameFormRegistration = register('lastName', { required: true });
  const activeFormRegistration = register('active');
  const adminFormRegistration = register('isAdmin');

  const isEditingSelfRole = isEditingSelf && !!dirtyFields.role;

  const onSubmit = (formData: AgGridUser) => {
    patchUser(formData)
      .then(isEditingSelfRole ? logout : toggle)
      .catch(() => {});
  };

  return (
    <>
      <Button color="link" onClick={toggle}><MdEdit /></Button>
      <Modal isOpen={modalOpen} toggle={cancelAddSectionName}>
        <Form
          className="bg-white container-lg g-0 rounded"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <RhinoModalHeader toggle={toggle}>
            Modify
            { ' ' }
            {user.firstName /* cSpell: disable-line */ }
            { ' ' }
            {user.lastName /* cSpell: disable-line */ }
          </RhinoModalHeader>
          <ModalBody>
            <FormGroup floating className="shadow-sm">
              <input
                type="text"
                className={`form-control ${(errors['firstName'] ? errorClass : '')}`} // cSpell: disable-line
                id="inputGivenNameInput"
                placeholder=" "
                autoComplete="off"
                onChange={givenNameFormRegistration.onChange}
                onBlur={givenNameFormRegistration.onBlur}
                name={givenNameFormRegistration.name}
                ref={givenNameFormRegistration.ref}
              />
              <Label htmlFor="inputGivenNameInput">First/Given Name</Label>
            </FormGroup>
            <FormGroup floating className="shadow-sm">
              <input
                type="text"
                className={`form-control ${(errors['lastName'] ? errorClass : '')}`} // cSpell: disable-line
                id="inputFamilyNameInput"
                autoComplete="off"
                placeholder=" "
                onChange={familyNameFormRegistration.onChange}
                onBlur={familyNameFormRegistration.onBlur}
                name={familyNameFormRegistration.name}
                ref={familyNameFormRegistration.ref}
              />
              <Label htmlFor="inputFamilyNameInput">Last/Family Name</Label>
            </FormGroup>
            <Controller
              control={control}
              name="role"
              rules={{ required: true }}
              render={({
                field,
                fieldState,
              }) => (
                <RolesSelect
                  id="inputRoleSelect"
                  onValueChange={(selected) => {
                    if (selected && selected.value) {
                      field.onChange(selected.value);
                    } else {
                      field.onChange(null);
                    }
                  }}
                  onBlur={field.onBlur}
                  className="shadow-sm"
                  name={field.name}
                  formRef={field.ref}
                  isInvalid={fieldState.invalid}
                  defaultValue={field.value || undefined}
                />
              )}
            />
            <Controller
              control={control}
              name="sectionId"
              rules={{
                validate: (sectionId: number | undefined | null) => {
                  if (!watch('role')) {
                    return false;
                  }
                  return Number.isFinite(sectionId) || !ROLE_REQUIRING_SECTION.includes(watch('role') || '');
                },
              }}
              render={({
                field,
                fieldState,
              }) => (
                <OrganizationSectionsSelect
                  id="inputOrganizationSectionSelect"
                  onBlur={field.onBlur}
                  onValueChange={field.onChange}
                  name={field.name}
                  formRef={field.ref}
                  defaultValue={field.value || undefined}
                  isInvalid={fieldState.invalid}
                />
              )}
            />
            <div className="d-flex justify-content-between mb-3">
              <FormGroup
                check
                inline
              >
                <Label check>
                  <input
                    type="checkbox"
                    onChange={activeFormRegistration.onChange}
                    onBlur={activeFormRegistration.onBlur}
                    name={activeFormRegistration.name}
                    ref={activeFormRegistration.ref}
                  />
                  Active
                </Label>
              </FormGroup>
              <FormGroup
                check
                inline
              >
                <Label check>
                  <input
                    type="checkbox"
                    onChange={adminFormRegistration.onChange}
                    onBlur={adminFormRegistration.onBlur}
                    name={adminFormRegistration.name}
                    ref={adminFormRegistration.ref}
                  />
                  Admin
                </Label>
              </FormGroup>
            </div>
            {
              Object.keys(errors || {}).includes('section')
              && <Alert className="mb-0 text-center" color="danger">The selected role requires and organization</Alert>
            }
            {
              isEditingSelfRole && (
                <Alert color="info">
                  You will be logged out upon changing your own role
                </Alert>
              )
            }
          </ModalBody>
          <ModalFooter className="justify-content-between">
            <Button color="secondary" onClick={cancelAddSectionName}>
              Cancel
            </Button>
            <input
              className="btn btn-primary text-dark"
              disabled={(!errors || (Object.keys(errors)).length > 0)}
              type="submit"
              value="Update"
            />
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

function AgGridUserAdminEditCellRenderer({ data } : ICellRendererParams) {
  const restrictUpdates = useRestrictUpdates({ requireAdmin: true });

  if (!restrictUpdates) return <EditUserModal user={data} />;
  return null;
}

export default AgGridUserAdminEditCellRenderer;
