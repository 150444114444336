import React from 'react';
import { Button } from 'reactstrap';
import {
  MdCancel,
  MdCheck,
  MdEdit,
  MdUndo,
} from 'react-icons/md';

interface IChangeControls {
  inEdit: (value: boolean) => void,
  confirmEdits: () => void,
  cancelEdits: () => void,
  undoEdits: () => void,
  canUndo: boolean,
  isDisabled: boolean,
  isInValid: boolean,
}

function ChangeControls({
  inEdit,
  confirmEdits,
  cancelEdits,
  undoEdits,
  canUndo,
  isDisabled,
  isInValid,
}: IChangeControls) {
  const [isEditing, setIsEditing] = React.useState(false);

  const beginEditing = () => {
    inEdit(true);
    setIsEditing(true);
  };

  const endEditingConfirm = () => {
    inEdit(false);
    setIsEditing(false);
    confirmEdits();
  };

  const endEditingCancel = () => {
    inEdit(false);
    setIsEditing(false);
    cancelEdits();
  };

  if (isEditing) {
    return (
      <div className="d-flex gap-1 align-items-center bg-white rounded">
        <Button disabled={isInValid} onClick={endEditingConfirm} className="p-0" color="link">
          <MdCheck size={20} className="text-success" />
        </Button>
        <Button onClick={endEditingCancel} className="p-0" color="link">
          <MdCancel size={20} className="text-danger" />
        </Button>
      </div>
    );
  }

  return (
    <>
      <Button
        className="p-0"
        onClick={beginEditing}
        color="link"
        disabled={isDisabled}
      >
        <MdEdit />
      </Button>
      { canUndo && <Button className="py-0" disabled={isDisabled} onClick={undoEdits} color="link"><MdUndo /></Button>}
    </>
  );
}

export default ChangeControls;
