import type { IColumnFilterSet, IColumnFilter } from 'types/IFilter';

const mergeSectionIdFilters = (
  generatedFilter: IColumnFilter,
  userFilter: IColumnFilter,
) => {
  const generatedSetFilter = generatedFilter as IColumnFilterSet;
  const userSetFilter = userFilter as IColumnFilterSet;

  if (!generatedSetFilter.options) {
    return userSetFilter;
  }

  const {
    options: generatedFilterOptions,
    filterValues: generatedFilterValues,
  } = generatedSetFilter;

  const {
    options: userFilterOptions,
    filterValues: userFilterValues,
  } = userSetFilter;

  const filterValues = generatedFilterOptions.map((sectionId, generatedSetFilterIndex) => {
    const userSetFilterIndex = userFilterOptions.indexOf(sectionId);

    if (userSetFilterIndex === -1) {
      return generatedFilterValues[generatedSetFilterIndex] ?? true;
    }

    return userFilterValues[userSetFilterIndex] ?? true;
  });

  return {
    ...generatedFilter,
    filterValues,
  };
};

export default mergeSectionIdFilters;
