import React, { useState } from 'react';

import type { IOrganization } from 'types/IOrganization';

import {
  Button, FormGroup, Input, Label,
} from 'reactstrap';
import { useAtomValue } from 'jotai';
import OrganizationDetailsGroupBySection from './OrganizationDetailsGroupBySection';
import AddNewSectionModal from './AddNewSectionModal';
import OrganizationDetailsGroupByVehicleType from './OrganizationDetailsGroupByVehicleType';
import AddNewVehicleTypeModal from './AddNewVehicleTypeModal';
import adminCollapseStateAtom, { useToggleAdminCollapseState } from './adminCollapseState.store';

function OrganizationDetails({ orgDetails }: { orgDetails: IOrganization }) {
  const [newSectionName, setNewSectionName] = useState('');
  const [newVehicleTypeName, setNewVehicleTypeName] = useState('');
  const [groupByVehicleType, setGroupByVehicleType] = React.useState(false);
  const adminCollapseState = useAtomValue(adminCollapseStateAtom);
  const toggleAdminCollapseState = useToggleAdminCollapseState();

  const adminCollapseAction = (
    adminCollapseState === 'COLLAPSED'
      ? 'Expand'
      : 'Collapse'
  );

  return (
    <div className="d-flex flex-column overflow-hidden">
      <div className="d-flex p-2 align-items-center justify-content-between shadow">
        <div className="d-flex gap-2">
          <Label>Group by Section</Label>
          <FormGroup switch>
            <Input
              type="switch"
              checked={groupByVehicleType}
              onChange={() => setGroupByVehicleType((b) => !b)}
            />
            <Label>Group by Vehicle Type</Label>
          </FormGroup>
        </div>

        <Button onClick={toggleAdminCollapseState}>
          {`${adminCollapseAction} All`}
        </Button>

        {
          groupByVehicleType
            ? (
              <AddNewVehicleTypeModal
                setNewVehicleTypeName={setNewVehicleTypeName}
              />
            )
            : (
              <AddNewSectionModal
                organization={orgDetails}
                setNewSectionName={setNewSectionName}
              />
            )
        }

      </div>
      {
        groupByVehicleType
          ? (
            <OrganizationDetailsGroupByVehicleType
              newVehicleTypeName={newVehicleTypeName}
              orgDetails={orgDetails}
            />
          )
          : (
            <OrganizationDetailsGroupBySection
              newSectionName={newSectionName}
              orgDetails={orgDetails}
            />
          )
      }
    </div>
  );
}

export default OrganizationDetails;
