import React from 'react';
import {
  Alert,
  Button,
  Spinner,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { MdEdit } from 'react-icons/md';

import { usePatchVehicle, useGetFleet } from 'apiHooks/Vehicles.Hook';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import useRestrictUpdates from 'utils/helpers/useRestrictUpdates.Helper';

interface IEditRegNumberModal {
  regNumber: string,
}

function EditRegNumberModal({ regNumber }: IEditRegNumberModal) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [tmpRegNumber, setTmpRegNumber] = React.useState(regNumber);
  const navigate = useNavigate();
  const {
    isLoading,
    isError,
    data,
    error,
  } = useGetFleet();
  const patchVehicle = usePatchVehicle();

  const restrictUpdates = useRestrictUpdates();

  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    return <div>{ error as string }</div>;
  }

  const toggle = () => setModalOpen(!modalOpen);

  const resetState = () => setTmpRegNumber(regNumber);

  const regNumberExists = () => (
    tmpRegNumber !== ''
    && (data || []).some((vehicle) => (
      vehicle.regNumber.trim().toLowerCase() === tmpRegNumber.toLowerCase()
    )));

  const onSubmit = () => patchVehicle(regNumber, { regNumber: tmpRegNumber })
    .then(() => {
      toggle();
      navigate(`/Vehicle/${tmpRegNumber}`);
    })
    .catch(() => {});

  return (
    <>
      <div className="col d-flex align-items-end">
        <span className="text-nowrap lh-1 fs-4">{regNumber}</span>
        {!restrictUpdates && (
          <Button color="link" onClick={toggle} className="py-0">
            <MdEdit />
          </Button>
        )}
      </div>
      <RhinoModal
        title={`Update registration number for ${regNumber}`}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={regNumber !== tmpRegNumber}
        onSubmit={onSubmit}
        onClosed={resetState}
      >
        <div className="form-floating">
          <input
            className="form-control mb-3"
            id="inputRegNumber"
            autoComplete="off"
            defaultValue={tmpRegNumber}
            onChange={(event) => setTmpRegNumber(event.target.value.trim())}
            placeholder=" "
          />
          <label htmlFor="inputRegNumber">
            Registration Number
          </label>
        </div>

        {
          tmpRegNumber.toLowerCase() !== regNumber.trim().toLowerCase()
          && regNumberExists()
          && (
            <Alert color="danger">
              Registration number exists for this organization
            </Alert>
          )
        }
      </RhinoModal>
    </>
  );
}

export default EditRegNumberModal;
