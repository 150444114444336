import React from 'react';
import { Spinner } from 'reactstrap';
import type {
  ColDef,
  ValueFormatterParams,
  RowClassParams,
} from '@ag-grid-community/core';
import { useParams, useNavigate } from 'react-router-dom';

import AgGridHtmlDecodeValueFormatter from 'components/agGridRenderers/AgGridHtmlDecodeValueFormatter';
import AgGridBooleanIconCellRenderer from 'components/agGridRenderers/AgGridBooleanIconCellRenderer';
import AgGridIsTrueIconCellRenderer from 'components/agGridRenderers/AgGridIsTrueIconCellRenderer';
import AgGridImageCellRenderer from 'components/agGridRenderers/AgGridImageCellRenderer';
import { WORKFLOW_RULE_LABEL } from 'utils/Constants';
import { useGetVehicleInspection } from 'apiHooks/Vehicles.Hook';
import type { IPprintResponseEntry, IPprintResult } from 'types/IVehicleInspections';
import type { RuleType } from 'types/common';

import RhinoGrid from 'components/shared/RhinoGrid';
import AgGridResultCellRenderer from './AgGridWorkflowRuleResultStatusCellRenderer';
import InspectionResultsSummary from './InspectionResultsSummary';

function InspectionResultsDetails({ inspection }: { inspection: IPprintResult }) {
  const columnDefs: ColDef<IPprintResponseEntry>[] = [
    {
      field: 'ruleId',
      headerName: 'Rule ID',
      width: 120,
    }, {
      field: 'overview.title',
      autoHeight: true,
      wrapText: true,
      headerName: 'Rule',
      cellClass: 'lh-base',
      valueFormatter: AgGridHtmlDecodeValueFormatter,
      width: 400,
    }, {
      field: 'result.isPassing',
      headerName: 'Passed?',
      cellRenderer: AgGridBooleanIconCellRenderer,
      width: 120,
    }, {
      field: 'isFixed',
      headerName: 'Was Fixed?',
      cellRenderer: AgGridIsTrueIconCellRenderer,
      width: 120,
    }, {
      field: 'overview.ruleType',
      headerName: 'Type',
      width: 140,
      valueFormatter: (params: ValueFormatterParams) => (
        WORKFLOW_RULE_LABEL[params.value as RuleType]
      ),
    }, {
      field: 'overview.description',
      wrapText: true,
      headerName: 'Description',
      autoHeight: true,
      cellClass: 'lh-base',
      valueFormatter: AgGridHtmlDecodeValueFormatter,
    }, {
      field: 'result.value',
      headerName: 'Result',
      cellRenderer: AgGridResultCellRenderer,
      width: 120,
    }, {
      field: 'pictures',
      headerName: 'Image(s)',
      cellRenderer: AgGridImageCellRenderer,
    }, {
      field: 'comment',
      headerName: 'Comment',
      valueFormatter: AgGridHtmlDecodeValueFormatter,
      cellClass: 'lh-base',
      wrapText: true,
      autoHeight: true,
    },
  ];

  return (
    <div className="ag-theme-material w-100 h-100">
      <RhinoGrid<IPprintResponseEntry>
        rowData={inspection.results}
        columnDefs={columnDefs}
        getRowClass={(params: RowClassParams) => (
          params.data.result.isPassing ? '' : 'bg-warning'
        )}
        suppressRowHoverHighlight
      />
    </div>
  );
}

function InspectionResults() {
  const { regNumber, inspectionId } = useParams();
  const navigate = useNavigate();

  const {
    isLoading,
    isError,
    data,
    error,
  } = useGetVehicleInspection(regNumber || '', Number.parseInt(inspectionId || '', 10) || 0);

  if (!regNumber) {
    navigate('/FleetDetails');
    return <div />;
  }
  if (!inspectionId) {
    navigate(`/Vehicle/${regNumber}`);
    return <div />;
  }

  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    return <div>{ error as string }</div>;
  }

  return (
    <div className="d-flex flex-column w-100 h-100">
      <InspectionResultsSummary inspection={data} />
      <InspectionResultsDetails inspection={data} />
    </div>
  );
}

export default InspectionResults;
