import { saveAs } from 'file-saver';
import type IReport from 'types/IReport';

const saveReportsAsCSV = (reports: IReport[], filename: string) => {
  const reportHeaders = [
    '',

    'Total Vehicles',
    'InOp Vehicles',
    '(N)WRM Vehicles',

    'Total Inspections',
    'Failed Inspections',
    'Inspection Notes',

    'Mx Total Discrepancies',
    'Disregarded Discrepancies',
    'Addressable Discrepancies',

    'Scheduled LTI',
    'Performed LTI',
    'Failed LTI',

    'Scheduled PMI',
    'Performed PMI',
    'Failed PMI',

    'Expected Mx',
    'Performed Mx',

    'Most Failed Inspections',
    'Most discrepancies',
    'Most addressable discrepancies',
    // 'Common Failure types',
  ];

  const reportHeadersCSV = `"${reportHeaders.join('","')}"`;

  const reportsEntriesCSV = reports.map((report) => {
    const reportFields = [
      report.name,

      report.vehicles.total,
      report.vehicles.inOp,
      report.vehicles.wrm,

      report.inspections.total,
      report.inspections.failed,
      report.inspections.notes,

      report.mx.discrepancies.total,
      report.mx.discrepancies.disregarded,
      report.mx.discrepancies.addressable,

      report.mx.lti.scheduled,
      report.mx.lti.performed,
      report.mx.lti.failed,

      report.mx.pmi.scheduled,
      report.mx.pmi.performed,
      report.mx.pmi.failed,

      report.mx.scheduled.scheduled,
      report.mx.scheduled.performed,

      report.problems.mostFailedInspections
        ? `${report.problems.mostFailedInspections.regNumber}, (${report.problems.mostFailedInspections.num})`
        : '',
      report.problems.mostTotalDiscrepancies
        ? `${report.problems.mostTotalDiscrepancies.regNumber}, (${report.problems.mostTotalDiscrepancies.num})`
        : '',
      report.problems.mostAddressableDiscrepancies
        ? `${report.problems.mostAddressableDiscrepancies.regNumber}, (${report.problems.mostAddressableDiscrepancies.num})`
        : '',
      // ??? common failure types ???
    ];
    return `"${reportFields.join('","')}"`;
  });
  const reportCsvString = [reportHeadersCSV, ...reportsEntriesCSV].join('\n');
  const reportBlob = new Blob([reportCsvString]);
  saveAs(reportBlob, `${filename}.csv`);
};

export default saveReportsAsCSV;
