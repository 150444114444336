import React from 'react';
import { FormGroup, Label, Spinner } from 'reactstrap';
import { Typeahead, Hint } from 'react-bootstrap-typeahead';
import { useAtom } from 'jotai';
import type { TypeaheadPropsAndState, Option } from 'react-bootstrap-typeahead/types/types';

import { useGetVehicleTypes } from 'apiHooks/VehicleTypes.Hook';
import RhinoUserCacheAtom from 'store/Rhino.store';
import type { ITypeAheadReactHookForm } from 'types/ITypeAheadReactHookForm';

interface INewVehicleType {
  id: string,
  label: string,
  customOption: boolean,
}

const allowedCharsRegex = /^[\d #&()/;A-Za-z-]+$/;

function VehicleTypesSelect({
  className,
  id,
  onValueChange,
  onBlur,
  name,
  formRef,
  defaultValue,
  value,
  isInvalid,
  allowNew,
} : ITypeAheadReactHookForm) {
  const {
    isLoading,
    isError,
    data,
    error,
    refetch,
  } = useGetVehicleTypes();
  const [rhinoUserCache] = useAtom(RhinoUserCacheAtom);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useAtom(rhinoUserCache.vehicleTypesAtom);
  const [touched, setTouched] = React.useState(false);

  React.useEffect(() => {
    if (!data) {
      refetch().catch(() => undefined);
    }
  }, [data, refetch]);

  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    return <div>{ error as string }</div>;
  }

  return (
    <Typeahead
      id={id}
      className={className || ''}
      options={vehicleTypeOptions}
      placeholder=" "
      selectHint={(shouldSelect, event) => (event.key === 'Enter' || shouldSelect)}
      onChange={(selected) => {
        if (!onValueChange) {
          return;
        }
        const vehicleTypeSelected = selected as string[] | INewVehicleType[];
        if (vehicleTypeSelected.length === 0) {
          onValueChange(undefined);
        } else if (typeof vehicleTypeSelected[0] === 'string') {
          onValueChange(vehicleTypeSelected[0]);
        } else {
          const newType = vehicleTypeSelected[0] as INewVehicleType;

          setVehicleTypeOptions([...vehicleTypeOptions, newType.label].sort());

          onValueChange(newType.label);
        }
      }}
      labelKey="label"
      newSelectionPrefix="New Type: "
      allowNew={(results: Option[], state: TypeaheadPropsAndState) => {
        const inputSearchText = state.text.trim().toLowerCase();
        return (
          allowNew === true
          && !results.some((result) => ((result as string).toLowerCase() === inputSearchText))
          && !state.options.some((result) => (
            (result as string).toLowerCase() === inputSearchText
          ))
          && allowedCharsRegex.test(inputSearchText)
        );
      }}
      inputProps={{ name, value }}
      defaultSelected={vehicleTypeOptions.filter((x) => x === defaultValue)}
      ref={formRef}
      positionFixed
      size="lg"
      renderInput={({ inputRef, referenceElementRef, ...inputProperties }, state) => (
        <Hint>
          <FormGroup floating>
            <input
              {...inputProperties /* eslint-disable-line react/jsx-props-no-spreading */}
              className={`form-control ${className || ''} ${(isInvalid && touched) ? 'is-invalid' : ''}`}
              ref={(node) => {
                inputRef(node);
                referenceElementRef(node);
              }}
              id={id}
              onBlur={(event) => {
                state.hideMenu();
                setTouched(true);

                if (onBlur) {
                  onBlur(event);
                }
              }}
            />
            <Label for={id}>Vehicle Type</Label>
          </FormGroup>
        </Hint>
      )}
    />
  );
}

export default VehicleTypesSelect;
