import React, { useEffect, useState } from 'react';
import {
  Nav,
  NavItem,
  TabPane,
  TabContent,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import { userInfo } from 'utils/Helpers';
import { useGetUsers } from 'apiHooks/Users.Hook';
import { useGetOrganizationSections } from 'apiHooks/OrganizationSections.Hook';
import Loading from 'components/shared/Loading/Loading';
import { tabClassNames } from './common';
import FleetOverview from './FleetOverview';
import SectionOverview from './SectionOverview';

const OVERVIEW_SCOPE_TAB_NAMES = [
  'Whole Fleet',
  'Section',
] as const;

export default function OverviewView() {
  const { role, userId } = userInfo() || {};

  const { data: users } = useGetUsers();
  const { data: sections } = useGetOrganizationSections();

  const thisUser = users?.find((user) => user.userId === userId);
  const thisUserSection = sections?.find((section) => section.sectionId === thisUser?.sectionId);

  const [activeTab, setActiveTab] = useState(0);
  const [activeSection, setActiveSection] = useState(thisUserSection || sections?.[0]);

  useEffect(
    () => setActiveSection(thisUserSection || sections?.[0]),
    [sections, thisUserSection],
  );

  const sectionViewRoles = ['sectionLead', 'vco'] as const;

  if (!users || !sections) return <Loading />;

  return (
    <div className="vh-100 d-flex flex-column overflow-hidden">
      <Nav className="bg-gray" tabs>
        {
          OVERVIEW_SCOPE_TAB_NAMES.map((tabName, tabIndex) => (
            <NavItem
              key={tabName}
              hidden={
                tabName === 'Section'
                && (!role || !sectionViewRoles.includes(role))
              }
            >
              <NavLink
                onClick={() => setActiveTab(tabIndex)}
                className={`${tabClassNames(tabIndex, activeTab)} border border-bottom-0`}
              >
                {
                  tabName === 'Section' && !!activeSection && thisUser?.role === 'vco'
                    ? (
                      <>
                        {activeSection.sectionName}
                        <UncontrolledDropdown>
                          <DropdownToggle className="py-0 bg-secondary border-0" caret />
                          <DropdownMenu>
                            {sections?.map((section) => (
                              <DropdownItem
                                key={section.sectionId}
                                onClick={() => setActiveSection(section)}
                              >
                                {section.sectionName}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </>
                    ) : (tabName)
                }

              </NavLink>
            </NavItem>
          ))
        }
      </Nav>
      <TabContent className="h-100" activeTab={activeTab}>
        {
          OVERVIEW_SCOPE_TAB_NAMES.map((tabName, tabIndex) => (
            <TabPane key={tabName} tabId={tabIndex} className="h-100">
              {tabName === 'Whole Fleet' && <FleetOverview />}
              {tabName === 'Section' && activeSection && (
                <SectionOverview
                  sectionId={activeSection.sectionId}
                  sectionName={activeSection.sectionName}
                />
              )}
            </TabPane>
          ))
        }
      </TabContent>
    </div>
  );
}
