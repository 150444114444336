import type { ColDef, ValueGetterParams } from '@ag-grid-community/core';
import { atomWithReset } from 'jotai/utils';

import { ROLES } from 'utils/Constants';
import AgGridBooleanIconCellRenderer from 'components/agGridRenderers/AgGridBooleanIconCellRenderer';
import AgGridIsTrueIconCellRenderer from 'components/agGridRenderers/AgGridIsTrueIconCellRenderer';
import AgGridUserAdminEditCellRenderer from 'views/UserAdmin/AgGridUserAdminEditCellRenderer';
import type { Role } from 'utils/constants/Roles';
import type { AgGridUser } from 'types/IUser';
import {
  AG_GRID_USER_ADMIN_FIELDS,
  AG_GRID_USER_ADMIN_HEADER_MAP,
} from './UserAdminTableColumns';

const AgGridSectionNameGetter = (params: ValueGetterParams<AgGridUser>) => (
  params.data?.sectionName || ''
);

const initialColumns: ColDef[] = (
  AG_GRID_USER_ADMIN_FIELDS.map((field) => {
    const headerName = AG_GRID_USER_ADMIN_HEADER_MAP[field] || '';

    if (field === 'lastName' || field === 'firstName') {
      return {
        field,
        headerName,
        width: 130,
        pinned: 'left',
      };
    }

    if (field === 'active') {
      return {
        field,
        headerName,
        cellRenderer: AgGridBooleanIconCellRenderer,
        width: 100,
        initialHide: true,
      };
    }

    if (field === 'isAdmin') {
      return {
        field,
        headerName,
        cellRenderer: AgGridIsTrueIconCellRenderer,
        width: 100,
      };
    }

    if (field === 'role') {
      return {
        field,
        headerName,
        valueFormatter: (params) => ROLES[params.value as Role] || '',
        width: 150,
      };
    }

    if (field === 'actions') {
      return {
        field,
        headerName,
        cellRenderer: AgGridUserAdminEditCellRenderer,
        width: 100,
      };
    }

    if (field === 'sectionId') {
      return {
        field,
        headerName,
        valueGetter: AgGridSectionNameGetter,
      };
    }

    return {
      field,
      headerName,
    };
  })
);

const UserAdminAgGridColDefsAtom = atomWithReset<ColDef[]>(initialColumns);

export default UserAdminAgGridColDefsAtom;
