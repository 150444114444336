import React, { useEffect } from 'react';
import { Spinner, Label, Input } from 'reactstrap';
import type {
  GridApi,
  GridReadyEvent,
  ColDef,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { differenceInDays } from 'date-fns';

import AgGridDateValueFormatter from 'components/agGridRenderers/AgGridDateValueFormatter';
import { useGetVehicleMxScheduled } from 'apiHooks/Vehicles.Hook';
import type { IScheduledMx } from 'types/Mx';
import useRestrictUpdates from 'utils/helpers/useRestrictUpdates.Helper';
import RhinoGrid from 'components/shared/RhinoGrid';
import CreateScheduledModal from './CreateScheduledModal';
import AgGridEditScheduledMxCellRenderer from './AgGridEditScheduledMxCellRenderer';

interface IScheduledCols extends IScheduledMx {
  daysAway?: number,
  edit?: unknown,
}

const SCHEDULED_MX_COLUMNS_COL_DEFS: (restrictUpdates: boolean) => ColDef<IScheduledCols>[] = (
  (restrictUpdates) => [
    {
      field: 'mxType',
      headerName: 'Type',
    }, {
      field: 'lastModificationDate',
      valueFormatter: AgGridDateValueFormatter,
      headerName: 'Last Modified',
      width: 250,
      initialHide: true,
    }, {
      field: 'mxDate',
      valueFormatter: AgGridDateValueFormatter,
      headerName: 'Scheduled For',
      width: 250,
    }, {
      field: 'daysAway',
      valueGetter: (params: ValueGetterParams<IScheduledCols>) => (
        (params.data?.mxDate && !params.data.completionDate)
          ? differenceInDays(new Date(params.data.mxDate), new Date())
          : ''
      ),
      headerName: 'Days Away',
      width: 120,
    }, {
      field: 'completionDate',
      valueFormatter: AgGridDateValueFormatter,
      headerName: 'Completed On',
      initialHide: true,
      width: 250,
    }, {
      field: 'edit',
      headerName: '',
      cellRenderer: AgGridEditScheduledMxCellRenderer,
      width: 100,
      hide: restrictUpdates,
    },
  ]
);

export default function VehicleMaintenanceScheduled({ regNumber }: { regNumber: string }) {
  const restrictUpdates = useRestrictUpdates();

  const [columnDefs] = React.useState(SCHEDULED_MX_COLUMNS_COL_DEFS(restrictUpdates));
  const [gridApi, setGridApi] = React.useState<GridApi>();
  const [showHistoric, setShowHistoric] = React.useState(false);
  const [currentRowData, setCurrentRowData] = React.useState<IScheduledMx[]>();
  const {
    data,
    isLoading,
    isError,
    error,
  } = useGetVehicleMxScheduled(regNumber);

  useEffect(() => {
    setCurrentRowData(data?.filter((x) => !x.completed));
  }, [data]);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const toggleShowHistoric = () => {
    gridApi?.setColumnsVisible(['completionDate'], !showHistoric);
    setShowHistoric(!showHistoric);
  };

  if (isLoading || !data) {
    return <Spinner />;
  }

  if (isError) {
    return <span>{error as string}</span>;
  }

  return (
    <div className="d-flex flex-column h-100">
      <div className="w-100 d-flex p-2 align-items-center justify-content-between border-bottom">
        <Label>
          <span className="px-2">Show Completed Scheduled Mx:</span>
          <Input
            defaultChecked={showHistoric}
            onClick={toggleShowHistoric}
            type="checkbox"
          />
        </Label>
        {!restrictUpdates && <CreateScheduledModal regNumber={regNumber} />}
      </div>
      <div className="ag-theme-material w-100 h-100">
        <RhinoGrid<IScheduledCols>
          rowData={(showHistoric ? data : (currentRowData || []))}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
        />
      </div>
    </div>
  );
}
