import type { ICellRendererParams } from '@ag-grid-community/core';
import { usePatchLti } from 'apiHooks/Vehicles.Hook';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { MdEdit } from 'react-icons/md';
import {
  Button,
  Input,
} from 'reactstrap';
import type { ILti, ILtiPatch } from 'types/Mx';

function EditLtiModal({ lti }: { lti: ILti; }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [date, setDate] = useState(
    lti.mxDate ? new Date(lti.mxDate) : null,
  );
  const [completed, setCompleted] = useState(lti.completed);
  const [completionDate, setCompletionDate] = useState(
    lti.completionDate ? new Date(lti.completionDate) : null,
  );
  const [passed, setPassed] = useState(!!lti.passed);
  const [notes, setNotes] = useState(lti.notes);

  const resetState = () => {
    setDate(lti.mxDate ? new Date(lti.mxDate) : null);
    setCompleted(lti.completed);
    setCompletionDate(lti.completionDate ? new Date(lti.completionDate) : null);
    setPassed(!!lti.passed);
    setNotes(lti.notes);
  };

  const patchLti = usePatchLti(lti.regNumber, lti.ltiId);

  const ltiPatch: ILtiPatch = {
    ...(
      date && date.toISOString() !== lti.mxDate
      && { mxDate: date?.toISOString() }
    ),
    ...(
      completed !== lti.completed
      && { completed }
    ),
    ...(
      completed
      && { passed }
    ),
    ...(
      completionDate && completionDate.toISOString() !== lti.completionDate
      && { completionDate: completionDate?.toISOString() }
    ),
    ...(
      ((notes || lti.notes) && (notes !== lti.notes))
      && { notes: notes || null }
    ),
  };

  const canSubmit = (
    Object.keys(ltiPatch).length > 0
  );

  const toggleCompleted = () => setCompleted((state) => {
    if (state) {
      setCompletionDate(null);
      setPassed(false);
    } else {
      setCompletionDate(new Date());
    }
    return !state;
  });

  const togglePassed = () => setPassed((state) => !state);

  const handleSetCompletionDate = (newDate: Date | null) => {
    if (newDate) setCompleted(true);
    setCompletionDate(newDate);
  };

  const handleSubmit = async () => {
    if (canSubmit) {
      await patchLti(ltiPatch)
        .catch(() => {});
    }
  };

  return (
    <>
      <Button
        type="button"
        color="link"
        onClick={() => setModalOpen(true)}
      >
        <MdEdit />
      </Button>

      <RhinoModal
        title={`Edit LTI for ${lti.regNumber}`}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={canSubmit}
        onSubmit={handleSubmit}
        onClosed={resetState}
      >
        <div className="d-grid">
          Scheduled For
          <ReactDatePicker
            showIcon
            placeholderText={new Date(lti.mxDate).toLocaleDateString('en-US')}
            selected={date}
            onChange={setDate}
          />
        </div>

        <div className="d-flex gap-1">
          <Input
            type="checkbox"
            checked={completed}
            onClick={toggleCompleted}
          />
          Completed
        </div>

        <div className="d-flex gap-1">
          <Input
            type="checkbox"
            disabled={!completed}
            checked={passed ?? undefined}
            onClick={togglePassed}
          />
          Passed
        </div>

        <div className="d-grid gap-1">
          Notes
          <Input
            type="textarea"
            value={notes ?? undefined}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>

        <div className="d-grid">
          Manual Completion Date
          <ReactDatePicker
            showIcon
            maxDate={new Date()}
            selected={completionDate}
            onChange={handleSetCompletionDate}
          />
        </div>
      </RhinoModal>
    </>
  );
}

function AgGridEditLtiMxCellRenderer({ data }: ICellRendererParams<ILti>) {
  return (
    data
      ? <EditLtiModal lti={data} />
      : null
  );
}

export default AgGridEditLtiMxCellRenderer;
