import { useSetAtom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

const rhinoEventsWsOpenAtom = atomWithReset<boolean>(false);
const rhinoEventsAtom = atomWithReset<CompletionEvent[]>([]);

const useRecordRhinoEvent = () => {
  const setRhinoEvents = useSetAtom(rhinoEventsAtom);
  return (e: MessageEvent) => {
    const completionEvent = JSON.parse(e.data) as CompletionEvent;
    setRhinoEvents((prev) => ([completionEvent, ...prev]));
  };
};

const useDeleteRhinoEvent = () => {
  const setRhinoEvents = useSetAtom(rhinoEventsAtom);
  return (eventId: CompletionEvent['eventId']) => (
    setRhinoEvents((prev) => {
      const eventIndex = prev.findIndex((event) => event.eventId === eventId);
      if (eventIndex < 0) return prev;
      const events = [...prev];
      events.splice(eventIndex, 1);
      return events;
    })
  );
};

export default rhinoEventsAtom;
export {
  useRecordRhinoEvent,
  useDeleteRhinoEvent,
  rhinoEventsWsOpenAtom,
};
