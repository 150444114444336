import React from 'react';
import type { ChartData } from 'chart.js';
import {
  Chart as ChartJS,
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import zoom from 'chartjs-plugin-zoom';
import type { IFailuresPerVehicleResponseItem, IVehicleFailureEntry } from 'types/Failures';
import { uniq } from 'lodash-es';
import { stringToColor } from 'utils/Helpers';
import { decode } from 'html-entities';

interface IVehicleDatum {
  regNumber: string,
  totalFailures: number,
  failuresByRule: IVehicleFailureEntry[],
}

type IFormatData = (
  data: IFailuresPerVehicleResponseItem[],
) => ChartData<'bar', IVehicleDatum[]>;

const formatData: IFormatData = (data) => {
  if (!data[0]) return { datasets: [] };

  // todo: asess this usage of reduce; will the array ever be empty?
  const allRegNumbers = uniq(data.map((vehicleTypeDatum) => (
    vehicleTypeDatum.vehicles.map((vehicle) => vehicle.regNumber)
  )).reduce((prevRegNumbers, regNumbers) => [...prevRegNumbers, ...regNumbers]));

  return ({
    labels: allRegNumbers,
    datasets: data.map((vehicleTypeDatum) => ({
      label: decode(vehicleTypeDatum.vehicleType),
      data: vehicleTypeDatum.vehicles.map((vehicle) => ({
        regNumber: vehicle.regNumber,
        totalFailures: vehicle.totalFailures,
        failuresByRule: vehicle.failuresByRule,
      })),
      backgroundColor: stringToColor(vehicleTypeDatum.vehicleType),
    })),
  });
};

ChartJS.register(
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
  zoom,
);

interface IFailuresPerVehicleChart {
  data: IFailuresPerVehicleResponseItem[],
  af1800RulesTitleMap: Record<string, string>,
}

export default function FailuresPerVehicleChart({
  data,
  // vehiclesRecord,
  af1800RulesTitleMap,
}: IFailuresPerVehicleChart) {
  const chartData = formatData(data);

  const labelTooltipCallback = (datum: IVehicleDatum) => datum.failuresByRule.map((f) => {
    const ruleTitle = af1800RulesTitleMap[f.ruleId];
    const ruleString = `${ruleTitle} (${f.ruleId})`.trim();
    return `${f.failures} - ${ruleString}`;
  });

  return (
    <div className="position-relative w-100">
      <Bar
        id="FailuresPerVehicleChart"
        data={chartData}
        options={{
          responsive: true,
          aspectRatio: 4,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              beginAtZero: true,
              stacked: true,
              ticks: {
                stepSize: 1,
              },
            },
          },
          animation: {
            duration: 200,
          },
          parsing: {
            xAxisKey: 'regNumber',
            yAxisKey: 'totalFailures',
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: ((item) => labelTooltipCallback(item.raw as IVehicleDatum)),
              },
            },
            legend: {
              position: 'right',
            },
            // zoom: {
            //   pan: {
            //     enabled: true,
            //     mode: 'x',
            //   },
            //   zoom: {
            //     mode: 'x',
            //     wheel: {
            //       enabled: true,
            //     },
            //   },
            // },
          },
        }}
      />
    </div>
  );
}
