import React from 'react';
import {
  Alert,
  Button,
  Input,
  Modal,
  ModalFooter,
} from 'reactstrap';
import type { BatchItem, SendOptions } from '@rpldy/shared';
import Uploady, {
  type Batch,
  FILE_STATES,
} from '@rpldy/uploady';
import type { SendResult } from '@rpldy/sender';

import { usePostVehicleAuxFile } from 'apiHooks/Vehicles.Hook';
import ImageLightBox from 'components/shared/ImageLightBox/ImageLightBox';
import PdfViewer from 'components/shared/PdfViewer/PdfViewer';
import {
  UploadyDropZone,
  UploadyUploadButton,
} from 'components/shared/Uploady/uploadyComponents';
import UploadFailureModal from 'components/shared/UploadFailureModal/UploadFailureModal';
import { RhinoModalBody, RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';
import styles from './UploadDocumentsModal.module.css';

function UploadDocumentsModal({ regNumber }: { regNumber: string; }) {
  const [imagePreview, setImagePreview] = React.useState('');
  const [pdfPreview, setPdfPreview] = React.useState<Uint8Array | undefined>();
  const description = React.useRef('');
  const [descriptionLengthOk, setDescriptionLengthOk] = React.useState(true);
  const [modalOpen, setModalOpen] = React.useState(false);
  const postVehicleAuxFile = usePostVehicleAuxFile(regNumber);
  const [uploadError, setUploadError] = React.useState('');

  const clearPreviewData = () => {
    setImagePreview('');
    setPdfPreview(undefined);
  };

  const closeModal = () => {
    clearPreviewData();
    setModalOpen(false);
  };

  const newFileCallback = (batch: Batch) => {
    if (batch.items[0]?.file.type === 'application/pdf') {
      (batch.items[0]?.file as File).arrayBuffer()
        .then((pdfArray) => pdfArray && setPdfPreview(new Uint8Array(pdfArray)))
        .catch(() => {});
    } else {
      setImagePreview(URL.createObjectURL(batch.items[0]?.file as File));
    }
  };

  const canSubmit = (
    descriptionLengthOk
    && (
      !!imagePreview
      || !!pdfPreview
    )
  );

  const uploadFile = (
    items: BatchItem[],
    url: string | undefined,
    sendOptions: SendOptions,
  ) => {
    const sendResponse = postVehicleAuxFile(items, description.current, undefined, sendOptions);

    return {
      ...sendResponse,
      request: sendResponse.request.then((data) => {
        if (data.state === FILE_STATES.ERROR) {
          setUploadError(data.response.data);
          clearPreviewData();
        } else {
          closeModal();
        }

        return data;
      }).catch(() => {}),
    } as SendResult;
  };

  return (
    <>
      <Button
        type="button"
        onClick={() => setModalOpen(true)}
      >
        Upload Documents
      </Button>
      <Modal
        isOpen={modalOpen}
        toggle={closeModal}
        className="image-lightbox-modal"
      >
        <Uploady
          autoUpload={false}
          accept=".png,.jpg,.jpeg,.pdf"
          send={uploadFile}
        >
          <UploadFailureModal
            error={uploadError}
            acknowledgeError={() => setUploadError('')}
          />
          <RhinoModalHeader toggle={closeModal}>
            {`Upload Documents for ${regNumber}`}
          </RhinoModalHeader>

          <RhinoModalBody className="d-flex flex-column gap-3 text-secondary fw-bold">
            <div className="border">
              {(!imagePreview && !pdfPreview) && (
                <UploadyDropZone newFileCallback={newFileCallback} dropZoneText="Drop files or click here..." />
              )}
              {imagePreview && (
                <div className={styles['drop-zone'] || ''}>
                  <ImageLightBox pictures={[{ url: imagePreview, file: '' }]} preLoaded />
                </div>
              )}
              {pdfPreview && (
                <div className="overflow-hidden">
                  <PdfViewer filename="" pdfData={pdfPreview} hideDownloadButton />
                </div>
              )}
            </div>

            <div>
              Description
              <Input
                onChange={(e) => {
                  const newDescription = e.target.value;
                  description.current = newDescription;
                  setDescriptionLengthOk(newDescription.length <= 50);
                }}
              />
            </div>

            {!descriptionLengthOk && (
              <Alert color="danger">
                Descriptions must contain 50 characters or fewer.
              </Alert>
            )}

          </RhinoModalBody>

          <ModalFooter className="d-flex justify-content-between">
            <Button onClick={closeModal}>
              Close
            </Button>
            {
              (imagePreview || pdfPreview)
                ? <Button onClick={clearPreviewData}>Remove</Button>
                : <div />
            }
            <UploadyUploadButton disabled={!canSubmit} />
          </ModalFooter>
        </Uploady>
      </Modal>
    </>
  );
}

export default UploadDocumentsModal;
