import {
  PDFDocument,
  type PDFFont,
  type PDFPage,
  StandardFonts,
} from 'pdf-lib';
import get from 'lodash-es/get';
import { decode } from 'html-entities';
import { format } from 'date-fns';

import type {
  IAf1800Result,
  IAf1800MappedField,
} from 'types/IAF1800Pdf';
import { formatDate } from 'utils/Helpers';
import af1800FieldMappingsJSON from './af1800FieldMappings.json';

/**
 *
 * @param font
 * @param value
 * @param maxWidth
 * @returns
 */
const calculateFontSize = (font: PDFFont, value: string | number, maxWidth: number) => {
  let fontSize = 8;

  while (maxWidth < font.widthOfTextAtSize(String(value), fontSize)) {
    fontSize *= 0.9;
  }

  return fontSize;
};

/**
 *
 * @param currentPage
 * @param font
 * @param field
 * @param value
 */
const drawAf1800Text = (
  currentPage: PDFPage,
  font: PDFFont,
  field: IAf1800MappedField,
  value: string,
) => {
  const {
    x,
    y,
    type,
    maxWidth,
    dateFormat,
  } = field;

  if (type === 'text') {
    currentPage.drawText(decode(String(value)), {
      x,
      y,
      font,
      size: calculateFontSize(font, value, maxWidth),
    });
  } else if (type === 'date' && (typeof value === 'string') && dateFormat) {
    const formattedDate = format(new Date(value), dateFormat);
    currentPage.drawText(formattedDate, {
      x,
      y,
      font,
      size: calculateFontSize(font, formattedDate, maxWidth),
    });
  }
};

/**
 *
 * @param currentPage
 * @param font
 * @param field
 */
const drawAf1800Checkbox = (currentPage: PDFPage, font: PDFFont, field: IAf1800MappedField) => {
  const { x, y } = field;

  currentPage.drawText('x', {
    x,
    y,
    font,
    size: 14,
  });
};

/**
 *
 * @param reportData
 * @param apiField
 * @returns
 */
const determineValueSuffix = (reportData: IAf1800Result, apiField: string) => {
  if (apiField === 'page2.monthlyRequirement.tirePressure.operator') {
    const operatorDate = get(reportData, 'page2.monthlyRequirement.tirePressure.date');
    return typeof operatorDate === 'string' ? ` ${formatDate(operatorDate)}` : '';
  }
  if (apiField === 'page2.monthlyRequirement.milesHours.operator') {
    const operatorDate = get(reportData, 'page2.monthlyRequirement.milesHours.date');
    return typeof operatorDate === 'string' ? ` ${formatDate(operatorDate)}` : '';
  }

  return '';
};

/**
 *
 * @param reportData
 * @param pdfFile
 * @returns
 */
function populatePdfDoc(reportData: IAf1800Result, pdfFile: ArrayBuffer) {
  return PDFDocument.load(new Uint8Array(pdfFile)).then((pdfDoc) => (
    pdfDoc.embedFont(StandardFonts.Helvetica).then((font) => {
      try {
        const pdfPages = pdfDoc.getPages();

        af1800FieldMappingsJSON.forEach((field: IAf1800MappedField) => {
          const { pdfPageNumber, apiField } = field;

          const currentPage = pdfPages[pdfPageNumber];

          if (currentPage) {
            const value = get(reportData, apiField);

            if (typeof value === 'number' || typeof value === 'string') {
              drawAf1800Text(currentPage, font, field, `${String(value)}${determineValueSuffix(reportData, apiField)}`);
            } else if (typeof value === 'boolean' && value === true) {
              drawAf1800Checkbox(currentPage, font, field);
            }
          }
        });

        return pdfDoc;
      } catch {
        return pdfDoc;
      }
    })));
}

function populateAndMergePdfDocuments(reportsData: IAf1800Result[], pdfFile: ArrayBuffer) {
  return PDFDocument.create().then((mergedPdf) => (
    Promise.all(reportsData.map((report) => (
      populatePdfDoc(report, pdfFile)
        .then((populatedPdf) => (
          mergedPdf.copyPages(populatedPdf, populatedPdf.getPageIndices())
            .then((pages) => {
              pages.forEach((page) => mergedPdf.addPage(page));
              return mergedPdf.save();
            })
        ))
    ))).then(() => mergedPdf.save())));
}

function populateSinglePdf(reportData: IAf1800Result, pdfFile: ArrayBuffer) {
  return populatePdfDoc(reportData, pdfFile).then((pdfDoc) => pdfDoc.save()).catch(() => {});
}

export {
  populateSinglePdf,
  populateAndMergePdfDocuments,
};
