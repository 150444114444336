import React from 'react';
import { MdDirectionsCarFilled } from 'react-icons/md';

import { type IRoute } from 'types/IRoute';
import FleetDetails from 'views/FleetDetails/FleetDetails';

const fleetDetailsRoute: [IRoute] = [
  {
    component: <FleetDetails />,
    icon: <MdDirectionsCarFilled />,
    label: 'Fleet Details',
    path: '/FleetDetails',
  },
];

export default fleetDetailsRoute;
