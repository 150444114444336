import React from 'react';
import {
  Alert,
  Button,
} from 'reactstrap';
import { NumericFormat } from 'react-number-format';
import { MdClose } from 'react-icons/md';
import { nanoid } from 'nanoid';
import uniqBy from 'lodash-es/uniqBy';

import type { IOrganizationSectionWithMel, ISectionMelEphemeral, ISectionMelPatch } from 'types/IOrganizationSections';
import VehicleTypesSelect from 'components/fields/VehicleTypesSelect';
import { usePatchSectionVehicleMels } from 'apiHooks/OrganizationSections.Hook';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import { useGetVehicleTypes, usePostVehicleType } from 'apiHooks/VehicleTypes.Hook';

function AddVehicleTypeMELsModal({ section }: { section: IOrganizationSectionWithMel }) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [newVehicleTypeMELs, setNewVehicleTypeMELs] = React.useState<ISectionMelEphemeral[]>([]);
  const updateOperationSectionVehicleMels = usePatchSectionVehicleMels();
  const postVehicleType = usePostVehicleType();

  const { data: vehicleTypes } = useGetVehicleTypes();

  const toggle = () => setModalOpen(!modalOpen);

  const resetState = () => setNewVehicleTypeMELs([]);

  const submitAddVehicleTypes = async () => {
    if (!vehicleTypes) return;

    const sectionMels: ISectionMelPatch[] = (section.mel || []).map((sectionMel) => {
      const { vehicleType, mel } = sectionMel;
      return {
        vehicleType,
        mel,
      };
    });
    const newMels: ISectionMelPatch[] = newVehicleTypeMELs.map((sectionMelEph) => {
      const { vehicleType, mel } = sectionMelEph;
      return (vehicleType && mel)
        ? {
          vehicleType,
          mel,
        }
        : undefined;
    }).filter(Boolean);

    const newSectionNames = newMels.filter(
      (mel) => !!mel.vehicleType && !vehicleTypes?.includes(mel.vehicleType),
    ).map(
      (mel) => mel.vehicleType,
    ).filter(Boolean);

    if (newSectionNames.length > 0) {
      await Promise.all(
        newSectionNames.map((vehicleType) => (
          postVehicleType(vehicleType)
        )),
      );
    }

    await updateOperationSectionVehicleMels({
      sectionId: section.sectionId,
      sectionName: section.sectionName,
      mel: [
        ...sectionMels,
        ...newMels,
      ],
    });
  };

  const hasExistingMel = (vehicleType: string) => (
    (section.mel || []).some((existingMel) => existingMel.vehicleType === vehicleType)
  );

  const vehicleTypeDuplicated = () => uniqBy(newVehicleTypeMELs, 'vehicleType').length !== newVehicleTypeMELs.length;

  const isValid = () => (
    newVehicleTypeMELs.length > 0
    && !vehicleTypeDuplicated()
    && !newVehicleTypeMELs.some((newMEL) => (
      !newMEL.vehicleType
      || !newMEL.mel
      || hasExistingMel(newMEL.vehicleType)
    )));

  const newVehicleTypeMEL = () => {
    setNewVehicleTypeMELs([
      ...newVehicleTypeMELs,
      {
        mel: undefined,
        vehicleType: undefined,
        id: nanoid(),
      },
    ]);
  };

  const removeNewVehicleTypeMEL = (indexToRemove: number) => {
    setNewVehicleTypeMELs(newVehicleTypeMELs.filter((_, i) => i !== indexToRemove));
  };

  return (
    <>
      <Button onClick={toggle} className="mt-4">
        Add Vehicle Type MELs
      </Button>
      <RhinoModal
        title={`New Vehicle Type MELs for ${section.sectionName}`}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        onSubmit={submitAddVehicleTypes}
        canSubmit={isValid()}
        onClosed={resetState}
      >
        <div className="d-flex flex-column">
          {
            newVehicleTypeMELs.map((newMEL, idx) => (
              <div key={newMEL.id} className="d-flex">
                <VehicleTypesSelect
                  id={`newMelVehicleType-${idx}`}
                  allowNew
                  onValueChange={(vehicleType) => {
                    const tmpVehicleTypes = [...newVehicleTypeMELs];
                    (tmpVehicleTypes[idx] as ISectionMelEphemeral).vehicleType = vehicleType;
                    setNewVehicleTypeMELs(tmpVehicleTypes);
                  }}
                  defaultValue={newMEL.vehicleType}
                  isInvalid={newMEL.vehicleType !== '' && hasExistingMel(newMEL.vehicleType || '')}
                />
                <div className="form-floating">
                  <NumericFormat
                    className="form-control"
                    decimalScale={0}
                    min={1}
                    allowNegative={false}
                    id={`inputMel-${idx}`}
                    defaultValue={newMEL.mel || null} // eslint-disable-line unicorn/no-null
                    onValueChange={({ floatValue }) => {
                      const tmpVehicleTypes = [...newVehicleTypeMELs];

                      (tmpVehicleTypes[idx] as ISectionMelEphemeral).mel = floatValue;
                      setNewVehicleTypeMELs(tmpVehicleTypes);
                    }}
                    placeholder=" "
                  />
                  <label htmlFor={`inputMel-${idx}`}>
                    MEL
                  </label>
                </div>
                <Button
                  onClick={() => removeNewVehicleTypeMEL(idx)}
                  className="py-0"
                  color="link"
                >
                  <MdClose className="text-danger" />
                </Button>
              </div>
            ))
          }
          {
            newVehicleTypeMELs.some((newMEL) => hasExistingMel(newMEL.vehicleType || ''))
            && <Alert color="danger">Vehicle Type MEL(s) exists for section</Alert>
          }
          {
            vehicleTypeDuplicated() && <Alert color="danger">New vehicle type duplicated</Alert>
          }
          <div>
            <Button
              color="primary"
              onClick={newVehicleTypeMEL}
            >
              New Vehicle Type MEL
            </Button>
          </div>
        </div>
      </RhinoModal>
    </>
  );
}

export default AddVehicleTypeMELsModal;
