import type { IWorkflowRule } from 'types/IWorkflows';

const ruleIsCustom = (
  <R extends Pick<IWorkflowRule, (
    | 'isImmutable'
    | 'orgId'
  )>>(rule: R) => (
    !rule.isImmutable || !!rule.orgId
  )
);
export default ruleIsCustom;
