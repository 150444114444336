import React from 'react';
import {
  Button,
  Label,
  Input,
} from 'reactstrap';
import ReactDatePicker from 'react-datepicker';
import { MdEdit } from 'react-icons/md';

import { usePatchVehicle } from 'apiHooks/Vehicles.Hook';
import VehicleTypesSelect from 'components/fields/VehicleTypesSelect';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';

interface IEditVehicleDescriptionModal {
  eolDate: string | null | undefined,
  make: string | null,
  model: string | null,
  regNumber: string,
  vehicleType: string | null | undefined,
  wrm: boolean,
  year: number | null,
}

function EditVehicleDescriptionModal({
  eolDate,
  make,
  model,
  regNumber,
  vehicleType,
  wrm,
  year,
}: IEditVehicleDescriptionModal) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [tmpVehicleType, setTmpVehicleType] = React.useState(vehicleType);
  const [tmpYear, setTmpYear] = React.useState(year);
  const [tmpMake, setTmpMake] = React.useState(make);
  const [tmpModel, setTmpModel] = React.useState(model);
  const [tmpWRM, setTmpWRM] = React.useState(wrm);
  const [tmpEolDate, setTmpEolDate] = React.useState(eolDate);
  const patchVehicle = usePatchVehicle();

  const toggle = () => setModalOpen(!modalOpen);

  const canSubmit = (
    tmpVehicleType !== vehicleType
    || tmpYear !== year
    || tmpMake !== make
    || tmpModel !== model
    || tmpWRM !== wrm
    || tmpEolDate !== eolDate
  );

  const resetState = () => {
    setTmpVehicleType(vehicleType);
    setTmpYear(year);
    setTmpMake(make);
    setTmpModel(model);
    setTmpWRM(wrm);
    setTmpEolDate(eolDate);
  };

  const submitUpdatedVehicleDescription = () => (
    patchVehicle(regNumber, {
      ...(tmpVehicleType && tmpVehicleType !== vehicleType) && (
        { vehicleType: tmpVehicleType }
      ),
      ...(tmpYear !== year) && { year: tmpYear },
      ...(tmpMake !== make) && { make: tmpMake || null },
      ...(tmpModel !== model) && { model: tmpModel || null },
      ...(tmpWRM !== wrm) && { wrm: tmpWRM },
      ...(tmpEolDate !== eolDate) && { eolDate: tmpEolDate ?? null },
    })
  );

  return (
    <>
      <div className="justify-content-end d-flex">
        <Button color="link" onClick={toggle} className="py-0">
          <MdEdit />
        </Button>
      </div>
      <RhinoModal
        title={`Update Description for ${regNumber}`}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={canSubmit}
        onSubmit={submitUpdatedVehicleDescription}
        onClosed={resetState}
      >
        <div className="d-flex flex-column">
          <VehicleTypesSelect
            id={`vehicleTypeSelect-${regNumber}`}
            onValueChange={setTmpVehicleType}
            defaultValue={tmpVehicleType || ''}
            isInvalid={!tmpVehicleType}
          />
          <div className="form-floating">
            <input
              className="form-control mb-3"
              id={`inputYear-${regNumber}`}
              type="number"
              autoComplete="off"
              defaultValue={tmpYear || undefined}
              onChange={(event) => setTmpYear(Number.parseInt(event.target.value, 10))}
              placeholder=" "
            />
            <label htmlFor={`inputYear-${regNumber}`}>
              Year
            </label>
          </div>
          <div className="form-floating">
            <input
              className="form-control mb-3"
              id={`inputMake-${regNumber}`}
              autoComplete="off"
              defaultValue={tmpMake || undefined}
              onChange={(event) => setTmpMake((event.target.value || '').trim())}
              placeholder=" "
            />
            <label htmlFor={`inputMake-${regNumber}`}>
              Make
            </label>
          </div>
          <div className="form-floating">
            <input
              className="form-control mb-3"
              id={`inputModel-${regNumber}`}
              autoComplete="off"
              defaultValue={tmpModel || undefined}
              onChange={(event) => setTmpModel(event.target.value.trim())}
              placeholder=" "
            />
            <label htmlFor={`inputModel-${regNumber}`}>
              Model
            </label>
          </div>
          <div className="d-flex justify-content-between p-3">
            <Label check>
              WRM
              {' '}
              <Input
                type="checkbox"
                defaultChecked={tmpWRM}
                onChange={(e) => setTmpWRM(e.target.checked)}
              />
            </Label>
          </div>
          <div className="d-flex">
            <ReactDatePicker
              showIcon
              className="form-control"
              placeholderText="EOL Date"
              selected={tmpEolDate ? (new Date(tmpEolDate)) : null}
              onChange={(date) => (
                setTmpEolDate(date ? new Date(date?.toUTCString()).toISOString() : null)
              )}
            />
          </div>
        </div>
      </RhinoModal>
    </>
  );
}

export default EditVehicleDescriptionModal;
