import React, { useState } from 'react';
import { useGetVehicleNotes } from 'apiHooks/Vehicles.Hook';
import Loading from 'components/shared/Loading/Loading';
import { Button } from 'reactstrap';
import Note from './Note';

type NotesHistoryProps = {
  regNumber: string;
};

export default function NoteHistory(props: NotesHistoryProps) {
  const { regNumber } = props;
  const [showHistory, setShowHistory] = useState(false);

  const {
    data: notesData,
    isInitialLoading,
    isError,
    error,
  } = useGetVehicleNotes(regNumber, showHistory);

  const noteHistory = notesData?.[1]
    ? notesData.slice(1)
    : undefined;

  if (isError) return error as string;
  if (isInitialLoading) return <Loading />;

  return (
    <div className="w-100 h-100 d-flex flex-column align-items-start gap-3">
      <Button
        onClick={() => setShowHistory((b) => !b)}
        color="primary"
      >
        {`${showHistory ? 'Hide' : 'Show'} History`}
      </Button>

      {
        showHistory && (
          <div className="w-100 overflow-visible d-grid gap-3 pb-4">
            {
              noteHistory
                ? noteHistory.map((note) => (
                  <Note note={note} key={note.noteId} />
                ))
                : <span>No history</span>
            }
          </div>
        )
      }
    </div>

  );
}
