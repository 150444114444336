import React from 'react';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { type ICellRendererParams } from '@ag-grid-community/core';
import { type IFleetDetail } from 'types/IFleetDetails';

function AgGridFleetDetailVehicleLinkCellRenderer({ data }: ICellRendererParams<IFleetDetail>) {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => navigate(`/Vehicle/${data?.regNumber}`)}
      color="link"
      className="p-0 font-monospace fw-bold fs-6 w-100 text-start"
    >
      {data?.regNumber}
    </Button>
  );
}

export default AgGridFleetDetailVehicleLinkCellRenderer;
