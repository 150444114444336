import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import {
  Button,
  Label,
} from 'reactstrap';

export enum TimeRangeOption {
  CurrentMonth = 'Current Month',
  FromDate = 'From Date',
  BetweenDates = 'Between Dates',
}

interface ITimeRangeModal {
  timeRangeOption: TimeRangeOption,
  startDate: Date | undefined,
  endDate: Date | undefined,
  onSubmit: (
    newTimeRangeOption: TimeRangeOption,
    newStartDate: Date | undefined,
    newEndDate: Date | undefined
  ) => Promise<void>;
}

function TimeRangeModal({
  timeRangeOption,
  startDate,
  endDate,
  onSubmit,
}: ITimeRangeModal) {
  const [tmpTimeRangeOption, setTmpTimeRangeOption] = useState(timeRangeOption);
  const [tmpStartDate, setTmpStartDate] = useState(startDate);
  const [tmpEndDate, setTmpEndDate] = useState(endDate);
  const [modalOpen, setModalOpen] = useState(false);

  const resetState = () => {
    setTmpTimeRangeOption(timeRangeOption);
    setTmpStartDate(startDate);
    setTmpEndDate(endDate);
  };

  const showStartDate = () => (
    tmpTimeRangeOption === TimeRangeOption.FromDate
    || tmpTimeRangeOption === TimeRangeOption.BetweenDates
  );
  const showEndDate = () => (tmpTimeRangeOption === TimeRangeOption.BetweenDates);

  const now = new Date();

  const canSubmit = () => {
    if (tmpTimeRangeOption === TimeRangeOption.FromDate) {
      return (!!tmpStartDate
        && (
          tmpTimeRangeOption !== timeRangeOption
          || tmpStartDate !== startDate
        )
      );
    }
    if (tmpTimeRangeOption === TimeRangeOption.BetweenDates) {
      return (!!tmpStartDate && !!tmpEndDate
        && (
          tmpTimeRangeOption !== timeRangeOption
          || tmpStartDate !== startDate
          || tmpEndDate !== endDate
        )
      );
    }

    return tmpTimeRangeOption !== timeRangeOption;
  };

  return (
    <>
      <Button onClick={() => setModalOpen(true)}>
        Time Range Options
      </Button>

      <RhinoModal
        title="Select Time Range"
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={canSubmit()}
        onSubmit={() => onSubmit(tmpTimeRangeOption, tmpStartDate, tmpEndDate)}
        onClosed={resetState}
      >
        <div className="d-flex flex-column">
          {Object.values(TimeRangeOption).map((option) => (
            <Label key={option}>
              <input
                type="radio"
                onChange={() => setTmpTimeRangeOption(option)}
                checked={option === tmpTimeRangeOption}
              />
              {' '}
              <span>{option}</span>
            </Label>
          ))}
        </div>
        <div className="d-flex justify-content-between pt-2">
          {showStartDate() && (
            <ReactDatePicker
              showIcon
              wrapperClassName="m-0"
              maxDate={tmpEndDate ?? now}
              placeholderText="Start Date"
              selected={tmpStartDate}
              onChange={(date) => setTmpStartDate(date ?? undefined)}
            />
          )}

          {showEndDate() && (
            <ReactDatePicker
              showIcon
              wrapperClassName="m-0"
              minDate={tmpStartDate}
              maxDate={now}
              placeholderText="End Date"
              selected={tmpEndDate}
              onChange={(date) => setTmpEndDate(date ?? undefined)}
            />
          )}
        </div>
      </RhinoModal>
    </>
  );
}

export default TimeRangeModal;
