import React, { useRef, useState } from 'react';
import { Button } from 'reactstrap';
import useGetMonthlyReports from 'apiHooks/MonthlyReports.Hook';

import type IReport from 'types/IReport';
import type { TypeaheadRef } from 'react-bootstrap-typeahead';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useGetOrganizationRules } from 'apiHooks/WorkFlows.Hook';
import saveReportsAsCSV from 'utils/helpers/saveReportsAsCsv.Helper';
import Loading from 'components/shared/Loading/Loading';
import customFilterBy from 'utils/helpers/customFilterBy.Helper';
import TimeRangeModal, { TimeRangeOption } from './TimeRangeModal';
import Report from './Report';

function MonthlyReport() {
  const [
    timeRangeOption,
    setTimeRangeOption,
  ] = useState<TimeRangeOption>(TimeRangeOption.CurrentMonth);

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const [selectedReportId, setSelectedReportId] = useState<number>();

  const typeaheadRef = useRef<TypeaheadRef>(null);

  const {
    isLoading,
    isError,
    data: reports,
    error,
    refetch,
  } = useGetMonthlyReports(startDate, endDate);

  const {
    isLoading: orgRulesLoading,
    isError: orgRulesAreError,
    data: orgRules,
    error: orgRulesError,
  } = useGetOrganizationRules();

  const onSubmitTimeRangeModal = async (
    newTimeRangeOption: TimeRangeOption,
    newStartDate: Date | undefined,
    newEndDate: Date | undefined,
  ) => {
    setTimeRangeOption(newTimeRangeOption);

    switch (newTimeRangeOption) {
      case TimeRangeOption.CurrentMonth: {
        // setTmpStartDate(undefined);
        setStartDate(undefined);

        // setTmpEndDate(undefined);
        setEndDate(undefined);
        break;
      }
      case TimeRangeOption.FromDate: {
        setStartDate(newStartDate);

        // setTmpEndDate(undefined);
        setEndDate(undefined);
        break;
      }
      case TimeRangeOption.BetweenDates: {
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        break;
      }
      default: {
        break;
      }
    }

    await refetch();
  };

  const makeTitleText = () => (startDate ? (`${startDate.toDateString()} - ${endDate ? endDate.toDateString() : 'Today'}`) : ('This Month'));

  if (isLoading || orgRulesLoading || !reports || !reports[0]) {
    return <Loading />;
  }
  if (isError || orgRulesAreError) {
    return (
      <div>
        {error as string}
        {orgRulesError as string}
      </div>
    );
  }

  const selectedReport = reports.find((report) => report.id === selectedReportId) ?? reports[0];

  const af1800RulesTitleMap: Record<string, string> = orgRules.reduce((o, rule) => ({
    ...o,
    [rule.ruleId]: rule.title,
  }), {});

  return (
    <div className="d-flex bg-gray h-100">
      <div className="d-flex flex-fill flex-column overflow-auto bg-gray px-3 py-2 h-100">

        <h2 className="text-secondary px-3">
          {selectedReport.name}
          {' '}
          Report
          {': '}
          {makeTitleText()}
        </h2>

        {/* Header bar with buttons */}
        <div className="d-flex flex-column w-100 flex-fill">
          <div className="flex-column bg-dark bg-gradient rounded-top p-3 gap-2">

            <div className="d-flex gap-3">

              {/* Report selection typeahead */}
              <Typeahead
                filterBy={customFilterBy<IReport>('name')}
                id="select-report-typeahead"
                ref={typeaheadRef}
                options={reports}
                defaultSelected={[reports[0]]}
                placeholder="Find a section..."
                labelKey="name"
                onChange={(selected) => {
                  const report = selected[0] as IReport;
                  if (!report) return;
                  setSelectedReportId(report.id);
                  typeaheadRef.current?.blur();
                }}
              />

              <TimeRangeModal
                timeRangeOption={timeRangeOption}
                startDate={startDate}
                endDate={endDate}
                onSubmit={onSubmitTimeRangeModal}
              />
              <Button
                onClick={() => (
                  saveReportsAsCSV(
                    reports,
                    'reports',
                  )
                )}
              >
                Download
              </Button>
            </div>
          </div>

          {/* Body containing report sections */}
          <div className="ag-theme-material w-100 h-100">

            <Report report={selectedReport} af1800RulesTitleMap={af1800RulesTitleMap} />

          </div>
        </div>
      </div>
    </div>

  );
}

export default MonthlyReport;
