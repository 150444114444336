const IS_RHINO_APP_US = window.location.host.includes('rhinoapp.us');

const url = IS_RHINO_APP_US ? 'https://sso.rhinoapp.us/' : 'https://sso.rhinoqa.us/';

const KEYCLOAK_CONFIG = {
  url,
  realm: 'artemis',
  clientId: 'rhino',
};

export default KEYCLOAK_CONFIG;
