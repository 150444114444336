import { usePostNewVehicleMxLti } from 'apiHooks/Vehicles.Hook';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Button } from 'reactstrap';

export default function CreateLtiModal({ regNumber }: { regNumber: string; }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [date, setDate] = useState<Date | null>(null);

  const postNewLti = usePostNewVehicleMxLti(regNumber);

  const handleSubmit = async () => {
    if (!date) return;
    await postNewLti(date)
      .then(() => {
        setModalOpen(false);
        setDate(null);
      })
      .catch(() => {});
  };

  return (
    <>
      <Button
        type="button"
        onClick={() => setModalOpen(true)}
      >
        Schedule LTI
      </Button>

      <RhinoModal
        title={`Schedule LTI for ${regNumber}`}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        onSubmit={handleSubmit}
        canSubmit={!!date}
      >
        <div className="d-grid text-secondary fw-bold">
          Date
          <ReactDatePicker
            showIcon
            selected={date}
            onChange={setDate}
          />
        </div>
      </RhinoModal>
    </>
  );
}
