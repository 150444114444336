/* eslint-disable react/jsx-props-no-spreading */
import type { SetStateAction } from 'react';
import React from 'react';
import type {
  ButtonProps,
  ModalBodyProps,
  ModalFooterProps,
  ModalHeaderProps,
  ModalProps,
} from 'reactstrap';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import SubmitButton from '../SubmitButton';

function RhinoModalContainer(props: ModalProps) {
  const { children } = props;
  return (
    <Modal {...props}>
      {children}
    </Modal>
  );
}

interface RhinoModalHeaderProps extends ModalHeaderProps {
  toggle: () => void,
}

function RhinoModalHeader(props: RhinoModalHeaderProps) {
  const { children, className } = props;
  return (
    <ModalHeader
      {...props}
      className={`bg-secondary text-light user-select-none ${className}`}
    >
      {children}
    </ModalHeader>
  );
}

interface RhinoModalBodyProps extends ModalBodyProps {}

function RhinoModalBody(props: RhinoModalBodyProps) {
  return <ModalBody {...props} />;
}

export interface AdditionalButtonProps extends ButtonProps {
  title: string;
}
interface RhinoModalFooterProps extends ModalFooterProps {
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  canSubmit?: boolean | undefined;
  onSubmit?: (() => Promise<void | void[]>) | undefined;
  submitButtonText?: string | undefined;
  additionalButtons?: AdditionalButtonProps[] | undefined;
}

function RhinoModalFooter(props: RhinoModalFooterProps) {
  const {
    setIsOpen,
    canSubmit,
    onSubmit,
    submitButtonText,
    additionalButtons,
    children,
  } = props;

  const handleSubmit = onSubmit ? (
    () => (
      onSubmit()
        .then(() => setIsOpen(false))
        .catch(() => {})
    )
  ) : (
    undefined
  );

  return (
    <ModalFooter className="justify-content-between">
      {children || (
        <>
          <Button
            title="Cancel"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
          {
            additionalButtons?.map((buttonProps) => (
              <Button key={buttonProps.title} {...buttonProps}>
                {buttonProps.title}
              </Button>
            ))
          }
          {handleSubmit && (
            <SubmitButton
              canSubmit={canSubmit ?? true}
              onSubmit={handleSubmit}
            >
              {submitButtonText}
            </SubmitButton>
          )}
        </>
      )}
    </ModalFooter>
  );
}

interface RhinoModalProps extends ModalProps {
  title: string | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  canSubmit?: boolean;
  onSubmit?: (() => Promise<void | void[]>) | undefined;
  headerClassName?: string | undefined;
  bodyClassName?: string | undefined;
  footerClassName?: string | undefined;
  submitButtonText?: string | undefined;
  additionalButtons?: AdditionalButtonProps[];
}

/**
 * All-in-one Modal component
 */
function RhinoModal(props: RhinoModalProps) {
  const {
    title,
    isOpen,
    setIsOpen,
    canSubmit,
    onSubmit,
    headerClassName,
    bodyClassName,
    footerClassName,
    submitButtonText,
    additionalButtons,
    children,
    ...modalProps
  } = props;

  const toggle = () => setIsOpen((state) => !state);

  return (
    <RhinoModalContainer
      {...modalProps}
      isOpen={isOpen}
      toggle={toggle}
    >
      <RhinoModalHeader
        toggle={toggle}
        className={headerClassName}
      >
        {title}
      </RhinoModalHeader>

      <RhinoModalBody className={bodyClassName}>
        {children}
      </RhinoModalBody>

      <RhinoModalFooter
        className={footerClassName}
        setIsOpen={setIsOpen}
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        submitButtonText={submitButtonText}
        additionalButtons={additionalButtons}
      />

    </RhinoModalContainer>
  );
}

/* eslint-enable react/jsx-props-no-spreading */

export default RhinoModal;

export {
  RhinoModalContainer,
  RhinoModalHeader,
  RhinoModalBody,
};
