import React from 'react';
import { useAtomValue } from 'jotai';
import {
  Button,
  Modal,
  ModalBody,
  Spinner,
} from 'reactstrap';
import format from 'date-fns/format';

import { useGetHistoricAF1800Reports, useAf1800ReportPdf } from 'apiHooks/Af1800Reports.Hook';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import PdfViewer from 'components/shared/PdfViewer/PdfViewer';
import emptyAf1800PdfAtom from 'store/EmptyAf1800Pdf.store';
import { RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';
import { populateAndMergePdfDocuments } from './Af1800PdfFormUtils';

type RhinoAf1800FormStage = 'DATE_SELECTION' | 'VIEW_PDF';

interface IDateSelectionStage {
  setStage: (stage: RhinoAf1800FormStage) => void
  setAF1800FormDate: (formDate: Date) => void,
}

interface IReportViewStage {
  af1800FormDate: Date | undefined,
}

// *************************************************************************

/**
 * Initial form to allow user to select date of export
 * @returns
 */
function DateSelectionForm({ setStage, setAF1800FormDate } : IDateSelectionStage) {
  const [dateSelected, setDateSelected] = React.useState<Date>(new Date());
  const beginDownload = () => {
    setAF1800FormDate(dateSelected);
    setStage('VIEW_PDF');
  };

  const updateDateSelected = (newDate: Date | null) => {
    if (newDate) {
      setDateSelected(newDate);
      setAF1800FormDate(newDate);
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center bg-gray-light">
      <div className="d-flex align-content-center justify-content-center py-4">
        <span className="mr-2 text-nowrap text-body">Export for the month of:</span>
        <DatePicker
          selected={dateSelected}
          showMonthYearPicker
          dateFormat="MM/yyyy"
          onChange={updateDateSelected}
          className="shadow"
        />
      </div>
      <div className="p-4">
        <Button onClick={beginDownload}>Create combined AF1800 Report</Button>
      </div>
    </div>
  );
}

/**
 *
 * @returns
 */
function DownloadPDFForm({ af1800FormDate } : IReportViewStage) {
  const [pdfData, setPdfData] = React.useState<Uint8Array | undefined>();
  const year = af1800FormDate?.getFullYear() || 0;
  const month = (af1800FormDate?.getMonth() || 0) + 1;
  const { isLoading, isError, data } = useGetHistoricAF1800Reports(year, month);
  useAf1800ReportPdf(true);
  const pdfFile = useAtomValue(emptyAf1800PdfAtom);

  React.useEffect(() => {
    if (data && pdfFile) {
      populateAndMergePdfDocuments(data, pdfFile)
        .then((pdf) => pdf && setPdfData(pdf))
        .catch(() => {});
    }
  }, [data, pdfFile]);

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return (<h4 className="p-4 text-center bg-gray-light">No report information for this month.</h4>);
  }

  return <PdfViewer pdfData={pdfData} filename={`${year}-${month}-af1800`} />;
}

/**
 *
 * @returns
 */
function AF1800HistoricReportsModal() {
  const [af1800FormStage, setAf1800FormStage] = React.useState<RhinoAf1800FormStage>('DATE_SELECTION');
  const [modal, setModal] = React.useState(false);
  const [monthYear, setMonthYear] = React.useState<Date | undefined>();

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
    setAf1800FormStage('DATE_SELECTION');
    setMonthYear(undefined);
  };

  return (
    <div>
      <Button onClick={openModal}>Monthly AF1800 Report</Button>
      <Modal className="pdf-modal" isOpen={modal} toggle={closeModal}>
        <RhinoModalHeader toggle={closeModal}>
          {
            af1800FormStage === 'VIEW_PDF' && monthYear
            && (
              `Combined AF1800 reports for ${format(monthYear, 'MMMM yyyy')}`
            )
          }
        </RhinoModalHeader>
        <ModalBody className="p-0">
          { af1800FormStage === 'DATE_SELECTION'
            ? (
              <DateSelectionForm
                setStage={setAf1800FormStage}
                setAF1800FormDate={setMonthYear}
              />
            ) : '' }
          {af1800FormStage === 'VIEW_PDF' ? <DownloadPDFForm af1800FormDate={monthYear} /> : '' }
        </ModalBody>
      </Modal>
    </div>
  );
}

export default AF1800HistoricReportsModal;
